import { useParams } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'

import PartCompareCell from "src/components/PartCompareCell"


const PartComparePage = () => {
  const { basePartNumber, baseVersion, incomingPartNumber, incomingVersion } = useParams()
  return (
    <>
      <MetaTags title={"#" + basePartNumber} description="Part compare page" />
      <div className='max-w-[1200px] mx-auto px-6 pb-12 pt-6'>
        <PartCompareCell
          basePartNumber={basePartNumber!}
          baseVersion={baseVersion!}
          incomingPartNumber={incomingPartNumber!}
          incomingVersion={incomingVersion!} />
      </div>
    </>
  )
}

export default PartComparePage
