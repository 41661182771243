import type { SuperUserOrganizationsQuery } from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'

import { navigate, routes } from '@redwoodjs/router';
import { useState, useEffect } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Button, Select, Dialog, Heading, Flex, TextField, Text, Checkbox } from '@radix-ui/themes';
import { PlusIcon, BoltIcon } from '@heroicons/react/24/solid';

export const QUERY = gql`
  query SuperUserOrganizationsQuery {
    organizations {
      id
      name
    }
  }
`

export const CREATE_ORG = gql`
  mutation CreateOrganizationMutation($id: ID!, $name: String!, $isDemo: Boolean, $isFreemium: Boolean) {
    createOrganization(input: { id: $id, name: $name, isDemo: $isDemo, isFreemium: $isFreemium }) {
      id
      name
    }
  }
`

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>


export const Failure = ({
  error,
}: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

// Define a new type for your cell's success props
interface SuperUserSwitcherSuccessProps extends CellSuccessProps<SuperUserOrganizationsQuery> {
  organizationId?: string;
  onChange: (value: string) => void;
}

const CreateOrganizationDialog: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [isFreemium, setIsFreemium] = useState(false);
  const [newOrgId, setNewOrgId] = useState('');
  const [newOrgName, setNewOrgName] = useState('');
  const [newOrgIdError, setNewOrgIdError] = useState('');
  const [orgFieldError, setOrgFieldError] = useState('');
  const [emailFieldError, setEmailFieldError] = useState('');
  const [CreateOrganizationMutation] = useMutation(CREATE_ORG);
  const createOrganization = (e: React.FormEvent<HTMLFormElement>) => {
    // The browser is handling validation via required attribute, and
    // the onInvalid event handler so we can assume everything is valid
    // here.
    e.preventDefault();

    const orgIdRegex = /^[a-z0-9-]+$/;
    if (!orgIdRegex.test(newOrgId)) {
      setNewOrgIdError('Organization ID must only contain letters, numbers, and dashes');

      // this is only because the error message shows up in
      // the placeholder. If I was less lazy I would add an
      // additional div to show the error message.
      setNewOrgId('');
      return;
    }

    // wait for the org to be created before navigating
    (async () => {
      await CreateOrganizationMutation({
        variables: {
          id: newOrgId,
          name: newOrgName,
          isDemo,
          isFreemium,
        },
        refetchQueries: [QUERY],
        awaitRefetchQueries: true
      });
      setDialogOpen(false);
      navigate(routes.superUserOrg({ orgId: newOrgId }));
    })();
  }

  useEffect(() => {
    if (newOrgName) {
      setOrgFieldError('');
    }
    if (newOrgId) {
      setNewOrgIdError('');
    }
  }, [newOrgName, newOrgId])

  return <Dialog.Root open={dialogOpen}>
    <Dialog.Trigger>
      <Button variant="surface" color="gray" onClick={() => setDialogOpen(true)}>
        <PlusIcon className="h-4 w-4 text-gray"/>Create New Organization
      </Button>
    </Dialog.Trigger>

    <Dialog.Content style={{ maxWidth: 600 }}>
      <Dialog.Title>Create new organization</Dialog.Title>
      <form onSubmit={createOrganization}>
        <Flex direction="column" gap="5">
        <div>
            <Text as="div" size="2" mb="3" weight="bold">
              Organization Name
            </Text>
            <TextField.Input
              required
              value={newOrgName}
              onInvalid={e => setOrgFieldError('Organization name is required')}
              onChange={e => setNewOrgName(e.target.value)}
              placeholder={orgFieldError || "Enter organization name"}
              color={orgFieldError ? 'red' : undefined}
              variant={orgFieldError ? 'soft' : undefined}
              autoComplete="off"
            />
          </div>
          <div>
            <Text as="div" size="2" mb="3" weight="bold">
              Identifier
            </Text>
            <Text as="div" size="1" mb="3">
              This is the unique identifier for the organization. It will be used in the URL that organization users
              enter access the application. This should be unique and only contain letters, numbers, and dashes.
            </Text>
            <TextField.Input
              required
              value={newOrgId}
              onInvalid={e => setNewOrgIdError('Organization slug is required')}
              onChange={e => setNewOrgId(e.target.value)}
              placeholder={newOrgIdError || "Example: my-org-name"}
              color={newOrgIdError ? 'red' : undefined}
              variant={newOrgIdError ? 'soft' : undefined}
              autoComplete="off"
            />
          </div>
          <div>
            <Text as="div" size="2" mb="1" weight="bold">
              Organization Type
            </Text>
            <Flex align="center" gap="2" className='pt-2 pb-2'>
              <Checkbox id="c1" checked={isDemo} onCheckedChange={
                (checked) => {
                  // handle silly "indeterminate" state
                  // which isn't possible in this case
                  if (typeof checked === 'string') return
                  setIsDemo(checked)
                }
              } />
              <label htmlFor="c1" className='cursor-pointer'>
                <Text>This is a <strong>demo</strong> organization</Text>
              </label>
            </Flex>
            <Flex align="center" gap="2" className='pt-2 pb-2'>
              <Checkbox id="c2" checked={isFreemium} onCheckedChange={
                (checked) => {
                  // handle silly "indeterminate" state
                  // which isn't possible in this case
                  if (typeof checked === 'string') return
                  setIsFreemium(checked)
                }
              } />
              <label htmlFor="c2" className='cursor-pointer'>
                <Text>This is a <strong>freemium</strong> organization</Text>
              </label>
            </Flex>
          </div>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
          </Dialog.Close>
          <Button type='submit' className='cursor-pointer'>Create</Button>
        </Flex>
      </form>
    </Dialog.Content>
  </Dialog.Root>
}

export const Success = ({
  organizations,
  onChange,
  organizationId,
  ...rest
}: SuperUserSwitcherSuccessProps) => {
  const value = organizationId ? organizationId : undefined;

  const orgOptions = organizations.map(org => <Select.Item key={org.id} value={org.id}>{org.id}</Select.Item>);



  return <div className='mb-4 flex justify-between p-4 bg-yellow-200'>
      <div className='flex gap-4 items-center'>
        <BoltIcon className="h-6 w-6 text-gray-900"/>
        <Heading size="5" weight="medium">Super User</Heading>
        <Select.Root value={value} onValueChange={onChange}>
          <Select.Trigger placeholder='Choose organization'>
            <ArrowDownTrayIcon />
          </Select.Trigger>
          <Select.Content>
            <Select.Group>
              {orgOptions}
            </Select.Group>
          </Select.Content>
        </Select.Root>
      </div>
      <CreateOrganizationDialog />
  </div>
}
