import {
  Part,
  Dependency,
  Source,
  Artifact,
  S3FileRef,
  PartProto,
  Organization,
  Maybe,
  Distributor,
  DependencySection,
  PartCategory,
  DependencyDisplayFragment
} from 'types/graphql'
import { ResolvedMetadata, resolveMetadata } from 'src/lib/metadata'
import { displayUnits } from '../Dependencies'
import type { ChangeType as MetadataChangeType } from '../Metadata'
import { getThumbnail } from '../Artifacts/Artifacts'
import keyBy from 'lodash.keyby'

export type PartArtifact = Pick<Artifact, 'id' | 'isThumbnail' | 'filename' | 'fileId'> & {
  file: Pick<S3FileRef, 'url' | 'inlineUrl' | 'size' | 'contentType' | 'timestamp'>
}

type WholeChange = 'added' | 'removed'

export type EditMode = 'locked' | 'edit'
type DistributorChangeInput = Omit<Distributor,
  '__typename'>
type SourceChangeInput = Omit<Source,
  'distributor' | '__typename'> & {
    distributor?: DistributorChangeInput | null
  }
type SourceInputChanges = Pick<SourceChangeInput,
  'priority' |
  'distributor' |
  'distributorId' |
  'id' |
  'distributorSku' |
  'price' |
  'priceCurrency' |
  'leadTimeDays' |
  'perQuantity' |
  'perQuantityUnit' |
  'stock' |
  'comment' |
  'url'
>

export type SourceFieldChange = 'added' | 'removed' | 'modified' | null

type ChangeFieldName = keyof Omit<SourceInputChanges, 'priority' | 'distributor' | 'id'>
type FieldChange = {
  [Key in ChangeFieldName]: SourceFieldChange
}
export type SourceChange = {
  priority: number
  wholeSource?: WholeChange
  fields?: FieldChange
};

export type PartChangePart = Pick<Part,
  'partNumber'
  | 'name'
  | 'version'
  | 'lifeCycle'
  | 'isRoot'
  | 'isOffTheShelf'
  | 'summary'
  | 'metadata'
  | 'changeMessage'
  | 'transitionPlan'
  | 'nodeType'
  | 'cadRev'> & {
  dependencies: DependencyDisplayFragment[]
  sources: SourceInputChanges[]
  artifacts: PartArtifact[]
  // optional because it is only used when editing
  ancestors?: {
    part: Pick<Part, 'partNumber'>
  }[]
}

type IncomingPart = PartChangePart & {
  changeMessage?: Part['changeMessage']
  transitionPlan?: Part['transitionPlan']
  updateToPublishId?: Part['updateToPublishId']
}

type PartDiffInput = IncomingPart & {
  // proto: Pick<PartProto, 'partNumber'> & {
  //   category: Pick<PartCategory, 'name'>
  // }
  updateTo?: Maybe<PartChangePart>
}

// type PartWithUpdatePart = PartChangePart & {
//   updateTo?: Maybe<PartChangePart>
// }

export type PartUpdate = {
  // proto: {}
  headPart: PartChangePart
  incomingPart?: IncomingPart
}

type OrgForChanges = Pick<Organization, 'metadataSchema'>
type MatchType = 'nothing' | 'edit' | 'deletion'
type OmissionType = 'addition'
export type ArtifactChangeType = MatchType | OmissionType
export type DependencyChange = {
  partNumber: string
  wholeDependency?: WholeChange
  originalSection?: DependencySection
  version?: boolean
  versionNeedsRebase?: boolean
  versionRange?: boolean
  quantity?: boolean
  referenceDesignator?: boolean
  propertyChange?: boolean
}
export type PartDiffSummary = {
  incomingPart: IncomingPart
  headPart?: Maybe<PartChangePart>
  type: 'update' | 'none' | 'create'
  partNumber: string
  onlyBomChanges: boolean
  fields: {
    metadata: {
      hasChange: boolean
      change?: (ResolvedMetadata & { changeType?: MetadataChangeType })[]
    }
    name : {
      hasChange: boolean
    }
    lifeCycle : {
      hasChange: boolean
    }
    summary : {
      hasChange: boolean
    }
    cadRev : {
      hasChange: boolean
    }
    nodeType: {
      hasChange: boolean
    }
    artifacts: {
      hasChange: boolean
      change?: {
        changeType: ArtifactChangeType
        artifact: PartArtifact
        concreteIndex: number
      }[]
    }
    thumbnail: {
      hasChange: boolean,
    }
    isOffTheShelf: {
      hasChange: boolean
    }
    isRoot: {
      hasChange: boolean
    }
    sources: {
      hasChange: boolean
      change: SourceChange[]
    }
    dependencies: {
      hasChange: boolean
      change: DependencyChange[]
    }
  }
}

export const calculateChange = (currentOrg: OrgForChanges, incomingPart: PartDiffInput): PartDiffSummary => {
  const diffSummary: Omit<PartDiffSummary, 'type'> = {
    incomingPart,
    partNumber: incomingPart.partNumber,
    onlyBomChanges: false,
    // proto: incomingPart.proto,
    fields: {
      metadata: {
        hasChange: false
      },
      name : {
        hasChange: false
      },
      lifeCycle: {
        hasChange: false
      },
      summary : {
        hasChange: false
      },
      nodeType: {
        hasChange: false
      },
      cadRev : {
        hasChange: false
      },
      artifacts: {
        hasChange: false
      },
      thumbnail: {
        hasChange: false
      },
      sources: {
        hasChange: false,
        change: []
      },
      dependencies: {
        hasChange: false,
        change: []
      },
      isRoot: {
        hasChange: false
      },
      isOffTheShelf: {
        hasChange: false
      }
    }
  }

  const headPart = incomingPart.updateTo
  if (!headPart) {
    return {
      ...diffSummary,
      type: 'create'
    }
  }

  // Overview
  diffSummary.fields.name.hasChange = headPart.name !== incomingPart.name
  diffSummary.fields.lifeCycle.hasChange = headPart.lifeCycle !== incomingPart.lifeCycle
  diffSummary.fields.summary.hasChange = headPart.summary !== incomingPart.summary
  diffSummary.fields.cadRev.hasChange = headPart.cadRev !== incomingPart.cadRev
  diffSummary.fields.nodeType.hasChange = headPart.nodeType !== incomingPart.nodeType
  diffSummary.fields.isOffTheShelf.hasChange = headPart.isOffTheShelf !== incomingPart.isOffTheShelf
  diffSummary.fields.isRoot.hasChange = headPart.isRoot !== incomingPart.isRoot

  const resolvedHeadMetadata = headPart.metadata ? resolveMetadata(currentOrg.metadataSchema, headPart.metadata as any) : undefined
  const incomingMetadata = resolveMetadata(currentOrg.metadataSchema, incomingPart.metadata as any);

  const metadataWithChange = resolvedHeadMetadata ?
    resolvedHeadMetadata.map((old) => {
      const current = incomingMetadata.find(m => m.displayName === old.displayName)

      const changeType = !current ? 'Remove'
        : JSON.stringify(current.entry) !== JSON.stringify(old.entry) ? 'Change' : undefined

      const ref = current ?? old;

      return {
        ...ref,
        changeType: changeType as MetadataChangeType
      }
    }).concat(
      incomingMetadata.filter(m => !resolvedHeadMetadata.find(old => old.displayName === m.displayName)).map(additions => ({
        ...additions,
        changeType: 'Add' as MetadataChangeType
      }))
    ) :
    incomingMetadata.map(m => ({...m, changeType: undefined as unknown as MetadataChangeType}))

  diffSummary.fields.metadata.hasChange = metadataWithChange.some(change => change.changeType)
  diffSummary.fields.metadata.change = metadataWithChange

  // Artifacts
  let concreteIndex = 0;
  const matches = headPart.artifacts.map(oldArtifact => {
    const nameMatch = incomingPart.artifacts.find(newArtifact => newArtifact.filename === oldArtifact.filename);

    const changeType = (!nameMatch ? 'deletion'
      : (nameMatch.fileId !== oldArtifact.fileId || nameMatch.isThumbnail !== oldArtifact.isThumbnail) ? 'edit'
        : 'nothing') as MatchType as ArtifactChangeType

    if (changeType !== 'deletion') {
      concreteIndex += 1;
    }

    return {
      changeType,
      concreteIndex,
      artifact: nameMatch ?? oldArtifact
    }
  })

  const additions = incomingPart.artifacts.filter(newArtifact => {
    const match = headPart.artifacts.find(oldArtifact => oldArtifact.filename === newArtifact.filename)
    return !match;
  }).map(newArtifact => ({
    changeType: 'addition' as OmissionType as ArtifactChangeType,
    artifact: newArtifact,
    concreteIndex: concreteIndex++
  }))

  const artifactChangeInfo = matches.concat(additions)
  const hasArtifactsChange = artifactChangeInfo.some(change => change.changeType !== 'nothing')
  diffSummary.fields.artifacts.hasChange = hasArtifactsChange
  diffSummary.fields.artifacts.change = artifactChangeInfo

  if (hasArtifactsChange) {
    const headThumbnail = getThumbnail(headPart.artifacts)
    const incomingThumbnail = getThumbnail(incomingPart.artifacts)
    if (headThumbnail?.fileId !== incomingThumbnail?.fileId) {
      diffSummary.fields.thumbnail.hasChange = true;
    }
  }

  const findDependencyChanges = (headDependencies: DependencyDisplayFragment[], incomingDependencies: DependencyDisplayFragment[]) => {
    const removedAndModified = headDependencies.reduce((memo, head) => {
      const changes: DependencyChange = {
        partNumber: head.to.partNumber,
        originalSection: head.section,
      }
      const incoming = incomingDependencies.find(incoming => incoming.to.partNumber === head.to.partNumber)
      if (!incoming) {
        return [...memo, { ...changes, wholeDependency: 'removed' }] as DependencyChange[]
      }
      const versionChange = incoming.to.publishId !== head.to.publishId;
      const versionNeedsRebaseChange = incoming.to.version === head.to.version && incoming.to.publishId !== head.to.publishId;
      const versionRangeChange = incoming.toVersionRange !== head.toVersionRange;
      const quantityChange = incoming.quantity !== head.quantity || displayUnits(incoming.units) !== displayUnits(head.units);
      const referenceDesignatorChange = Boolean(incoming.referenceDesignator || head.referenceDesignator) && incoming.referenceDesignator !== head.referenceDesignator;

      const fullChanges = {
        ...changes,
        version: versionChange,
        // used in the UI to disambiguate when version looks the same
        versionNeedsRebase: versionNeedsRebaseChange,
        versionRange: versionRangeChange,
        quantity: quantityChange,
        referenceDesignator: referenceDesignatorChange,
        propertyChange: versionChange || versionNeedsRebaseChange || versionRangeChange || quantityChange || referenceDesignatorChange
      }
      return [...memo, fullChanges]
    }, [] as DependencyChange[])
    return incomingDependencies.reduce((memo, incoming) => {
      const changes: DependencyChange = {
        partNumber: incoming.to.partNumber,
      }
      const head = headDependencies.find(head => head.to.partNumber === incoming.to.partNumber)
      if (!head) {
        return [...memo, { ...changes, wholeDependency: 'added' } as DependencyChange]
      }
      return memo
    }, removedAndModified)
  }

  const dependencyChangeInfo = findDependencyChanges(headPart.dependencies, incomingPart.dependencies)

  const dependenciesHaveChange = dependencyChangeInfo.some(changeInfo => {
    return changeInfo.quantity || changeInfo.version || changeInfo.wholeDependency || changeInfo.versionRange || changeInfo.referenceDesignator
  })

  diffSummary.fields.dependencies.hasChange = dependenciesHaveChange
  diffSummary.fields.dependencies.change = dependencyChangeInfo

  type PossibleTypes = string | number | undefined | null
  const compareSourceField = (head: PossibleTypes, incoming: PossibleTypes) => {
    if (!head && incoming) return 'added'
    if (head && !incoming) return 'removed'
    if (!head && !incoming) return null
    if (head !== incoming) return 'modified'
    return null
  }
  const removedAndModifiedSources: SourceChange[] = headPart.sources.reduce((memo, head) => {
    const incoming = incomingPart.sources.find(incoming => incoming.priority === head.priority)
    if (!incoming) {
      return [...memo, { priority: head.priority, wholeSource: 'removed' }]
    }

    const fullChanges: SourceChange = {
      priority: head.priority,
      fields: {
        distributorId: compareSourceField(head.distributorId, incoming.distributorId),
        distributorSku: compareSourceField(head.distributorSku, incoming.distributorSku),
        price: compareSourceField(head.price, incoming.price),
        priceCurrency: compareSourceField(head.priceCurrency, incoming.priceCurrency),
        leadTimeDays: compareSourceField(head.leadTimeDays, incoming.leadTimeDays),
        perQuantity: compareSourceField(head.perQuantity, incoming.perQuantity),
        perQuantityUnit: compareSourceField(head.perQuantityUnit, incoming.perQuantityUnit),
        stock: compareSourceField(head.stock, incoming.stock),
        comment: compareSourceField(head.comment, incoming.comment),
        url: compareSourceField(head.url, incoming.url),
      }
    }
    return [...memo, fullChanges]
  }, [] as SourceChange[])

  // added goes last
  const sourceChanges = incomingPart.sources.reduce((memo, incoming) => {
    const head = headPart.sources.find(head => head.priority === incoming.priority)
    if (!head) {
      return [...memo, { priority: incoming.priority, wholeSource: 'added' } as SourceChange]
    }
    return memo
  }, removedAndModifiedSources)

  const sourcesHaveChange = sourceChanges.some(changeInfo => {
    return changeInfo.wholeSource ||
      changeInfo.fields?.distributorId ||
      changeInfo.fields?.distributorSku ||
      changeInfo.fields?.price ||
      changeInfo.fields?.priceCurrency ||
      changeInfo.fields?.leadTimeDays ||
      changeInfo.fields?.perQuantity ||
      changeInfo.fields?.perQuantityUnit ||
      changeInfo.fields?.stock
  })

  diffSummary.fields.sources.hasChange = sourcesHaveChange
  diffSummary.fields.sources.change = sourceChanges


  const partChanges = Object.entries(diffSummary.fields).some(([fieldName, field]) => {
    return fieldName !== 'dependencies' && field.hasChange
  })
  const anyChanges = partChanges || diffSummary.fields.dependencies.hasChange

  return {
    ...diffSummary,
    headPart: incomingPart.updateTo,
    type: anyChanges ? 'update' : 'none',
    onlyBomChanges: !partChanges && diffSummary.fields.dependencies.hasChange
  }
}

const calculateAllChanges: (currentOrg: OrgForChanges, parts: PartDiffInput[]) => Record<string, PartDiffSummary> = (currentOrg, parts) => {
  const allChanges = parts.map(incomingPart => calculateChange(currentOrg, incomingPart))
  return keyBy(allChanges, 'partNumber')
}

export default calculateAllChanges
