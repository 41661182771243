import { MetaTags } from '@redwoodjs/web'
import { useParams } from '@redwoodjs/router'
import TasksCell from 'src/components/TasksCell'

const TasksPage = () => {
  return (
    <>
      <MetaTags title="Tasks" description="Tasks page" />
      <div className="min-h-full flex">
        <TasksCell />
      </div>
    </>
  )
}

export default TasksPage