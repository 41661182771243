import type { Task, TaskStatus, SingleColumnTasksQuery, SingleColumnTasksQueryVariables, CreateTaskInput, TaskFilters } from 'types/graphql'
import { Link, navigate } from '@redwoodjs/router'
import { useRightPanel } from 'src/components/RightPanel/RightPanel'
import { PlusIcon } from '@heroicons/react/20/solid'
import { Tooltip2 } from "../Tooltip"
import { CellSuccessProps, useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import GenericFailure from '../Failure/Failure'
import EmptyState from '../EmptyState/EmptyState'
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box'
import { extractClosestEdge, Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { attachClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'

const TASK_DRAG_TYPE = 'task-drag-data' as const

interface TaskDragData {
  type: typeof TASK_DRAG_TYPE
  id: number
  index: number
}

function isTaskDragData(data: unknown): data is TaskDragData & Record<string, unknown> {
  return (
    typeof data === 'object' &&
    data !== null &&
    'type' in data &&
    data.type === TASK_DRAG_TYPE &&
    'id' in data &&
    typeof (data as TaskDragData).id === 'number' &&
    'index' in data &&
    typeof (data as TaskDragData).index === 'number'
  )
}

export const QUERY = gql`
  query SingleColumnTasksQuery($filters: TaskFilters) {
    tasks(filters: $filters) {
      id
      number
      title
      status
      priority
      body
      createdAt
      updatedAt
      assignedTo {
        id
        name
      }
      comments {
        id
      }
    }
  }
`

const CREATE_TASK_MUTATION = gql`
  mutation SingleColCreateTaskMutation($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
      number
      title
      status
    }
  }
`


export const INSERT_TASK_BEFORE_MUTATION = gql`
  mutation InsertTaskBeforeMutation($input: InsertTaskBeforeInput!) {
    insertTaskBefore(input: $input) {
      id
      number
      status
    }
  }
`

export const INSERT_TASK_AFTER_MUTATION = gql`
  mutation InsertTaskAfterMutation($input: InsertTaskAfterInput!) {
    insertTaskAfter(input: $input) {
      id
      number
      status
    }
  }
`


interface TaskCardProps {
  task: {
    number: number
    title: string
    status: TaskStatus
    assignedTo?: {
      id: string
      name: string
    } | null
    comments: {
      id: string
    }[]
  }
  index: number
  viewOnly?: boolean
}

const TaskCard = ({ task, index, viewOnly }: TaskCardProps) => {
  const { panelRoute, routeName, params } = useRightPanel()
  const ref = useRef(null)
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null)

  useEffect(() => {
    const el = ref.current
    if (el && !viewOnly) {
      const data: TaskDragData & Record<string, unknown> = {
        type: TASK_DRAG_TYPE,
        id: task.number,
        index
      }

      return combine(
        draggable({
          element: el,
          getInitialData: () => data,
        }),
        dropTargetForElements({
          element: el,
          getIsSticky: () => true,
          canDrop: ({ source }) => {
            const sourceData = isTaskDragData(source.data)
            return sourceData && sourceData.index !== index
          },
          getData: ({ input }) => {
            return attachClosestEdge(data, {
              element: el,
              input,
              allowedEdges: ['top', 'bottom'],
            })
          },
          onDrag: ({ self, source }) => {
            if (!isTaskDragData(source.data)) {
              return
            }

            if (source.element === el) {
              setClosestEdge(null)
              return
            }

            const edge = extractClosestEdge(self.data)
            const sourceIndex = source.data.index

            const isItemBeforeSource = index === sourceIndex - 1
            const isItemAfterSource = index === sourceIndex + 1
            const isDropIndicatorHidden =
              (isItemBeforeSource && edge === 'bottom') ||
              (isItemAfterSource && edge === 'top')

            setClosestEdge(isDropIndicatorHidden ? null : edge)
          },
          onDragLeave: () => setClosestEdge(null),
          onDrop: () => setClosestEdge(null),
        })
      )
    }
  }, [ref, task.number, index, viewOnly])

  const isActive = routeName === 'task' && params?.taskNumber === task.number

  const getStatusDisplayName = (status: Task['status']) => {
    switch (status) {
      case 'NotStarted':
        return 'Not Started'
      case 'InProgress':
        return 'In Progress'
      case 'WontDo':
        return "Won't Do"
      default:
        return status
    }
  }

  const className = classNames(
    `rounded-lg relative blockrounded-lg mb-[14px]
     bg-white border transition-colors duration-200`,
    {
      'border-blue-500 ring-1 ring-blue-500': isActive,
      'border-gray-200 hover:border-blue-500': !isActive,
      // 'bg-red-400': dragging
    })

  return (
    <div
      ref={ref}
      className={className}>
      <Link
        className="block p-4"
        draggable="false"
        to={panelRoute('task', { taskNumber: task.number })}
      >
        <div className="flex justify-between mb-2">
          <p className="text-md font-semibold text-gray-900">
            #T-{task.number} - {task.title}
          </p>
          <span className="text-sm text-gray-600">
            {getStatusDisplayName(task.status)}
          </span>
        </div>
        <p className="text-sm text-gray-600">
          {task.assignedTo ? `Assigned to ${task.assignedTo.name}` : 'Unassigned'}
        </p>
        {task.comments.length > 0 && (
          <p className="text-xs text-gray-500 mt-2">
            {task.comments.length} comment{task.comments.length === 1 ? '' : 's'}
          </p>
        )}
      </Link>
      {closestEdge && <DropIndicator edge={closestEdge} gap="16px" />}
    </div>
  )
}

// export const Loading = () => <div>Loading...</div>


export const Failure = GenericFailure

interface SuccessProps extends CellSuccessProps<SingleColumnTasksQuery, SingleColumnTasksQueryVariables> {
  newTask?: Partial<CreateTaskInput>
  viewOnly?: boolean
}

export const Success = ({ tasks, newTask, viewOnly, filters }: SuccessProps) => {
  const [createTask, { loading: createLoading }] = useMutation(CREATE_TASK_MUTATION)
  const [insertBefore] = useMutation(INSERT_TASK_BEFORE_MUTATION)
  const [insertAfter] = useMutation(INSERT_TASK_AFTER_MUTATION)
  const { panelRoute } = useRightPanel()
  const [clientTasks, setClientTasks] = useState<(typeof tasks)>([])

  // Update and sort client tasks whenever server tasks change
  useEffect(() => {
    const newClientTasks = [...tasks].sort((a, b) => a.priority - b.priority)
    setClientTasks(newClientTasks)
  }, [tasks])

  // Monitor for drops to reorder tasks
  useEffect(() => {
    return monitorForElements({
      canMonitor: ({ source }) => {
        return isTaskDragData(source.data)
      },
      onDrop: async ({ location, source }) => {
        const target = location.current.dropTargets[0]
        if (!target) return

        if (!isTaskDragData(source.data)) return

        const sourceIndex = source.data.index
        const sourceTaskNumber = source.data.id

        const targetData = target.data as Record<string | symbol, unknown>
        const edge = extractClosestEdge(targetData)

        if (!isTaskDragData(targetData)) return

        const targetTask = clientTasks[targetData.index]
        if (!targetTask) return

        // Save current state for rollback
        const previousTasks = [...clientTasks]

        // Optimistically update the UI
        setClientTasks(prevTasks => {
          const newTasks = [...prevTasks]
          const [movedTask] = newTasks.splice(sourceIndex, 1)

          const destinationIndex = edge === 'top'
            ? targetData.index
            : targetData.index + 1

          const adjustedDestination = sourceIndex < targetData.index
            ? destinationIndex - 1
            : destinationIndex

          newTasks.splice(adjustedDestination, 0, movedTask!)
          return newTasks
        })

        try {
          // Perform the mutation based on the edge
          if (edge === 'top') {
            await insertBefore({
              variables: {
                input: {
                  taskNumber: sourceTaskNumber,
                  beforeTaskNumber: targetTask.number
                }
              },
              refetchQueries:[{ query: QUERY, variables: {filters} }]
            })
          } else {
            await insertAfter({
              variables: {
                input: {
                  taskNumber: sourceTaskNumber,
                  afterTaskNumber: targetTask.number
                }
              },
              refetchQueries:[{ query: QUERY, variables: {filters} }]
            })
          }
        } catch (error) {
          // On error, rollback to previous state and refetch
          setClientTasks(previousTasks)
        }
      }
    })
  }, [clientTasks, filters, insertAfter, insertBefore])

  // No need to sort here anymore since order is maintained in the array
  const sortedTasks = clientTasks

  const handleCreateTask = async () => {
    const variables = {
      input: {
        title: 'New Task',
        body: '',
        status: 'NotStarted',
        ...newTask
      }
    }

    const { data, errors } = await createTask({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Failed to create task'
      })
    } else if (data?.createTask) {
      const route = panelRoute('task', { taskNumber: data.createTask.number })
      navigate(route)
    }
  }

  return (
    <div className="mb-6">
      {!viewOnly && (
        <div className="flex items-center mb-4">
          <div className='ml-auto'>
            <Tooltip2 content="Create new task" placement='top'>
              <button
                className="p-1 hover:bg-gray-100 rounded"
                onClick={handleCreateTask}
                disabled={createLoading}
              >
                <PlusIcon className="h-5 w-5 text-gray-500" />
              </button>
            </Tooltip2>
          </div>
        </div>
      )}
      <div className="">
        {sortedTasks.map((task, index) => (
          <TaskCard
            key={task.number}
            task={task}
            index={index}
            viewOnly={viewOnly}
          />
        ))}
        {
          !clientTasks.length && <EmptyState message='No tasks here' />
        }
      </div>
    </div>
  )
}
