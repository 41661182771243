import z from 'zod'
import {
  rValidPartNumber
} from './partNumbers'
import { validVersion as validateVersion } from 'src/lib/version'
import type {
  ChangeOrderState,
  ReviewerRole,
  UpdateChangeOrderInput,
} from 'types/graphql'

/*******************************************************
 *********************** Units *************************
 *******************************************************/

export const massUnits = [
  'kg',
  'g',
  'mg',
  'μg',
  'ng',
  'lb',
  'oz'
] as const
export const zMassUnit = z.enum(massUnits)
export type MassUnit = z.infer<typeof zMassUnit>
export const massConversionRates = {
  "type": "mass",
  "base": "kg",
  "rates": {
    "kg": 1,          // Base unit
    "g": 1000,        // 1 kg = 1000 grams
    "mg": 1_000_000,  // 1 kg = 1,000,000 milligrams
    "μg": 1_000_000_000, // 1 kg = 1,000,000,000 micrograms
    "ng": 1_000_000_000_000, // 1 kg = 1,000,000,000,000 nanograms
    "lb": 2.20462,    // 1 kg = 2.20462 pounds
    "oz": 35.274      // 1 kg = 35.274 ounces
  }
} as {
  type: "mass",
  base: Extract<MassUnit, 'kg'>,
  rates: Record<MassUnit, number>
}

const qUnits = [
  ...massUnits,
  'each',
  'm',
  'cm',
  'mm',
  'μm',
  'ft',
  'in',
  'barleycorn',
  'l',
  'ml',
  'm³',
  'cm³',
  'in³',
  'drop'
] as const
export const quantityUnits = [...qUnits].sort()
export const zQuantityUnit = z.enum(qUnits)
export type QuantityUnit = z.infer<typeof zQuantityUnit>

/*******************************************************
 ********************* Currency ************************
 *******************************************************/

export const currencySymbols = {
  '$': 'USD',
  '£': 'GBP',
  '€': 'EUR',
  '¥': 'JPY',
  '₹': 'INR',
  'R$': 'BRL',
  'C$': 'CAD',
  'A$': 'AUD'
} as const

export type CurrencySymbol = keyof typeof currencySymbols
export type CurrencyCode = typeof currencySymbols[CurrencySymbol]

const currencies = [
  'USD', 'EUR', 'GBP', 'JPY', 'AUD', 'CAD', 'CHF', 'CNY', 'INR', 'NZD',
  'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AWG', 'AZN', 'BAM',
  'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL', 'BSD',
  'BTN', 'BWP', 'BYN', 'BZD', 'CDF', 'CLF', 'CLP', 'CNH', 'COP', 'CRC',
  'CUC', 'CUP', 'CVE', 'CZK', 'DJF', 'DKK', 'DOP', 'DZD', 'EGP', 'ERN',
  'ETB', 'FJD', 'FKP', 'GEL', 'GGP', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ',
  'GYD', 'HKD', 'HNL', 'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'IMP', 'IQD',
  'IRR', 'ISK', 'JEP', 'JMD', 'JOD', 'KES', 'KGS', 'KHR', 'KMF', 'KPW',
  'KRW', 'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LYD',
  'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRU', 'MUR', 'MVR',
  'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'OMR',
  'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR', 'RON', 'RSD',
  'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL',
  'SOS', 'SRD', 'SSP', 'STN', 'SVC', 'SYP', 'SZL', 'THB', 'TJS', 'TMT',
  'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'UYU', 'UZS',
  'VES', 'VND', 'VUV', 'WST', 'XAF', 'XCD', 'XOF', 'XPF', 'YER', 'ZAR',
  'ZMW', 'ZWL'
] as const

export const standardCurrencies = [...currencies].sort()
export const zCurrency = z.enum(currencies)
export type Currency = z.infer<typeof zCurrency>

/*******************************************************
 ******************** Part Deltas **********************
 *******************************************************/

export const nullControlSeq = '~NULL~' as const
export const nullCtrlToUndefined = (v: string) => v === nullControlSeq ? undefined : v

export const versionRefinement = [validateVersion, {
  message: 'Version must be 2 to 4 dot separated segments (a.b) or (a.b.c.d)'
}] as const

export const zRawMetadata = z.record(z.string(), z.any())
type RawMetadata = z.infer<typeof zRawMetadata>

export const zCommonDeltaFields = z.object({
  partNumber: z.string().regex(rValidPartNumber),
  version: z.string(),
  part: z.object({
    nodeType: z.enum(['Part', 'PartGroup']).default('Part'),
    cadRev: z.string(),
    name: z.string(),
    summary: z.string(),
    isRoot: z.boolean(),
    isOffTheShelf: z.boolean(),
    changeMessage: z.string(),
    transitionPlan: z.string(),
    lifeCycle: z.string(),
    metadata: zRawMetadata,
    dependencies: z.array(z.object({
      partNumber: z.string(),
      versionRange: z.string(),
      section: z.enum(['CAD', 'Manual'] as const),
      quantity: z.number(),
      units: zQuantityUnit,
      referenceDesignator: z.string().transform(nullCtrlToUndefined),
    })),
    sources: z.array(z.object({
      comment: z.string().nullable().optional(),
      distributorId: z.string().nullable().optional(),
      priority: z.number().int(),
      distributorSku: z.string().nullable().optional(),
      url: z.string().nullable().optional(),
      price: z.number().nullable().optional(),
      priceCurrency: z.string().nullable().optional(),
      leadTimeDays: z.number().int().nullable().optional(),
      perQuantity: z.number().int().nullable().optional(),
      perQuantityUnit: z.string().nullable().optional(),
      stock: z.number().int().nullable().optional(),
    })),
    artifacts: z.array(z.object({
      fileId: z.number().int(),
      filename: z.string(),
      importId: z.string().nullable().optional(),
      isThumbnail: z.boolean().optional()
    })).max(30).refine(
      as => as.filter(a => a.isThumbnail).length <= 1, {
        message: 'Can only specify one artifact'
      }
    ),
  })
    .partial()
    .strict()
})

const zDependenciesSchema = zCommonDeltaFields.shape.part.shape.dependencies.unwrap().element
const zDependenciesWithVersion = zDependenciesSchema.extend({
  version: z.string(),
})

const zCommonDeltaFieldsWithVersion = zCommonDeltaFields.extend({
  part: zCommonDeltaFields.shape.part.extend({
    dependencies: z.array(zDependenciesWithVersion) // Update dependencies with the new schema
  })
})

export const zDeltaUnion = z.discriminatedUnion("type", [
  zCommonDeltaFields.extend({
    type: z.literal('Create'),
    version: z.string().refine(...versionRefinement),
    categoryId: z.string(),
    generateSequenceBlock: z.boolean().optional()
  }).strict(),
  zCommonDeltaFields.extend({
    type: z.literal('Push'),
    version: z.string().refine(...versionRefinement),
    part: zCommonDeltaFields.shape.part.optional(),
  }).strict(),
  zCommonDeltaFields.extend({
    type: z.literal('Patch'),
  }).strict(),
  z.object({
    type: z.literal('Version'),
    partNumber: z.string(),
    part: z.never().optional(),
    version: z.string().refine(...versionRefinement),
  }).strict()
])

// You'd think there would be an easier way
export const zDeltaUnionWithVersion = z.discriminatedUnion("type", [
  zCommonDeltaFieldsWithVersion.extend({
    type: z.literal('Create'),
    version: z.string().refine(...versionRefinement),
    categoryId: z.string(),
    generateSequenceBlock: z.boolean().optional()
  }).strict(),
  zCommonDeltaFieldsWithVersion.extend({
    type: z.literal('Push'),
    version: z.string().refine(...versionRefinement),
    part: zCommonDeltaFieldsWithVersion.shape.part.optional(),
  }).strict(),
  zCommonDeltaFieldsWithVersion.extend({
    type: z.literal('Patch'),
  }).strict(),
  z.object({
    type: z.literal('Version'),
    partNumber: z.string(),
    part: z.never().optional(),
    version: z.string().refine(...versionRefinement),
  }).strict(),
])

export type DeltaUnion = z.infer<typeof zDeltaUnion>
type DeltaUnionWithVersion = z.infer<typeof zDeltaUnionWithVersion>
export type CreateDelta = Extract<DeltaUnionWithVersion, { type: 'Create' }>
export type PushDelta = Extract<DeltaUnionWithVersion, { type: 'Push' }>
export type PatchDelta = Extract<DeltaUnionWithVersion, { type: 'Patch' }>

export type VersionDelta = Extract<DeltaUnionWithVersion, { type: 'Version' }>
export type DeltaDependency = NonNullable<NonNullable<DeltaUnion['part']>['dependencies']>[number]
export type DeltaDependencyWithVersion = DeltaDependency & {
  version: string
}





/*******************************************************
 ********************** Metadata ***********************
 *******************************************************/


const zMetadataSchema = z.object({
  displayName: z.string(),
  alwaysShow: z.boolean().optional()
})
export const zMetadataUnion = z.discriminatedUnion("type", [
  zMetadataSchema.extend({
    type: z.literal('String'),
    entry: z.string(),
  }),
  zMetadataSchema.extend({
    type: z.literal('Boolean'),
    entry: z.boolean(),
  }),
  zMetadataSchema.extend({
    type: z.literal('Number'),
    entry: z.number(),
  }),
  zMetadataSchema.extend({
    type: z.literal('URL'),
    entry: z.string().url()
  }),
  zMetadataSchema.extend({
    type: z.literal('Mass'),
    entry: z.object({
      unit: zMassUnit,
      value: z.number(),
    }).strict()
  }),
  zMetadataSchema.extend({
    type: z.literal('Price'),
    entry: z.object({
      unit: z.string(),
      value: z.number(),
    }).strict()
  }),
  zMetadataSchema.extend({
    type: z.literal('Time'),
    entry: z.date()
  }),
  zMetadataSchema.extend({
    type: z.literal('Option'),
    options: z.record(z.object({
      deprecated: z.boolean().optional()
    })),
    entry: z.string()
  }),
])

export type MetadataUnion = z.infer<typeof zMetadataUnion>
export type MassType = Extract<MetadataUnion, { type: 'Mass' }>
export type PriceType = Extract<MetadataUnion, { type: 'Price' }>
export type TimeType = Extract<MetadataUnion, { type: 'Time' }>
export type StringType = Extract<MetadataUnion, { type: 'String' }>
export type BooleanType = Extract<MetadataUnion, { type: 'Boolean' }>
export type NumberType = Extract<MetadataUnion, { type: 'Number' }>
export type URLType = Extract<MetadataUnion, { type: 'URL' }>


const zStringEntry = z.object({
  type: z.literal('String'),
  entry: z.string(),
});

const zURLEntry = z.object({
  type: z.literal('URL'),
  entry: z.string().url()
});

// Define the base for filters that can be applied to different types
const zFilterBase = z.object({
  key: z.string(),
  type: z.enum(['Contains', 'NotContains', 'Equals', 'NotEquals', 'OneOf', 'NotOneOf']),
})

// Specific filter configurations
const zContainsFilter = zFilterBase.extend({
  type: z.literal('Contains'),
  value: z.string()
})

export const zFilterType = z.enum([
  'Contains',
  'NotContains',
  'Equals',
  'NotEquals',
  'NotEqualsLifeCycle',
  'InChangeOrder',
  'NotInChangeOrder',
  'True',
  'NotTrue',
  'EqualsLifeCycle',
  'IsOwner',
  'IsNotOwner'
])
export const zAnyFilter = z.object({
  key: z.string(),
  type: zFilterType,
  value: z.union([z.string(), z.number(), z.boolean(), z.date(), zStringEntry, zURLEntry])
})
export type AnyFilter = z.infer<typeof zAnyFilter>
export type FilterType = z.infer<typeof zFilterType>
export type ContainsFilter = z.infer<typeof zContainsFilter>

/*******************************************************
 ******************** Aggregation **********************
 *******************************************************/

export const zAggregation = z.object({
  name: z.string(),
  reducer: z.enum(["Sum", "Max", "And"]),
  targetType: z.object({
    type: z.enum(["Number", "Price", "Mass", "Boolean"]),
    unit: z.string().optional().nullable()
  }),
  metadata: z.array(z.object({
    key: z.string(),
    multiplyByQuantity: z.boolean().optional().transform(Boolean)
  })),
  sources: z.array(z.object({
    key: z.string(),
    multiplyByQuantity: z.boolean().optional().transform(Boolean)
  }))
})

export const zAggregations = z.array(zAggregation)

export type AggregationConfig = z.infer<typeof zAggregation>
export type AggregationReducer = AggregationConfig['reducer']


/*******************************************************
 ********************** Workflow ***********************
 *******************************************************/

// Used for converting old workflow config to new ones
// This could just be a typescript type instead of zod, but
// it's not worth the time rewriting it.
export const zLegacyWorkflow = z.object({
  groupIds: z.array(z.number().int()).min(1),
  rules: z.array(z.object({
    condition: z.object({
      type: z.enum(['Lifecycle']),
      //lifecycle stage, condition could be made into a discriminatedUnion
      stage: z.string(),
    }),
    effect: z.object({
      type: z.enum(['AddGroup']),
      groupId: z.number().int(),
      dismissReviewOnUpdate: z.boolean().optional()
    }),
  })).optional()
}).strict()

export type LegacyWorkflow = z.infer<typeof zLegacyWorkflow>

export type ChangeOrderLogPayload = {
  'ApproveChangeOrder': { message: string },
  'RequestChanges': { message: string },
  'DismissReview': {
    id: number;
    userId: number;
    changeOrderId: number;
    user: {
      id: number;
      name: string;
      email: string;
      avatarHash: string;
    };
  },
  'AddDelta': { partName: string; delta: DeltaUnion },
  'RemovePart': { partName?: string },
  'RebasePart': { partName?: string },
  'Comment': { message: string },
  'ChangeState': { state: ChangeOrderState, override?: boolean, lifeCycleOverride?: boolean, message?: string },
  'ImportAssembly': { partIds: [string], rootPartName: string, deltaType: 'created' | 'updated' },
  'AddReviewer': {
    id: number;
    role: ReviewerRole;
    userId: number;
    changeOrderId: number;
    user: {
      id: number;
      name: string;
      email: string;
      avatarHash: string;
    };
  };
  'DeleteReviewer': {
    id: number;
    role: ReviewerRole;
    userId: number;
    changeOrderId: number;
    user: {
      id: number;
      name: string;
      email: string;
      avatarHash: string;
    };
  },
  'UpdateChangeOrder': {
    changes: UpdateChangeOrderInput & {
      metadata?: RawMetadata
    }
  },
  'RequestAnotherReview': {
    user: {
      id: number;
      name: string;
      email: string;
      avatarHash: string;
    };
  }
}
