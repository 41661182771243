import { MetaTags } from '@redwoodjs/web'
import OrgSettingsIntegrationsCell from 'src/components/OrgSettingsIntegrationsCell'

const OrgSettingsIntegrationsPage = () => {
  return (
    <>
      <MetaTags title="Integrations" description="Organization integrations settings page" />

      <div className="container mx-auto px-4 py-6">
        <h1 className="text-2xl font-bold mb-6">Integrations</h1>

        <div className="bg-white">
          <p className="text-gray-700 mb-4">
            Connect your organization with external services and tools.
          </p>

          <div className="space-y-6">
            <OrgSettingsIntegrationsCell/>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrgSettingsIntegrationsPage
