import { FileRefreshQuery, FileRefreshQueryVariables } from 'types/graphql'
import classNames from "classnames";
import { useEffect, useState } from "react";
import { addMinutes } from "date-fns/fp";
import { Artifact, S3FileRef } from "types/graphql";
import { useAppContext } from "src/lib/appContext";
import { useLazyQuery } from '@apollo/client'
import LoadingSpinner from './Loading';
import { XCircleIcon } from '@heroicons/react/24/outline';

const FILE_REFRESH_QUERY = gql`
  query FileRefreshQuery($fileId: Int!) {
    getFile(id: $fileId) {
      id
      url
      timestamp
    }
  }
`

export type ThumbnailArtifact = Pick<Artifact, 'id' | 'fileId'> & {
  file: Pick<S3FileRef, 'url' | 'timestamp'>
}

type ThumbnailProps = {
  noFrame?: boolean,
  className?: string,
  artifact?: ThumbnailArtifact,
  emptyText?: string
};

const addExpiry = addMinutes(18)

type FileCache = Pick<S3FileRef, 'url' | 'timestamp'>

const Thumbnail: React.FC<ThumbnailProps> = ({ artifact, noFrame, emptyText, className }) => {
  const [getFile, { loading: _loading, error }] = useLazyQuery<FileRefreshQuery, FileRefreshQueryVariables>(
    FILE_REFRESH_QUERY, { variables: { fileId: artifact?.fileId } }
  )
  const context = useAppContext()

  const containerStyle = classNames(
    !noFrame && 'bg-gray-50 border-gray-200 border',
    'flex justify-center items-center overflow-hidden',
    className
  )

  const [url, setUrl] = useState<string>()
  useEffect(() => {
    if (!artifact || !context.currentOrg) return;

    const { timestamp: serverTimestamp } = artifact.file

    const now = Date.now()
    const expires = addExpiry(new Date(serverTimestamp)).valueOf()

    //check for null timestamp defensively incase caller makes a mistake
    if (!serverTimestamp || now > expires) {
      if (!serverTimestamp) console.warn('incorrect type passed to thumbnail viewer')

      const cachedStr = sessionStorage.getItem(`${context.currentOrg}-${artifact.fileId}`)
      let expired = true;

      if (cachedStr) {
        const cached = JSON.parse(cachedStr) as FileCache
        const cacheExpires = addExpiry(new Date(cached.timestamp)).valueOf()

        expired = now > cacheExpires

        if (!expired) {
          //console.log("from cache")
          setUrl(cached.url)
          return;
        }
      }

      //either cache is empty or cache is expired
      if (expired) {
        (async () => {
          const file = (await getFile()).data?.getFile
          if (file) {
            //console.log("from fetch")
            const { url, timestamp } = file;
            sessionStorage.setItem(`${context.currentOrg}-${artifact.fileId}`, JSON.stringify({ url, timestamp }))
            setUrl(url)
          }
        })()
      }
    }
    else {
      //console.log("from server")
      setUrl(artifact.file.url)
    }
  }, [artifact?.fileId])

  // Add preloading when URL changes
  useEffect(() => {
    if (url) {
      const img = new Image();
      img.src = url;
    }
  }, [url]);

  return (
    <div className={containerStyle}>
      {
        error ? <div className="max-w-full max-h-full"> <XCircleIcon className='w-6'/> </div> :
        artifact ?
          (url ?
              <img
                src={url}
                alt="Thumbnail"
                className="max-w-full max-h-full object-contain"
              /> :
              <div className="max-w-full max-h-full">
                <LoadingSpinner className='flex p-10 items-center justify-center'/>
              </div>
          ) :
          <div className="max-w-full max-h-full text-sm italic text-gray-500">{emptyText ?? 'No thumbnail uploaded for this part'}</div>
      }
    </div>
  );
};

export default Thumbnail;
