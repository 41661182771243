import { useState, useEffect } from 'react'
import { useSignIn } from '@clerk/clerk-react'
import Button from 'src/components/Button'
import LoadingSpinner from 'src/components/Loading'
import {
  CardWrapper,
  ErrorDisplay,
  EmailInput,
  PasswordInput,
  buildRedirectUrl
} from 'src/components/auth/Common'
import * as Sentry from '@sentry/react'

const CustomLogin = ({ redirectUrl }: { redirectUrl: string | null }) => {
  const { isLoaded, signIn, setActive } = useSignIn();

  // Track the login state
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlternateMethod, setShowAlternateMethod] = useState(false);
  const [emailLinkSent, setEmailLinkSent] = useState(false);

  // Track page view when component mounts
  useEffect(() => {
    // Start a Sentry transaction for the login flow
    const transaction = Sentry.startInactiveSpan({
      name: 'login-flow',
      op: 'login',
      forceTransaction: true
    });

    // Log page view
    Sentry.addBreadcrumb({
      category: 'login',
      message: 'Login page viewed',
      level: 'info'
    });

    return () => {
      transaction?.end();
    };
  }, []);

  // Handle form submission for login
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    // Start a span for the login attempt
    const span = Sentry.startInactiveSpan({ name: 'login-attempt', op: 'auth' });

    // Log the login attempt
    Sentry.addBreadcrumb({
      category: 'login',
      message: 'User attempted login',
      level: 'info',
      data: {
        hasEmail: !!emailAddress,
        hasPassword: !!password
      }
    });

    if (!isLoaded) {
      span?.end();
      return;
    }

    try {
      // Attempt to sign in with email and password
      const result = await signIn.create({
        identifier: emailAddress,
        password,
      });

      if (result.status === 'complete') {
        // Log successful login
        Sentry.addBreadcrumb({
          category: 'login',
          message: 'User login successful',
          level: 'info'
        });

        // Mark span as successful
        span?.setStatus('ok');

        // Set the user session active and redirect
        await setActive({
          session: result.createdSessionId,
          redirectUrl: redirectUrl || '/'
        });
      } else {
        // Handle additional verification if needed (like 2FA)
        setError('Additional verification required. Please use the Clerk widget.');

        // Log verification needed
        Sentry.addBreadcrumb({
          category: 'login',
          message: 'Additional verification required',
          level: 'info',
          data: {
            status: result.status
          }
        });

        // Mark span as pending
        span?.setStatus('unset');
      }
    } catch (err: unknown) {
      const errorMessage = (err as any).errors?.[0]?.message || 'Invalid email or password';
      setError(errorMessage);

      // Log the error with context
      Sentry.captureException(err, {
        tags: {
          'login_step': 'authentication',
          'has_email': !!emailAddress
        }
      });

      // Mark span as failed
      span?.setStatus('internal_error');
    } finally {
      setLoading(false);
      span?.end();
    }
  }

  // Handle sending email sign-in link
  const handleSendEmailLink = async () => {
    if (!emailAddress) {
      setError('Please enter your email address');
      return;
    }

    setLoading(true);
    setError('');
    setEmailLinkSent(false);

    // Start a span for the email link attempt
    const span = Sentry.startInactiveSpan({ name: 'email-link-attempt', op: 'auth' });

    // Log the email link attempt
    Sentry.addBreadcrumb({
      category: 'login',
      message: 'User requested email sign-in link',
      level: 'info',
      data: {
        hasEmail: !!emailAddress
      }
    });

    if (!isLoaded) {
      span?.end();
      return;
    }

    try {
      // First, create a sign-in attempt with the email
      const { supportedFirstFactors } = await signIn.create({
        identifier: emailAddress,
      });


      // Find the email link factor from supported factors
      const emailLinkFactor = supportedFirstFactors?.find(
        factor => factor.strategy === 'email_link'
      );

      if (!emailLinkFactor || emailLinkFactor.strategy !== 'email_link') {
        throw new Error('Email link authentication not supported for this account');
      }

      // Get the emailAddressId from the factor
      const { emailAddressId } = emailLinkFactor;

      // Get the email link flow and start it
      const { startEmailLinkFlow } = signIn.createEmailLinkFlow();

      // Determine the redirect URL
      const protocol = window.location.protocol;
      const host = window.location.host;
      const path = redirectUrl || '/';

      // Create a promise that will resolve when the email is sent
      // but won't wait for the user to click the link
      const sendEmailPromise = new Promise<void>((resolve, reject) => {
        // Start the email link flow in a non-blocking way
        startEmailLinkFlow({
          emailAddressId,
          redirectUrl: `${protocol}//${host}${path}`,
        }).then(() => {
          // This will only execute if the user clicks the link and completes the flow
          // We don't need to handle this here as Clerk will redirect the user
        }).catch(error => {
          // Only reject if there's an error sending the email
          reject(error);
        });

        // Resolve the promise immediately after starting the flow
        // This makes the UI responsive without waiting for the user to click the link
        resolve();
      });

      // Wait for the email to be sent, but not for the user to click the link
      await sendEmailPromise;

      // Log email link sent
      Sentry.addBreadcrumb({
        category: 'login',
        message: 'Email sign-in link sent',
        level: 'info'
      });

      // Mark span as successful
      span?.setStatus('ok');

      // Show success message
      setEmailLinkSent(true);
    } catch (err: unknown) {
      const errorMessage = (err as any).errors?.[0]?.message || 'Failed to send sign-in link';
      setError(errorMessage);

      // Log the error with context
      Sentry.captureException(err, {
        tags: {
          'login_step': 'email_link_request',
          'has_email': !!emailAddress
        }
      });

      // Mark span as failed
      span?.setStatus('internal_error');
    } finally {
      setLoading(false);
      span?.end();
    }
  }

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  // Email link success message component with instructions
  const EmailLinkSuccess = () => (
    <div className="bg-green-50 border-l-4 border-green-500 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-sm text-green-700 font-medium">Sign-in link sent!</p>
          <p className="text-sm text-green-700 mt-1">Check your email and click the link to sign in.</p>
        </div>
      </div>
    </div>
  )

  return (
    <CardWrapper>
      <div className="text-center mb-6">
        <p className="text-gray-600 mt-1">Sign in to your account</p>
      </div>

      {/* Email link sent state - treat as a terminal state */}
      {emailLinkSent ? (
        <>
          <EmailLinkSuccess />

          <div className="mt-6 space-y-4">
            <div className="text-sm text-gray-600">
              <p>We've sent a sign-in link to <span className="font-medium">{emailAddress}</span>.</p>
              <p className="mt-1">Please check your email and click the link to sign in.</p>
            </div>

            <div className="text-xs text-gray-500 mt-4">
              <p>The link will expire after 10 minutes. If you don't see the email, check your spam folder.</p>
            </div>

            <div className="border-t border-gray-200 pt-4 mt-4">
              <Button
                onClick={() => {
                  setShowAlternateMethod(!showAlternateMethod);
                  setEmailLinkSent(false);
                  setError('');
                }}
                className="w-full py-2 text-gray-600 hover:text-gray-800 font-medium"
              >
                Try a different sign-in method
              </Button>
            </div>
          </div>

          <div className="mt-6 text-center">
            <div className="text-sm text-gray-500">
              Don't have an account? <a href={buildRedirectUrl(redirectUrl, '/signup')} className="text-brand-600 hover:text-brand-700 font-medium">Sign up</a>
            </div>
          </div>
        </>
      ) : (
        <>
          <ErrorDisplay error={error} />

          {!showAlternateMethod ? (
            <>
              <form onSubmit={handleSubmit} className="flex flex-col gap-5">
                <EmailInput
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />

                <PasswordInput
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div className="flex items-center justify-end mt-1">
                  <div className="text-sm">
                    <a href={buildRedirectUrl(redirectUrl, '/forgot-password')} className="font-medium text-brand-600 hover:text-brand-500">
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div className="mt-2">
                  <Button
                    type="submit"
                    disabled={loading}
                    className="w-full py-3 bg-brand-600 hover:bg-brand-700 text-white font-medium rounded-md shadow-sm transition-all duration-200"
                  >
                    {loading ? <LoadingSpinner size="sm" /> : 'Sign In'}
                  </Button>
                </div>
              </form>

              <div className="text-center">
                <div className="text-sm text-gray-500 mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAlternateMethod(true);
                      setError('');
                    }}
                    className="text-brand-600 hover:text-brand-700 font-medium"
                  >
                    Sign in with email link instead
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col gap-5">
                <EmailInput
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />

                <div className="mt-2">
                  <Button
                    onClick={handleSendEmailLink}
                    disabled={loading}
                    className="w-full py-3 bg-brand-600 hover:bg-brand-700 text-white font-medium rounded-md shadow-sm transition-all duration-200"
                  >
                    {loading ? <LoadingSpinner size="sm" /> : 'Send Sign-in Link'}
                  </Button>
                </div>
              </div>

              <div className="text-center">
                <div className="text-sm text-gray-500 mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAlternateMethod(false);
                      setError('');
                    }}
                    className="text-brand-600 hover:text-brand-700 font-medium"
                  >
                    Sign in with password instead
                  </button>
                </div>
              </div>
            </>
          )}

          <div className="mt-6 text-center">
            <div className="text-sm text-gray-500">
              Don't have an account? <a href={buildRedirectUrl(redirectUrl, '/signup')} className="text-brand-600 hover:text-brand-700 font-medium">Sign up</a>
            </div>
          </div>
        </>
      )}
    </CardWrapper>
  );
};
export default CustomLogin
