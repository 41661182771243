import { useResolvedLifeCycle } from "src/lib/lifecycle"
import { Container as TooltipContainer, Message as TooltipMessage } from "src/components/Tooltip"

const colors = {
  Hidden: 'opacity-0 hidden',
  Gray: 'bg-[rgb(245,245,245)] border-gray-50',
  Purple: 'bg-[rgb(255,182,255)] border-[rgb(192,148,226)]',
  Blue: 'bg-[rgb(46,228,255)] border-blue-400',
  Green: 'bg-[rgb(157,255,185)] border-[rgb(108,175,127)]',
  Red: 'bg-[rgb(255,149,152)] border-[rgb(231,93,97)]'

} as const

const sizes = {
  sm: 'w-2 h-2',
  md: 'w-3 h-3'
} as const

type StatusProps = {
  lifeCycle: string | undefined | null
  size?: keyof typeof sizes
  className?: string
  noTooltip?: boolean
  alignTooltip?: 'center' | 'left' | 'right' | 'leftHorizontal'
}

export const StatusDotLabel = ({lifeCycle}: {lifeCycle: string}) => {
  const state = useResolvedLifeCycle(lifeCycle)
  return <>
    Lifecycle stage: {state.name}
  </>
}
export const StatusDot: React.FC<StatusProps> = ({ lifeCycle, size, className = '', noTooltip, alignTooltip }) => {
  const state = useResolvedLifeCycle(lifeCycle)
  const colorClass = colors[state.color]
  const sizeClass = size ? sizes[size] : sizes.md
  return noTooltip ?
    <div className={`border rounded-xl group ${sizeClass} ${colorClass} ${className}`} tabIndex={0}/>
    :
    <TooltipContainer className={`h-fit ${className}`}>
      <div className={`border rounded-xl group ${sizeClass} ${colorClass}`} tabIndex={0}>
        <TooltipMessage className="group-focus:visible group-focus:z-50" position={alignTooltip || 'center'}>
          Lifecycle stage: {state.name}
        </TooltipMessage>
      </div>
    </TooltipContainer>
}

export const useStatusColor = (lifeCycle: string | undefined | null) => {
  const state = useResolvedLifeCycle(lifeCycle)
  return colors[state.color]
}
