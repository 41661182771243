import { useAuth } from 'src/auth'
import { useParams } from "@redwoodjs/router"
import { FileRef, createUppy } from './useFileUploader'

export function useFileUploaderSimple() {
  const orgId = useParams().orgId!
  const { getToken } = useAuth()

  const upload = (files: File[]): Promise<FileRef[]> => {
    return new Promise((resolve, reject) => {
      const onComplete = (fileRefs: FileRef[] | undefined, failed: any, failedConfirms: any) => {
        if (failed?.length || failedConfirms?.length) {
          reject(new Error('Some files failed to upload'))
          return
        }
        resolve(fileRefs || [])
      }

      // Create a new uppy instance for this upload
      const uploadUppy = createUppy(orgId, getToken, onComplete)

      try {
        uploadUppy.addFiles(files.map(file => ({
          source: 'upload',
          name: file.name,
          type: file.type,
          data: file,
        })))
      } catch (err) {
        reject(err)
      }
    })
  }

  return { upload }
}