import { Part, Dependency } from 'types/graphql'

import type { QuantityUnit } from 'shared/types'
import { AppContext } from 'src/lib/appContext'
import { Logo, SolidworksLogo } from 'src/components/CadLogo'

import * as carbonQuestConfig from './carbonQuest/config'
import * as amatis from './amatis/config'
import * as demoConfig from './demo/config'
import * as charbroilConfig from './charbroil/config'
import * as ozloConfig from './ozlo/config'
import patternConfig from './pattern/config'
import * as copperConfig from './copper/config'
import * as hextronicsConfig from './hextronics/config'
import * as xicatoConfig from './xicato/config'
import * as sagaConfig from './saga/config'
import * as rockytalkieConfig from './rockytalkie'
import * as oxygen8Config from './oxygen8/config'

type MappedPart = Pick<Part, 'partNumber' | 'cadRev' | 'summary' | 'name'> &
  Pick<Dependency, 'quantity' | 'referenceDesignator' | 'units'> & {
    hierarchy: string
    categoryId: string
    isOffTheShelf: string
  }

// export type MapTransform = {
//   type: 'leadingUnderscoreHierarchy'
// }
export type MapExecutionConfig =
  | {
      // Map a BOM column directly to MappedPart part field
      type: 'simple'
      value: string
    }
  | {
      // Do not change the value already in the PLM
      type: 'ignore'
      value?: never
    }
  | {
      // Same as simple but with static type checks
      // on the quantity enum
      type: 'quantity'
      value: QuantityUnit
    }
  | {
      type: 'generateByKey'
      schemaKey: string
      partNumberKey: string
      categoryId: string
      generatorOptions?: Record<string, unknown>
    }
  | {
      type: 'alwaysGenerate'
      categoryId: string
    }
  | {
      type: 'categoryIdByPartNumber'
      schemaKey: string
      partNumber: string
    }
  | {
      type: 'split'
      splitOn: string
      input: string
      // recieves a parts array
      value: string
    }
  | {
      type: 'regex'
      input: string
      regex: string
      value: string
    }
  | {
      type: 'empty'
      value?: never
    }

export type Condition =
  | {
      type: 'eq' | 'neq'
      column: string
      value: string
    }
  | {
      type: 'empty'
      column: string
    }
  | {
      type: 'notEmpty'
      column: string
    }
  | {
      type: 'contains'
      column: string
      value: string
    }
  | {
      type: 'startsWith'
      column: string
      value: string
    }
  | {
      type: 'regex'
      column: string
      value: string
    }

export type ConditionsAndExection =
  | {
      default?: false
      conditions: Condition[]
      onMatch: MapExecutionConfig
    }
  | {
      default: true
      onMatch: MapExecutionConfig
    }

export type PartNumberGeneration = {
  conditions: Condition[]
  onMatch: {
    type: 'generateByKey'
    schemaKey: string
    partNumberKey: string
    generatorOptions: Record<string, unknown>
  }
}

export type MapperSpec = MapExecutionConfig | ConditionsAndExection[]

type SourceMapper = {
  // will be mapped to a distributor ID
  distributorName?: MapperSpec
  distributorSku?: MapperSpec
  url?: MapperSpec
}

type ArtifactMapper = {
  fileId: MapperSpec
  previewUrl?: MapperSpec
  importId: MapperSpec
  filename: MapperSpec
}

type PartMap = {
  [Key in keyof MappedPart]: MapperSpec
} & {
  metadata?: Record<string, MapperSpec>
  sources?: SourceMapper[]
  artifacts?: ArtifactMapper[]
}

export type RowsMergeConfig = {
  type: 'MergeRows'
  config: {
    mergeOn: string
    output: Record<string, string>
  }
}

/*
 * Takes rows that match a criteria and normalizes
 * copies the first row over to the other rows.
 */
export type RowsNormalizeConfig = {
  type: 'NormalizeRows'
  config: {
    normalizeOn: string
    normalizeColumns: string[]
  }
}

export type RowsExplodeConfig = {
  type: 'SubassemblyExplode'
  config: {
    explodeIdentifier: string
    subassemblyKeyColumn: string
  }[]
}

export type StitchHierarchyConfig = {
  type: 'StitchHierarchy'
  config: {
    parentPartNumberColumn: string
    partNumberColumn: string
  }
}

export type RowsRemoveConfig = {
  type: 'FilterRemove'
  config: {
    conditions: Condition[]
  }
}

export type RowsKeepConfig = {
  type: 'FilterKeep'
  config: {
    conditions: Condition[]
  }
}

// Translates a leveled hierarchy in order into
// a regular hierarchy
export type RowsOrderedLevelConfig = {
  type: 'OrderedLevelHierarchy'
  config: {
    input: string
    // used when assemblies are filtered out
    // at the base of the tree, where you
    // want to filter the children in that case
    skipHierarchyJumps?: boolean
  }
}

export type MergeDuplicateChildrenConfig = {
  type: 'MergeDuplicateChildren'
  config: {
    duplicateCheck: MapExecutionConfig
    hierarchy: MapExecutionConfig
    quantity: MapExecutionConfig
  }
}

export type RowsToBomConfigs = (
  | RowsExplodeConfig
  | StitchHierarchyConfig
  | RowsMergeConfig
  | RowsRemoveConfig
  | RowsKeepConfig
  | RowsNormalizeConfig
  | RowsOrderedLevelConfig
  | MergeDuplicateChildrenConfig
)[]

// not sure if this is ever used?
export type ImportOutputFields = {
  metadata: string[]
}

export type FileType = 'xlsx' | 'csv' | 'txt' | 'json' | 'bom'
export type ParserId = 'creo' | 'xlsx' | 'csv'
export type Parser = {
  fileType: FileType
  parserId: 'creo' | 'xlsx' | 'csv'
}

export type ExtractRootField = {
  outputFieldName: string
  extractionType: 'cellRightOfTextIncluding'
  input: string
}

export type ExtractRootConfig = {
  inRowRange: [number, number]
  fields: ExtractRootField[]
}

export type MapperConfig = {
  orgIds: string[]
  name: string
  type: 'Upload' | 'OnShapeApi' | 'AltiumApi' | 'WindchillApi' | 'GoogleDrive'
  noProject?: boolean
  superUserOnly?: boolean
  Logo: Logo
  importOutputFields: ImportOutputFields
  rootIsTopLevel?: boolean
  fileToRows?: {
    parsers?: Parser[]
    xlsx?: {
      enable?: boolean
      firstRow?: number
      workbook?: string
      extractRoot?: ExtractRootConfig
    }
    csv?: {
      enable: boolean
    }
  }
  // Add Google Drive specific configuration
  googleDrive?: {
    firstRow?: number
    sheetRange?: string
    workbook?: string
    extractRoot?: ExtractRootConfig
  }
  rowsToBom?: RowsToBomConfigs
  standardizeBom: {
    columns: PartMap
  }
  generatePartNumbers?: PartNumberGeneration[]
}

export const mapperConfigs: MapperConfig[] = [
  carbonQuestConfig.plantMapper,
  carbonQuestConfig.partsImport,
  demoConfig.creoConfig,
  demoConfig.altiumConfig,
  demoConfig.onShapeConfig,
  demoConfig.solidworksConfig,
  demoConfig.windchillConfig,
  ozloConfig.electricalConfig,
  ozloConfig.mechanicalConfig,
  ozloConfig.allPartsConfig,
  charbroilConfig.mideaLoose,
  patternConfig,
  copperConfig.allParts,
  copperConfig.bom,
  hextronicsConfig.allPartsConfig,
  xicatoConfig.electricalConfig,
  xicatoConfig.mechancalConfig,
  sagaConfig.googleDrive,
  amatis.amatisConfig,
  rockytalkieConfig.rockytalkieConfig,
  oxygen8Config.onboarding,
] as const

export const getMapper = (orgId: string, name: string) => {
  if (orgId.startsWith('changeOrg') || orgId.startsWith('test_'))
    return demoConfig.solidworksConfig

  return mapperConfigs.find((m) => {
    return m.orgIds.includes(orgId) && m.name === name
  })
}

export const getMapperOptions = (orgId: string, { me }: AppContext) => {
  if (orgId.startsWith('test_') || orgId.startsWith('changeOrg')) {
    orgId = 'demo-1'
  }
  return mapperConfigs.filter((m) => {
    if (m.superUserOnly && !me.isSuperUser) return false
    return m.orgIds.includes(orgId)
  })
}
