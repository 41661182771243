import { Container as TooltipContainer, Message as TooltipMessage } from "src/components/Tooltip"
import Thumbnail from '../ThumbnailViewer'
import { getThumbnail } from '../Artifacts/Artifacts'
import { PartChangePart, PartDiffSummary } from './calculateAllChanges'
import { letF } from 'api/src/shared/functional'

type ThumbnailChangesProps = {
  viewMode: 'unified' | 'split'
  isExpanded: boolean
  headPart?: PartChangePart | null
  partDiff?: PartDiffSummary
  edit?: {
    partNumber: string
    changeOrderNumber: number
  }
  changeOrderComplete: boolean
}

export const ThumbnailChanges: React.FC<ThumbnailChangesProps> = ({
  viewMode,
  headPart,
  partDiff,
  edit,
  changeOrderComplete
}) => {
  const incomingPart = partDiff?.incomingPart
  const thumbnailHasChange = incomingPart && partDiff && partDiff.fields.thumbnail.hasChange


  return letF([getThumbnail(headPart?.artifacts), getThumbnail(incomingPart?.artifacts)] as const, ([headThumbnail, incomingThumbnail]) => {
    if (viewMode === 'unified') {
      return incomingThumbnail && (
        <div>
          <div className='text-sm font-semibold text-gray-900 h-8'>Thumbnail</div>
          {edit ?
            <TooltipContainer className='w-fit'>
              <TooltipMessage className='text-nowrap -top-6 bottom-auto left-0 right-0 w-fit' position='center'>
                Thumbnail can be changed using the photo icon on artifacts
              </TooltipMessage>
              <Thumbnail
                artifact={incomingThumbnail}
                className={`w-96 h-80 p-3 my-3 border-2 ${thumbnailHasChange ? 'border-yellow-200' : ''}`}
              />
            </TooltipContainer>
            :
            <Thumbnail
              artifact={incomingThumbnail}
              className={`w-96 h-80 p-3 my-3 border-2 ${thumbnailHasChange ? 'border-yellow-200' : ''}`}
            />
          }
        </div>
      )
    }

    return (headPart && incomingPart) ?
      ((headThumbnail || incomingThumbnail) &&
        <div className='flex'>
          <div className='flex-1'>
            <div className='text-sm font-semibold text-gray-900 h-8'>Thumbnail</div>
            <Thumbnail artifact={headThumbnail} className='w-96 h-80 p-3 my-3' />
          </div>
          <div className='border-l border-gray-200 mx-10'></div>
          <div className='flex-1'>
            <div className='text-sm font-semibold text-gray-900 h-8'>Thumbnail</div>
            {edit ?
              <TooltipContainer className='w-fit'>
                <TooltipMessage className='text-nowrap -top-6 bottom-auto left-0 right-0 w-fit' position='center'>
                  Thumbnail can be changed using the photo icon on artifacts
                </TooltipMessage>
                <Thumbnail
                  artifact={incomingThumbnail}
                  className={`w-96 h-80 p-3 my-3 border-2 ${thumbnailHasChange ? 'border-yellow-200' : ''}`}
                />
              </TooltipContainer>
              :
              <Thumbnail
                artifact={incomingThumbnail}
                className={`w-96 h-80 p-3 my-3 border-2 ${thumbnailHasChange ? 'border-yellow-200' : ''}`}
              />
            }
          </div>
        </div>
      ) :
      incomingThumbnail &&
      <div>
        <div className='text-sm font-semibold text-gray-900 h-8'>Thumbnail</div>
        <Thumbnail artifact={incomingThumbnail} className='w-96 h-80 p-3 my-3' />
      </div>
  })
}
