import type {
  FindChangeOrderQuery,
  FindChangeOrderQueryVariables,
  SetChangeOrderStateMutation,
  SetChangeOrderStateMutationVariables,
  ChangeOrderState,
} from 'types/graphql'

import { QUERY as CO_QUERY } from './ChangeOrderCell'

import { ReactNode, useContext, useState } from 'react'

import { FormError } from 'src/components/Form'
import { reportMutationError } from 'src/lib/reportError';
import {
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'

import { type CellSuccessProps, useMutation } from '@redwoodjs/web'

import { getGroupReviewStatus, WorkflowContext } from './Reviewers'
import Button from 'src/components/Button'
import AppContext from 'src/lib/appContext';
import englishJoin from 'src/lib/englishJoin'
import { ConditionalModal } from '../Modal'
import { usePartsCache } from 'src/lib/usePartsCache'
import { NewComment } from './Comment'
import { SET_CHANGE_ORDER_STATE_MUTATION } from 'src/lib/queries'
import { ActivityLog } from './ActivityLog'
import { ChangeOrderTasks } from './ChangeOrderTasks'

type SuccessProp = CellSuccessProps<FindChangeOrderQuery, FindChangeOrderQueryVariables>
type COProp = SuccessProp['changeOrder']

export default ({ changeOrder, hasEscalatedPrivileges }: { changeOrder: COProp, hasEscalatedPrivileges: boolean }) => {
  const appContext = useContext(AppContext)
  const workflowData = useContext(WorkflowContext)
  const { refresh: refreshPartsCache } = usePartsCache()

  const [showRaceConditionModal, setShowRaceConditionModal] = useState(false)

  const [setChangeOrderState, { loading, error: stateChangeError }] = useMutation<SetChangeOrderStateMutation, SetChangeOrderStateMutationVariables>(SET_CHANGE_ORDER_STATE_MUTATION)
  const updateState = async (state: ChangeOrderState) => {
    const variables = {
      orderNumber: changeOrder.number,
      state
    }

    const { errors } = await setChangeOrderState({
      errorPolicy: 'all',
      variables,
      refetchQueries: [CO_QUERY],
      awaitRefetchQueries: true
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Failed to change order state to ${state}`
      })
      if (errors.some(e => e.message.startsWith('There is a merge conflict with this change'))) {
        setShowRaceConditionModal(true)
      }
    }
    console.log('refreshing proto cacheeeee')
    refreshPartsCache()
  }

  const reviewers = changeOrder.reviewers.filter(r => r.role === 'Reviewer')
  const approveReviews = changeOrder.reviewers.filter(r => r.response === 'Approve')
  const approvesCount = reviewers.filter(r =>
    approveReviews.find(approve => r.user.id === approve.user.id)
  ).length
  const requiredReviewsApproved = approvesCount >= reviewers.length;
  const changeOrderApproved = reviewers.length > 0 && requiredReviewsApproved
  const reviewMessage = () => {
    if (reviewers.length === 0) {
      return 'There are no reviewers for this change order'
    }
    if (!requiredReviewsApproved) {
      return `${approvesCount} out of ${reviewers.length} reviewers have approved the change order`
    }
    return `All reviewers have approved the change order`
  }

  const { mergeConflicts, invalidLifeCycle } = changeOrder;
  const pluralConflicts = (mergeConflicts && mergeConflicts.length > 1) ?? false
  const pluralLifeCycleIssues = (invalidLifeCycle && invalidLifeCycle.length > 1) ?? false
  const reviewStatus = getGroupReviewStatus(workflowData)
  const groupsMissing = reviewStatus.valid ? [] : Object.values(reviewStatus.groupsReviews).filter(g => g.activeRule ? g.ruleConditionMet : true).filter(g => !g.approved)
  const actionsPending = Boolean(mergeConflicts?.length) || invalidLifeCycle?.length || !reviewStatus.valid

  const OrderStatus = ({title, body}: {title: ReactNode, body: ReactNode}) => <>
    <div className='font-medium mb-2'>
      {title}
    </div>
    <div className='text-sm mb-4'>
      {body}
    </div>
  </>

  const statusPanel = () => {
    if (changeOrder.state === 'Draft') {
      return <div className={`border-2 border-brand-500 rounded-lg p-4`}>
        <OrderStatus
          title='Change order is in draft state'
          body='Change the state to ready for review and reviewers will be notified'/>
        <Button writeOnly='left' variant='primary' disabled={loading} onClick={() => updateState('Review')}>Ready for Review</Button>
      </div>
    }
    if (changeOrder.state === 'Review') {
      const changeStateBorder = !changeOrderApproved ? 'border-red-300' : `border-brand-500`
      return <div className={`border-2 rounded-lg p-4 ${changeStateBorder}`}>
        { mergeConflicts?.length ?
            <OrderStatus
              title='Merge Conflicts'
              body={<>
                {'The change order cannot be applied due to '}
                {pluralConflicts ?
                  <>
                    merge conflicts with the parts {englishJoin(mergeConflicts.map((n) =>
                      <span className='font-semibold'>{'#' + n}</span>
                    ))}
                  </> :
                  <>a merge conflict with <span className='font-semibold'>#{mergeConflicts[0]}</span></>
                }
                . Please go to the "Changes" tab to review the conflict{pluralConflicts && 's'} and resolve {pluralConflicts ? 'them' : 'it'}.
              </>} />
          : invalidLifeCycle?.length ?
            <OrderStatus
              title='Invalid lifecycle status'
              body={<>
                {'The change order cannot be applied due to '}
                {pluralLifeCycleIssues ?
                  <>
                    invalid lifecycle states for {englishJoin(invalidLifeCycle.map(l =>
                      <span className='font-semibold'>{'#' + l}</span>
                    ))}.
                  </> :
                  <>
                    an invalid lifecycle state for <span className='font-semibold'>
                      #{invalidLifeCycle[0]}
                    </span>.
                  </>
                }
              </>} />
          : !reviewStatus.valid ?
            <OrderStatus
              title='Change order is in review'
              body={
                (groupsMissing.length === 1) ?
                  `Missing approval from reviewer group ${groupsMissing[0]!.name}.`
                  :
                  <>
                    Missing approval from reviewer groups {englishJoin(groupsMissing.map(g => g.name))}.
                  </>
              } />
          :
          <OrderStatus
            title='Change order is in review'
            body={reviewMessage()} />
        }
        <Button writeOnly='left' variant={!changeOrderApproved ? 'red' : 'primary'} onClick={() => updateState('Complete')} disabled={loading || Boolean(mergeConflicts?.length) || (!hasEscalatedPrivileges && Boolean(actionsPending))}>
          {hasEscalatedPrivileges && actionsPending && !mergeConflicts?.length ?
            'Apply via Override' :
            'Apply Change Order'
          }
        </Button>
        <FormError error={stateChangeError} wrapperClassName="text-white bg-red-500 w-full my-2 p-2 rounded" />
        </div>
    }
    if (changeOrder.state === 'Cancelled') {
      return <div className={`border-2 border-gray-200 rounded-lg p-4`}>
        <OrderStatus
          title='Change order cancelled'
          body='You may restore the change order back to draft state'/>
        <Button disabled={loading} onClick={() => updateState('Draft')}>Restore Change Order</Button>
        </div>
    }

    // Complete
    return null
  }

  return (
    <div className='flex flex-col gap-6 pl-5'>
      {showRaceConditionModal &&
        <ConditionalModal className='w-xs mt-10' onClose={() => location.reload()}>
          <div className='flex flex-col gap-4 p-4'>
            <div className='flex gap-2 font-medium items-center'>
              <ExclamationTriangleIcon className='w-10 mt-0.5 text-yellow-400' />
              Conflicts Detected
            </div>
            <div className='text-sm'>
              Another change order was submitted while you were viewing the page, refresh the change order to resolve conflicts.
            </div>
            <div className='flex mt-4'>
              <Button type='button' variant='primary' className='ml-auto' onClick={() => location.reload()}>Refresh</Button>
            </div>
          </div>
        </ConditionalModal>
      }
      <ActivityLog changeOrder={changeOrder} />
      <div>{statusPanel()}</div>

      {appContext?.canEdit &&
        <NewComment
          orderNumber={changeOrder.number}
          canEdit={appContext?.canEdit}
          cancelDisabled={changeOrder.state === 'Complete' || loading}
          state={changeOrder.state}
          context={{ changeOrder: { number: changeOrder.number, partNumbers: changeOrder.includedPartNumbers } }}
        />
      }
    </div>
  )
}
