import * as XLSX from 'xlsx'
import { parse } from 'papaparse'
import snakeCase from 'lodash.snakecase'
import { parseJsObjectString } from './lib'
import { MapperConfig, ParserId, ExtractRootConfig } from './mapperConfigs'
import omit from 'lodash.omit'



type FileType = 'xlsx' | 'csv' | 'txt' | 'json' | 'bom'
const getFileType: (file: File) => FileType = (file) => {
  // try from the mime type
  if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return 'xlsx'
  if (file.type === 'text/csv') return 'csv'
  if (file.type === 'text/plain') return 'txt' as FileType
  if (file.type === 'application/json') return 'json' as FileType

  // try using the extension
  const rExtension = /.*\.([^.]+$)/
  const extension = file.name.match(rExtension)
  const match = extension?.[1]
  if (match === 'xlsx') return 'xlsx'
  if (match === 'csv') return 'csv'
  if (match === 'numbers') return 'xlsx'
  if (match === 'bom') return 'bom'
  if (match === 'json') return 'json' as FileType
  throw new Error(`Cannot deduce file type, or the file type is unsupported, file.type: ${file.type} extension: ${extension}`)
}

type Parser = {
  readAs: 'arraybuffer' | 'text'
  parse: (file: ArrayBuffer | string, config: MapperConfig) => BomRow[]
}

export type BomRowMessage = {
  level: 'info' | 'warn' | 'error'
  message: string
}
export type BomRowMetadata = {
  originalIndex: number
  removed?: {
    by: string
    stage: 'fileToRows' | 'rowsToBom' | 'standardizeBom'
  }
  messages: BomRowMessage[]
}
export type BomRow = {
  [key: string]: string | BomRowMetadata
  __metadata: BomRowMetadata
}

export const parsers: Partial<Record<FileType, Parser>> = {
  xlsx: {
    readAs: 'arraybuffer',
    parse(file, config) {
      const binaryFile = file as ArrayBuffer
      const sheets = XLSX.read(binaryFile)

      // Get specified workbook or default to first sheet
      const workbookName = config.fileToRows?.xlsx?.workbook
      const sheet = workbookName ?
        sheets.Sheets[workbookName] :
        sheets.Sheets[sheets.SheetNames[0]!]

      if (!sheet) {
        throw new Error(`Workbook "${workbookName}" not found. Available workbooks: ${sheets.SheetNames.join(', ')}`)
      }

      // Handle root extraction if configured
      let rootRow: BomRow | undefined
      if (config.fileToRows?.xlsx?.extractRoot) {
        rootRow = extractRootFields(sheet, config.fileToRows.xlsx.extractRoot) as BomRow
      }

      const firstRow = config.fileToRows?.xlsx?.firstRow || 0
      // parsing with links
      const rows = parseXlsxWorksheet(sheet, {
        firstRow
      })

      const processedRows = rows.map((row, i) => {
        const trimmed = Object.entries(omit(row, '__originalIndex')).reduce((memo, [k, v]) => {
          return {
            ...memo,
            [k]: String(v).trim()
          }
        }, {} as Omit<BomRow, '__originalIndex'>)
        const snaked = snakeCasedColumns(trimmed)
        return {
          ...snaked,
          __metadata: {
            originalIndex: row.__originalIndex as number,
            messages: []
          }
        }
      })

      // Add root row if it exists
      return rootRow ? [rootRow, ...processedRows] : processedRows
    }
  },
  csv: {
    readAs: 'text',
    parse(file, config) {
      const textFile = file as string
      const { data } = parse(textFile, {
        transform: (input: string) => input.trim(),
        header: true,
        skipEmptyLines: 'greedy'
      })

      return data.map((row, i) => {
        const snaked = snakeCasedColumns(row as Record<string, string>)
        return {
          ...snaked,
          __metadata: {
            originalIndex: i,
            messages: []
          }
        } satisfies BomRow
      })
    }
  },
}

type ParserConfig = {
  parserId: ParserId
  readAs: string
  parse: (file: ArrayBuffer | string, config: MapperConfig) => BomRow[]
}

type PartChunk = {
  header: {
    name: string
    type: 'Assembly' | 'Sub-Assembly'
    columns: string[]
  }
  rows: (string | number)[][]
}

type Assembly = {
  assemblyType: 'Assembly' | 'Sub-Assembly'
  name: string
  rows: Record<string, string>[]
}

const newParsers: ParserConfig[] = [
  {
    parserId: 'creo',
    readAs: 'text',
    parse: (file) => {
      const textFile = file as string

      const escapedQuotesString = textFile.replace(/{{{([^}]*)}}}/g, (match, p1) => {
        // Escape double quotes inside the text
        const escapedText = p1.replace(/"/g, '\\"');
        return escapedText;
      })
      const partChunks: PartChunk[] = parseJsObjectString(escapedQuotesString)
      const partsByName: Record<string, Assembly> = {}
      for (const partChunk of partChunks) {
        const chunkRows = partChunk.rows.map(cols => {
          return cols.reduce((output, value, i) => {
            const fieldName = partChunk.header.columns[i]!
            return {
              ...output,
              [fieldName]: String(value)
            }
          }, {} as Record<string, string>)
        })

        const partBase = partsByName[partChunk.header.name]
        if (!partBase) {
          partsByName[partChunk.header.name] = {
            assemblyType: partChunk.header.type,
            name: partChunk.header.name,
            rows: chunkRows
          }
          continue
        }

        const mergedRows = partBase.rows.map((row, i) => {
          return {
            ...row,
            ...chunkRows[i]!
          }
        })

        partsByName[partChunk.header.name] = {
          ...partBase,
          rows: mergedRows
        }
      }

      // This removes self referencing names in the BOM.
      // But we may need to tweek this to implement some
      // logic to merge self references or validate they
      // are the same.
      for (const partName in partsByName) {
        const part = partsByName[partName]!
        part.rows = part.rows.filter(row => {
          return row.name !== part.name
        })
      }

      const assemblies = Object.values(partsByName)
      const root = assemblies.find(part => part.assemblyType === 'Assembly')!
      return childHierarchies(root, '1', assemblies)
    }
  }
]

const snakeCasedColumns = (row: Record<string, string>) => {
  return Object.entries(row).reduce((cased, [k, v]) => {
    return {
      ...cased,
      [snakeCase(k)]: v
    }
  }, {} as BomRow)
}

type FileToRows = (config: MapperConfig, file: File) => Promise<BomRow[]>
const fileToRows: FileToRows = async (config, file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = event => {
      try {
        if (!event?.target?.result) {
          throw new Error('Empty file')
        }

        if (parser!.readAs === 'arraybuffer') {
          resolve(
            parser!.parse(event?.target?.result as ArrayBuffer, config)
          )
        }
        if (parser!.readAs === 'text') {
          resolve(
            parser!.parse(event?.target?.result as string, config)
          )
        }
      } catch (e) {
        reject(e)
      }
    }
    const fileType = getFileType(file)
    const parserId = config.fileToRows?.parsers?.find(p => p.fileType === fileType)?.parserId
    const parser = parserId ? newParsers.find(p => p.parserId === parserId) : parsers[fileType]
    reader.onerror = error => reject(error)
    if (parser!.readAs === 'arraybuffer') {
      reader.readAsArrayBuffer(file)
    }
    if (parser!.readAs === 'text') {
      reader.readAsText(file, 'UTF-8')
    }
  })
}

export default fileToRows



const childHierarchies: (part: Assembly, currentHierarchy: string, assemblies: Assembly[]) => BomRow[] =
  (part, currentHierarchy, assemblies) => {
  return part.rows.flatMap((row, i) => {
    const partWithHierarchy = {
      ...row,
      __metadata: {
        originalIndex: 0,
        messages: []
      },
      hierarchy: `${currentHierarchy}.${i + 1}`
    }
    const assembly = assemblies.find(a => a.name === row.name)
    if (assembly) {
      return [
        partWithHierarchy,
        ...childHierarchies(assembly, `${currentHierarchy}.${i + 1}`, assemblies),
      ]
    }
    return partWithHierarchy
  })
}

type ParseXlsxOptions = {
  firstRow?: number
}
const parseXlsxWorksheet = (worksheet: XLSX.WorkSheet, options?: ParseXlsxOptions) => {
  const firstRow = options?.firstRow || 0
  // Function to get the cell address in A1 notation
  function getCellAddress(row: number, col: number) {
    const colLetter = XLSX.utils.encode_col(col) // Converts 0 -> A, 1 -> B, etc.
    const rowNumber = row + 1 // Convert 0-based index to 1-based row number
    return `${colLetter}${rowNumber}`
  }

  // Get the range of the worksheet
  let minRow = Number.MAX_SAFE_INTEGER
  let maxRow = 0
  let minCol = Number.MAX_SAFE_INTEGER
  let maxCol = 0

  Object.keys(worksheet).forEach((cellAddress) => {
      if (cellAddress[0] === '!') return; // Skip special keys

      const { r, c } = XLSX.utils.decode_cell(cellAddress);
      if (r < minRow) minRow = Math.max(firstRow, r)
      if (r > maxRow) maxRow = r;
      if (c < minCol) minCol = c;
      if (c > maxCol) maxCol = c;
  });

  // Iterate through each cell in the worksheet
  const data = [];

  for (let R = minRow; R <= maxRow; ++R) {
      const row = [];
      for (let C = minCol; C <= maxCol; ++C) {
          const cellAddress = getCellAddress(R, C);
          const cell = worksheet[cellAddress];

          if (cell && cell.l && cell.l.Target) {
              row.push({
                isLink: true,
                  text: cell.v,     // Cell text
                  url: cell.l.Target // Hyperlink URL
              });
          } else {
              row.push(cell ? cell.v : ''); // If no hyperlink, just push cell value or empty string
          }
      }
      // remove empties
      if (!row.every(value => typeof value === 'string' && value.trim() === '')) {
        data.push({ cells: row, __originalIndex: R + 1 })
      }
  }



  const headers = data[0]!.cells
  return data.slice(1).map(row => {
    const parsedRow = row.cells.reduce((byHeader, cell, i) => {
      const key = headers[i]!
      if (cell && cell.isLink) {
        return {
          ...byHeader,
          [key]: cell.text,
          [`${key}_url`]: cell.url
        }
      }
      return {
        ...byHeader,
        [key]: cell
      }
    }, { __originalIndex: row.__originalIndex })
    return parsedRow
  })
}

const extractRootFields = (
  worksheet: XLSX.WorkSheet,
  config: ExtractRootConfig
): Partial<BomRow> => {
  const [startRow, endRow] = config.inRowRange
  const extractedFields: Record<string, string> = {
    __isRoot: 'true'
  }

  // First handle any constant fields
  for (const field of config.fields) {
    if (field.extractionType === 'constant') {
      extractedFields[field.outputFieldName] = field.input
    }
  }

  // For each row in the range
  for (let row = startRow; row <= endRow; row++) {
    // For each column, look for matching text
    let col = 0
    while (true) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
      const cell = worksheet[cellAddress]

      if (!cell) break // End of row

      const cellValue = cell.v?.toString().trim()
      if (!cellValue) {
        col++
        continue
      }

      // Check if this cell matches any of our search fields
      for (const field of config.fields) {
        if (field.extractionType === 'cellRightOfTextIncluding' &&
            cellValue.includes(field.input)) {
          // Get the value from the cell to the right
          const rightCellAddress = XLSX.utils.encode_cell({ r: row, c: col + 1 })
          const rightCell = worksheet[rightCellAddress]
          if (rightCell) {
            extractedFields[field.outputFieldName] = rightCell.v?.toString().trim() || ''
          }
        }
      }
      col++
    }
  }

  return {
    ...extractedFields,
    __metadata: {
      originalIndex: 0,
      messages: []
    }
  } as BomRow
}
