import type { TasksQuery, TaskStatus } from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { routes, useParams, Link } from '@redwoodjs/router'
import { prettyDateTime } from 'src/lib/formatters'
import { ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid'
import GenericFailure from '../Failure/Failure'
import { useAppContext } from 'src/lib/appContext'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import { useRightPanel } from '../RightPanel/RightPanel'
import { Tooltip2 } from "src/components/Tooltip"
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import Sortable from 'sortablejs'
import { useEffect, useState } from 'react'

export const QUERY = gql`
  query TasksQuery {
    tasks {
      id
      number
      title
      status
      priority
      createdAt
      updatedAt
      createdBy {
        id
        name
      }
      assignedTo {
        id
        name
      }
      assignedChangeOrder {
        number
        name
      }
      comments {
        id
      }
    }
  }
`

export const CREATE_TASK_MUTATION = gql`
  mutation CreateTaskMutation($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
      number
      title
      status
      priority
    }
  }
`

export const INSERT_TASK_BEFORE_MUTATION = gql`
  mutation InsertTaskBeforeMutation($input: InsertTaskBeforeInput!) {
    insertTaskBefore(input: $input) {
      id
      number
      status
    }
  }
`

export const INSERT_TASK_AFTER_MUTATION = gql`
  mutation InsertTaskAfterMutation($input: InsertTaskAfterInput!) {
    insertTaskAfter(input: $input) {
      id
      number
      status
    }
  }
`

export const UPDATE_TASK_MUTATION = gql`
  mutation TasksCellUpdateTaskMutation($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      number
      status
      priority
    }
  }
`

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

export const Failure = GenericFailure

const TASK_STATUSES = ['NotStarted', 'InProgress', 'Blocked', 'Done', 'WontDo'] as const

type Task = TasksQuery['tasks'][0]

const TaskCard = ({ task }: { task: Task }) => {
  const { panelRoute } = useRightPanel()

  return (
    <div
      className='block bg-white border border-gray-200 hover:border-blue-500 transition-colors duration-200 rounded-lg mb-3'
      data-task-id={task.id}
    >
      <Link
        className="block p-4"
        to={panelRoute('task', { taskNumber: task.number })}
      >
        <p className="text-md font-semibold text-gray-900 mb-2">
          #T-{task.number} - {task.title}
        </p>
        <p className="text-xs text-gray-600">
          {task.assignedTo ? `Assigned to ${task.assignedTo.name}` : 'Unassigned'}
          {task.assignedChangeOrder &&
            ` • #CO-${task.assignedChangeOrder.number}`}
        </p>
        {task.comments.length > 0 && (
          <p className="text-xs text-gray-500 mt-2">
            {task.comments.length} comment{task.comments.length === 1 ? '' : 's'}
          </p>
        )}
      </Link>
    </div>
  )
}

export const Success = ({ tasks }: CellSuccessProps<TasksQuery>) => {
  const orgId = useParams().orgId!
  const { me } = useAppContext()
  const [localTasks, setLocalTasks] = useState(tasks)

  const [createTask, { loading: createLoading }] = useMutation(CREATE_TASK_MUTATION)
  const [updateTask] = useMutation(UPDATE_TASK_MUTATION)
  const [insertBefore] = useMutation(INSERT_TASK_BEFORE_MUTATION)
  const [insertAfter] = useMutation(INSERT_TASK_AFTER_MUTATION)

  useEffect(() => {
    setLocalTasks(tasks)
  }, [tasks])

  useEffect(() => {
    TASK_STATUSES.forEach(status => {
      const el = document.getElementById(`tasks-${status}`)
      if (el) {
        Sortable.create(el, {
          group: 'tasks',
          animation: 150,
          draggable: '[data-task-id]',
          ghostClass: 'invisible',
          chosenClass: 'opacity-50',
          onEnd: async (evt) => {
            const { item, from, to, newIndex } = evt
            const taskId = item.getAttribute('data-task-id')
            const fromStatus = from.getAttribute('data-status') as TaskStatus
            const toStatus = to.getAttribute('data-status') as TaskStatus

            if (!taskId) return

            // Get the task numbers for the insert operation
            const tasksInNewStatus = localTasks.filter((t: Task) => t.status === toStatus)
            const targetTask = tasksInNewStatus[newIndex ?? 0]
            const nextTask = tasksInNewStatus[(newIndex ?? 0) + 1]

            try {
              // First update the status
              await updateTask({
                variables: {
                  input: {
                    id: parseInt(taskId),
                    status: toStatus
                  }
                }
              })

              // Then handle the ordering
              if (targetTask) {
                console.log('insert afert', taskId)
                // If dropping after a task
                await insertAfter({
                  variables: {
                    input: {
                      taskNumber: parseInt(taskId),
                      afterTaskNumber: targetTask.number
                    }
                  },
                  refetchQueries: [QUERY]
                })
              } else if (nextTask) {
                console.log('insert before', taskId)
                // If dropping before a task
                await insertBefore({
                  variables: {
                    input: {
                      taskNumber: parseInt(taskId),
                      beforeTaskNumber: nextTask.number
                    }
                  },
                  refetchQueries: [QUERY]
                })
              }

              // Refetch the entire list to get the server's state
              // console.log('refetching')
              // const { tasks } = await refetch()
              // console.log('refeteched', { tasks })
              // // Clear local state by setting it to the new server state
              // setLocalTasks(tasks)

            } catch (error) {
              // Revert to server state on error
              setLocalTasks(tasks)
              reportMutationError({
                errors: error,
                message: 'Failed to update task'
              })
            }
          }
        })
      }
    })
  }, [])

  const handleCreateTask = async (status: TaskStatus) => {
    const variables = {
      input: {
        title: 'New Task',
        body: '',
        status,
        priority: 1
      }
    }

    const { errors } = await createTask({
      variables,
      refetchQueries: [{ query: QUERY }],
      awaitRefetchQueries: true
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Failed to create task'
      })
    }
  }

  const tasksByStatus = TASK_STATUSES.reduce((acc, status) => {
    acc[status] = localTasks.filter(task => task.status === status)
      .sort((a, b) => {
        if (a.priority !== b.priority) {
          return  a.priority - b.priority
        }
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
    return acc
  }, {} as Record<typeof TASK_STATUSES[number], Task[]>)

  console.log({ tasksByStatus })

  const getStatusDisplayName = (status: TaskStatus) => {
    switch (status) {
      case 'NotStarted':
        return 'Not Started'
      case 'InProgress':
        return 'In Progress'
      case 'WontDo':
        return "Won't Do"
      default:
        return status
    }
  }

  return (
    <div className="overflow-x-auto p-8 min-h-full">
      <div className="flex gap-4 p-4 min-w-max">
        {TASK_STATUSES.map(status => (
          <div key={status} className="w-96">
            <div className="flex items-center mb-4">
              <h3 className="font-medium text-gray-900">
                {getStatusDisplayName(status)}
                <span className="ml-2 text-gray-500 text-sm">
                  ({tasksByStatus[status].length})
                </span>
              </h3>
              <div className='ml-auto'>
                <Tooltip2 content="Create new task" placement='top'>
                  <button
                    className="p-1 hover:bg-gray-100 rounded"
                    onClick={() => handleCreateTask(status)}
                    disabled={createLoading}
                  >
                    <PlusIcon className="h-5 w-5 text-gray-500" />
                  </button>
                </Tooltip2>
              </div>
            </div>
            <div
              id={`tasks-${status}`}
              data-status={status}
              className="bg-gray-50 rounded-lg p-2 min-h-[200px]"
            >
              {tasksByStatus[status].length > 0 ? (
                tasksByStatus[status].map(task => (
                  <TaskCard key={task.number} task={task} />
                ))
              ) : (
                <div className="text-gray-500 text-sm text-center p-4 bg-white rounded-lg border-2 border-dashed">
                  No tasks in {getStatusDisplayName(status).toLowerCase()}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
