import * as React from "react"
import validator from "validator"

export default () => {
  //compact = compact || false
  const [sent, setSent] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [invalid, setInvalid] = React.useState("")

  React.useEffect(() => {
      if (email.length > 0) {
          setInvalid("")
      }
  }, [email])

  const handleSubmit = async (event) => {
      event.preventDefault()

      if (email === "") return
      if (!validator.isEmail(email)) {
          setInvalid("Use a valid email")
          setEmail("")
          return
      }
      const HUBSPOT_FORM_URL = `https://api.hsforms.com/submissions/v3/integration/submit/43927499/edc7ea01-50b4-4aed-8931-6d938ccdab36`

      const payload = {
          submittedAt: Date.now(),
          fields: [
              {
                  objectTypeId: "0-1",
                  name: "email",
                  value: email,
              },
          ],
          context: {
              pageUri: window.location.href,
              pageName: "bomello landing",
          },
      }

    //   try {
    //       window.rdt("track", "SignUp")
    //   } catch (e) {
    //       console.error("Problem reporting conversion to Reddit")
    //   }

    //   try {
    //       window.gtag("event", "book_demo", {
    //           event_category: "engagement",
    //           event_label: "Demo Booking",
    //           value: 1, // optional, could represent some numeric value (e.g., 1 booking)
    //       })
    //   } catch (e) {
    //       console.error("Problem reporting conversion to GA")
    //   }

      try {
          const response = await fetch(HUBSPOT_FORM_URL, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
          })

          if (response.ok) {
              setSent(true)
          } else {
              setInvalid("Error, try again later")
          }
      } catch (error) {
          setInvalid("Error, try again later")
      }
  }

  let css = `

  `

  let placeHolder = "Enter email address"
  const inputStyle = {
      background: "rgba(255, 255, 255, 0.8)",
  }
  if (invalid) {
      placeHolder = invalid
      css += `
           .hubspot-form input::placeholder {
               color: red;
           }
      `
  }

  const signUpForm = <form onSubmit={handleSubmit} className='flex flex-col gap-5'>
    <img className="w-[110px]" src='/bomello.avif' />
    <div>Sign up for an account</div>
    <div>
        <div className="text-xs font-semibold mb-2">Email address</div>
        <input
            type="email"
            style={inputStyle}
            className="border rounded border-gray-300 py-2 px-3 text-sm w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={placeHolder}
        />
    </div>
    {/* Add other fields here */}
    <button className="text-xs font-bold block bg-[#103FEF] p-2 rounded text-white" type="submit">SIGN UP</button>
    </form>

  const doneMessage = <div className="text-center">
    <div className="text-lg font-semibold mb-3">You're signed up!</div>
    <div>An email with instructions will be sent to you later today.</div>
  </div>
  return (
        <div className="bg-brand-500 fixed top-0 left-0 right-0 bottom-0 justify-center flex items-center">
            <div className="w-[400px]">
                <div className="bg-white px-10 pb-10 pt-8 rounded-md">
                    {sent ? doneMessage : signUpForm}
                    <style>{css}</style>
                </div>
            </div>
        </div>
  )
}
