import { QuantityUnit } from 'src/shared/types'
import type { MapperConfig } from '../mapperConfigs'
import { SolidworksLogo } from 'src/components/CadLogo'

export const rockytalkieConfig: MapperConfig = {
  name: 'RockyTalkie',
  type: 'Upload',
  orgIds: ['rockytalkie'],
  rootIsTopLevel: true,
  Logo: SolidworksLogo,
  importOutputFields: {
    metadata: [],
  },
  standardizeBom: {
    columns: {
      summary: {
        type: 'simple',
        value: '{{row.summary}}',
      },
      hierarchy: {
        value: '{{row.hierarchy}}',
        type: 'simple',
      },
      partNumber: [{
        conditions: [{
          type: 'empty',
          column: 'part_number'
        }],
        onMatch: {
          type: 'alwaysGenerate',
          categoryId: '{{row.category_code}}'
        },
      }, {
        default: true,
        onMatch: {
          value: '{{row.part_number}}',
          type: 'simple',
        },
      }],
      cadRev: {
        value: '{{row.cad_revision}}',
        type: 'simple',
      },
      categoryId: {
        value: '{{row.category_code}}',
        type: 'simple',
      },
      name: {
        value: '{{row.name}}',
        type: 'simple',
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      isOffTheShelf: {
        value: 'false',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity',
      },
    },
  },
}
