import {
  DependencyDisplayFragment,
  Part,
  PartProto,
} from 'types/graphql'

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Link, routes, useParams } from '@redwoodjs/router'

import { dependencyDisplayFragment } from 'src/lib/queries'
import { ChangeOrderContextInfo } from './ChangeOrderChangesCell'
import type { PartChangePart, PartDiffSummary } from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import { StatusDot } from './LifecycleStatus/LifecycleStatus'
import { Popover } from '@headlessui/react'
import { letF } from 'shared/functional'
import { isVersionIncrement } from 'src/lib/version'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { PinnedIcon, AlwaysUpdateIcon } from './VersionRange/VersionRange'
import invariant from 'tiny-invariant'

//force into bundle
dependencyDisplayFragment;

const MAIN_BRANCH = -1

type DependencyPart = Pick<Part, 'cadRev' | 'version' | 'name' | 'partNumber' | 'lifeCycle' | 'branch' | 'publishId'> & {
  proto: Pick<PartProto, 'currentVersionString'>
}
type DependencyInput = Pick<DependencyDisplayFragment, 'section' | 'quantity' | 'units' | 'referenceDesignator' | 'toVersionRange'> & {
  to: DependencyPart
}

type PartDependencyChangesProps = {
  changeOrderComplete: boolean
  partDiff?: PartDiffSummary
  headPart?: PartChangePart
  isExpanded: boolean
  changeOrderContext?: ChangeOrderContextInfo
  viewMode: 'unified' | 'split'
}

export const displayUnits = (units?: string) => {
  const un = units?.toLowerCase()
  return (!un || un === 'units' || un === 'each') ? 'each' : units
}

export const DependencyChanges: React.FC<PartDependencyChangesProps> = ({
  partDiff,
  headPart,
  isExpanded,
  changeOrderContext,
  viewMode
}) => {
  const header = (part: PartChangePart) => {
    const nodeType = part.nodeType

    if (nodeType === 'PartGroup') {
      return <div>
        <div className='flex items-center gap-2 text-sm font-semibold text-gray-900 h-8 mb-2'>
          <div>Part Choice</div>
        </div>
        <div className='text-sm text-gray-700 mb-4'>
          One of the following parts can be chosen
        </div>
      </div>
    }

    return <div>
      <div className='flex items-center gap-2 text-sm font-semibold text-gray-900 h-8 mb-2'>
        <div>Bill of Materials</div>
      </div>
      <div className='text-sm text-gray-700 mb-4'>
        This list of parts is directly used by this assembly
      </div>
    </div>
  }

  if (!partDiff?.incomingPart) {
    invariant(headPart, 'Must have headPart or incoming part')
    return <div>
              {header(headPart)}
        <Dependencies
          part={headPart}
          dependencies={headPart.dependencies}
          sideBySide
          links={{
            changeOrder: changeOrderContext,
          }}
        />
    </div>
  }

  // Part is being created
  if (!headPart) {
    return <div data-testid='singleDeps'>
      {header(partDiff.incomingPart)}
      <Dependencies
        sideBySide
        part={partDiff.incomingPart}
        dependencies={partDiff.incomingPart.dependencies}
        links={{
          changeOrder: changeOrderContext,
        }}
      />
    </div>
  }

  const dependencyChangeInfo = partDiff.fields.dependencies.change
  const headDependencies = headPart.dependencies
  const incomingDependencies = partDiff.incomingPart.dependencies

  const changedPartNumbers = dependencyChangeInfo.filter(change => {
    return (change.quantity || change.referenceDesignator || change.version || change.wholeDependency || change.versionRange)
  }).map(d => d.partNumber)
  const showOnly = (isExpanded) ? undefined : changedPartNumbers
  if (!isExpanded && changedPartNumbers.length === 0) return null

  const changes = { headDependencies, dependencyChangeInfo }

  if (viewMode === 'unified') {
    return <div data-testid={`all-dependencies`}>
      {header(partDiff.incomingPart)}
      <Dependencies
        warningOldVersions
        showOnly={showOnly}
        part={partDiff.incomingPart}
        changes={changes}
        dependencies={incomingDependencies}
        sideBySide
        links={{
          changeOrder: changeOrderContext,
        }}
      />
    </div>
  }

  return <div data-testid={`all-dependencies`}>
    <div className='flex'>
      <div className='flex-1' data-testid='baseDeps'>
        {header(headPart)}
        <Dependencies
          showOnly={showOnly}
          part={partDiff.incomingPart}
          dependencies={headPart.dependencies}
          incomingDependencies={partDiff.incomingPart.dependencies}
          incomingChanges={changes}
          sideBySide
          links={{
            changeOrder: changeOrderContext,
          }}
        />
      </div>
      <div className='border-l border-gray-200 mx-10'></div>
      <div className='flex-1' data-testid='changeDeps'>
        {header(partDiff.incomingPart)}
        <Dependencies
          warningOldVersions
          showOnly={showOnly}
          part={partDiff.incomingPart}
          changes={changes}
          dependencies={incomingDependencies}
          sideBySide
          links={{
            changeOrder: changeOrderContext,
          }}
        />
      </div>
    </div>
  </div>
}

type PartDependenciesProps = {
  part: {
    partNumber: string
    version: string
  }
  warningOldVersions?: boolean
  dependencies: DependencyDisplayFragment[]
  incomingDependencies?: DependencyDisplayFragment[]
  incomingChanges?: {
    dependencyChangeInfo: PartDiffSummary['fields']['dependencies']['change'],
    headDependencies: DependencyInput[]
  }
  changes?: {
    dependencyChangeInfo: PartDiffSummary['fields']['dependencies']['change'],
    headDependencies: DependencyInput[]
  }
  showOnly?: string[]
  sideBySide?: boolean
  links?: {
    changeOrder?: ChangeOrderContextInfo
  }
}

export const Dependencies: React.FC<PartDependenciesProps> = ({
  warningOldVersions,
  dependencies,
  incomingDependencies,
  incomingChanges,
  changes,
  sideBySide,
  showOnly,
  links,
}) => {
  const orgId = useParams().orgId!

  let dependenciesToRender = dependencies
  let dependencyChanges = changes?.dependencyChangeInfo

  if (dependencyChanges) {
    const { headDependencies } = changes!
    dependenciesToRender = dependencyChanges.map(change => {
      if (change.wholeDependency === 'removed') {
        const deleted = headDependencies.find(head => head.to.partNumber === change.partNumber)
        return deleted;
      }
      return dependencies.find(incoming => incoming.to.partNumber === change.partNumber)
    }) as DependencyInput[]
  }

  if (showOnly) {
    dependenciesToRender = dependenciesToRender.filter(d =>
      showOnly.includes(d.to.partNumber))
  }

  const versionAndRange = (version: string, versionRange: string, versionNeedsRebase?: boolean) => {
    const extra = versionNeedsRebase ? <div>(in change order)</div> : null
    if (versionRange === '*') {
      return <div className='flex items-center gap-1'>
        <div>{version}</div>
        {extra}
        <AlwaysUpdateIcon />
      </div>
    }
    return <div className='flex items-center gap-1'>
      <div>{version}</div>
      {extra}
      <PinnedIcon version={version} />
    </div>
  }

  const depsNotInGroups = dependenciesToRender

  const renderDep = (d: DependencyInput) => {
    const change = dependencyChanges?.find(change => change.partNumber === d.to.partNumber)

    const colorClasses =
      change?.wholeDependency === 'added' ? {
        main: 'bg-green-100 border-green-300',
        header: 'bg-green-200',
        quantity: '',
        version: '',
        refdes: '',
        hoverContrast: 'hover:bg-green-300'
      } :
      change?.wholeDependency === 'removed' ? {
        main: 'bg-red-100 border-red-300',
        header: 'bg-red-200',
        quantity: '',
        version: '',
        refdes: '',
        hoverContrast: 'hover:bg-red-300'
      } :
      {
        main: 'border-gray-200',
        header: 'bg-gray-50',
        version: (change?.version || change?.versionRange) ? 'bg-yellow-100' : '',
        quantity: change?.quantity ? 'bg-yellow-100' : '',
        refdes: !change?.referenceDesignator ? '' :
                d.referenceDesignator ? 'bg-yellow-100' : 'bg-red-100',
        hoverContrast: 'hover:bg-gray-200'
      }

    return (
      <div key={d.to.partNumber} className={`${colorClasses.main} grow border rounded-lg text-xs`} data-testid={`dependency-${d.to.partNumber}-${change?.wholeDependency ?? 'standard'}`}>
        <div className={`text-gray-900 p-4 py-3 rounded-t-lg flex gap-2 font-semibold ${colorClasses.header}`}>
          <StatusDot lifeCycle={d.to.lifeCycle} size='sm' className='self-center'/>
          <div className='relative w-full'>
            <div className={`absolute w-full hover:w-fit hover:z-10 whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible ${colorClasses.hoverContrast} hover:bg-gray-200 px-1 -ml-1 !text-xs`}>
              {d.to.name}
            </div>
          </div>
          <div className='relative ml-auto font-medium pl-8 whitespace-nowrap flex gap-2 items-center'>
            {'#' + d.to.partNumber}
            {links &&
              <Popover className="relative">
                <Popover.Button className='relative top-[4px] -my-1'>
                  <EllipsisVerticalIcon className='w-4'/>
                </Popover.Button>
                <Popover.Panel className='absolute *:py-2 *:pl-3 *:pr-3 *:cursor-pointer text-xs z-30 min-w-40 mt-1 max-h-96 rounded-md bg-white py-1
shadow-lg ring-1 ring-black ring-opacity-5
focus:outline-none
 right-0'>
                  {letF(links.changeOrder?.isPartInOrder(d.to.partNumber), partInOrder =>
                    <>
                      {partInOrder &&
                        <a href={`#part-change-${d.to.partNumber}`} className='flex gap-1 hover:bg-brand-500 hover:text-white'>View in change order</a>
                      }
                      {(d.to.branch === MAIN_BRANCH || typeof partInOrder?.proto?.currentPublishId === 'number') &&
                        <>
                          <Link to={routes.part({ orgId, partNumber: d.to.partNumber })} className='flex gap-1 hover:bg-brand-500 hover:text-white'>View part page</Link>
                        </>
                      }
                    </>
                  )}

                </Popover.Panel>
              </Popover>
            }
          </div>
        </div>
        <div className={`p-4 py-3 flex flex-col gap-4 text-xs rounded-b-lg`}>
          <div className='flex gap-1'>
            <div className='flex gap-1 min-w-28 items-center' data-testid='dep-version'>
              <div className='text-gray-900 font-semibold'>Version</div>
              <div className={`px-1 w-full ${colorClasses.version}`}>
                {versionAndRange(d.to.version, d.toVersionRange, change?.versionNeedsRebase)}
              </div>
            </div>
            <div className='flex gap-1 min-w-32 items-center' data-testid='dep-quantity'>
              <div className='text-gray-900 font-semibold'>Quantity</div>
              <div className={`px-1 w-full ${colorClasses.quantity}`}>
                {d.quantity} {displayUnits(d.units)}
              </div>
            </div>
            {(d.referenceDesignator || change?.referenceDesignator) &&
              <div className='flex gap-1 grow items-center' data-testid='dep-refdes'>
                <div className='text-gray-900 font-semibold'>Designator</div>
                <div className={`px-1 min-w-28 h-full ${colorClasses.refdes}`}>{d.referenceDesignator}</div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  const ungroupedDeps = () => {
    if ((dependenciesToRender.length === 0)) {
      return <div className='text-center text-sm italic text-gray-600 bg-gray-100 p-5 rounded-md col-span-2'>
        There are no materials associated with this part
      </div>
    }


    let prefixElements: JSX.Element[] = []
    let postfixElements: JSX.Element[] = []

    const toRender = depsNotInGroups

    const tail = toRender.map(renderDep)

    return prefixElements.concat(tail).concat(postfixElements)
  }

  return <div className={`grid ${sideBySide ? 'grid-cols-1 gap-3 -mx-1' : 'grid-cols-2 gap-x-5 gap-y-3 -mx-1'}`}>
    {ungroupedDeps()}
  </div>
}
