import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import type { ThumbnailQuery } from 'types/graphql'
import Thumbnail from 'src/components/ThumbnailViewer'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import GenericFailure from '../Failure/Failure'

export const QUERY = gql`
  query ThumbnailQuery($fileId: Int!) {
    getFile(id: $fileId) {
      id
      url
      timestamp
    }
  }
`

export const Loading = ({ className }: { className?: string }) => (
  <div className={className}>
    <LoadingSpinner className='flex p-10 items-center justify-center' />
  </div>
)

export const Failure = ({ error }: CellFailureProps) => <GenericFailure error={error} />

interface Props extends CellSuccessProps<ThumbnailQuery> {
  fileId: number
  className?: string
}

export const Success = ({ getFile, fileId, className }: Props) => {
  return (
    <Thumbnail
      className={className}
      artifact={{
        id: fileId,
        fileId,
        file: {
          url: getFile.url,
          timestamp: getFile.timestamp
        }
      }}
    />
  )
}