import { useState } from 'react';
import type { Organization, PartCompareQuery, PartCompareQueryVariables } from 'types/graphql'
import type { CellSuccessProps } from '@redwoodjs/web'

import { useParams, routes, navigate } from '@redwoodjs/router';
import calculateAllChanges, { PartDiffSummary } from 'src/components/ChangeOrderChangesCell/calculateAllChanges'
import { PartChange, LifecycleStatus } from '../ChangeOrderChangesCell'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import * as ListBox from 'src/components/ListBox'
import { tabularizeChanges, useChangeExport, ChangeExportButtons } from '../ExportChanges';
import { ConditionalModal } from '../Modal';
import Button from 'src/components/Button'

export const QUERY = gql`
  query PartCompareQuery($basePartNumber: String!, $baseVersion: String!, $incomingPartNumber: String!, $incomingVersion: String!) {
    currentOrg {
      id
      name
      distributors {
        id
        name
      }
      metadataSchema
    }
    baseProto: partProto(partNumber: $basePartNumber) {
      partNumber
      owner {
        name
      }
      category {
        id
        name
      }
      protoUpdatedBy {
        name
      }
      instances {
        version
        branch
        publishId
        inChangeOrder {
          number
          name
        }
      }
      selectedVersion: instance(version: $baseVersion) {
        name
        isRoot
        isOffTheShelf
        partNumber
        version
        summary
        changeMessage
        transitionPlan
        cadRev
        metadata
        lifeCycle
        artifacts {
          id
          filename
          fileId
          file {
            url
            inlineUrl
            contentType
            size
          }
        }
        sources {
          id
          url
          comment
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          perQuantity
          perQuantityUnit
          stock
        }
        dependencies {
          section
          units
          quantity
          toVersionRange
          to {
            id
            lifeCycle
            name
            proto {
              id
              currentVersionString
            }
            partNumber
            publishId
            version
            cadRev
            branch
          }
        }
      }
    }
    incomingProto: partProto(partNumber: $incomingPartNumber) {
      partNumber
      owner {
        name
      }
      category {
        id
        name
      }
      protoUpdatedBy {
        name
      }
      instances {
        version
        branch
        publishId
        inChangeOrder {
          number
          name
        }
      }
      selectedVersion: instance(version: $incomingVersion) {
        name
        isRoot
        isOffTheShelf
        partNumber
        version
        summary
        changeMessage
        transitionPlan
        cadRev
        metadata
        lifeCycle
        artifacts {
          id
          filename
          fileId
          file {
            url
            inlineUrl
            contentType
            size
          }
        }
        sources {
          id
          url
          comment
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          perQuantity
          perQuantityUnit
          stock
        }
        dependencies {
          section
          units
          quantity
          toVersionRange
          to {
            id
            publishId
            lifeCycle
            name
            proto {
              id
              currentVersionString
            }
            partNumber
            version
            cadRev
            branch
          }
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center' />

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'
import PartSelect from '../PartSelect/PartSelect';
import { useLifeCycleStages } from 'src/lib/lifecycle';
import invariant from 'tiny-invariant';
export const Failure = GenericFailure

export const Success = ({ currentOrg, ...props }: PartCompareQueryVariables & CellSuccessProps<PartCompareQuery, PartCompareQueryVariables>) => {
  return <PartCompare currentOrg={currentOrg} {...props} />
}

const PartCompare = ({
  baseProto,
  basePartNumber,
  baseVersion,
  incomingProto,
  incomingVersion,
  incomingPartNumber,
  currentOrg
}: PartCompareQueryVariables & CellSuccessProps<PartCompareQuery, PartCompareQueryVariables>) => {
  const orgId = useParams().orgId!
  if (!baseProto) throw new Error(`Base part not found: ${basePartNumber}`)
  if (!incomingProto) throw new Error(`Base part not found: ${incomingPartNumber}`)

  const allChanges = calculateAllChanges(currentOrg, [{
    ...incomingProto.selectedVersion,
    proto: incomingProto,
    updateTo: baseProto.selectedVersion
  }])
  const partDiff = allChanges[incomingPartNumber]
  console.log({ allChanges, basePartNumber, incomingProto, baseProto })
  invariant(partDiff)

  const [showExport, setShowExport] = useState(false)

  const renderSelectors = (proto: typeof baseProto, onChange: (pn: string, v: string) => void) => {
    const instances = proto.instances.filter(p => {
      // main branch
      return p.branch === -1
    })
    // sorted latest version first
    instances.sort((a, b) => {
      // those without publish ID will be filtered anyway
      return b.publishId! - a.publishId!
    })

    const versionString = proto.selectedVersion.version

    return <div className='text-xs flex gap-2'>
      <PartSelect
        value={proto.partNumber}
        sort={(a, b) => {
          if (a.partNumber === proto.partNumber) return -1
          if (b.partNumber === proto.partNumber) return 1
          return 0
        }}
        // omit={[proto.partNumber]}
        onSelect={p => onChange(p.partNumber, p.version)} />
      <ListBox.ListBox value={versionString} onChange={v => onChange(proto.partNumber, v)}>
        {({ open }) => (<>
          <div className="relative inline-block text-sm">
            <ListBox.Button className='' displayValue={`Version ${versionString}`} />
            <ListBox.Options open={open}>
              {instances.map(o => {
                return <ListBox.Option value={o.version} display={`Version ${o.version}`} key={o.version} />
              })}
            </ListBox.Options>
          </div>
        </>
        )}
      </ListBox.ListBox>
    </div>
  }

  return <div>
    {showExport && <CompareExportModal
      currentOrg={currentOrg}
      parts={Object.values(allChanges)}
      baseProto={baseProto}
      incomingProto={incomingProto}
      onClose={() => setShowExport(false)}
    />}
    <div className='flex mt-8 mb-4'>
      <div>
        <div className='text-2xl mb-1'>Compare Changes</div>
        <div className='text-sm text-gray-600'>Compare changes across parts and versions</div>
      </div>
      <Button type='button' className='ml-auto' onClick={() => setShowExport(true)} >Export</Button>
    </div>
    <div className=''>
      <div className='pb-5 flex flex-col pt-6'>
        <div className='flex mb-6 mt-4'>
          <div className="flex-1 flex justify-between items-center">
            {/* Left version list is reversed, UI is more intuitive that way */}
            {renderSelectors(baseProto, (pn, v) => {
              navigate(routes.partCompare({
                orgId,
                basePartNumber: pn,
                baseVersion: v,
                incomingPartNumber,
                incomingVersion
              }))
            })}
            <LifecycleStatus disabled part={baseProto.selectedVersion} />
          </div>
          <div className='border-l border-gray-200 mx-10'></div>
          <div className="flex-1 flex justify-between items-center">
            {renderSelectors(incomingProto, (pn, v) => {
              navigate(routes.partCompare({
                orgId,
                basePartNumber,
                baseVersion,
                incomingPartNumber: pn,
                incomingVersion: v,
              }))
            })}
            <LifecycleStatus disabled part={incomingProto.selectedVersion} />
          </div>
        </div>
    </div>
    <PartChange
      hideVersion
      distributors={currentOrg.distributors}
      changeOrderComplete
      partDiff={partDiff}
      hideSwitch
      viewMode='split'
      headPart={baseProto.selectedVersion}
      incomingPart={incomingProto.selectedVersion}
    /></div>
    </div>
}

type CompareExportModalProps = {
  currentOrg: Pick<Organization, 'metadataSchema' | 'name'>
  parts: PartDiffSummary[]
  baseProto: NonNullable<CellSuccessProps<PartCompareQuery, PartCompareQueryVariables>['baseProto']>
  incomingProto: NonNullable<CellSuccessProps<PartCompareQuery, PartCompareQueryVariables>['incomingProto']>
  onClose: () => void
}
const CompareExportModal = ({parts, currentOrg, baseProto, incomingProto, onClose}: CompareExportModalProps) => {
  const lifeCycleStages = useLifeCycleStages()

  const leftColumnName = `#${baseProto.partNumber} v${baseProto.selectedVersion.version}`
  let rightColumnName = `#${incomingProto.partNumber} v${incomingProto.selectedVersion.version}`
  if (rightColumnName === leftColumnName) {
    rightColumnName = 'Same Part'
  }

  const table = tabularizeChanges({lifeCycleStages, parts, currentOrg, leftColumnName, rightColumnName})
  const fileName =
    `${currentOrg.name}_compare_#${baseProto.partNumber}-v${baseProto.selectedVersion.version}_to_${incomingProto.partNumber}-v${incomingProto.selectedVersion.version}`

  const exportParams = useChangeExport({table, parts, fileName, dropPartNumber: true})

  return (
    <ConditionalModal className='!w-[30rem]' onClose={onClose}>
      <ChangeExportButtons title={'Export Comparison'}  exportParams={exportParams} onComplete={onClose} />
    </ConditionalModal>
  )
}


