import { useState, useRef, PropsWithChildren, useEffect, useCallback, SetStateAction, Dispatch } from 'react';
import { createPortal } from 'react-dom';
import { produce, Draft } from 'immer';

import type {
  Source,
  Maybe,
  MetadataType,
  SetAggregationConfigs,
  SetAggregationConfigsVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import AutosizeInput from 'react-input-autosize'
import { ArrowsPointingInIcon, TrashIcon, ArrowsPointingOutIcon, FunnelIcon, ArrowTurnRightDownIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { CheckIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { FormError } from 'src/components/Form'
import PartHierarchy, { HierarchyController, PartHierarchyHeader, EditableAggregator, HierarchyMode } from '../PartHierarchy/PartHierarchy';
import { codes as currencyCodes } from 'currency-codes'
import { letF } from 'api/src/shared/functional';
import {
  SumAggregator,
  Aggregator,
  SumFieldConfig,
  MaxAggregator,
  AndAggregator,
  AggregatorTarget,
  NumericTarget,
  BooleanTarget
} from './aggregation';
import * as ListBox from '../ListBox';
import Button from 'src/components/Button'
import { ConditionalModal } from '../Modal';
import { useMetadataSchema } from 'src/lib/metadata'
import { QUERY } from './PartCell';
import { ExchangeRates } from 'src/lib/exchangeRates'
import { Container as TooltipContainer, Message as TooltipMessage } from 'src/components/Tooltip'
import groupBy from 'lodash.groupby';
import keyBy from 'lodash.keyby';
import ChangeOrderSelectDialog from '../ChangeOrderSelectDialog/ChangeOrderSelectDialog';
import { useScrollListener } from '../hooks';
import { reportMutationError } from 'src/lib/reportError';
import { calculateExports, PlainCsvLink, ExportDialog, generateDownloadBundle } from './ExportDialog';
import ToggleSwitch from '../ToggleSwitch';
import { useRightPanel } from '../RightPanel/RightPanel'
import ToolButton from '../ToolButton';

const SET_AGGREGATION_CONFIGS = gql`
  mutation SetAggregationConfigs($input: SetAggregationConfigsInput!) {
    setAggregationConfigs(input: $input) {
      id
      aggregationConfigs {
        name
        reducer
        targetType {
          type
          unit
        }
        metadata {
          key
          multiplyByQuantity
        }
        sources {
          key
          multiplyByQuantity
        }
      }
    }
  }
`

const ThemePortal = ({ children }: PropsWithChildren) => {
  const appPortal = useRef<Element>()
  if (!appPortal.current) {
    appPortal.current = document.querySelector('#mainPortal')!
  }
  return appPortal.current && createPortal(children, appPortal.current)
}

interface ChangeOrderWithNumber {
  number: number
}

export type ToolBarMode = 'agg' | null
type FullScreenHierarchyProps = {
  initialMode?: React.MutableRefObject<ToolBarMode>
  flatExportOption?: Boolean
} & Pick<AggregationControlPanelProps, 'hierarchyController'>

export default ({ initialMode, flatExportOption, ...rest }: FullScreenHierarchyProps) => {
  const { hierarchyController } = rest
  const [deleteCandidate, setDeleteCandidate] = useState<Maybe<EditableAggregator>>(null)
  const [showViewConfig, setShowViewConfig] = useState<boolean>(false)
  const [showAddToChangeOrderDialog, setShowAddToChangeOrderDialog] = useState<boolean>(false)


  const [hasHorizontalScroll] = useScrollListener()

  const { fullScreen, onToggleFullscreen, filterController, hierarchyMode } = hierarchyController

  const [exportDialogOpen, setExportDialogOpen] = useState(false)

  const aggregators: EditableAggregator[] = (hierarchyController?.aggregators || []) as EditableAggregator[]

  const handleEscape = useCallback((event: KeyboardEvent) => {
    if (deleteCandidate || showViewConfig || showAddToChangeOrderDialog) return;

    const { key } = event;
    if (key === 'Escape' && fullScreen && onToggleFullscreen) {
      onToggleFullscreen()
    }
  }, [deleteCandidate, showViewConfig])
  useEffect(() => {
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [handleEscape]);

  useEffect(() => {
    window.scrollTo(0, 0), []
    if (initialMode?.current) {
      initialMode.current = null
    }
  }, [])


  const { toolbarMode, showAggregations } = hierarchyController


  const nothingSelected = hierarchyController.roots.every(root => root.selectedNodes.length === 0)

  const exportInfo = calculateExports({ hierarchyController })

  const toolbar = <div className={`
    pt-3 pb-3 flex gap-2 leading-none bg-white items-center
  `}>
    {
      hierarchyController.onHierarchyModeClick ? <div className="flex-1">
        <FlatHierarchySwitch currentMode={hierarchyMode} onHierarchyModeClick={hierarchyController.onHierarchyModeClick} />
      </div> : null
    }
    <ToolButton
      onClick={() => hierarchyController?.setSelectable?.(!hierarchyController.selectable)}
      testId="select-parts"
      rightText="Select Parts"
    >
      <input
        type="checkbox"
        checked={hierarchyController.selectable}
        aria-describedby="select-children"
        className={`
          rounded border-gray-300
          text-brand-600 focus:ring-brand-600 cursor-pointer
        `}
        onClick={(e) => e.stopPropagation()}
      />
    </ToolButton>
    <ToolButton
      onClick={() => filterController.addFilter()}
      tooltipContent="Add Filter"
      testId="add-filter"
    >
      <FunnelIcon />
    </ToolButton>
    <ToolButton
      onClick={() => setShowAddToChangeOrderDialog(true)}
      tooltipContent={`Add${hierarchyController.selectable ? ' Selection' : ''} to Change Order`}
      disabled={hierarchyController.selectable && nothingSelected}
      testId="add-to-change-order"
    >
      <ArrowTurnRightDownIcon />
    </ToolButton>
    <ToolButton
      onClick={() => generateDownloadBundle({ files: exportInfo.bundle, filename: `${exportInfo.name}.zip` })}
      tooltipContent={`Export${hierarchyController.selectable ? ' Selection' : ''} Bundle`}
      disabled={exportInfo.bundle.length === 0 || hierarchyController.selectable && nothingSelected}
      testId='materials-more-export-bundle'
    >
      <ArrowDownTrayIcon />
    </ToolButton>
    <ToolButton
      testId="materials-more"
      menu={{
        options: [
          {
            value: 'configureAggs',
            display: toolbarMode === 'agg' ? 'Hide Aggregation Configuration' : 'Configure Aggregations',
            disabled: !showAggregations
          },
          {
            value: 'viewSettings',
            display: 'View Settings'
          },
          {
            value: 'export-csv',
            display: <PlainCsvLink testId={'materials-more-export-bom'} body={exportInfo.csv} fileName={`${exportInfo.name}.csv`}>
              Export{(hierarchyController.selectable ? ' Selection' : '')} BOM CSV
            </PlainCsvLink>,
            disabled: exportInfo.csv.length === 0 || hierarchyController.selectable && nothingSelected
          },
          ...(flatExportOption ? [{
            value: 'export-csv-flat',
            display: <PlainCsvLink testId={'materials-more-export-bom-flat'} body={exportInfo.flatCsv} fileName={`${exportInfo.name}-flat.csv`}>
              Export{(hierarchyController.selectable ? ' Selection' : '')} BOM CSV (flattened)
            </PlainCsvLink>,
            disabled: exportInfo.flatCsv.length === 0 || hierarchyController.selectable && nothingSelected
          }] : []),
        ],
        onSelect: (value) => {
          switch (value) {
            case 'configureAggs':
              if (toolbarMode === 'agg') {
                hierarchyController.setToolbarMode(null)
                hierarchyController.setSelectedAgg(null)
              }
              else {
                hierarchyController.setToolbarMode('agg')
                if (!hierarchyController.fullScreen && onToggleFullscreen) onToggleFullscreen()
                if (aggregators.length) {
                  hierarchyController.setSelectedAgg(0)
                }
              }
              break;
            case 'viewSettings':
              setShowViewConfig(true)
              break;
          }
        }
      }}
    >
      <EllipsisVerticalIcon />
    </ToolButton>
    {hierarchyController.onToggleFullscreen ?
      <ToolButton
        onClick={hierarchyController.onToggleFullscreen}
        tooltipContent={fullScreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        testId='fullscreen'
      >
        {fullScreen ?
          <ArrowsPointingInIcon /> :
          <ArrowsPointingOutIcon />
        }
      </ToolButton>
    : null}
  </div>

  const controlPanel = toolbarMode === 'agg' && (Boolean(hierarchyController.aggregators.length) &&
      <AggregationControlPanel {...rest}
        hierarchyController={hierarchyController}
        deleteCandidate={deleteCandidate}
        setDeleteCandidate={setDeleteCandidate}
      />
    )

  return <FullScreenWrapper toolbar={toolbar} fullScreen={fullScreen} controlPanel={controlPanel}>
    <ExportDialog
      open={exportDialogOpen}
      setOpen={setExportDialogOpen}
      hierarchyController={hierarchyController} />
    {showViewConfig &&
      <ConditionalModal className='' onClose={() => setShowViewConfig(false)}>
        <div className='p-1 flex flex-col gap-8'>
          <div className='flex flex-col gap-3'>
            <div className=''>View Settings</div>
            <div className='flex justify-between'>
              <div className='max-w-60'>
                <div className='text-sm mb-1'>Target Currency</div>
                <div className='text-xs text-gray-600'>All monetary values will be converted to this currency</div>
              </div>
              <ListBox.ListBox
                defaultValue={hierarchyController.targetCurrency?.unit ?? 'Default'}
                onChange={unit => {
                  hierarchyController.setTargetCurrency(unit === 'Default' ? undefined : {
                    type: 'Price',
                    unit
                  })
                }} >
                <div className="relative">
                  <ListBox.UncontrolledButton size='sm' className='w-[81px]' wrapperClassName='' />
                  <div className='absolute z-50 right-0'>
                    <ListBox.Options className='text-xs'>
                      <ListBox.Option key={'__'} className='py-3' value={'Default'} display={'Default'} />
                      {currencyCodes().map(t => (
                        <ListBox.Option key={t} className='py-3' value={t} display={t} />
                      ))}
                    </ListBox.Options>
                  </div>
                </div>
              </ListBox.ListBox>
            </div>
          </div>
        </div>
      </ConditionalModal>
    }
    {showAddToChangeOrderDialog &&
      <ChangeOrderSelectDialog
        onSelect={(selected) => {
          if (selected.createChangeOrder) {
            hierarchyController.createChangeOrderWithSelection()
          }
          if (selected.changeOrderNumber !== null) {
            hierarchyController.addSelectionToChangeOrder(selected.changeOrderNumber)
          }
          setShowAddToChangeOrderDialog(false)
        }}
        onCancel={() => setShowAddToChangeOrderDialog(false)} />
    }<>
      <PartHierarchy
        hierarchyController={hierarchyController} />
    </>
  </FullScreenWrapper>
}

type AggregationConfigRowProps<T extends string> = Omit<SumFieldConfig<T>, 'key'> & {
  valueKey: SumFieldConfig<T>['key']
  zone: 'metadata' | 'sources'
  handleChange: (value: SumFieldConfig<any>) => void
  handleDelete: () => void
}
const AggregationConfigRow = <T extends string>
  ({ zone, valueKey, multiplyByQuantity, handleChange, handleDelete }: AggregationConfigRowProps<T>) => {
  return <div className='inline-flex leading-none items-center bg-indigo-600 text-white rounded-lg px-1 h-7 text-sm mr-2 gap-1'>
    <div className='px-2'>
      {zone === 'sources' ? 'sources.' : ''}{valueKey}
    </div>
    {/* <div className='border-l border-indigo-100 h-7'></div> */}
    {/* <div
      className='flex px-2 py-0.5'
      onClick={() => handleChange({
        multiplyByQuantity: !multiplyByQuantity,
        key: valueKey
      })}
      >
        {multiplyByQuantity ? 'by quantity' : 'once'}</div> */}
    <div className='border-l border-indigo-100 h-7'></div>
    <button className='rounded px-0.5 py-0.5 hover:bg-indigo-400 cursor-pointer' onClick={handleDelete}>
      <XMarkIcon className='w-4' />
    </button>
  </div>
}

type AggregationControlPanelProps = {
  hierarchyController: HierarchyController
  deleteCandidate: Maybe<EditableAggregator>
  setDeleteCandidate: Dispatch<SetStateAction<Maybe<EditableAggregator>>>

}

const AggregationControlPanel = ({ hierarchyController, ...rest }: AggregationControlPanelProps) => {
  const { savedAggregators, exchangeRates } = hierarchyController
  const { setAggregators = () => { } } = hierarchyController
  const aggregators: EditableAggregator[] = hierarchyController.aggregators as EditableAggregator[]

  const { setToolbarMode, selectedAgg } = hierarchyController

  const {
    deleteCandidate,
    setDeleteCandidate,
  } = rest

  const [updateAggregationConfigs, { loading, error }] =
    useMutation<SetAggregationConfigs, SetAggregationConfigsVariables>(SET_AGGREGATION_CONFIGS)

  const handleSave = async (agg: EditableAggregator, isDelete?: boolean) => {
    if (!agg.isDirty ||
      (agg.metadataConfig.length === 0 && agg.sourcesConfig.length === 0)) return
    const newAggs = [...savedAggregators]

    const savedIndex = agg.savedIndex

    if (agg.isNew) { newAggs.push(agg) }
    else if (isDelete) { newAggs.splice(savedIndex!, 1) }
    else { newAggs[savedIndex!] = agg }

    const variables: SetAggregationConfigsVariables = {
      input: {
        aggregationConfigs: newAggs.map(({ name, reducer, targetType, metadataConfig, sourcesConfig }) => ({
          name,
          reducer,
          targetType,
          metadata: metadataConfig ?? [],
          sources: sourcesConfig ?? [],
        }))
      }
    }
    const { errors } = await updateAggregationConfigs({
      variables,
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating aggregation config`
      })
    }
    else {
      if (isDelete) {
        modifyAggregatorState(agg, (idx, aggs) => {
          aggs.splice(idx, 1)
          aggs.forEach((a, newIdx) => {
            a.index = newIdx
            if (newIdx >= idx && a.savedIndex) {
              a.savedIndex -= 1
            }
          })
        })
      }
      else {
        modifyAggregatorState(agg, (idx, aggs) => {
          aggs[idx]!.isDirty = false
          aggs[idx]!.isNew = false

          if (agg.isNew) {
            aggs[idx]!.savedIndex = savedAggregators.length
          }
        })
      }
    }
  }

  const modifyAggregator = (agg: EditableAggregator, f: (idx: number, draft: Draft<EditableAggregator[]>) => void) =>
    produce(aggregators, d => void (f(agg.index, d)))

  const modifyAggregatorState = (...args: Parameters<typeof modifyAggregator>) => {
    const state = modifyAggregator(...args)
    setAggregators(state)
    return state
  }

  const metadataSchema = useMetadataSchema()!

  const editAggProp = <T extends keyof EditableAggregator>(agg: EditableAggregator, prop: T, value: EditableAggregator[T]) =>
    modifyAggregatorState(agg, (idx, aggs) => {
      aggs[idx]![prop] = value
      aggs[idx]!.isDirty = true
    })

  const editFieldCfg = <T extends 'metadataConfig' | 'sourcesConfig'>(agg: EditableAggregator, prop: T, cfgIndex: number) => (value: Aggregator[T][number]) =>
    modifyAggregatorState(agg, (idx, aggs) => {
      aggs[idx]![prop][cfgIndex] = value
      aggs[idx]!.isDirty = true
    })

  const deleteFieldCfg = <T extends 'metadataConfig' | 'sourcesConfig'>(agg: EditableAggregator, prop: T, cfgIndex: number) => () =>
    modifyAggregatorState(agg, (idx, aggs) => {
      const selectedAgg = aggs[idx]!
      selectedAgg[prop].splice(cfgIndex, 1)
      const totalFields = selectedAgg.metadataConfig.length + selectedAgg.sourcesConfig.length
      if (totalFields === 0) selectedAgg.targetType = { type: 'Number', unit: undefined as never }
      selectedAgg.isDirty = true
    })

  const discardChanges = (agg: EditableAggregator): void => {
    if (agg.isNew) {
      setAggregators(produce(aggregators, aggs => {
        aggs.splice(agg.index, 1)
        aggs.forEach((a, idx) => a.index = idx)
        if (aggs.length === 0) setToolbarMode(null)
      }))
      hierarchyController.setSelectedAgg(aggregators.length ? 0 : null)
    }
    else {
      const original = savedAggregators[agg.savedIndex!]!
      setAggregators(produce(aggregators, aggs => {
        aggs[agg.index] = {
          ...original,
          index: agg.index,
          isActive: agg.isActive,
          savedIndex: agg.savedIndex,
        }
      }))
    }
  }

  useSaveShortcut(() => selectedAgg && handleSave(selectedAgg))

  type FieldZone = keyof Pick<Aggregator, 'metadataConfig' | 'sourcesConfig'>
  type SourceOption = keyof Pick<Source, 'stock' | 'price'>
  type OptionKey = `${keyof Pick<Aggregator, 'metadataConfig'>}.${string}` | `${keyof Pick<Aggregator, 'sourcesConfig'>}.${SourceOption}`
  type AllowedRangeType = Extract<MetadataType, 'Price' | 'Number' | 'Mass'>
  type AllowedBooleanType = Extract<MetadataType, 'Boolean'>
  type FieldOption = {
    type: AllowedRangeType | AllowedBooleanType
    key: OptionKey
    display: string
    disabled: boolean
  }

  const rangeTypes = ['Price', 'Number', 'Mass'] as (MetadataType)[]
  const booleanTypes = ['Boolean'] as (MetadataType)[]

  const getFieldOptions = () => {
    if (!selectedAgg) return []

    const metadataTypesAllowed = selectedAgg.reducer === 'And' ? booleanTypes : rangeTypes

    const totalFields = selectedAgg.metadataConfig.length + selectedAgg.sourcesConfig.length
    const mustBeType = totalFields > 0 ? selectedAgg.targetType : null

    const metadataByKey = keyBy(selectedAgg.metadataConfig, 'key')
    const metadataOptions: FieldOption[] = Object.entries(metadataSchema)
      .filter(e => metadataTypesAllowed.includes(e[1].type))
      .map(([key, cfg]) => ({
        type: cfg.type as AllowedRangeType | AllowedBooleanType,
        disabled: Boolean(metadataByKey[key] || (mustBeType && cfg.type !== mustBeType.type)),
        key: `metadataConfig.${key}`,
        display: cfg.displayName
      }))

    if (selectedAgg.reducer === 'And') return metadataOptions

    const sourcesByKey = keyBy(selectedAgg.sourcesConfig, 'key')

    const sourceOptions: FieldOption[] = [{
      disabled: Boolean(sourcesByKey['price'] || (mustBeType && mustBeType.type !== 'Price')),
      key: `sourcesConfig.price` as OptionKey,
      display: 'Source Price',
      type: 'Price' as AllowedRangeType,
    },
    {
      disabled: Boolean(sourcesByKey['leadTimeDays'] || (mustBeType && mustBeType.type !== 'Number')),
      key: `sourcesConfig.leadTimeDays` as OptionKey,
      display: 'Source Lead Time',
      type: 'Number' as AllowedRangeType,
    }
      // sources stock isn't useful in any way (yet?)
      // , {
      //   disabled: Boolean(selectedAgg.sourcesConfig.find(sCfg => sCfg.key === 'stock')),
      //   key: `sourcesConfig.stock` as OptionKey,
      //   display: 'Source Stock',
      //   type: 'Number' as AllowedType,
      // }
    ]
    return [...metadataOptions, ...sourceOptions]
  }


  const allFieldOptions = getFieldOptions()
  const fieldOptionsByType = Object.entries(groupBy(allFieldOptions, 'type'))

  return (
    <div className='flex gap-6 bg-gray-100 p-8 relative border-t border-gray-400'>
      {deleteCandidate &&
        <ConditionalModal className='w-96 mt-10' onClose={() => setDeleteCandidate(null)}>
          <div className='p-4 flex flex-col gap-4'>
            <div className='text-sm text-gray-700'>
              Are you sure you want to delete the aggregator named "{deleteCandidate.name}"?
            </div>
            <div className='flex'>
              <Button onClick={() => setDeleteCandidate(null)}>Cancel</Button>
              <Button variant='red' className='ml-auto' onClick={() => {
                setDeleteCandidate(null)
                hierarchyController.setSelectedAgg(aggregators.length - 1 <= 0 ? null : 0)
                handleSave(deleteCandidate, true)
              }}>Delete</Button>
            </div>
          </div>
        </ConditionalModal>
      }
      <div className='grow'>
        {selectedAgg && letF(selectedAgg!, ({ name, metadataConfig: m, sourcesConfig: s }) =>
          <div className='flex gap-6'>
            <div className='border-r border-gray-300 flex gap-4 flex-col pr-5 py-2 min-h-24'>
              <div className='flex items-center gap-3'>
                <AutosizeInput
                  className='min-w-24'
                  inputClassName='bg-transparent'
                  value={selectedAgg.name}
                  placeholder='Aggregation Name'
                  onChange={e => {
                    editAggProp(selectedAgg, 'name', e.target.value || '')
                  }} />
                {!selectedAgg.isNew &&
                  <TooltipContainer>
                    <TooltipMessage className='text-nowrap'>
                      Delete Aggregation
                    </TooltipMessage>
                    <button className='flex items-center gap-1' onClick={() => setDeleteCandidate(selectedAgg)}>
                      <TrashIcon className='h-4 w-4' />
                    </button>
                  </TooltipContainer>
                }
              </div>
              {selectedAgg.isDirty && <div className='flex gap-1'>
                <TooltipContainer>
                  <TooltipMessage position='right' className='bottom-8 whitespace-nowrap'>Discard Changes</TooltipMessage>
                  <Button size='xs' disabled={!selectedAgg.isDirty}
                    onClick={() => discardChanges(selectedAgg)}><XMarkIcon className='w-4 h-4' /></Button>
                </TooltipContainer>
                <TooltipContainer>
                  <TooltipMessage position='right' className='bottom-8 whitespace-nowrap'>Save Changes</TooltipMessage>
                  <Button size='xs'
                    disabled={
                      !selectedAgg.isDirty ||
                      (selectedAgg.metadataConfig.length === 0 && selectedAgg.sourcesConfig.length === 0) ||
                      loading}
                    onClick={() => handleSave(selectedAgg)}><CheckIcon className='w-4 h-4' /></Button>
                </TooltipContainer>
                {false && <Button size='xs' disabled>Duplicate</Button>}
              </div>}
            </div>
            <div className='flex flex-col gap-2 items-end pt-2'>
              <ListBox.ListBox
                value={selectedAgg.reducer}
                onChange={reducerKey => {
                  modifyAggregatorState(selectedAgg, (idx, aggs) => {
                    const c = aggs[idx]!

                    const reducerInput = {
                      metadataSchema,
                      name: c.name,
                      exchangeRates: exchangeRates as ExchangeRates,
                      metadata: c.metadataConfig,
                      sources: c.sourcesConfig
                    }

                    const currentType = c.targetType.type
                    const numericTypes: (typeof currentType)[] = ['Number', 'Mass', 'Price']
                    if (reducerKey === 'Sum') {
                      if (!numericTypes.includes(currentType)) {
                        reducerInput.metadata = []
                        reducerInput.sources = []
                      }
                      const targetType = c.targetType as NumericTarget
                      aggs[idx] = {
                        ...aggs[idx],
                        ...SumAggregator({
                          targetType,
                          ...reducerInput
                        })
                      }
                    }
                    if (reducerKey === 'Max') {
                      const targetType = c.targetType as NumericTarget
                      if (!numericTypes.includes(currentType)) {
                        reducerInput.metadata = []
                        reducerInput.sources = []
                      }
                      aggs[idx] = {
                        ...aggs[idx],
                        ...MaxAggregator({
                          targetType,
                          ...reducerInput
                        })
                      }
                    }
                    if (reducerKey === 'And') {
                      if (currentType !== 'Boolean') {
                        reducerInput.metadata = []
                        reducerInput.sources = []
                      }
                      const targetType = c.targetType as BooleanTarget
                      aggs[idx] = {
                        ...aggs[idx],
                        ...AndAggregator({
                          targetType,
                          ...reducerInput
                        })
                      }
                    }
                    aggs[idx]!.isDirty = true
                  })
                }} >
                <div className="relative">
                  <ListBox.UncontrolledButton wrapperClassName='' className='whitespace-nowrap' displayFunction={(v) => v.value} />
                  <div className=''>
                    <ListBox.Options align='left' className='text-xs'>
                      <ListBox.Option key={'Sum'} value={'Sum'} display='Sum' />
                      <ListBox.Option key={'Max'} value={'Max'} display='Max' />
                      <ListBox.Option key={'And'} value={'And'} display='And' />
                    </ListBox.Options>
                  </div>
                </div>
              </ListBox.ListBox>
              <div className='w-60 text-xs text-gray-900 text-right'>
                {selectedAgg.reducer === 'Max' && `Take the max of the fields for each part, rolling up against it's parents max values`}
                {selectedAgg.reducer === 'Sum' && 'Sum the listed fields, multiplying by the node quantity and aggregating at each level of the BOM'}
                {selectedAgg.reducer === 'And' && 'And the fields, which have to be true for the node and all child nodes to pass true'}
              </div>
            </div>
            <div className='flex flex-col gap-1 w-full'>
              <div className='w-full py-2'>
                {(s.length + m.length) === 0 ?
                  <div className='italic text-xs bg-gray-50 flex items-center h-10 pl-3 w-full rounded border text-gray-400 border-gray-400'>
                    Add a field to get started
                  </div> :
                  <div className='bg-white p-1.5 w-full rounded border border-gray-400'>
                    {m?.map((fieldCfg, idx) =>
                      <AggregationConfigRow {...fieldCfg} valueKey={fieldCfg.key} zone='metadata'
                        handleChange={editFieldCfg(selectedAgg, 'metadataConfig', idx)}
                        handleDelete={deleteFieldCfg(selectedAgg, 'metadataConfig', idx)}
                      />
                    )}
                    {s?.map((fieldCfg, idx) =>
                      <AggregationConfigRow {...fieldCfg} valueKey={fieldCfg.key} zone='sources'
                        handleChange={editFieldCfg(selectedAgg, 'sourcesConfig', idx)}
                        handleDelete={deleteFieldCfg(selectedAgg, 'sourcesConfig', idx)}
                      />
                    )}
                  </div>
                }
              </div>
              <div className='flex justify-end'>
                <ListBox.ListBox
                  value=''
                  onChange={key => {
                    const field = allFieldOptions.find(o => o.key === key)!
                    const [zone, zoneKey] = key.split('.') as [FieldZone, string]

                    const getUnit = (aggType: AggregatorTarget['type']) => {
                      if (aggType === 'Number') return
                      if (aggType === 'Price') return 'USD'
                      if (aggType === 'Mass') return 'kg'
                    }

                    modifyAggregatorState(selectedAgg, (idx, aggs) => {
                      const agg = aggs[idx]!
                      if (agg.metadataConfig.length + agg.sourcesConfig.length === 0) {
                        const unit = getUnit(field.type as AggregatorTarget['type'])
                        agg.targetType = {
                          type: field.type,
                          unit
                        } as AggregatorTarget
                      }
                      if (zone === 'metadataConfig') {
                        agg.metadataConfig = [
                          ...agg.metadataConfig,
                          {
                            key: zoneKey,
                            multiplyByQuantity: true
                          }
                        ]
                      }
                      if (zone === 'sourcesConfig') {
                        agg.sourcesConfig = [
                          ...agg.sourcesConfig,
                          {
                            key: zoneKey as keyof Source,
                            multiplyByQuantity: true
                          }
                        ]
                      }
                      agg.isDirty = true
                    })
                  }} >
                  <div className="relative">
                    <ListBox.UncontrolledButton wrapperClassName='' className='whitespace-nowrap' displayFunction={() => 'Add Field'} />
                    <div className='absolute z-50 right-0'>
                      <ListBox.Options align='right' className='text-xs'>
                        {
                          fieldOptionsByType.length === 0 ? <div className='p-4 italic text-gray-600'>No Fields Available</div> : fieldOptionsByType.map(([fieldType, fields]) => {
                            return <div key={fieldType} className='mb-4 mt-2'>
                              <div className='border-b-2 border-gray-500 px-5 py-1 text-gray-700 font-bold'>{fieldType}</div>
                              {fields.map(f => {
                                return <ListBox.Option key={f.key} value={f.key} display={f.display} disabled={f.disabled} />
                              })}
                            </div>
                          })
                        }
                      </ListBox.Options>
                    </div>
                  </div>
                </ListBox.ListBox>
              </div>
            </div>
          </div>
        )}
        <FormError error={error} wrapperClassName="text-white bg-red-500 w-full p-2 my-2 rounded" />
      </div>
      <button className='absolute right-2 top-2' onClick={() => {
        setToolbarMode(null)
        hierarchyController.setSelectedAgg(null)
      }}>
        <XMarkIcon className='w-4 h-4 text-gray-500' />
      </button>
    </div>
  )
}

const useSaveShortcut = (onSave: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault()
        onSave()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onSave])
}

const FullScreenWrapper: React.FC<{
  children: React.ReactNode
  toolbar: React.ReactNode
  controlPanel: React.ReactNode
  fullScreen: boolean,
}> = ({
  fullScreen,
  toolbar,
  children,
  controlPanel
}) => {
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    const handleDragStart = () => setIsDragging(true)
    const handleDragEnd = () => setIsDragging(false)

    window.addEventListener('rightpanel:dragstart', handleDragStart)
    window.addEventListener('rightpanel:dragend', handleDragEnd)

    return () => {
      window.removeEventListener('rightpanel:dragstart', handleDragStart)
      window.removeEventListener('rightpanel:dragend', handleDragEnd)
    }
  }, [])

  useEffect(() => {
    if (fullScreen) {
      document.body.style.setProperty('overflow', 'hidden')
    } else {
      document.body.style.removeProperty('overflow')
    }
    return () => {
      document.body.style.removeProperty('overflow')
    }
  }, [fullScreen])

  const { width: panelWidth, isOpen } = useRightPanel()

  const topBarClass = fullScreen ? `bg-white` : ''
  const toolBarClass = fullScreen ? 'flex flex-col px-4' : ''
  const wrapperClass = fullScreen ? 'z-20 fixed top-[64px] left-0 bottom-0 bg-white flex flex-col' : 'z-10 relative'

  const inner = <div
    className={`${wrapperClass} ${
      !isDragging ? 'transition-[right] duration-300 ease-in-out' : ''
    }`}
    style={{
      right: fullScreen ? (isOpen ? panelWidth : 0) : undefined
    }}>
    <div className={topBarClass}>
      <div>
        <div className={toolBarClass}>
          {toolbar}
        </div>
        {controlPanel}
      </div>
    </div>
    <div className='flex-auto overflow-hidden'>
      {children}
    </div>
  </div>

  if (!fullScreen) return inner

  return (
    <ThemePortal>
      {inner}
    </ThemePortal>
  )
}

interface FlatHierarchySwitchProps {
  currentMode: HierarchyMode
  onHierarchyModeClick: (mode: HierarchyMode) => void
}
const FlatHierarchySwitch: React.FC<FlatHierarchySwitchProps> = ({ currentMode, onHierarchyModeClick }) =>
  <ToggleSwitch
    options={[
      { value: 'hierarchy', label: 'Hierarchy' },
      { value: 'flat', label: 'Flat' }
    ]}
    value={currentMode}
    onChange={onHierarchyModeClick}
    testId='flat-mode-button'
    width='default'
  />
