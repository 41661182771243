import {
  Part,
  PartProto,
} from 'types/graphql'

import { useState, useEffect } from 'react'
import { usePartsCache } from 'src/lib/usePartsCache'
import Combobox from 'src/components/Combobox/Combobox'

export type PartDetail = Pick<Part, 'partNumber' | 'version' | 'name' | 'isRoot'> & {
  proto: Pick<PartProto, 'currentVersionString'>
}

type PartComboboxProps = {
  disabled?: boolean
  placeholder?: string
  value?: string
  sort?: (a: PartDetail, b: PartDetail) => number
  defaultValue?: string
  omit?: string[]
  extraParts?: PartDetail[]
  omitTopLevel?: boolean
  onSelect: (part: PartDetail) => void
  testId?: string
  error?: string
}

const PartCombobox = ({
  disabled,
  sort,
  omit,
  placeholder,
  onSelect,
  value,
  extraParts,
  omitTopLevel,
  testId = 'part-combobox',
  error,
}: PartComboboxProps) => {
  const { data } = usePartsCache()
  const partProtos = data?.protos
  const [showConfirm, setShowConfirm] = useState(false)

  extraParts = extraParts || []
  const mainBranchParts = partProtos?.filter(p => {
    if (!p.currentVersion) return false
    if (extraParts?.some(extra => extra.partNumber === p.partNumber)) return false
    return true
  }) || []

  const mainParts: PartDetail[] = mainBranchParts.map(proto => {
    return {
      partNumber: proto.partNumber,
      version: proto.currentVersion.version,
      name: proto.currentVersion.name,
      isRoot: proto.currentVersion.isRoot,
      proto
    }
  })

  const allParts = [...extraParts, ...mainParts]

  const filteredParts = allParts.filter(p => {
    if (omit && omit.includes(p.partNumber)) return false
    if (omitTopLevel && p.isRoot) return false
    return true
  })

  if (sort) {
    filteredParts.sort(sort)
  }

  const handleSelect = (partNumber: string) => {
    const selectedPart = allParts.find(p => p.partNumber === partNumber)
    if (selectedPart) {
      onSelect(selectedPart)
      setShowConfirm(true)

      setTimeout(() => {
        setShowConfirm(false)
      }, 1000)
    }
  }

  // Transform parts to the format expected by Combobox
  const comboboxOptions = filteredParts.map(part => ({
    id: part.partNumber,
    display: `${part.partNumber}${part.name ? ` - ${part.name}` : ''}`,
  }))

  return <Combobox
    selectedId={value || null}
    onSelectId={handleSelect}
    options={comboboxOptions}
    confirm={showConfirm ? '✓ Part Added' : undefined}
    height={24}
    placeholder={placeholder || 'Add Part to Change Order'}
    testId={testId}
    disabled={disabled}
    error={error}
  />
}

export default PartCombobox