import {
  MapperConfig,
  RowsExplodeConfig,
  RowsMergeConfig,
  RowsNormalizeConfig,
  RowsOrderedLevelConfig,
  StitchHierarchyConfig,
  MergeDuplicateChildrenConfig
} from './mapperConfigs'
import type { BomRow, BomRowMessage, BomRowMetadata } from './1-fileToRows'
import set from 'lodash.set'
import get from 'lodash.get'
import { renderTemplate } from './lib'

import { conditionMatch } from './3-standardizeBom'
import type { SelectedProject } from 'src/components/ProjectSelectCell'
import pick from 'lodash.pick'
import { filterFalsy } from '../util'
import { renderRowValue } from './lib'

type Extra = {
  selectedProject: SelectedProject
}

const rowsToBom = (config: MapperConfig, input: BomRow[], extra: Extra) => {
  if (!config.rowsToBom) return input
  return config.rowsToBom.reduce((output, config) => {
    console.log(`Running ${config.type}`, { input: output })
    if (input.length === 0) return output

    if (config.type === 'MergeRows') {
      return mergeRows(output, config.config, extra)
    }
    if (config.type === 'NormalizeRows') {
      return normalizeRows(output, config.config, extra)
    }
    if (config.type === 'SubassemblyExplode') {
      return explodeSubAssembly(output, config)
    }
    if (config.type === 'StitchHierarchy') {
      return stitchHierarchy(output, config)
    }
    if (config.type === 'FilterRemove') {
      return output.filter(row => !conditionMatch(config.config.conditions, row))
    }
    if (config.type === 'FilterKeep') {
      return output.filter(row => conditionMatch(config.config.conditions, row))
    }
    if (config.type === 'OrderedLevelHierarchy') {
      return orderedLevelHierarchy(output, config)
    }
    if (config.type === 'MergeDuplicateChildren') {
      return mergeDuplicateChildren(output, config.config, extra)
    }
    return output
  }, input)
}

const mergeRows = (input: BomRow[], config: RowsMergeConfig['config'], { selectedProject }: Extra) => {
  const rowsByCheckValue = input.reduce((rowsByCheckValue, row, i) => {
    const checkValue = renderTemplate(config.mergeOn, {
      row,
      project: selectedProject
    })
    return {
      ...rowsByCheckValue,
      [checkValue]: [
        ...(rowsByCheckValue[checkValue] || []),
        row
      ] as [BomRow, ...BomRow[]]
    }
  }, {} as Record<string, [BomRow, ...BomRow[]]>)

  return input.map(row => {
    const checkValue = renderTemplate(config.mergeOn, {
      row,
      project: selectedProject
    })
    const matchingGroup = rowsByCheckValue[checkValue]!
    const rowIsFirstMatch = matchingGroup[0].__metadata.originalIndex === row.__metadata.originalIndex

    if (!rowIsFirstMatch) return false

    return Object.entries(config.output).reduce((outputRow, [fieldName, outputValue]) => {
      return {
        ...outputRow,
        [fieldName]: renderTemplate(outputValue, {
          project: selectedProject,
          rows: matchingGroup
        })
      }
    }, row)
  }).filter(Boolean)
}

const normalizeRows = (input: BomRow[], config: RowsNormalizeConfig['config'], { selectedProject }: Extra) => {
  let rowsByCheckValue: Record<string, BomRow> = {}
  return input.map((row, i) => {
    const checkValue = renderTemplate(config.normalizeOn, {
      row,
      project: selectedProject
    })
    if (rowsByCheckValue[checkValue]) {
      return {
        ...row,
        ...pick(rowsByCheckValue[checkValue], config.normalizeColumns)
      }
    }
    rowsByCheckValue[checkValue] = row
    return row
  })
}

const orderedLevelHierarchy = (input: BomRow[], config: RowsOrderedLevelConfig) => {
  let currentIncrementors: number[] = [0]
  if (!input[0]) return []
  const baseLevel = Number(
    renderTemplate(config.config.input, { row: input[0] })
  )

  const output = input.map((row, i) => {
    // Subtracting the base level normalizes the
    // base level to 0
    const level = Number(
      renderTemplate(config.config.input, { row })
    ) - baseLevel

    if (isNaN(level)) {
      throw new Error(`Level invalid: ${renderTemplate(config.config.input, { row })}`)
    }
    if (level < 0) {
      throw new Error(`Level less than base level ${renderTemplate(config.config.input, { row })} (base level: ${baseLevel})`)
    }

    const levelSkippedAhead = level > currentIncrementors.length
    if (config.config.skipHierarchyJumps && levelSkippedAhead) {
      return null
    }

    if (levelSkippedAhead) {
      throw new Error(`Skipped Level from ${(currentIncrementors.length - 1) + baseLevel} to ${level + baseLevel}, line ${row.__metadata.originalIndex} ${JSON.stringify(row)}`)
    }

    if (currentIncrementors.length > level + 1) {
      currentIncrementors = currentIncrementors.slice(0, level + 1)
    } else if (currentIncrementors.length < (level + 1)) {
      currentIncrementors = [...currentIncrementors, 0]
    }

    currentIncrementors[level]!++
    return {
      ...row,
      __hierarchy: currentIncrementors.join('.')
    }
  })
  return filterFalsy(output)
}

const explodeSubAssembly = (input: BomRow[], config: RowsExplodeConfig) => {
  const configInputs = config.config
  const emptyRow = Object.keys(input[0]!).reduce((empty, key) => {
    return {
      ...empty,
      [key]: ''
    }
  }, {} as BomRow)

  const byKeyGroups = input.reduce((output, bomRow) => {
    const keys = configInputs.map(c => bomRow[c.subassemblyKeyColumn]!)
    const groupRows = get(output, keys) || []
    return set(output, keys, [...groupRows, bomRow])
  }, {})

  return Object.entries(byKeyGroups).flatMap(([skidNumber, skid], i) => {
    const keyedColumn0 = configInputs[0]!.subassemblyKeyColumn
    const skidPart = {
      ...emptyRow,
      __firstPart: skid[0],
      [keyedColumn0]: skidNumber,
      __hierarchy: `1.${i + 1}`,
      __explode_identifier: configInputs[0]!.explodeIdentifier
    }

    return [skidPart,
      ...Object.entries(skid)
        .flatMap(([lineNumber, partOrParts], j) => {
          const linePart = {
            [keyedColumn0]: skidNumber,
            __hierarchy: `1.${i + 1}.${j + 1}`,
          }
          if (configInputs[1]) {
            const keyedColumn1 = configInputs[1].subassemblyKeyColumn
            const partRows = partOrParts.map((p, k) => {
              return {
                ...p,
                __hierarchy: `1.${i + 1}.${j + 1}.${k + 1}`,
              }
            })
            return [{
              ...emptyRow,
              [keyedColumn1]: lineNumber,
              __explode_identifier: configInputs[1].explodeIdentifier,
              ...linePart
            }, ...partRows]
          }
          return {
            ...partOrParts,
            ...linePart
          }
        })
    ]
  })
}

const stitchHierarchy = (input: BomRow[], config: StitchHierarchyConfig) => {
  const partNumberCol = config.config.partNumberColumn
  const parentPartNumberCol = config.config.parentPartNumberColumn

  const allPartNumbers = new Set<string>()
  const allParentPartNumbers = new Set<string>()
  const partInstancesByParent: Record<string, BomRow[]> = {}

  // First pass: collect all part numbers and parent part numbers
  input.forEach(row => {
    const partNumber = row[partNumberCol]
    const parentPartNumber = row[parentPartNumberCol]

    if (partNumber) allPartNumbers.add(partNumber)
    if (parentPartNumber) {
      allParentPartNumbers.add(parentPartNumber)
      if (!partInstancesByParent[parentPartNumber]) {
        partInstancesByParent[parentPartNumber] = []
      }
      partInstancesByParent[parentPartNumber].push(row)
    }
  })

  // Find root part numbers (those that are only referenced as parents)
  const rootPartNumbers = Array.from(allParentPartNumbers).filter(parentNum =>
    !allPartNumbers.has(parentNum)
  )

  // Create root part rows with first child data
  const rootRows = rootPartNumbers.map((rootNum, index) => {
    const firstChild = partInstancesByParent[rootNum]?.[0]
    const rootRow: BomRow = {
      [partNumberCol]: rootNum,
      __hierarchy: `${index + 1}`,
      __metadata: firstChild?.__metadata ?? {
        originalIndex: -1,
        messages: []
      }
    }

    // Add __first_row_ prefixed columns from the first child
    if (firstChild) {
      Object.entries(firstChild).forEach(([key, value]) => {
        if (key !== partNumberCol && key !== parentPartNumberCol && key !== '__hierarchy' && key !== '__metadata') {
          rootRow[`__first_row_${key}`] = value
        }
      })
    }

    return rootRow
  })

  // Helper function to build hierarchy numbers recursively
  const buildHierarchy = (
    parentPartNumber: string,
    parentHierarchy: string,
    processedParts: Set<string>
  ): BomRow[] => {
    const children = partInstancesByParent[parentPartNumber] || []
    return children.map((child, index) => {
      const childPartNumber = child[partNumberCol]
      const hierarchyNumber = `${parentHierarchy}.${index + 1}`

      const newRow = {
        ...child,
        __hierarchy: hierarchyNumber
      }

      if (!processedParts.has(`${parentPartNumber}-${childPartNumber}`)) {
        processedParts.add(`${parentPartNumber}-${childPartNumber}`)
        const childRows = buildHierarchy(childPartNumber, hierarchyNumber, new Set(processedParts))
        return [newRow, ...childRows]
      }

      return [newRow]
    }).flat()
  }

  // Build complete hierarchy starting from each root
  const processedParts = new Set<string>()
  const hierarchicalRows = rootPartNumbers.flatMap((rootNum, index) => {
    return buildHierarchy(rootNum, `${index + 1}`, processedParts)
  })

  // Combine root rows with hierarchical rows
  return [...rootRows, ...hierarchicalRows]
}

const mergeDuplicateChildren = (input: BomRow[], config: MergeDuplicateChildrenConfig['config'], { selectedProject }: Extra) => {
  // Group rows by their parent hierarchy level
  const rowsByParentHierarchy = input.reduce((groups, row, rowIndex) => {
    // Get parent hierarchy by rendering the hierarchy config
    const parentHierarchy = renderRowValue(
      config.hierarchy,
      row,
      rowIndex,
      selectedProject
    ).split('.').slice(0, -1).join('.')

    if (!groups[parentHierarchy]) {
      groups[parentHierarchy] = []
    }
    groups[parentHierarchy].push(row)
    return groups
  }, {} as Record<string, BomRow[]>)

  // Process each group of siblings
  return input.filter((row, rowIndex) => {
    const parentHierarchy = renderRowValue(
      config.hierarchy,
      row,
      rowIndex,
      selectedProject
    ).split('.').slice(0, -1).join('.')

    const siblings = rowsByParentHierarchy[parentHierarchy] || []

    // Skip if this is not a child row
    if (!parentHierarchy) return true

    // Get duplicate check value for this row
    const checkValue = renderRowValue(
      config.duplicateCheck,
      row,
      rowIndex,
      selectedProject
    )

    // Find all siblings with matching check value
    const matchingSiblings = siblings.filter((sibling, siblingIndex) =>
      renderRowValue(
        config.duplicateCheck,
        sibling,
        siblingIndex,
        selectedProject
      ) === checkValue
    )

    // If this is the first matching sibling, merge quantities from others
    if (matchingSiblings[0] === row && matchingSiblings.length > 1) {
      // Sum quantities using the quantity config
      const totalQty = matchingSiblings.reduce((sum, sibling, siblingIndex) => {
        const qty = Number(renderRowValue(
          config.quantity,
          sibling,
          siblingIndex,
          selectedProject
        ))
        return sum + (isNaN(qty) ? 0 : qty)
      }, 0)
      console.log('totalQty', totalQty)
      if (totalQty > 0) {
        console.log('total Quant', totalQty)
        // Set the quantity using the same template format as the config
        row.__quantity = totalQty.toString()
      }

      // Add merge message to metadata
      const mergedIndices = matchingSiblings
        .slice(1)
        .map(r => r.__metadata.originalIndex)
        .join(', ')

      row.__metadata.messages.push({
        level: 'info',
        message: `Merged with duplicate siblings from rows: ${mergedIndices}`
      } as BomRowMessage)

      return true
    }

    // Filter out non-first matching siblings
    return matchingSiblings[0] === row
  })
}

export default rowsToBom