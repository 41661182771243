import { useEffect, useState, createContext, useContext } from 'react'
import { useQuery } from '@redwoodjs/web'
import type {
  PartCacheQuery,
  PartCacheQueryVariables
} from 'types/graphql'
import Sentry from './sentry'
import { simpleHash } from './util'

import 'src/lib/queries'

// Context definition
type PartsCacheContextType = {
  cache: PartCacheQuery['partProtosCache'] | null
  setCache: (cache: PartCacheQuery['partProtosCache'] | null) => void
  loading: boolean
  refresh: (input?: RefreshInput) => void
}

export const PartsCacheContext = createContext<PartsCacheContextType | undefined>(undefined)

export function PartsCacheProvider({ children, orgId }: { children: React.ReactNode, orgId: string }) {
  const [cache, setCache] = useState<PartCacheQuery['partProtosCache'] | null>(getCacheFromLocalStorage(orgId))
  const [loading, setLoading] = useState(false)

  const { refetch } = useQuery<PartCacheQuery, PartCacheQueryVariables>(PART_PROTOS_CACHE_QUERY, {
    variables: { ts: dateTimeToTs(cache?.lastUpdate) },
    skip: true,
  })

  const refresh = async ({debounce} : RefreshInput = {}) => {
    console.log('Refresh cache called')
    const lastQueryTimestamp = localStorage.getItem(CACHE_TIMESTAMP_NAME)
    const now = Date.now()

    if (debounce && lastQueryTimestamp && now - parseInt(lastQueryTimestamp, 10) < 60 * 1000) {
      return
    }

    setLoading(true)

    try {
      console.log('Fetching cache')
      const { data } = await refetch({ ts: dateTimeToTs(cache?.lastUpdate) })
      if (data?.partProtosCache?.protos) {
        const updatedCache = data.partProtosCache
        console.log('Saving cache to memory and localstorage')
        setCache(updatedCache)
        setCacheInLocalStorage(orgId, updatedCache)
      }
    } catch (e) {
      Sentry.captureException(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    refresh({ debounce: true })
  }, [])

  return (
    <PartsCacheContext.Provider value={{ cache, setCache, loading, refresh }}>
      {children}
    </PartsCacheContext.Provider>
  )
}

function usePartsCacheContext() {
  const context = useContext(PartsCacheContext)
  if (context === undefined) {
    throw new Error('usePartsCacheContext must be used within a PartsCacheProvider')
  }
  return context
}

export const PART_PROTOS_CACHE_QUERY = gql`
  query PartCacheQuery($ts: String!) {
    partProtosCache(ts: $ts) {
      lastUpdate
      orphanParts {
        tree {
          ...PartTreeNodeFragment
        }
      }
      protos {
        id
        inChangeOrders {
          number
          name
          state
        }
        category {
          id
          name
        }
        updatedAt
        partNumber
        protoUpdatedBy {
          name
        }
        currentVersionString
        currentVersion {
          ...PartTreeNodePartFragment
        }
        owner {
          id
          name
        }
      }
    }
  }
`

const CACHE_NAME = 'partProtosCache'
const CACHE_TIMESTAMP_NAME = 'partProtosCacheTimestamp'
const CACHE_KEY_NAME = 'partProtosCacheKey'

// always default in dev env
const SOURCE_VERSION = process.env.SOURCE_VERSION || 'default'

const queryHash = simpleHash(JSON.stringify(PART_PROTOS_CACHE_QUERY, (key, value) =>
  typeof value === 'function' ? value.toString() : value
))

const getCacheKeyValue = (orgId: string) => {
  return `${SOURCE_VERSION}::${queryHash}::${orgId}`
}

const getCacheFromLocalStorage = (orgId: string): PartCacheQuery['partProtosCache'] | null => {
  const cachedData = localStorage.getItem(CACHE_NAME)
  const lastCacheKey = localStorage.getItem(CACHE_KEY_NAME)
  const nextCacheKey = getCacheKeyValue(orgId)

  // delete the cache due to change in org, query or bundle version
  if (nextCacheKey !== lastCacheKey) {
    console.log('Clearing protos cache')
    localStorage.removeItem(CACHE_NAME)
    localStorage.removeItem(CACHE_TIMESTAMP_NAME)
    localStorage.removeItem(CACHE_KEY_NAME)
    return null
  }

  return cachedData ? JSON.parse(cachedData) : null
}

const setCacheInLocalStorage = (orgId: string, cache: PartCacheQuery['partProtosCache']) => {
  localStorage.setItem(CACHE_NAME, JSON.stringify(cache))
  localStorage.setItem(CACHE_TIMESTAMP_NAME, Date.now().toString())
  localStorage.setItem(CACHE_KEY_NAME, getCacheKeyValue(orgId))
}

type RefreshInput = {
  debounce?: boolean
}

const dateTimeToTs = (input?: string | null) => {
  if (!input) return '0'
  return String((new Date(input)).getTime())
}

export type PartsCacheData = Required<PartCacheQuery['partProtosCache']>

export const usePartsCache = (): {
  data: PartsCacheData
  loading: boolean
  refresh: (input?: RefreshInput) => void
} => {
  const { cache, loading, refresh } = usePartsCacheContext()

  return {
    data: cache as PartsCacheData,
    loading,
    refresh,
  }
}
