import { useState, useEffect } from 'react'
import { useSignIn } from '@clerk/clerk-react'
import LoadingSpinner from 'src/components/Loading'
import Button from 'src/components/Button'
import {
  CardWrapper,
  ErrorDisplay,
  EmailInput,
  PasswordInput,
  buildRedirectUrl
} from 'src/components/auth/Common'
import * as Sentry from '@sentry/react'

const ForgotPassword = ({ redirectUrl }: { redirectUrl: string | null }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [code, setCode] = useState('')
  const [successfulCreation, setSuccessfulCreation] = useState(false)
  const [error, setError] = useState('')
  const [processingRequest, setProcessingRequest] = useState(false)

  const { isLoaded, signIn, setActive } = useSignIn()

  // Track page view when component mounts
  useEffect(() => {
    // Start a Sentry transaction for the password reset flow
    const transaction = Sentry.startInactiveSpan({
      name: 'password-reset-flow',
      op: 'password-reset',
      forceTransaction: true
    });

    // Log page view
    Sentry.addBreadcrumb({
      category: 'password-reset',
      message: 'Password reset page viewed',
      level: 'info'
    });

    return () => {
      transaction?.end();
    };
  }, []);

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  // Send the password reset code to the user's email
  async function requestResetCode(e: React.FormEvent) {
    e.preventDefault()
    setProcessingRequest(true)
    setError('')

    // Start a span for the reset code request
    const span = Sentry.startInactiveSpan({
      name: 'password-reset-request',
      op: 'auth'
    });

    // Log the reset code request
    Sentry.addBreadcrumb({
      category: 'password-reset',
      message: 'User requested password reset code',
      level: 'info',
      data: {
        hasEmail: !!email
      }
    });

    try {
      await signIn!.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      })
      setSuccessfulCreation(true)

      // Log successful reset code sent
      Sentry.addBreadcrumb({
        category: 'password-reset',
        message: 'Reset code sent successfully',
        level: 'info'
      });

      // Mark span as successful
      span?.setStatus('ok');
    } catch (err: any) {
      const errorMessage = err.errors?.[0]?.message || 'Failed to send reset code. Please try again.';
      setError(errorMessage)

      // Log the error with context
      Sentry.captureException(err, {
        tags: {
          'reset_step': 'code_request',
          'has_email': !!email
        }
      });

      // Mark span as failed
      span?.setStatus('internal_error');
    } finally {
      setProcessingRequest(false)
      span?.end();
    }
  }

  // Reset the user's password
  async function resetPassword(e: React.FormEvent) {
    e.preventDefault()
    setProcessingRequest(true)
    setError('')

    // Start a span for the password reset attempt
    const span = Sentry.startInactiveSpan({
      name: 'password-reset-attempt',
      op: 'auth'
    });

    // Log the password reset attempt
    Sentry.addBreadcrumb({
      category: 'password-reset',
      message: 'User attempted password reset',
      level: 'info',
      data: {
        codeLength: code.length,
        hasPassword: !!password
      }
    });

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match')
      setProcessingRequest(false)

      // Log password mismatch
      Sentry.addBreadcrumb({
        category: 'password-reset',
        message: 'Password mismatch',
        level: 'warning'
      });

      // Mark span as failed
      span?.setStatus('internal_error');
      span?.end();
      return
    }

    try {
      const result = await signIn!.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code,
        password,
      })

      if (result.status === 'complete') {
        // Log successful password reset
        Sentry.addBreadcrumb({
          category: 'password-reset',
          message: 'Password reset successful',
          level: 'info'
        });

        // Mark span as successful
        span?.setStatus('ok');

        // Set the active session to the newly created session (user is now signed in)
        await setActive!({
          session: result.createdSessionId,
          redirectUrl: redirectUrl || '/'
        })
      } else {
        setError('Something went wrong. Please try again.')

        // Log incomplete reset
        Sentry.addBreadcrumb({
          category: 'password-reset',
          message: 'Password reset incomplete',
          level: 'warning',
          data: {
            status: result.status
          }
        });

        // Mark span as unset (pending)
        span?.setStatus('unset');
      }
    } catch (err: any) {
      const errorMessage = err.errors?.[0]?.message || 'Failed to reset password. Please try again.';
      setError(errorMessage)

      // Log the error with context
      Sentry.captureException(err, {
        tags: {
          'reset_step': 'password_reset',
          'code_length': code.length
        }
      });

      // Mark span as failed
      span?.setStatus('internal_error');
    } finally {
      setProcessingRequest(false)
      span?.end();
    }
  }

  return (
    <CardWrapper>
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Reset Your Password</h2>
        <p className="text-gray-600 mt-1">
          {!successfulCreation
            ? 'Enter your email to receive a reset code'
            : 'Enter the code sent to your email'}
        </p>
      </div>

      <ErrorDisplay error={error} />

      {!successfulCreation ? (
        <form onSubmit={requestResetCode} className="flex flex-col gap-5">
          <EmailInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            type="submit"
            disabled={processingRequest}
            className="w-full py-3 bg-brand-600 hover:bg-brand-700 text-white font-medium rounded-md shadow-sm transition-all duration-200"
          >
            {processingRequest ? <LoadingSpinner size="sm" /> : 'Send Reset Code'}
          </Button>

          <div className="mt-4 text-center">
            <a href={buildRedirectUrl(redirectUrl, '/login')} className="text-brand-600 hover:text-brand-700 text-sm font-medium">
              ← Back to login
            </a>
          </div>
        </form>
      ) : (
        <form onSubmit={resetPassword} className="flex flex-col gap-5">
          <div className="relative">
            <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-1">
              Verification Code
            </label>
            <input
              id="code"
              name="code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-brand-500"
              placeholder="Enter the code from your email"
              required
            />
          </div>

          <PasswordInput
            id="password"
            label="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Create a new password"
          />

          <PasswordInput
            id="confirmPassword"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your new password"
          />

          <Button
            type="submit"
            disabled={processingRequest}
            className="w-full py-3 bg-brand-600 hover:bg-brand-700 text-white font-medium rounded-md shadow-sm transition-all duration-200"
          >
            {processingRequest ? <LoadingSpinner size="sm" /> : 'Reset Password'}
          </Button>

          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={() => setSuccessfulCreation(false)}
              className="text-brand-600 hover:text-brand-700 text-sm font-medium"
            >
              ← Try a different email
            </button>
          </div>
        </form>
      )}
    </CardWrapper>
  )
}

export default ForgotPassword
