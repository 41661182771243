import { useState, useEffect } from 'react'
import { useSignUp } from '@clerk/clerk-react'
import LoadingSpinner from 'src/components/Loading'
import Button from 'src/components/Button'
import {
  CardWrapper,
  ErrorDisplay,
  EmailInput,
  PasswordInput,
  buildRedirectUrl
} from 'src/components/auth/Common'
import * as Sentry from '@sentry/react'

const CustomSignup = ({ redirectUrl }: { redirectUrl: string | null }) => {
  const { isLoaded, signUp, setActive } = useSignUp();

  // Track the signup state
  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pendingVerification, setPendingVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  // Track page view when component mounts
  useEffect(() => {
    // Start a Sentry transaction for the signup flow
    const transaction = Sentry.startInactiveSpan({
      name: 'signup-flow',
      op: 'signup',
      forceTransaction: true
    });

    // Log page view
    Sentry.addBreadcrumb({
      category: 'signup',
      message: 'Signup page viewed',
      level: 'info'
    });

    // You could also track with your analytics tool
    // track('signup_page_viewed');

    return () => {
      transaction?.end();
    };
  }, []);

  // Handle form submission for the initial signup
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    // Start a span for the signup attempt
    const span = Sentry.startInactiveSpan({ name: 'signup-attempt', op: 'auth' });

    // Log the signup attempt
    Sentry.addBreadcrumb({
      category: 'signup',
      message: 'User attempted signup',
      level: 'info',
      data: {
        hasName: !!name,
        hasEmail: !!emailAddress,
        hasPassword: !!password
      }
    });

    if (!isLoaded) {
      span?.end();
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);

      span?.end();
      return;
    }

    try {
      // Start the signup process
      await signUp.create({
        firstName: name, // Using name for the firstName field in Clerk
        emailAddress,
        password,
      });

      // Log successful account creation
      Sentry.addBreadcrumb({
        category: 'signup',
        message: 'User account created',
        level: 'info'
      });

      // Send the email verification code
      await signUp.prepareEmailAddressVerification({ strategy: 'email_code' });

      // Log verification email sent
      Sentry.addBreadcrumb({
        category: 'signup',
        message: 'Verification email sent',
        level: 'info'
      });

      // Change the UI to show the verification form
      setPendingVerification(true);

      // Mark span as successful
      span?.setStatus('ok');
    } catch (err: unknown) {
      const errorMessage = (err as any).errors?.[0]?.longMessage ||
                          (err as any).errors?.[0]?.message ||
                          'An error occurred during sign up';

      setError(errorMessage);

      // Log the error with context
      Sentry.captureException(err, {
        tags: {
          'signup_step': 'initial_creation',
          'has_name': !!name,
          'has_email': !!emailAddress
        }
      });

      // Mark span as failed
      span?.setStatus('internal_error');
    } finally {
      setLoading(false);
      span?.end();
    }
  };

  // Handle verification code submission
  const handleVerify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    // Start a span for the verification attempt
    const span = Sentry.startInactiveSpan({ name: 'verification-attempt', op: 'auth' });

    // Log verification attempt
    Sentry.addBreadcrumb({
      category: 'signup',
      message: 'User attempted verification',
      level: 'info',
      data: {
        codeLength: verificationCode.length
      }
    });

    if (!isLoaded) {
      span?.end();
      return;
    }

    try {
      // Attempt to verify the email
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code: verificationCode,
      });

      if (completeSignUp.status !== 'complete') {
        // If the signup is still not complete, show an error
        setError('Verification failed. Please try again.');

        // Log verification failure
        Sentry.addBreadcrumb({
          category: 'signup',
          message: 'Verification failed',
          level: 'error',
          data: {
            status: completeSignUp.status
          }
        });

        // Mark span as failed
        span?.setStatus('internal_error');
      } else {
        // Log successful verification
        Sentry.addBreadcrumb({
          category: 'signup',
          message: 'Verification successful',
          level: 'info'
        });

        // Mark span as successful
        span?.setStatus('ok');

        // Set the user session active and redirect
        await setActive({
          session: completeSignUp.createdSessionId,
          redirectUrl: redirectUrl || '/'
        });

        // Log signup completion
        Sentry.addBreadcrumb({
          category: 'signup',
          message: 'Signup completed',
          level: 'info'
        });
      }
    } catch (err: unknown) {
      const errorMessage = (err as any).errors?.[0]?.longMessage ||
                          (err as any).errors?.[0]?.message ||
                          'An error occurred during verification';

      setError(errorMessage);

      // Log the error with context
      Sentry.captureException(err, {
        tags: {
          'signup_step': 'verification',
          'code_length': verificationCode.length
        }
      });

      // Mark span as failed
      span?.setStatus('internal_error');
    } finally {
      setLoading(false);
      span?.end();
    }
  };

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  // Show the verification form if we're pending verification
  if (pendingVerification) {
    return (
      <CardWrapper>
        <div className="text-center mb-6">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-brand-100 text-brand-600 mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
          </div>
          <h2 className="text-2xl font-bold text-gray-800">Check your inbox</h2>
          <p className="text-gray-600 mt-2">We've sent a verification code to your email</p>
        </div>

        <ErrorDisplay error={error} />

        <form onSubmit={handleVerify} className="flex flex-col gap-5">
          <div className="relative">
            <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-1">
              Verification Code
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <input
                id="code"
                name="code"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="pl-10 block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-brand-500 text-lg"
                placeholder="Enter code"
                required
              />
            </div>
          </div>

          <Button
            type="submit"
            disabled={loading}
            className="w-full py-3 bg-brand-600 hover:bg-brand-700 text-white font-medium rounded-md shadow-sm transition-all duration-200"
          >
            {loading ? <LoadingSpinner size="sm" /> : 'Verify & Continue'}
          </Button>

          <div className="text-center mt-4">
            <button
              type="button"
              onClick={() => setPendingVerification(false)}
              className="text-brand-600 hover:text-brand-700 text-sm font-medium"
            >
              ← Back to signup
            </button>
          </div>
        </form>
      </CardWrapper>
    );
  }

  // Show the signup form
  return (
    <CardWrapper>
      <div className="text-center mb-6">
        <p className="text-gray-600 mt-1">Create your account to get started</p>
      </div>

      <ErrorDisplay error={error} />

      <form onSubmit={handleSubmit} className="flex flex-col gap-5">
        <div className="relative">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
            Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-brand-500"
            placeholder="Your name"
            required
          />
        </div>

        <EmailInput
          value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)}
        />

        <PasswordInput
          id="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Create a secure password"
        />

        <PasswordInput
          id="confirmPassword"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm your password"
        />

        <div className="mt-2">
          <Button
            type="submit"
            disabled={loading}
            className="w-full py-3 bg-brand-600 hover:bg-brand-700 text-white font-medium rounded-md shadow-sm transition-all duration-200"
          >
            {loading ? <LoadingSpinner size="sm" /> : 'Create Account'}
          </Button>
        </div>
      </form>

      <div className="mt-6 text-center">
        <div className="text-sm text-gray-500">
          Have an account? <a href={buildRedirectUrl(redirectUrl, '/login')} className="text-brand-600 hover:text-brand-700 font-medium">Sign in</a>
        </div>
      </div>
    </CardWrapper>
  );
};

export default CustomSignup;

