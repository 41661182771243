import React from 'react'
import { Dialog } from '@headlessui/react'
import { Link, routes, useParams } from '@redwoodjs/router'

type OnboardingModalProps = {
  isOpen: boolean
  onClose: () => void
}

const OnboardingModal: React.FC<OnboardingModalProps> = ({ isOpen, onClose }) => {
  const orgId = useParams().orgId!
  const closeButtonRef = React.useRef(null)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      initialFocus={closeButtonRef}
      className="fixed inset-0 z-50 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center p-4">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="relative mx-auto max-w-xl rounded-lg bg-white p-6 shadow-xl">
          <Dialog.Title className="text-2xl font-bold text-gray-900">
            Welcome to Your New Organization!
          </Dialog.Title>

          <div className="mt-4">
            <p className="text-gray-600">
              Your new workspace is ready! Here's how to get the most out of the platform:
            </p>

            <div className="mt-6 bg-blue-50 p-4 rounded-lg border border-blue-200">
              <h3 className="font-semibold text-blue-800 text-lg">Try Our Demo Data</h3>
              <p className="mt-2 text-gray-700">
                The fastest way to explore the platform is to populate your workspace with demo data:
              </p>
              <ul className="mt-3 list-disc pl-5 text-gray-700">
                <li className="mt-1">Go to <Link to={routes.orgSettingsDemoData({orgId})} className="text-blue-600 underline hover:text-blue-800" tabIndex={-1} onClick={onClose}>Organization Settings &gt; Demo Data</Link></li>
                <li className="mt-1">Click "Generate Demo Data" to populate your workspace with sample parts, BOMs, and relationships</li>
                <li className="mt-1">You can clear the workspace anytime using the "Clear Workspace" button on the same page</li>
              </ul>
            </div>

            <p className="mt-4 text-gray-600 font-medium">Other helpful tips:</p>
            <ul className="mt-2 list-disc pl-5 text-gray-600">
              <li className="mt-2">Create change orders to edit BOMs</li>
              <li className="mt-2">Invite team members from the organization settings</li>
              <li className="mt-2">Schedule time with our team to learn about more advanced features by clicking on the dropdown on the top right of the screen.</li>
            </ul>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="button"
              ref={closeButtonRef}
              className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              onClick={onClose}
            >
              Got it, thanks!
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default OnboardingModal
