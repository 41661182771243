import ChangeOrderTreeCell from '../ChangeOrderTreeCell'

export default ({ changeOrderNumber, partContext }: { changeOrderNumber: number, partContext?: string }) => {

  return  <div className='flex flex-col gap-16 pt-8'>
    <ChangeOrderTreeCell orderNumber={changeOrderNumber} partContext={partContext} />
  </div>
}


