type ToggleOption<T> = {
  value: T
  label: string
}

type ToggleSwitchProps<T> = {
  options: [ToggleOption<T>, ToggleOption<T>]
  value: T
  onChange: (value: T) => void
  testId?: string
  width?: 'default' | 'wide' | string
}

const ToggleSwitch = <T extends string | number>({
  options,
  value,
  onChange,
  testId,
  width = 'default'
}: ToggleSwitchProps<T>) => {
  const itemClasses = 'flex-1 text-center flex items-center justify-center py-0.5'
  const selectedClass = 'pointer-events-none bg-white rounded'

  const widthClass = width === 'default' ? 'w-36'
    : width === 'wide' ? 'w-48'
    : width // allow custom width class if provided

  return (
    <div className={`bg-gray-100 rounded flex ${widthClass} p-0.5 h-6 gap-1 text-xs shadow-xs ring-1 ring-inset ring-gray-200`}>
      {options.map((option) => (
        <button
          key={option.value}
          type='button'
          data-testid={option.value === options[1].value ? testId : undefined}
          className={`${value === option.value ? selectedClass : ''} ${itemClasses}`}
          onClick={() => {
            if (value !== option.value) {
              onChange(option.value)
            }
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  )
}
export default ToggleSwitch
