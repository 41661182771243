import { Link } from '@redwoodjs/router'
import { useRef, useState, useMemo } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { prettyDate } from 'src/lib/formatters'
import { usePartsCache } from 'src/lib/usePartsCache'
import { useRightPanel } from '../RightPanel/RightPanel'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import type {
  RecentPartsQuery,
} from 'types/graphql'
import { gql } from '@apollo/client'

export const QUERY = gql`
  query RecentPartsQuery {
    me {
      id
      latestPartViews {
        partNumber
      }
    }
  }
`

export const Loading = () => {
  return <RecentParts partNumbers={[]} />
}

export const Failure = () => {
  return <RecentParts partNumbers={[]} />
}

export const Success = ({ me }: CellSuccessProps<RecentPartsQuery>) => {
  return <RecentParts partNumbers={me.latestPartViews.map(v => v.partNumber)} />
}

type RecentPartsProps = {
  partNumbers: string[]
}

const RecentParts: React.FC<RecentPartsProps> = ({ partNumbers }) => {
  const { data } = usePartsCache()
  const { panelRoute } = useRightPanel()
  const [startIndex, setStartIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  // Get all part data for the latest viewed parts
  const recentParts = useMemo(() => {
    if (!data?.protos) return []

    return partNumbers
      .map(partNumber => data.protos?.find(p => p.partNumber === partNumber))
      .filter((part): part is NonNullable<(typeof data.protos)[number]> => part !== undefined)
  }, [partNumbers, data?.protos])

  const CARD_WIDTH = 192 // w-48
  const CARD_GAP = 16 // gap-4

  const canScrollLeft = startIndex > 0
  const canScrollRight = useMemo(() => {
    if (!containerRef.current) return false
    const containerWidth = containerRef.current.offsetWidth
    const totalWidth = recentParts.length * (CARD_WIDTH + CARD_GAP) - CARD_GAP
    const visibleWidth = containerWidth
    const scrolledWidth = startIndex * (CARD_WIDTH + CARD_GAP)

    return scrolledWidth + visibleWidth < totalWidth
  }, [startIndex, recentParts.length, containerRef.current?.offsetWidth])

  const scrollLeft = () => {
    setStartIndex(prev => Math.max(0, prev - 1))
  }

  const scrollRight = () => {
    setStartIndex(prev => prev + 1)
  }

  if (recentParts.length === 0) {
    return <div className="text-gray-500">No recently viewed parts</div>
  }

  return (
    <div className="relative overflow-hidden w-full" ref={containerRef}>
      <div
        className="flex gap-4 transition-transform duration-50 ease-in-out"
        style={{
          transform: `translateX(-${startIndex * (CARD_WIDTH + CARD_GAP)}px)`,
          width: `${recentParts.length * (CARD_WIDTH + CARD_GAP) - CARD_GAP}px`,
        }}
      >
        {recentParts.map((part) => (
          <Link
            key={part.partNumber}
            to={panelRoute('part', { partNumber: part.partNumber })}
            className="block w-48 h-48 rounded-lg border border-gray-200 hover:border-blue-500 transition-colors duration-200 flex-shrink-0"
          >
            <div className="p-4 h-full flex flex-col">
              <div className="text-sm font-medium text-gray-900 mb-2">
                #{part.partNumber}
              </div>
              <div className="text-sm text-gray-500 mb-2">
                {part.currentVersion?.name || 'Unnamed Part'}
              </div>
              <div className="text-xs text-gray-400 mt-auto">
                Last updated: {part.updatedAt ? prettyDate(new Date(part.updatedAt)) : 'Unknown'}
              </div>
            </div>
          </Link>
        ))}
      </div>

      {/* Gradient Fade Mask */}
      <div
        className="absolute top-0 right-0 h-full w-24 pointer-events-none"
        style={{
          background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'
        }}
      />

      {/* Navigation Arrows */}
      {canScrollLeft && (
        <button
          onClick={scrollLeft}
          className="absolute left-0 top-1/2 -translate-y-1/2 p-1 rounded-full bg-white shadow-md hover:bg-gray-100 border border-gray-200"
          aria-label="Previous part"
        >
          <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
        </button>
      )}
      {canScrollRight && (
        <button
          onClick={scrollRight}
          className="absolute right-0 top-1/2 -translate-y-1/2 p-1 rounded-full bg-white shadow-md hover:bg-gray-100 border border-gray-200"
          aria-label="Next part"
        >
          <ChevronRightIcon className="w-5 h-5 text-gray-600" />
        </button>
      )}
    </div>
  )
}
