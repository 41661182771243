import React, { useEffect } from 'react'
import { Editor } from '@tiptap/core'
import tippy from 'tippy.js'
import { createRoot } from 'react-dom/client'


const attachTooltips = (editor: Editor) => {
  // Only select elements that don't already have tooltips attached
  const elements = editor.view.dom.querySelectorAll('[data-tooltip]:not([data-tooltip-attached])')

  tippy(elements, {
    content: (reference) => {
      const element = document.createElement('div')
      const root = createRoot(element)
      root.render(
        <PartTooltip content={reference.getAttribute('data-tooltip') || ''} />
      )

      return element
    },
    allowHTML: true,
    delay: [50, 0],
    duration: 50,
    interactive: true,
    appendTo: document.body,
    onCreate(instance) {
      // Mark the reference element as having a tooltip attached
      instance.reference.setAttribute('data-tooltip-attached', 'true')
    },
    onDestroy(instance) {
      // Remove the marker when tooltip is destroyed
      instance.reference.removeAttribute('data-tooltip-attached')
    }
  })
}

export default function useTooltips (editor: Editor | null) {
  useEffect(() => {
    if (editor) {
      attachTooltips(editor)
      editor?.on('update', () => {
        attachTooltips(editor)
      })
    }
  }, [editor])
}

// Add this interface near the top with other interfaces
interface PartTooltipProps {
  content: string;
}

// Add this component near the MentionList component
const PartTooltip = ({ content }: PartTooltipProps) => {
  return (
    <div className="p-2 max-w-96 bg-gray-700 text-white text-xs rounded-md shadow-md">
      {content}
    </div>
  )
}
