import { humanBoolean } from 'src/lib/formatters'
import { sortVersionFn } from 'src/lib/version'
import { getThumbnail } from '../Artifacts/Artifacts'
import { StatusDot } from '../LifecycleStatus/LifecycleStatus'
import { ColumnType, ControllerRoot, ControllerTreeNode, quantityAndUnit } from './hierarchyController'
import { routes, useParams } from '@redwoodjs/router'
import { MetadataValue } from 'src/lib/metadata'
import type { MetadataType } from 'types/graphql'
import { ArtifactHoverPopover } from './PartHierarchy'
import { VersionIcon } from '../VersionRange/VersionRange'

interface DisplayOptions {
  root: ControllerRoot
}
export type PartHierarchyHeaderColumn = {
  fullKey: string
  name: string
  width: number
  hide?: boolean
  key: string
  notInFilters?: boolean
  filterValue: (node: ControllerTreeNode) => MetadataValue | null | number[]
  valueType: MetadataType | 'LifeCycle' | 'ChangeOrder' | 'Owner',
  showNonFullscreen?: boolean
  type: ColumnType
  align?: 'center' | 'right' | 'left'
  headerAlign?: 'center' | 'right' | 'left'
  displayFn: (node: ControllerTreeNode, o: DisplayOptions) => React.ReactNode
  sortFn: (nodeA: ControllerTreeNode, nodeB: ControllerTreeNode) => -1 | 0 | 1
}

export const baseColumns: PartHierarchyHeaderColumn[] = [{
  // this is only actually used for sorting
  name: 'Part Number/ Name',
  key: 'partNumberName',
  fullKey: 'field.partNumberName',
  type: 'field',
  width: 60,
  notInFilters: true,
  showNonFullscreen: true,
  align: 'left',
  valueType: 'String',
  filterValue() { return null },
  displayFn(node) {
    return node.part.partNumber
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.partNumber
    const b = nodeB.part.partNumber
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
}, {
  // this is only actually used for sorting
  name: 'Part Number',
  key: 'partNumber',
  fullKey: 'field.partNumber',
  type: 'field',
  width: 60,
  // this column just for filtering
  hide: true,
  align: 'left',
  valueType: 'String',
  filterValue(node) {
    return node.part.partNumber
  },
  displayFn(node) {
    return node.part.partNumber
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.partNumber
    const b = nodeB.part.partNumber
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
},
{
  // this is only actually used for sorting
  name: 'Part Name',
  key: 'name',
  fullKey: 'field.name',
  type: 'field',
  width: 350,
  valueType: 'String',
  showNonFullscreen: true,
  align: 'left',
  hide: true,
  filterValue(node) {
    return node.part.name || null
  },
  displayFn(node) {
    return node.part.partNumber
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.partNumber
    const b = nodeB.part.partNumber
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
}, {
  name: 'Version',
  key: 'version',
  fullKey: 'field.version',
  type: 'field',
  showNonFullscreen: true,
  width: 100,
  valueType: 'String',
  align: 'right',
  filterValue(node) {
    return node.part.version
  },
  displayFn(node) {
    return <div className='flex items-center gap-1'>
      <VersionIcon versionRange={node.parentToNodeDependency?.toVersionRange} version={node.part.version}/>
      {node.part.version}
    </div>
  },
  sortFn(nodeA, nodeB) {
    return sortVersionFn(nodeA.part.version, nodeB.part.version) as 1 | 0 | -1
  }
}, {
  name: 'Lifecycle',
  key: 'lifeCycle',
  fullKey: 'field.lifeCycle',
  type: 'field',
  width: 60,
  valueType: 'LifeCycle',
  showNonFullscreen: true,
  align: 'center',
  filterValue(node) {
    return node.part.lifeCycle || null
  },
  displayFn(node) {
    return <div className='flex items-center justify-center h-5'>
      <StatusDot lifeCycle={node.part.lifeCycle} size='sm' alignTooltip='right' />
    </div>
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.lifeCycle || ''
    const b = nodeB.part.lifeCycle || ''
    if (a > b) return 1
    if (a < b) return -1
    return 0
  }
}, {
  name: 'Quantity',
  key: 'quantity',
  fullKey: 'field.quantity',
  type: 'field',
  width: 100,
  valueType: 'Number',
  showNonFullscreen: true,
  align: 'center',
  notInFilters: true,
  filterValue(node) {
    return node.parentToNodeDependency?.quantity || null
  },
  displayFn(node) {
    const quantity = node.parentToNodeDependency?.quantity
    const units = node.parentToNodeDependency?.units
    return quantityAndUnit(quantity, units)
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.parentToNodeDependency?.quantity || 0
    const b = nodeB.parentToNodeDependency?.quantity || 0
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}, {
  name: 'Thumbnail',
  key: 'thumbnail',
  fullKey: 'field.thumbnail',
  type: 'field',
  width: 60,
  valueType: 'String',
  showNonFullscreen: true,
  align: 'center',
  notInFilters: true,
  filterValue(node) {
    //not sure about this
    return Boolean(getThumbnail(node.part.artifacts))
  },
  displayFn(node) {
    return <ArtifactHoverPopover artifact={getThumbnail(node.part.artifacts)} />
  },
  sortFn(nodeA, nodeB) {
    const a = getThumbnail(nodeA.part.artifacts)
    const b = getThumbnail(nodeB.part.artifacts)
    if (a && b || !a && !b) return 0
    if (a) return 1
    return -1
  }
}, {
  name: 'Category',
  key: 'category',
  fullKey: 'field.category',
  valueType: 'String',
  showNonFullscreen: true,
  type: 'field',
  width: 150,
  align: 'left',
  displayFn(node) {
    return node.part.proto.category.name
  },
  filterValue(node) {
    return node.part.proto.category.name
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.proto.category.name
    const b = nodeB.part.proto.category.name
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}, {
  name: 'Change Orders',
  key: 'changeOrders',
  fullKey: 'field.changeOrders',
  type: 'field',
  showNonFullscreen: false,
  width: 200,
  valueType: 'ChangeOrder',
  align: 'left',
  filterValue(node) {
    return node.proto?.inChangeOrders.map(c => c.number) || null
  },
  displayFn(node) {
    return <ChangeOrdersList node={node} />
  },
  sortFn(nodeA, nodeB) {
    const coCountA = nodeA.proto?.inChangeOrders.length || 0
    const coCountB = nodeB.proto?.inChangeOrders.length || 0
    if (coCountA > coCountB) return 1
    if (coCountA < coCountB) return -1
    return 0
  }
}, {
  name: 'Owner',
  key: 'owner',
  fullKey: 'field.owner',
  type: 'field',
  showNonFullscreen: false,
  width: 150,
  valueType: 'Owner',
  align: 'left',
  filterValue(node) {
    return node.proto?.owner.id || null
  },
  displayFn(node) {
    return node.proto?.owner.name || '-'
  },
  sortFn(nodeA, nodeB) {
    const ownerNameA = nodeA.proto?.owner.id || ''
    const ownerNameB = nodeB.proto?.owner.id || ''
    if (ownerNameA > ownerNameB) return -1
    if (ownerNameA < ownerNameB) return 1
    return 0
  }
}, {
  name: 'Designator',
  key: 'referenceDesignator',
  fullKey: 'field.referenceDesignator',
  type: 'field',
  showNonFullscreen: false,
  valueType: 'String',
  width: 100,
  align: 'left',
  notInFilters: true,
  displayFn(node) {
    return String(node.parentToNodeDependency?.referenceDesignator || '-')
  },
  filterValue(node) {
    return typeof node.parentToNodeDependency?.referenceDesignator === 'string' ? node.parentToNodeDependency.referenceDesignator : null
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.parentToNodeDependency?.referenceDesignator || ''
    const b = nodeB.parentToNodeDependency?.referenceDesignator || ''
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}, {
  name: 'CAD Rev',
  key: 'cadRev',
  fullKey: 'field.cadRev',
  valueType: 'String',
  type: 'field',
  showNonFullscreen: false,
  width: 50,
  align: 'center',
  displayFn(node) {
    return String(node.part.cadRev || '-')
  },
  filterValue(node) {
    return node.part.cadRev || null
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.cadRev || ''
    const b = nodeB.part.cadRev || ''
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}, {
  name: 'Off the shelf',
  key: 'isOffTheShelf',
  fullKey: 'field.isOffTheShelf',
  valueType: 'Boolean',
  type: 'field',
  showNonFullscreen: false,
  width: 90,
  align: 'center',
  displayFn(node) {
    return humanBoolean(node.part.isOffTheShelf)
  },
  filterValue(node) {
    return node.part.isOffTheShelf
  },
  sortFn(nodeA, nodeB) {
    const a = nodeA.part.isOffTheShelf
    const b = nodeB.part.isOffTheShelf
    if (a && !b) return 1
    if (!a && b) return -1
    return 0
  }
}]

interface PartNodeProps {
  node: ControllerTreeNode
}

export const ChangeOrdersList: React.FC<PartNodeProps> = ({ node }) => {
  const orgId = useParams().orgId!
  return <div className='flex gap-2' onClick={(e) => e.stopPropagation()}>
  {
    node.proto?.inChangeOrders.filter(c => (['Review', 'Draft']).includes(c.state)).map(c => {
      return <a
        href={routes.changeOrder({ orderNumber: c.number, orgId })}
        target='_blank'
        key={c.number}
        className='text-white bg-blue-400 rounded-md inline-block p-0.5 px-2 text-xs hover:underline'>
        #CO-{c.number} - {c.name}
      </a>
    })
  }
</div>
}
