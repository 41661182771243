import { prettyDateTime } from 'src/lib/formatters'
import { useState } from 'react'
import humanizeString from 'humanize-string'
import type { ChangeOrderLogPayload } from 'shared/types'
import { Comment } from './Comment'
import {
  CheckIcon,
  UserPlusIcon,
  UserMinusIcon,
  PencilIcon,
  PlusIcon,
  StopIcon,
  SparklesIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import {
  BookOpenIcon,
  DocumentPlusIcon,
  ChatBubbleLeftIcon,
  DocumentArrowUpIcon,
  FlagIcon,
  TrashIcon,
  ForwardIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline'

import type { FindChangeOrderQuery } from 'types/graphql'

const highlight = (children: React.ReactNode) => {
  return <span className='font-medium text-gray-900'>{children}</span>
}

const changeIcon = ({ icon, color }: { color?: string; icon?: React.ReactNode }) => {
  color = color || 'bg-white'
  const iconClass = `
    rounded-xl border border-gray-200 ${color}
    w-7 h-7 flex items-center justify-center
    absolute top-4 left-1/2 -translate-x-1/2
  `
  return (
    <div className='flex relative min-w-10 min-h-16'>
      <div className='flex-1 border-r border-gray-200'></div>
      <div className='flex-1'></div>
      <div className={iconClass}>{icon}</div>
    </div>
  )
}

const isSignificantEvent = (log: any) => {
  return [
    'Comment',
    'ApproveChangeOrder',
    'RequestChanges',
    'ChangeState',
    'RequestAnotherReview',
  ].includes(log.action)
}

interface ActivityLogProps {
  changeOrder: FindChangeOrderQuery['changeOrder']
}

export const ActivityLog = ({ changeOrder }: ActivityLogProps) => {
  const { log: initialLog } = changeOrder
  const [expandedGroups, setExpandedGroups] = useState<{[key: string]: number}>({})

  const processLogWithCollapses = () => {
    const processed: (typeof initialLog[number] | 'collapse' | {type: 'collapse', groupId: string, total: number})[] = []
    let currentGroup: typeof initialLog[number][] = []
    let groupCounter = 0

    // Get the last 3 significant items to always show
    const lastThreeSignificant = initialLog.slice(-3)

    const handleGroup = (group: typeof initialLog[number][], isLast = false) => {
      if (group.length >= 3) {
        // Don't collapse if any items in this group are part of the last 3
        if (group.some(item => lastThreeSignificant.includes(item))) {
          processed.push(...group)
          return
        }

        const groupId = `group-${groupCounter}`
        const expandedCount = expandedGroups[groupId] || 0
        const remainingItems = group.length - 1 - expandedCount

        if (remainingItems <= 0) {
          processed.push(...group)
        } else {
          processed.push(group[0]) // Show first item
          if (expandedCount > 0) {
            // Show expanded items after the first item
            processed.push(...group.slice(1, 1 + expandedCount))
          }
          processed.push({
            type: 'collapse',
            groupId,
            total: remainingItems
          })
        }
        groupCounter++
      } else {
        processed.push(...group)
      }
    }

    // Process all but the last 3 items
    const itemsToProcess = initialLog.slice(0, -3)
    itemsToProcess.forEach((item, index) => {
      if (isSignificantEvent(item)) {
        handleGroup(currentGroup)
        processed.push(item)
        currentGroup = []
      } else {
        currentGroup.push(item)
      }
    })

    // Handle any remaining group before last 3
    if (currentGroup.length > 0) {
      handleGroup(currentGroup, true)
    }

    // Add the last 3 items
    processed.push(...lastThreeSignificant)

    return processed
  }

  const processedLog = processLogWithCollapses()

  return (
    <div>{
      processedLog.map((log: any) => {
        if (log !== 'collapse' && typeof log === 'object' && 'type' in log && log.type === 'collapse') {
          return (
            <div className='flex -mr-2' key={`collapse-${log.groupId}`}>
              <button
                onClick={() => setExpandedGroups(prev => ({
                  ...prev,
                  [log.groupId]: (prev[log.groupId] || 0) + 10
                }))}
                className='flex flex-1 rounded-md justify-center my-4 bg-gray-100 p-2 text-xs hover:bg-gray-200'>
                <div className='flex items-center gap-1'>
                  Show More Items ({log.total}) <ChevronUpDownIcon className='w-4' />
                </div>
              </button>
            </div>
          )
        }

        if (log === 'collapse') return null

        const logItem = () => {
          if (log.action === 'AddDelta') {
            const payload = log.payload as ChangeOrderLogPayload['AddDelta']
            if (payload.delta.type === 'Patch' || payload.delta.type === 'Push') {
              const changes = Object.keys(payload.delta.part || {}).filter(k => k !== 'updateToPublishId').map(s => humanizeString(s).toLowerCase())
              const body = payload.delta.type === 'Push' && !changes.length ? <div>
              <span className='font-medium'>{log.user.name}</span> added {highlight(`#${log.partNumber} - ${payload.partName}`)} to change order </div> : <div>
                  <span className='font-medium'>{log.user.name}</span> updated {changes.join(', ')} for part {highlight(`#${log.partNumber} - ${payload.partName}`)}</div>
              return {
                icon: <PencilIcon className='h-4 w-4' />,
                body
              }
            }
            if (payload.delta.type === 'Version') {
              return {
                icon: <PlusIcon className='h-4 w-4' />,
                body: <div>
                  <span className='font-medium'>{log.user.name}</span> changed part {highlight(`#${log.partNumber}`)} version to {highlight(payload.delta.version)} </div>
              }
            }
            if (payload.delta.type === 'Create') {
              return {
                icon: <PlusIcon className='h-4 w-4' />,
                body: <div>
                  <span className='font-medium'>{log.user.name}</span> created part {highlight(`#${log.partNumber}`)} - {highlight(payload.partName)}  </div>
              }
            }
          }
          if (log.action === 'RemovePart') {
            const payload = log.payload as ChangeOrderLogPayload['RemovePart']
              return {
                icon: <TrashIcon className='h-4 w-4' />,
                body: <div>
                  <span className='font-medium'>{log.user.name}</span> removed part {highlight(`#${log.partNumber}`)} {payload.partName && <>- {highlight(payload.partName)}</>} from the order
                </div>
              }
          }
          if (log.action === 'RebasePart') {
            const payload = log.payload as ChangeOrderLogPayload['RebasePart']
              return {
                icon: <ForwardIcon className='h-4 w-4'/>,
                body: <div>
                  <span className='font-medium'>{log.user.name}</span> rebased part {highlight(`#${log.partNumber}`)} {payload.partName && <>- {highlight(payload.partName)}</>} onto the current version
                </div>
              }
          }
          if (log.action === 'ImportAssembly') {
            const payload = log.payload as ChangeOrderLogPayload['ImportAssembly']
            const importTypes = {
              created: 'created',
              updated: 'updated'
            }
            const importType = importTypes[payload.deltaType]
            const body = log.partNumber ?
              <div>{highlight(log.user.name)} {importType} assembly {highlight(`#${log.partNumber} - ${payload.rootPartName}`)} with an import of {payload.partIds.length} parts</div> :
              <div>{highlight(log.user.name)} imported changes with {payload.partIds.length} parts</div>
            return {
              icon: <DocumentArrowUpIcon className='h-4 w-4' />,
              body
            }
          }
          if (log.action === 'Comment') {
            const payload = log.payload as ChangeOrderLogPayload['Comment']
            return {
              bubble: <Comment
                content={payload.message}
                userName={log.user.name}
                createdAt={prettyDateTime(new Date(log.createdAt))}
                context={{ changeOrder: { number: changeOrder.number, partNumbers: changeOrder.includedPartNumbers } }}
              />,
              icon: <ChatBubbleLeftIcon className='h-4 w-4' />,
              body: <div>{highlight(log.user.name)} commented</div>
            }
          }
          if (log.action === 'ApproveChangeOrder') {
            const payload = log.payload as ChangeOrderLogPayload['ApproveChangeOrder']
            return {
              bubble: payload.message,
              color: 'bg-green-200',
              icon: <CheckIcon className='h-4 w-4' />,
              body: <div>{highlight(log.user.name)} approved change order {payload.message ? `with comment` : ""}</div>
            }
          }
          if (log.action === 'RequestChanges') {
            const payload = log.payload as ChangeOrderLogPayload['RequestChanges']
            return {
              bubble: payload.message,
              color: 'bg-yellow-200',
              icon: <div className='p-4 font-bold'>!</div>,
              body: <div>{highlight(log.user.name)} requested changes {payload.message ? `with comment` : ""}</div>
            }
          }
          if (log.action === 'DismissReview') {
            const payload = log.payload as ChangeOrderLogPayload['DismissReview']
            return {
              icon: <XMarkIcon className='h-4 w-4' />,
              body: <div>{highlight(log.user.name)} dismissed review from {highlight(payload.user.name)}</div>
            }
          }
          if (log.action === 'Creation') {
            return {
              icon: <DocumentPlusIcon className='h-4 w-4' />,
              body: <div>{highlight(log.user.name)} created draft change order</div>
            }
          }
          if (log.action === 'AddReviewer') {
            const payload = log.payload as ChangeOrderLogPayload['AddReviewer']
            return {
              icon: <UserPlusIcon className='h-4 w-4' />,
              body: <div>{highlight(log.user.name)} added {payload.role === 'Reviewer' ? 'reviewer' : 'watcher'} {highlight(payload.user.name)}</div>
            }
          }
          if (log.action === 'DeleteReviewer') {
            const payload = log.payload as ChangeOrderLogPayload['DeleteReviewer']
            return {
              icon: <UserMinusIcon className='h-4 w-4' />,
              body: <div>{highlight(log.user.name)} removed {payload.role === 'Reviewer' ? 'reviewer' : 'watcher'} {highlight(payload.user.name)}</div>
            }
          }
          if (log.action === 'UpdateChangeOrder') {
            const payload = log.payload as ChangeOrderLogPayload['UpdateChangeOrder']
            if (payload.changes.description) {
              return {
                icon: <PencilIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} updated change order description</div>
              }
            }
            if (payload.changes.name) {
              return {
                icon: <PencilIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} updated change order name</div>
              }
            }
            if (payload.changes.metadata) {
              return {
                icon: <PencilIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} updated change order properties</div>
              }
            }
          }
          if (log.action === 'ChangeState') {
            const payload = log.payload as ChangeOrderLogPayload['ChangeState']
            if (payload.state === 'Review') {
              return {
                icon: <BookOpenIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} set change order state to review</div>
              }
            }
            if (payload.state === 'Draft') {
              return {
                icon: <SparklesIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} set change order state to draft</div>
              }
            }
            if (payload.state === 'Complete') {
              return {
                icon: <FlagIcon className='h-4 w-4' />,
                body: <div>{highlight(log.user.name)} applied the change order {
                  payload.lifeCycleOverride ? 'via lifecycle validation override' :
                  (payload.override && 'via workflow override')
                }</div>
              }
            }

            if (payload.state === 'Cancelled') {
              return {
                color: 'bg-red-400',
                icon: <StopIcon className='h-3 w-3' />,
                body: <div>{highlight(log.user.name)} cancelled the change order</div>,
                bubble: payload.message ? <Comment
                  content={payload.message}
                  userName={log.user.name}
                  createdAt={prettyDateTime(new Date(log.createdAt))}
                  context={{ changeOrder: { number: changeOrder.number, partNumbers: changeOrder.includedPartNumbers } }}
                /> : null
              }
            }
          }
          if (log.action === 'RequestAnotherReview') {
            const payload = log.payload as ChangeOrderLogPayload['RequestAnotherReview']
            return {
              icon: <ArrowPathIcon className='h-4 w-4' />,
              body: <div>{highlight(log.user.name)} requested another review from {highlight(payload.user.name)}</div>
            }
          }
        }

        const { body, icon, color, bubble } = logItem() ?? {}
        return (
          <div key={log.id} className='flex text-gray-600 gap-3'>
            {changeIcon({ icon, color })}
            <div className='mt-4 flex-1 flex flex-col'>
              <div className='flex items-center h-7'>
                <div className='flex flex-col gap-4 flex-1'>{body}</div>
                <div className='text-xs'>
                  {prettyDateTime(new Date(log.createdAt))}
                </div>
              </div>
              {bubble && (
                <div className='border border-gray-200 rounded-lg p-4 flex-1 min-w-96 my-3 max-w-4xl whitespace-prewrap'>
                  {bubble}
                </div>
              )}
            </div>
          </div>
        )
      })
    }</div>
  )
}