import {
  HTMLAttributes,
  useEffect,
  useState,
  createContext,
  useContext
} from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

import { useParams, Link } from '@redwoodjs/router'
import { useVirtualizer } from '@tanstack/react-virtual'

import { XCircleIcon, ChevronDownIcon, ChevronRightIcon, ExclamationTriangleIcon, PlusIcon, ChevronUpIcon, XMarkIcon, PhotoIcon } from '@heroicons/react/20/solid'

import { ScaleIcon } from '@heroicons/react/16/solid'

import { AggregationNode, Aggregator } from 'src/components/PartCell/aggregation';
import { useRequiredContext } from 'src/lib/appContext'

import type {
  PartHierarchy,
  PartTreeNodeFragment,
} from 'types/graphql'

import { isVersionIncrement, validVersion } from 'src/lib/version'

import { SwatchIcon } from '@heroicons/react/24/outline';
import padEnd from 'lodash.padend'
import { Container as TooltipContainer, Message as TooltipMessage, Tooltip2 } from "src/components/Tooltip"
import { letF } from 'api/src/shared/functional'
import { findPartChildren } from 'api/src/lib/bom'

import type {
  HierarchyController,
  ControllerTreeNode,
  SubjectPart,
  EditableAggregator,
} from './hierarchyController'

import {
    getSourceColumnWidth,
  HierarchyControllerContext
} from './hierarchyController'
import { PartColumnSelect, PartFilterSelect, allFilters } from '../PartColumnSelector/PartColumnSelector'
import keyBy from 'lodash.keyby'
import { useLifeCycleStages } from 'src/lib/lifecycle'
import Thumbnail from '../ThumbnailViewer'
import HoverPopover from '../HoverPopover'
import { useRightPanel } from '../RightPanel/RightPanel'
import React from 'react'

export {
  HierarchyControllerContext,
  useHierarchyControllerFactory,
} from './hierarchyController'

export type {
  RootInput,
  HierarchyController,
  HierarchyControllerInput,
  ControllerTreeNode,
  SubjectPart,
  PartHierarchyHeaderColumn,
  ActivatableAggregator,
  EditableAggregator,
  HierarchyMode
} from './hierarchyController'

type PartHierarchyExplorerProps = {
  hierarchyController: HierarchyController
}


const BranchDisabledContext = createContext<boolean>(false)

const HEADER_HEIGHT = 50
const FILTER_HEIGHT = 40

const ROW_HEIGHT = 32

const leftSticky = {
  position: 'sticky',
  left: 0,
  zIndex: 22,
  overflow: 'visible',
  height: ROW_HEIGHT - 6,
  alignItems: 'center',
} as const
interface AggWidthInput {
  name: string
  index: number
}

const aggWidth = (a: AggWidthInput, columnWidths: Record<string, number>) => {
  const minWidth = 40 + (10 * a.name.length)
  return Math.max(
    (columnWidths[`agg.${a.index}`] || 0),
    minWidth
  )
}

const PartHierarchy: React.FC<PartHierarchyExplorerProps> = ({
  hierarchyController,
}) => {
  const [nameWidthNoMin, setNameColumnWidth] = useState(hierarchyController.allColumns.find(c => c.fullKey === 'field.name')!.width)
  const nameColumnWidth = Math.max(nameWidthNoMin, 250)

  const { roots, selectable, fullScreen, selectedAgg, setSelectedAgg, filterController, allColumns, filteredColumns } = hierarchyController

  const [columnWidths, setColumnWidths] = useState<Record<string, number>>(allColumns.reduce((widths, c) => {
    return {
      ...widths,
      [c.fullKey]: c.width
    }
  }, {} as Record<string, number>))

  const updateColumnWidth = (columnKey: string, width: number) => {
    setColumnWidths(prev => ({
      ...prev,
      [columnKey]: width
    }))
  }

  // Update columnWidths when allColumns changes
  useEffect(() => {
    setColumnWidths(prev => {
      const newWidths = { ...prev };
      allColumns.forEach(c => {
        if (!newWidths[c.fullKey]) {
          // For source columns, use the getSourceColumnWidth function
          if (c.fullKey.includes('part.sources[')) {
            const propertyKey = c.fullKey.split('.').pop() || '';
            newWidths[c.fullKey] = getSourceColumnWidth(propertyKey);
          } else if (c.width) {
            // For other columns, use their defined width
            newWidths[c.fullKey] = c.width;
          }
        }
      });
      return newWidths;
    });
  }, [allColumns]);

  const rootComponents = roots
    .map((root, i) => {
      return <HierarchyControllerContext.Provider value={{ hierarchyController, root, columnWidths, nameColumnWidth }} key={root.rootNode.address}>
        <PartHierarchyNode node={root.rootNode} depth={0} />
      </HierarchyControllerContext.Provider>
    })

  const parentRef = React.useRef(null)

  const rowCountByRoot = roots.reduce((rowCountByRoot, root, rootIndex) => {
    // we should index the root in the controller, and then use the root index
    // elsewhere to refer to it, or more ideally adjust all the nodes to include
    // the root index as the first part of their hierarchy
    // const { rootIndex } = root

    const treeNodeCount = root.tree.reduce((treeNodeCount, node) => {
      if (node.filterRemove) return treeNodeCount

      // always count the root
      if (node.isTreeRoot) return treeNodeCount + 1

      let anyCollapsedAncestor = false
      const levels = node.partNumberAddress.split('.')
      for (let i = 1; i < levels.length; i++) {
        const ancestorLevel = levels.slice(0, i).join('.')
        anyCollapsedAncestor = anyCollapsedAncestor || !root.expandedNodes[ancestorLevel]
      }
      if (!anyCollapsedAncestor) {
        return treeNodeCount + 1
      }

      return treeNodeCount
    }, 0)


    return {
      ...rowCountByRoot,
      [rootIndex]: treeNodeCount
    }
  }, {} as Record<number, number>)

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: roots.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (rootIndex) => {
      // Row height plus 1px border
      return (ROW_HEIGHT + 1) * rowCountByRoot[rootIndex]!
    },
  })

  React.useEffect(() => {
    rowVirtualizer.measure()
  }, [roots])

  const allSelected = roots.every(root => {
    const selectedByLevel = keyBy(root.selectedNodes)
    const allSelected = root.tree.every(node => {
      if (node.filterRemove) {
        return true
      }
      if (!selectedByLevel[node.hierarchy]) {
        return false
      }
      return true
    })

    return allSelected
  })
  const selectAll = <div className='flex items-center cursor-pointer'>
    <input
      onClick={e => e.stopPropagation()}
      type="checkbox"
      onChange={e => {
        if (e.target.checked) {
          hierarchyController.selectAll()
        } else {
          hierarchyController.unselectAll()
        }
      }}
      checked={allSelected}
      aria-describedby="select-all-children"
      className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
    />
  </div>

  const totalRowCount = Object.values(rowCountByRoot).reduce((total, rowCount) => total + rowCount, 0)

  const uiFilters = hierarchyController.hideFilterUi ? [] : filterController.filters
  const filtersHeight = FILTER_HEIGHT * uiFilters.length
  const totalHeight = (((ROW_HEIGHT + 1) * totalRowCount) + HEADER_HEIGHT * 2) + filtersHeight + 60
  const containerHeight = fullScreen ? undefined : totalHeight
  const stages = useLifeCycleStages()


  return <>
    <div className='overflow-auto h-full w-full' ref={parentRef} style={{ height: containerHeight }}>
      <div
        className='w-fit min-w-full'
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          position: 'relative',
        }}
      >
        {
          uiFilters.map((f, i) => {
            const selectedFilter = allFilters.find(filter => filter.key === f.type)!
            const selectedColumn = allColumns.find(c => c.fullKey === f.key)!
            return <div
              key={i}
              className='sticky bg-gray-100 text-xs border-t border-gray-200' style={{
                height: FILTER_HEIGHT,
                top: (i * (FILTER_HEIGHT)),
                // hack of the century to make dropdowns not on top of each other
                zIndex: 26 + (uiFilters.length - i)
              }}>
              <div className='fixed left-0 right-0 flex items-center gap-2 pl-10 pr-6' style={{
                position: fullScreen ? 'fixed' : 'sticky',
                height: FILTER_HEIGHT
              }}>
                <div>Filter</div>
                <PartColumnSelect columns={allColumns} value={f.key} onChange={(fullKey) => {
                  const column = allColumns.find(c => c.fullKey === fullKey)!
                  // default to another filter if the type isn't supported
                  const getFilterOrDefault = () => {
                    if (selectedFilter.forType.includes(column.valueType)) {
                      return {
                        type: f.type
                      }
                    }

                    // option not allowed unless there is at least
                    // one stage
                    const defaultStage = stages[0]!.key

                    const value = column.valueType === 'Boolean' ? true
                      : column.valueType === 'Number' ? 0
                        : column.valueType === 'String' ? ''
                          : column.valueType === 'URL' ? ''
                            : column.valueType === 'LifeCycle' ? defaultStage : null

                    const defaultFilter = allFilters.find(filter => filter.forType.includes(column.valueType))!
                    return {
                      type: defaultFilter.key,
                      value
                    }
                  }

                  filterController.updateFilter(i, {
                    ...f,
                    ...getFilterOrDefault(),
                    key: column.fullKey
                  })
                }} />
                <PartFilterSelect value={f.type} forType={selectedColumn.valueType} onChange={(filterType) => {
                  filterController.updateFilter(i, {
                    ...f,
                    type: filterType
                  })
                }} />
                <selectedFilter.Input value={f.value} valueType={selectedColumn.valueType} onChange={(v) => {
                  filterController.updateFilter(i, {
                    ...f,
                    value: v
                  })
                }} />
                {/* <div className='flex-1'>{JSON.stringify(f.value)}</div> */}
                <button
                  className='ml-auto'
                  onClick={() => filterController.removeFilter(i)}><XMarkIcon className='w-4 h-4' /></button>
              </div>
            </div>
          })
        }
        <div className='border-t bg-white z-[25] sticky top-0 w-fit min-w-full' style={{
          top: (uiFilters.length * FILTER_HEIGHT)
        }}>
          <PartHierarchyHeader
            hierarchyController={hierarchyController}
            selectedAggIndex={selectedAgg?.index}
            onAggregationHeaderClicked={(a) => {
              hierarchyController.setToolbarMode('agg')
              setSelectedAgg(a.index)
            }}
            selectedStyle='!border-gray-200 rounded-b pt-[17px] -mt-[17px] -mb-[5px] bg-gray-100 w-auto hover:bg-gray-100'
            onCreateLocalAggregator={hierarchyController.createLocalAggregator}
            nameColumnWidth={nameColumnWidth}
            onWidthChange={setNameColumnWidth}
            columnWidths={columnWidths}
            onColumnWidthChange={updateColumnWidth}
          />
        </div>
        <div className='auto pb-4 w-fit min-w-full'>
          {selectable &&
            <div className='w-full border-t flex text-xs'>
              <div className={`px-5 flex border-gray-200 items-center gap-3 bg-white`} style={{
                ...leftSticky,
                minWidth: `${nameColumnWidth}px`,
                height: ROW_HEIGHT,
              }} >
                <div className='w-4 -mx-1' />
                {selectAll}
                Select All
              </div>
            </div>
          }
          {rootComponents.length > 0 ?
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative',
              }}
            >
              {/* Only the visible items in the virtualizer, manually positioned to be in view */}
              {rowVirtualizer.getVirtualItems().map((virtualItem) => (
                <div
                  key={virtualItem.key}
                  data-index={virtualItem.index}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                >
                  {rootComponents[virtualItem.index]}
                </div>
              ))}
            </div>
            : <div className='italic text-gray-600 text-center bg-gray-100 p-12'>There are no parts</div>}
        </div>
      </div>
    </div>
  </>
}

type PartHierarchyHeaderProps = {
  selectedAggIndex?: number
  selectedStyle?: string
  onAggregationHeaderClicked?: (agg: EditableAggregator) => void
  onCreateLocalAggregator?: () => void
  hierarchyController: HierarchyController
  nameColumnWidth: number
  onWidthChange: (width: number) => void
  columnWidths: Record<string, number>
  onColumnWidthChange: (columnKey: string, width: number) => void
}

export const PartHierarchyHeader: React.FC<PartHierarchyHeaderProps> = ({
  selectedAggIndex,
  selectedStyle = '',
  onAggregationHeaderClicked,
  onCreateLocalAggregator,
  hierarchyController,
  nameColumnWidth,
  onWidthChange,
  columnWidths,
  onColumnWidthChange
}) => {
  const { filteredColumns, aggregators, showAggregations, sortColumn, sortBy } = hierarchyController
  const shownAggs = showAggregations ? aggregators : []

  const [isDragging, setIsDragging] = useState<string | null>(null)
  const getTargetType = (a: Aggregator) => {
    const currencySetting = hierarchyController.targetCurrency
    return currencySetting?.type === a.targetType.type ? currencySetting : a.targetType
  }

  const aggClassName = (agg: EditableAggregator) =>
    twMerge(
      classNames(`
          group shrink-0 p-2 -my-2 ml-[9px] -mr-[9px] border border-t-0 min-w-32 border-white text-right
          whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible flex items-center
          hover:z-10 hover:bg-white relative justify-end pr-4
        `,
        {
          [selectedStyle]: agg.index === selectedAggIndex,
          'italic': agg.isDirty
        })
    )

  const AggTab = onAggregationHeaderClicked ? 'button' : 'div'


  const [startX, setStartX] = useState(0)
  const [startWidth, setStartWidth] = useState(0)

  const handleMouseDown = (columnKey: string, initialWidth: number) => (e: React.MouseEvent) => {
    setIsDragging(columnKey)
    setStartX(e.clientX)
    setStartWidth(initialWidth)
    e.preventDefault()
  }

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return

      const diff = e.clientX - startX
      const newWidth = Math.max(50, startWidth + diff) // Minimum width of 100px

      if (isDragging === 'nameColumn') {
        onWidthChange(newWidth)
      } else {
        onColumnWidthChange(isDragging, newWidth)
      }
      e.preventDefault()
    }

    const handleMouseUp = () => {
      setIsDragging(null)
    }

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, startX, startWidth, onWidthChange, onColumnWidthChange])

  const ResizeHandle = ({ columnKey }: { columnKey: string }) => {
    const rightClass = columnKey === 'nameColumn' || columnKey.startsWith('agg.') ? '-right-2' : '-right-5'
    return <div
      className={`absolute cursor-col-resize w-4 items-center flex flex-col h-6 ${rightClass}`}
      onMouseDown={handleMouseDown(columnKey, columnKey === 'nameColumn' ? nameColumnWidth : columnWidths[columnKey] || 100)}
    >
      <div className='border-r border-r-gray-200 flex-1'></div>
    </div>
  }

  return <div className={`flex text-xs pr-2 justify-between`}>
    <div className='flex items-center' style={{
      ...leftSticky,
      height: HEADER_HEIGHT,
      minWidth: `${nameColumnWidth}px`,
    }}>
      <div className={`pl-10 flex items-center bg-white relative`} style={{ width: '100%', height: HEADER_HEIGHT }}>
        Part Number / Name
        <ResizeHandle columnKey="nameColumn" />
      </div>
    </div>
    <div className='flex gap-6 items-center overflow-hidden'>
      {filteredColumns.filter(c => c.key !== 'partNumberName').map(column => {
        const columnKey = column.fullKey
        const isSortColumn = column.type === sortColumn.type && column.key === sortColumn.key
        const width = columnWidths[columnKey] || column.width

        return (
          <div key={columnKey} className="relative flex items-center" style={{ width, height: HEADER_HEIGHT }}>
            <button
              title={column.name}
              className='flex items-center'
              onClick={() => {
                if (!isSortColumn) {
                  hierarchyController.setSortBy({
                    type: column.type,
                    key: column.key,
                    direction: 'asc'
                  })
                  return
                }
                if (sortBy.direction === 'asc') {
                  hierarchyController.setSortBy({
                    ...sortBy,
                    direction: 'desc'
                  })
                  return
                }
                hierarchyController.resetSort()
              }}
            >
              <div className='shrink-0 overflow-hidden text-ellipsis whitespace-nowrap h-[17px] items-center'
                style={{
                  width: isSortColumn ? width - 20 : width,
                  textAlign: column.headerAlign || column.align || 'left',
                }}
              >
                {column.name}
              </div>
              {isSortColumn ? <div>
                {sortBy.direction === 'asc' ? <ChevronUpIcon className='w-[20px]' /> : <ChevronDownIcon className='w-[20px]' />}
              </div> : null}
            </button>
            <ResizeHandle columnKey={columnKey} />
          </div>
        )
      })}
      {shownAggs && shownAggs.length > 0 &&
        shownAggs.map((a, idx) => letF(getTargetType(a), targetType => {
          // I can't figure out why this should
          // be editable when shownAggs are ActiveAggregators??
          const agg = a as EditableAggregator
          return <AggTab
            key={idx}
            className={aggClassName(a)}
            style={{ width: aggWidth(a, columnWidths), height: HEADER_HEIGHT }}
            type={onAggregationHeaderClicked ? 'button' : undefined}
            onClick={() => onAggregationHeaderClicked?.(a)}
          >
            <span className={`p-2 -m-2 bg-inherit`}>
              <ScaleIcon className='w-4 inline -mt-1' />{' '}
              {a.name} {(targetType.type === "Number" || targetType.type === "Boolean") ? null : `(${targetType.unit})`} {agg.isDirty && '*'}
            </span>
            <ResizeHandle columnKey={`agg.${agg.index}`} />
          </AggTab>
        }))
      }
      {(showAggregations) ?
        <TooltipContainer>
          <TooltipMessage position='leftHorizontal' className='right-8'>
            Add New Aggregation
          </TooltipMessage>
          <button className='flex w-5 h-5 justify-center items-center mx-3 hover:bg-gray-200 rounded' type='button' onClick={onCreateLocalAggregator}>
            <PlusIcon className='w-4 h-4' />
          </button>
        </TooltipContainer>
        : null}
    </div>
  </div>
}

type Padding = {
  partNumber: number
}

type PartHierarchyNodeProps = {
  padding?: Padding
  node: ControllerTreeNode
  parentKey?: string
  depth: number
}
const PartHierarchyNode: React.FC<PartHierarchyNodeProps> = ({
  node,
  padding,
  depth,
}) => {
  const {
    root,
    hierarchyController: {
      filteredColumns,
      selectable,
      aggregators,
      usageContext,
      showAggregations,
      sortBy,
      sortColumn,
    },
    nameColumnWidth,
    columnWidths
  } = useRequiredContext(HierarchyControllerContext)
  const {
    tree,
    subjectParts,
    expandedNodes,
    setExpanded,
    aggregations,
    selectedNodes,
    setSelectedNodes,
  } = root
  const shownAggs = showAggregations ? aggregators : []

  const branchDisabled = useContext(BranchDisabledContext)

  const { panelRoute, params } = useRightPanel()

  const isGroup = node.part.nodeType === 'PartGroup'
  const hasAggregations = (shownAggs?.length || 0) > 0
  const nodeId = node.partNumberAddress
  const expanded = expandedNodes[nodeId]

  const children = findPartChildren(tree, node.hierarchy).sort((childA, childB) => {
    // Check for sortOverride on either root node
    if (childA.sortOverride !== undefined || childB.sortOverride !== undefined) {
      const aSort = childA.sortOverride ?? Number.MAX_SAFE_INTEGER
      const bSort = childB.sortOverride ?? Number.MAX_SAFE_INTEGER
      return aSort - bSort // 0 sorts first, undefined sorts last
    }
    // Handle sort direction before the default sort
    if (sortBy.direction === 'asc') {
      return -1 * sortColumn.sortFn(childA, childB)
    }
    return sortColumn.sortFn(childA, childB)
  })

  const nodeProps = subjectParts?.find(p => p.hierarchy === node.hierarchy)

  const expandable = Boolean(children.filter(c => !c.filterRemove).length)
  const iconClass = 'h-4 w-4'

  const partActive = node.part.id === params?.partId

  const toggleClass = twMerge(classNames(
    'flex items-center justify-between bg-white box-content pr-2 border-t',
    {
      'ring-1 ring-inset ring-blue-600': partActive,
      'bg-blue-100': !nodeProps?.highlightColor && partActive,
      'bg-red-50': nodeProps?.highlightColor === 'red',
      'bg-green-50': nodeProps?.highlightColor === 'green',
      'bg-yellow-50': nodeProps?.highlightColor === 'yellow',
      'cursor-pointer': expandable
    }
  ))


  const toggleExpand = () => {
    if (expandable) setExpanded(nodeId, !expanded)
  }

  const expandButton = () => {
    const icon = expanded ?
      <ChevronDownIcon data-testid='collapse-hierarchy' className={iconClass} /> :
      <ChevronRightIcon data-testid='expand-hierarchy' className={iconClass} />

    const className = expandable ? ' text-gray-500 hover:bg-gray-900/10 cursor-pointer rounded-md p-0.5' : ''

    return <div className={className} onClick={toggleExpand}>
      {expandable ? icon : <div className={iconClass} />}
    </div>
  }

  //TODO: consider if this is still right
  const checked = selectedNodes.includes(node.hierarchy)
  const disableChildren = branchDisabled || (!checked);

  const selectBox = () => {
    if (!selectable) return null
    return <div className='flex items-center cursor-pointer'>
      <input
        onClick={e => e.stopPropagation()}
        id="hierarchy-node-toggle"
        data-testid={`hierarchy-node-checkbox-${node?.part?.partNumber}`}
        name="hierarchy-node"
        type="checkbox"
        onChange={e => {
          let newNodes: typeof selectedNodes
          if (e.currentTarget.checked) {
            if (!expanded) {
              //when node is collapsed, include children automatically
              const allDecendants = tree
                .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
                .map(d => d.hierarchy)
              newNodes = Array.from(new Set([...selectedNodes, node.hierarchy, ...allDecendants]))
            }
            else {
              newNodes = [...selectedNodes, node.hierarchy]
            }
          }
          else {
            const dSet = new Set(tree
              .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))
              .map(d => d.hierarchy))
            newNodes = selectedNodes.filter(n => n !== node.hierarchy && !dSet.has(n))
          }

          setSelectedNodes(newNodes)
        }}
        checked={checked}
        disabled={branchDisabled}
        aria-describedby="hierarchy-node-checkbox"
        className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
      />
    </div>
  }

  const AggNode = ({ agg, values }: { agg: Aggregator, values?: AggregationNode['values'] }) => {
    const formatter = (n: number) => agg.targetType.type === 'Price' ?
      n.toLocaleString("en", { minimumFractionDigits: 2 }) : n

    const inner = () => {
      if (agg.reducer === 'And') {
        return String(values?.[agg.name]?.[0] ?? '-')
      }

      if (!values?.[agg.name]) return '-'
      const [min, max, note] = values[agg.name]!
      return <div className='text-right' style={{ textAlign: 'right' }}>
        {note?.type === 'failed' ?
          <TooltipContainer className='relative'>
            <XCircleIcon className='w-4 inline' />
            <TooltipMessage className="bottom-10" position='left'>
              There was an error calulating this value
            </TooltipMessage>
          </TooltipContainer> :
          note?.type === 'success' ?
            <TooltipContainer className='relative'>
              {min === max ?
                formatter(min) : `${formatter(min)} - ${formatter(max)}`
              }*
              <TooltipMessage className="bottom-10 z-50" position='left'>
                This value includes conversions from the following units: [{Array.from(note.from).join(", ")}]
              </TooltipMessage>
            </TooltipContainer> :
            min === max ?
              formatter(min) : `${formatter(min)} - ${formatter(max)}`
        }
      </div>
    }
    return <div className='text-right min-w-32 pr-2' style={{ width: aggWidth(agg, columnWidths), textAlign: 'right' }}>
      {inner()}
    </div>
  }

  const thickIndentationLevel: HTMLAttributes<HTMLElement>['style'] = {
    paddingLeft: `${24 + (depth * 12)}px`
  }

  const indentationLevel: HTMLAttributes<HTMLElement>['style'] = {
    paddingLeft: `${depth * 12}px`
  }
  const SPACE = '\u00A0'
  if (node.filterRemove) return null

  const columnValues = filteredColumns.map(c => {
    if (c.type === 'field') {
      return {
        ...c,
        value: c.displayFn(node, {root})
      }
    }
    if (c.type === 'metadata') {
      const m = node.part.resolvedMetadata[c.key]
      return {
        ...c,
        value: m?.displayValue || '-'
      }
    }
    throw new Error('Invalid column type')
  }).filter(c => {
    return c.key !== 'partNumberName'
  })

  const partNumberPadding = padding?.partNumber ? padding.partNumber + 1 : undefined
  const partNumber = padEnd(`#${node.part.partNumber}`, partNumberPadding, SPACE)

  const message = nodeProps?.message || null

  const aggValues = aggregations?.getPartNode(node.hierarchy)?.values

  // Hack because the version summary component changes the version string
  // to illustrate a version change
  const getVersionLink = () => {
    if (usageContext?.changeOrder) {
      return panelRoute('partChanges', {
            partNumber: node.part.partNumber,
            orderNumber: usageContext!.changeOrder.number,
            partId: node.part.id
          })
    }

    return panelRoute('part', {
          partNumber: node.part.partNumber,
          version: node.part.version,
          partId: node.part.id
        })
  }

  const tools = nodeProps?.tools
  const partNameInner = <>
    <div className='whitespace-nowrap'>{partNumber}</div>
    {isGroup && <Tooltip2
        content='Part Choice'
      ><SwatchIcon className='w-4' /></Tooltip2>}
    {node.part.name}
  </>
  const partNameCell = () => {
    return <div className='gap-2 absolute left-0 right-0 bg-inherit h-[28px] flex items-center overflow-hidden text-ellipsis whitespace-nowrap hover:underline underline-offset-4'>
      {partNameInner}
    </div>
  }

  return <div key={node.address} className='text-[13px]'>
    <div className={toggleClass} style={{ height: ROW_HEIGHT }}>
      <div className={`gap-1.5 flex bg-inherit pr-1 group relative ml-[1px]`} style={{
        ...leftSticky,
        width: `${nameColumnWidth}px`,
      }}>
        {nodeProps?.leftMark}
        <div style={indentationLevel}/>
        {expandButton()}
        {selectBox()}
        <div className='flex gap-1 group-hover:opacity-100 opacity-0 transition-opacity text-gray-500 absolute right-0 z-10'>
          {tools && tools({ controllerRoot: root })}
        </div>
        <Link to={getVersionLink()} className='relative flex-1 bg-inherit flex items-center whitespace-nowrap font-medium'>
          {node.mainCell || partNameCell()}
        </Link>
      </div>
      <div className='flex flex-row justify-end gap-6'>
        {
          (columnValues)
            .map(f => {
              return <div
                style={{ width: columnWidths[f.fullKey] }}
                key={`${f!.type}.${f!.key}`}
                className='group shrink-0 text-right whitespace-nowrap overflow-hidden text-ellipsis hover:overflow-visible hover:z-10'>
                <div className='group-hover:bg-inherit px-2 -mx-2 flex items-center' style={{ justifyContent: alignToFlexAlign(f?.align) }}>
                  <div>{f!.value}</div>{f?.key === 'version' && message}
                </div>
              </div>
            })
        }
        {(hasAggregations) && (shownAggs!).map((a, idx) => <AggNode key={idx} values={aggValues} agg={a} />)}
        <CreateAggSpacer show={showAggregations} />
      </div>
    </div>
    {(expanded && children.length) ? letF(
      children.reduce((padding, child) =>
        ({ partNumber: Math.max(child.part.partNumber.length, padding.partNumber) }),
        { partNumber: 0 } as { partNumber: number }),
      childPadding =>
        <div className='flex'>
          <div className='flex flex-col flex-1'>
            <BranchDisabledContext.Provider value={disableChildren}>
              {selectable &&
                <div className='w-full border-t flex'>
                  <div className={`h-[32px] px-3 text-xs flex border-gray-200 items-center gap-3 bg-white`} style={{
                    ...leftSticky,
                    width: `${nameColumnWidth}px`,
                    ...thickIndentationLevel,
                    height: '32px', //not sure why this is necessary to get the rows to line up
                  }} >
                    <div className='w-4 -mx-1' />
                    <SelectAllBox node={node} />
                    Select All Children
                  </div>
                  <div className='bg-green-20 shrink-0 flex gap-6' /*make row the correct width*/ >
                    {(filteredColumns).filter(c => c.key !== 'partNumberName').map(f =>
                      <div style={{ width: columnWidths[f.fullKey] }} className='shrink-0' key={f.name} />)}
                    {hasAggregations && shownAggs!.map((a, idx) =>
                      <div className={`shrink-0 min-w-32`} style={{ width: aggWidth(a, columnWidths) }} key={idx} />)}
                  </div>
                </div>
              }
              {children.map(c =>
                <PartHierarchyNode depth={depth + 1} key={c.address} node={c} padding={childPadding}
                  parentKey={node.hierarchy} />
              )}
            </BranchDisabledContext.Provider>
          </div>
        </div>
    )
      : null
    }
  </div>
}

type SelectAllBoxProps = {
  node: ControllerTreeNode
}
const SelectAllBox: React.FC<SelectAllBoxProps> = ({ node }) => {
  const {
    root: {
      tree,
      selectedNodes,
      setSelectedNodes,
    },
  } = useRequiredContext(HierarchyControllerContext)

  const branchDisabled = useContext(BranchDisabledContext)
  const allDecendants = tree
    .filter(h => h.hierarchy.startsWith(node.hierarchy + '.'))

  const selectedByLevel = keyBy(selectedNodes)
  const allSelected = allDecendants.every(node => {
    if (node.filterRemove) {
      return true
    }
    if (!selectedByLevel[node.hierarchy]) {
      return false
    }
    return true
  })

  const allDescendentLevels = [...allDecendants.map(n => n.hierarchy)]
  const allDescendentsByLevel = keyBy(allDescendentLevels)

  return <div className='flex items-center cursor-pointer'>
    <input
      onClick={e => e.stopPropagation()}
      name="dependencyControl"
      type="checkbox"
      onChange={e => {
        setSelectedNodes(
          e.target.checked ?
            Array.from(new Set([...selectedNodes, ...allDescendentLevels])) :
            selectedNodes.filter(n => !allDescendentsByLevel[n])
        )
      }}
      checked={allSelected}
      disabled={branchDisabled}
      aria-describedby="select-all-children"
      className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600 cursor-pointer"
    />
  </div>
}

export const addVersionWarnings = (tree: PartTreeNodeFragment[], existingSubjectParts?: SubjectPart[]) => {
  const outOfDateRefsByLevel = tree.reduce((byLevel, n) => {
    if (!validVersion(n.part.version as string)) return byLevel
    if (!validVersion(n.part.proto.currentVersionString)) return byLevel
    if (!n.part.proto.currentVersionString) return byLevel
    if (!isVersionIncrement(n.part.version, n.part.proto.currentVersionString)) return byLevel

    return {
      ...byLevel,
      [n.hierarchy]: true as const
    }
  }, {} as Record<string, true>)

  const outOfDateParentsByLevel = Object.keys(outOfDateRefsByLevel)
    .reduce((parentsByLevel, level) => {
      const levelParts = level.split(/\./g)
      return {
        ...parentsByLevel,
        [levelParts.slice(0, -1).join('.')]: true as const
      }
    }, {} as Record<string, true>)

  const outOfDateAncestorsByLevel = Object.keys(outOfDateRefsByLevel)
    .reduce((ancestorsByLevel, level) => {
      const levelParts = level.split(/\./g)
      const nextAncestors = levelParts.reduce(({ levelAcc, ancestorsByLevel }, level) => {
        const nextLevel = [...levelAcc, level]
        return {
          ancestorsByLevel: {
            ...ancestorsByLevel,
            [nextLevel.join('.')]: true as const
          },
          levelAcc: nextLevel
        }
      }, { ancestorsByLevel, levelAcc: [] as string[] })
      return nextAncestors.ancestorsByLevel
    }, {} as Record<string, true>)

  const subjectParts = [...tree].map(n => {
    const existingSubject = (existingSubjectParts?.find(s => s.hierarchy === n.hierarchy))

    const partRefOutOfDate = outOfDateRefsByLevel[n.hierarchy]
    if (partRefOutOfDate && !n.isTreeRoot) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <TooltipContainer className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <TooltipMessage className="bottom-10">
              Newer version v{n.part.proto.currentVersionString} available
            </TooltipMessage>
          </TooltipContainer>
        </div>
      }
    }

    const childrenOutOfDate = outOfDateParentsByLevel[n.hierarchy]
    if (childrenOutOfDate) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <TooltipContainer className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <TooltipMessage className="bottom-10">
              Uses out of date parts
            </TooltipMessage>
          </TooltipContainer>
        </div>
      }
    }

    const descendentsOutOfDate = outOfDateAncestorsByLevel[n.hierarchy]
    if (descendentsOutOfDate) {
      return {
        ...(existingSubject || {}),
        hierarchy: n.hierarchy,
        message: <div>
          <TooltipContainer className='relative'>
            <ExclamationTriangleIcon className='w-4 ml-1 text-yellow-500' />
            <TooltipMessage className="bottom-10">
              Parts in this tree are out of date
            </TooltipMessage>
          </TooltipContainer>
        </div>
      }
    }
    return existingSubject
  }).filter(Boolean) as SubjectPart[]
  return subjectParts
}

const CreateAggSpacer = ({ show }: { show?: boolean }) =>
  show ? <div className='inline-block w-5 mx-3' /> : null

const alignToFlexAlign = (input?: 'center' | 'left' | 'right') => {
  if (input === 'center') return 'center'
  if (input === 'right') return 'end'

  // left is default
  return 'start'
}

export interface ArtifactHoverPopoverProps {
  className?: string
  artifact?: {
    id: number
    fileId: number
    file: {
      url: string
      timestamp: string
    }
  }
}

// Example usage for ArtifactHoverPopover
export function ArtifactHoverPopover({ artifact, className }: ArtifactHoverPopoverProps) {
  if (!artifact) return null;

  return (
    <HoverPopover
      trigger={<PhotoIcon className="w-4 h-4 text-gray-600 relative top-0.5" />}
      content={<Thumbnail artifact={artifact} className="w-full h-full" />}
      className={className}
    />
  );
}

/*
const isSyntheticDelete = (node: ControllerTreeNode | SyntheticDeleteNode | GroupNode): node is SyntheticDeleteNode =>
  'type' in node && node.type === 'syntheticDelete'
*/


export default PartHierarchy
