import {
  AddPartDeltaMutationWithCache,
  AddPartDeltaMutationWithCacheVariables
} from 'types/graphql'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import type { InternalRefetchQueriesInclude } from '@apollo/client'
import { SMART_ADD_PART_DELTA_MUTATION } from './queries'

// this is actually just an addpartdelta mutation with error reporting
export const useAddPartToChangeOrderMutation = () => {
  const [mutation, result] = useMutation<AddPartDeltaMutationWithCache, AddPartDeltaMutationWithCacheVariables>(SMART_ADD_PART_DELTA_MUTATION)

  const handleMutation = async (
    variables: AddPartDeltaMutationWithCacheVariables,
    options?: {
      refetchQueries?: InternalRefetchQueriesInclude
    }
  ) => {
    const mutationResult = await mutation({
      variables,
      refetchQueries: options?.refetchQueries,
      awaitRefetchQueries: true
    })

    if (mutationResult.errors) {
      reportMutationError({
        errors: mutationResult.errors,
        variables,
        message: `Error adding part to change order ${variables.changeOrderNumber}`
      })
    }

    return mutationResult
  }

  return [handleMutation, result] as const;
}
