import Combobox from 'src/components/Combobox/Combobox'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import { QUERY } from './TaskCell'
import { useAppContext } from 'src/lib/appContext'
import { QUERY as TASKS_QUERY } from 'src/components/TasksCell'

const UPDATE_TASK_ASSIGNEE_MUTATION = gql`
  mutation UpdateTaskAssigneeMutation($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      assignedTo {
        id
        name
      }
    }
  }
`

interface UserSelectProps {
  taskId: string
  currentUserId: string | null
}

const UserSelect = ({ taskId, currentUserId }: UserSelectProps) => {
  const { currentOrg } = useAppContext()
  const [updateTaskAssignee, { loading }] = useMutation(UPDATE_TASK_ASSIGNEE_MUTATION)

  const userOptions = currentOrg.members.map(member => ({
    id: member.user.id,
    display: member.user.name
  }))

  const handleUserChange = async (newUserId: string) => {
    const variables = {
      input: {
        id: Number(taskId),
        assignedToId: Number(newUserId)
      }
    }

    const { errors } = await updateTaskAssignee({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: QUERY, variables: { taskNumber: Number(taskId) } },
        { query: TASKS_QUERY }
      ]
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Error updating task assignee'
      })
    }
  }

  return (
    <Combobox
      frameless
      height={32}
      selectedId={currentUserId}
      onSelectId={handleUserChange}
      options={userOptions}
      placeholder="Assign to..."
      testId="task-assignee-select"
    />
  )
}

export default UserSelect