import { useMutation } from '@redwoodjs/web'
import { Form, Submit } from 'src/components/Form'
import Button from 'src/components/Button'
import { RichContent, RichContentEditor, RichContentContext } from '../RichContent/RichContent'
import { reportMutationError } from 'src/lib/reportError'
import { QUERY as CO_QUERY } from './ChangeOrderCell'
import type {
  AddChangeOrderCommentMutation,
  AddChangeOrderCommentMutationVariables,
} from 'types/graphql'
import { useEffect, useRef, useState } from 'react'
import type { Editor } from '@tiptap/react'
import { SET_CHANGE_ORDER_STATE_MUTATION } from 'src/lib/queries'
import type { SetChangeOrderStateMutation, SetChangeOrderStateMutationVariables } from 'types/graphql'

import { ADD_CHANGE_ORDER_COMMENT_MUTATION } from 'src/lib/queries';

type CommentProps = {
  content: string
  userName: string
  createdAt: string
  context: RichContentContext
}

export const Comment = ({ content, context }: CommentProps) => {
  return (
    <RichContent content={content} context={context} />
  )
}

type NewCommentProps = {
  orderNumber: number
  canEdit: boolean
  cancelDisabled?: boolean
  state?: string
  context: RichContentContext
}

export const NewComment = ({ orderNumber, canEdit, cancelDisabled, state }: NewCommentProps) => {
  const editorRef = useRef<Editor | null>(null)
  const [editorEmpty, setEditorEmpty] = useState(true)

  useEffect(() => {
    const editor = editorRef.current
    if (editor) {
      editor.on('update', ({ editor }) => {
        setEditorEmpty(editor.isEmpty)
      })
    }
  }, [editorRef.current])

  const [addComment] = useMutation<
    AddChangeOrderCommentMutation,
    AddChangeOrderCommentMutationVariables
  >(ADD_CHANGE_ORDER_COMMENT_MUTATION)

  const [setChangeOrderState, { loading: cancelLoading }] = useMutation<
    SetChangeOrderStateMutation,
    SetChangeOrderStateMutationVariables
  >(SET_CHANGE_ORDER_STATE_MUTATION)

  const handleCommentSubmit = async () => {
    if (!editorRef.current) return

    const variables = {
      message: JSON.stringify(editorRef.current.getJSON()),
      orderNumber
    }

    const { errors } = await addComment({
      variables,
      refetchQueries: [CO_QUERY],
      awaitRefetchQueries: true
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Failed to comment on change order`
      })
    } else {
      editorRef.current.commands.clearContent()
      setEditorEmpty(true)
    }
  }

  const handleCancel = async () => {
    if (!editorRef.current) return
    const variables = {
      orderNumber,
      state: 'Cancelled' as const,
    } as SetChangeOrderStateMutationVariables
    if (!editorRef.current.isEmpty) {
      variables.message = JSON.stringify(editorRef.current.getJSON())
    }

    const { errors } = await setChangeOrderState({
      variables,
      refetchQueries: [CO_QUERY],
      awaitRefetchQueries: true
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Failed to cancel change order'
      })
    } else {
      editorRef.current.commands.clearContent()
      setEditorEmpty(true)
    }
  }

  if (!canEdit) return null

  return (
    <Form
      className='border rounded-lg p-4'
      onSubmit={handleCommentSubmit}>
      <div className='font-medium mb-4'>
        Add a comment
      </div>
      <div>
        <RichContentEditor editorRef={editorRef} placeholder='Write your comment...' />
      </div>
      <div className='flex mt-4'>
        <div className='flex gap-2 ml-auto'>
          {state !== 'Cancelled' &&
            <Button
              writeOnly
              disabled={cancelDisabled || cancelLoading}
              onClick={handleCancel}>
              Cancel Change Order
            </Button>
          }
          <Submit
            writeOnly
            variant='primary'
            disabled={editorEmpty}>
            Submit Comment
          </Submit>
        </div>
      </div>
    </Form>
  )
}