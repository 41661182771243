import Combobox from 'src/components/Combobox/Combobox'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import { QUERY } from './TaskCell'
import { QUERY as TASKS_QUERY } from 'src/components/TasksCell'
import type { TaskStatus } from 'types/graphql'

const UPDATE_TASK_STATUS_MUTATION = gql`
  mutation UpdateTaskStatusMutation($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      status
    }
  }
`

const TASK_STATES = [
  { id: 'NotStarted', display: 'Not Started', color: '#6B7280' },
  { id: 'InProgress', display: 'In Progress', color: '#6198f1' },
  { id: 'Blocked', display: 'Blocked', color: '#ff6e6e' },
  { id: 'Done', display: 'Done', color: '#6cc954' },
  { id: 'WontDo', display: "Won't Do", color: '#374151' },
]

interface StateSelectProps {
  taskId: string
  currentState: string
}

const StateSelect = ({ taskId, currentState }: StateSelectProps) => {
  const [updateTaskStatus, { loading }] = useMutation(UPDATE_TASK_STATUS_MUTATION)

  const handleStateChange = async (newState: string) => {
    const variables = {
      input: {
        id: parseInt(taskId),
        status: newState as TaskStatus
      }
    }

    const { errors } = await updateTaskStatus({
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: QUERY, variables: { taskNumber: Number(taskId) } },
        { query: TASKS_QUERY }
      ]
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Error updating task status'
      })
    }
  }

  return (
    <Combobox
      height={32}
      frameless
      noSort
      selectedId={currentState}
      onSelectId={handleStateChange}
      options={TASK_STATES}
      placeholder="Select state..."
      testId="task-state-select"
    />
  )
}

export default StateSelect