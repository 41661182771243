import {
  ChangeOrder,
  Part,
  Dependency,
  Maybe,
  PartCategory,
  PartProto,
  PartTreeNodeFragment,
  PartTreeNodePartFragment,
  ChangeOrderChangesQuery,
  ChangeOrderChangesQueryVariables,
  PartAncestors,
  PartAncestorsVariables,
  RemovePart,
  RemovePartVariables,
  AddPartDeltaMutation,
  AddPartDeltaMutationVariables,
  EditPartMutation,
  EditPartMutationVariables,
  NodeType,
  PartDeltaInput,
  CreatePartsMutation,
  CreatePartsMutationVariables,
  AddPartDeltaMutationWithCache,
  AddPartDeltaMutationWithCacheVariables
} from 'types/graphql'
import CreatePart from 'src/components/CreatePartCell';

import PartHierarchy, { SubjectPart, RootInput, useHierarchyControllerFactory, PartHierarchyHeaderColumn } from '../PartHierarchy/PartHierarchy'
import calculateAllChanges, { PartDiffSummary } from '../ChangeOrderChangesCell/calculateAllChanges'
import { ThumbnailArtifact } from '../ThumbnailViewer'
import { getThumbnail } from '../Artifacts/Artifacts'
import ToggleSwitch from '../ToggleSwitch'
import { useEffect, useMemo, useState } from 'react'
import { block } from 'api/src/shared/functional'
import { CHANGE_ORDER_TREE_QUERY, PART_ANCESTORS_QUERY, REMOVE_PART_MUTATION, EDIT_PART_MUTATION, SMART_ADD_PART_DELTA_MUTATION } from 'src/lib/queries'
import type { CellSuccessProps } from '@redwoodjs/web'
import LoadingSpinner, { LoadingSpinnerWithDelay } from '../Loading'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { navigate, routes, useParams } from '@redwoodjs/router'
import ToolButton from '../ToolButton/ToolButton'
import { FolderPlusIcon, TrashIcon, ArrowRightEndOnRectangleIcon, ArrowUturnLeftIcon, ArrowTurnRightDownIcon, QueueListIcon, SwatchIcon } from '@heroicons/react/24/outline'
import { InputNode, NodeHighlightColor, quantityAndUnit } from '../PartHierarchy/hierarchyController'
import { usePartsCache } from 'src/lib/usePartsCache'
import Combobox from '../Combobox/Combobox'
import GenericFailure from '../Failure/Failure'
import { useQuery, useMutation } from '@apollo/client'
import { nullControlSeq } from 'shared/types'
import { useModifyPartDependency } from './useModifyPartDependency'
import { useRightPanel } from '../RightPanel/RightPanel'
import { useAddPartToChangeOrderMutation } from 'src/lib/mutations'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import VersionCell from './VersionCell'
import invariant from 'tiny-invariant'
import { findAncestors } from 'api/src/lib/bom'
import ImportAssembly from '../ImportAssembly/ImportAssembly'
import { ConditionalModal } from '../Modal'
import { StarIcon } from '@heroicons/react/24/outline';
import PartCombobox from '../PartSelect/PartCombobox';
import QuantityCell from './QuantityCell'
import { EyeIcon } from '@heroicons/react/24/outline'
import RefDesCell from './RefDesCell';
import { Tooltip2 } from '../Tooltip'
import { CreatePartProps } from '../CreatePartCell/CreatePartCell';
import { withErrorBoundary } from '../ErrorBoundary';

const CREATE_PARTS_MUTATION = gql`
mutation CreatePartsMutation (
  $changeOrderNumber: Int!
  $partDeltas: [PartDeltaInput!]!
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: $partDeltas) {
    partNumber
    proto {
      categoryId
      category {
        id
        name
      }
    }
    name
  }
}
`

type OrphanPartHierarchyDependency = Pick<Dependency, 'quantity' | 'section' | 'units' | 'referenceDesignator' | 'groupId'> & {
  to: Pick<Part, 'partNumber' | 'version'>
}

type IncludedPartDependency = Pick<Dependency, 'section' | 'quantity' | 'units' | 'referenceDesignator' | 'groupId' | 'toVersionRange'> & {
  to: Pick<Part, 'partNumber' | 'version' | 'lifeCycle' | 'artifacts'> & {
    proto: Pick<PartProto, 'currentVersionString'> & {
      category: Pick<PartCategory, 'name'>
      currentVersion?: {
        dependencies: Pick<Dependency, 'toPartNumber'>[]
      }
    }
    dependencies: OrphanPartHierarchyDependency[]
  }
}

type IncludedPartHead = Pick<Part, 'partNumber' | 'version' | 'lifeCycle' | 'artifacts' | 'nodeType'> & {
  dependencies: IncludedPartDependency[]
}
export type IncludedPart = IncludedPartHead & {
  updateTo?: Maybe<IncludedPartHead>
}
type VersionChangeSummaryProps = {
  changeOrder: ChangeOrder
  diffSummary: Record<string, PartDiffSummary>
}

export const QUERY = CHANGE_ORDER_TREE_QUERY

export const Loading = () => <LoadingSpinnerWithDelay className='flex p-10 items-center justify-center' />

export const Failure = GenericFailure

export const beforeQuery = ({ orderNumber, partContext }) => {
  return { variables: { orderNumber, partContext }, fetchPolicy: 'cache-first', }
}

type ChangeOrderChanges = CellSuccessProps<ChangeOrderChangesQuery, ChangeOrderChangesQueryVariables>
export const Success = withErrorBoundary(({ changeOrder, currentOrg }: ChangeOrderChanges) => {
  // overarching function for calculating changes - should be refactored to pass changes to
  // components instead of duplicating their logic
  const partDiffSummary = useMemo(() => {
    const changes = calculateAllChanges(currentOrg, changeOrder.includedParts)
    return changes
  }, [currentOrg, changeOrder.includedParts])

  // Use type assertion to fix the type error
  const changeOrderForSummary = {
    _id: changeOrder._id,
    state: changeOrder.state,
    number: changeOrder.number,
    name: changeOrder.name,
    description: changeOrder.description,
    reviewers: changeOrder.reviewers,
    baseOrphans: changeOrder.baseOrphans,
    includedParts: changeOrder.includedParts,
  } as ChangeOrder

  return <>
    <VersionChangeSummary changeOrder={changeOrderForSummary} diffSummary={partDiffSummary} />
  </>
})

type InsertedNode = {
  hierarchy: string
  rootAddress: string // Store which root address this node belongs to
}

type AvailablePart = {
  name?: string | null
  partNumber: string
  version: string | null
}

// Add this new component above VersionChangeSummary
type NodeToolsProps = {
  nodeInfo: {
    node: PartTreeNodeFragment
    dereferenced: boolean
  }
  changeOrder: ChangeOrder
  orphanTree: PartTreeNodeFragment[]
  includedPartsByNumber: Record<string, Part>
  onInsert: () => void
}

const NodeTools: React.FC<NodeToolsProps> = ({
  nodeInfo,
  changeOrder,
  orphanTree,
  includedPartsByNumber,
  onInsert
}) => {
  const { partContext } = useParams()
  const partIsPinned = (a: PartTreeNodeFragment) => {
    if (!a.parentToNodeDependency) return false
    if (a.parentToNodeDependency.toVersionRange === '*') return false

    const coPart = includedPartsByNumber[a.part.partNumber]
    // CO parts can still be pinned, but then they are editable
    return (coPart?.id !== a.part.id)
  }

  const ancestors = findAncestors(orphanTree, nodeInfo.node.address, 'address')
  const pinnedAncestor = ancestors.some(partIsPinned)
  const nodePinned = partIsPinned(nodeInfo.node)

  const [removePart, { loading: removePartLoading }] = useMutation<
    RemovePart,
    RemovePartVariables
  >(REMOVE_PART_MUTATION)

  const [addPartToChangeOrder, { loading: addPartLoading }] = useAddPartToChangeOrderMutation()
  const { modifyPartDependency, loading: modifyLoading } = useModifyPartDependency(changeOrder)
  const { routeName, params, closePanel } = useRightPanel()
  const { data: partsCache } = usePartsCache()

  const partInChangeOrder = includedPartsByNumber[nodeInfo.node.part.partNumber]
  const isRootNode = nodeInfo.node.isTreeRoot

  const hasChildren = useMemo(() => {
    if (!partInChangeOrder) return false
    const children = findChildren(orphanTree, nodeInfo.node.hierarchy)
    return children.some(child => {
      const includedPart = includedPartsByNumber[child.part.partNumber]
      return includedPart?.id === child.part.id
    })
  }, [nodeInfo.node, orphanTree, includedPartsByNumber, partInChangeOrder])

  const [addPartDeltaMutation, { loading: editLoading }] = useMutation<
    AddPartDeltaMutationWithCache,
    AddPartDeltaMutationWithCacheVariables
  >(SMART_ADD_PART_DELTA_MUTATION)

  // For dereferenced parts, only show the Undo tool
  if (nodeInfo.dereferenced) {
    return <>
      <ToolButton
        tooltipContent='Undo Dereference'
        onClick={(e) => {
          e.stopPropagation()

          // Find parent node
          const hierarchyParts = nodeInfo.node.hierarchy.split('.')
          hierarchyParts.pop() // Remove last part to get parent hierarchy
          const parentHierarchy = hierarchyParts.join('.')
          const parentNode = orphanTree.find(n => n.hierarchy === parentHierarchy)

          if (parentNode && nodeInfo.node.part.partNumber) {
            const deletedDependency = nodeInfo.node.parentToNodeDependency
            if (!deletedDependency) {
              throw new Error(`Can't restore node that doesn't have a parent assembly`)
            }
            modifyPartDependency(
              parentNode.part.partNumber,
              {
                toAdd: [{
                  partNumber: nodeInfo.node.part.partNumber,
                  versionRange: deletedDependency.toVersionRange,
                  quantity: deletedDependency.quantity,
                  units: deletedDependency.units,
                  section: deletedDependency.section,
                  referenceDesignator: deletedDependency.referenceDesignator || nullControlSeq,
                }]
              }
            )
          }
        }}
        disabled={modifyLoading}
      >
        {modifyLoading && nodeInfo.node.part.partNumber ?
          <LoadingSpinner size='xs' /> :
          <ArrowUturnLeftIcon />
        }
      </ToolButton>
    </>
  }

  // For non-dereferenced parts, show all tools
  return <>
    <ToolButton
      tooltipContent={(pinnedAncestor || nodePinned) ? `Can't Update Under Pinned` : 'Insert Part'}
      onClick={(e) => {
        e.stopPropagation()
        onInsert()
      }}
      disabled={modifyLoading || removePartLoading || pinnedAncestor || nodePinned}
    >
      <FolderPlusIcon />
    </ToolButton>

    <ToolButton
      tooltipContent={nodeInfo.node.part.nodeType === 'Part' ? 'Switch to Part Choice' : 'Switch to Assembly'}
      onClick={async (e) => {
        e.stopPropagation()

        const partInCO = includedPartsByNumber[nodeInfo.node.part.partNumber]

        const newNodeType: NodeType = (nodeInfo.node.part.nodeType === 'Part' ? 'PartGroup' : 'Part')

        const pushPatch: Pick<PartDeltaInput, 'version' | 'type'> = partInCO ? {
          version: partInCO.version,
          type: 'Patch',
        }: {
          version: calculateNextVersion(nodeInfo.node.part.proto.currentVersionString!),
          type: 'Push'
        }
        await addPartDeltaMutation({
          variables: {
            partContext,
            input: {
              ...pushPatch,
              partNumber: nodeInfo.node.part.partNumber,
              part: {
                nodeType: newNodeType
              }
            },
            changeOrderNumber: changeOrder.number,
          }
        })
      }}
      disabled={nodePinned || pinnedAncestor || editLoading}
    >
      {editLoading ? (
        <LoadingSpinner size='xs' />
      ) : nodeInfo.node.part.nodeType === 'Part' ? (
        <SwatchIcon />
      ) : (
        <QueueListIcon />
      )}
    </ToolButton>

    {partInChangeOrder ? (
      <ToolButton
        tooltipContent={hasChildren ? 'Must remove children first' : 'Remove from Change Order'}
        onClick={async (e) => {
          e.stopPropagation()

          if (!hasChildren) {
            await removePart({
              variables: {
                changeOrderNumber: changeOrder.number,
                input: {
                  partNumber: nodeInfo.node.part.partNumber
                },
                partContext
              },
            })

            // Check if right panel is showing changes for this part and close it if so
            if ((routeName === 'partChanges' || routeName === 'partChangesSplit') &&
                params?.partNumber === nodeInfo.node.part.partNumber) {
              closePanel()
            }
          }
        }}
        disabled={hasChildren || modifyLoading || removePartLoading}
      >
        {removePartLoading && nodeInfo.node.part.partNumber === partInChangeOrder?.partNumber ?
          <LoadingSpinner size='xs' /> :
          <ArrowRightEndOnRectangleIcon />
        }
      </ToolButton>
    ) : (
      <ToolButton
        tooltipContent='Add to Change Order'
        onClick={async (e) => {
          e.stopPropagation()

          // Get the current version from the proto
          const proto = partsCache?.protos?.find(p =>
            p.partNumber === nodeInfo.node.part.partNumber
          )
          const currentVersion = proto?.currentVersion?.version

          if (!currentVersion) {
            console.error('Could not find current version for part', nodeInfo.node.part.partNumber)
            return
          }

          await addPartToChangeOrder({
            changeOrderNumber: changeOrder.number,
            input: [{
              type: 'Push',
              partNumber: nodeInfo.node.part.partNumber,
              version: calculateNextVersion(currentVersion)
            }]
          })
        }}
        disabled={addPartLoading || modifyLoading}
      >
        {addPartLoading ?
          <LoadingSpinner size='xs' /> :
          <ArrowTurnRightDownIcon />
        }
      </ToolButton>
    )}

    <ToolButton
      tooltipContent={isRootNode ? 'No assembly to remove from' : (pinnedAncestor ? `Can't Update Under Pinned` : 'Remove from Assembly')}
      onClick={(e) => {
        if (isRootNode) return

        e.stopPropagation()

        // Find parent node
        const hierarchyParts = nodeInfo.node.hierarchy.split('.')
        hierarchyParts.pop()
        const parentHierarchy = hierarchyParts.join('.')
        const parentNode = orphanTree.find(n => n.hierarchy === parentHierarchy)

        if (parentNode && nodeInfo.node.part.partNumber) {
          modifyPartDependency(
            parentNode.part.partNumber,
            {
              toRemove: [nodeInfo.node.part.partNumber]
            }
          )
        }
      }}
      disabled={isRootNode || modifyLoading || removePartLoading || pinnedAncestor}
    >
      {modifyLoading && nodeInfo.node.part.partNumber ?
        <LoadingSpinner size='xs' /> :
        <TrashIcon />
      }
    </ToolButton>
  </>
}

const VersionChangeSummary: React.FC<VersionChangeSummaryProps> = React.memo(({ changeOrder, diffSummary }) => {
  const { partContext } = useParams()
  const { data: partsCache } = usePartsCache()
  const { params: rightPanelParams } = useRightPanel()
  const [showCreatePart, setShowCreatePart] = useState<string | boolean>()
  const changeOrderComplete = changeOrder.state === 'Cancelled' || changeOrder.state === 'Complete'

  const [addPartMutation, { loading: addLoading }] = useAddPartToChangeOrderMutation()
  const [createPartsMutation] = useMutation<CreatePartsMutation, CreatePartsMutationVariables>(CREATE_PARTS_MUTATION)
  const addChangeOrderPart = async (partNumber: string, version: string) => {
    const variables: AddChangeOrderPartMutationVariables = {
      changeOrderNumber: changeOrder.number,
      input: {
        type: 'Push',
        partNumber,
        version: calculateNextVersion(version)
      }
    }
    await addPartMutation(variables)
  }

  // Use our new hook with changeOrder passed directly
  const { modifyPartDependency, loading: mutationLoading } = useModifyPartDependency(changeOrder)

  // Combine cache and change order parts
  const availableParts = useMemo(() => {
    // Start with cache parts
    const parts = (partsCache?.protos || []).map(proto => ({
      name: proto.currentVersion?.name,
      partNumber: proto.partNumber,
      version: proto.currentVersion?.version || null
    }))

    // Create lookup of cache parts by part number
    const partsByNumber: Record<string, AvailablePart> = {}
    parts.forEach(p => {
      partsByNumber[p.partNumber] = p
    })

    // Override/add change order parts
    changeOrder.includedParts.forEach(part => {
      partsByNumber[part.partNumber] = {
        name: part.name || null,
        partNumber: part.partNumber,
        version: part.version
      }
    })

    return Object.values(partsByNumber)
  }, [partsCache, changeOrder.includedParts])

  const [viewMode, setViewMode] = useState<'changes' | 'full'>('changes')
  const [nextInsertId, setNextInsertId] = useState(99999)
  const [insertedNodes, setInsertedNodes] = useState<InsertedNode[]>([])
  const { includedParts } = changeOrder

  const includedPartsByNumber = includedParts.reduce((memo, p) => {
    return {
      ...memo,
      [p.partNumber]: p
    }
  }, {} as Record<string, Part>)

  const [activeColumns, setActiveColumns] = useState<string[]>([])

  const columns: Partial<PartHierarchyHeaderColumn>[] = [
    {
      name: 'Part Name',
      key: 'name',
      fullKey: 'field.name',
      type: 'field',
      width: 550,
    }, {
      name: 'Version',
      key: 'version',
      fullKey: 'field.version',
      type: 'field',
      width: 180,
      align: 'center',
      displayFn (node, { root }) {
        if (!node.part.version) return
        return <VersionCell
          node={node}
          changeOrderPart={includedPartsByNumber[node.part.partNumber]}
          readOnly={changeOrderComplete}
          onVersionRangeChange={async versionRange => {
            const parentAddress = node.address.split('.').slice(0, -1).join('.')
            const parent = root.tree.find(n => n.address === parentAddress)
            invariant(parent, `Can't change version reference without parent`)
            await modifyPartDependency(parent.part.partNumber, {
              toModify: [{
                partNumber: node.part.partNumber,
                versionRange: versionRange
              }]
            })
          }}
          changeOrderNumber={changeOrder.number} />
      }
    }, {
      fullKey: 'field.quantity',
      key: 'quantity',
      type: 'field',
      displayFn(node, { root }) {
        return <QuantityCell
          readOnly={changeOrderComplete}
          node={node}
          onQuantityChange={async (quantity, units) => {
            const parentAddress = node.address.split('.').slice(0, -1).join('.')
            const parent = root.tree.find(n => n.address === parentAddress)
            invariant(parent, `Can't change quantity without parent`)
            await modifyPartDependency(parent.part.partNumber, {
              toModify: [{
                partNumber: node.part.partNumber,
                quantity,
                units
              }]
            })
          }}
        />
      }
    }, {
      name: 'Ref Des',
      fullKey: 'field.referenceDesignator',
      key: 'referenceDesignator',
      type: 'field',
      showNonFullscreen: activeColumns.includes('field.referenceDesignator'),
      displayFn(node, { root }) {
        return <RefDesCell
          readOnly={changeOrderComplete}
          node={node}
          onRefDesChange={async (refDes) => {
            const parentAddress = node.address.split('.').slice(0, -1).join('.')
            const parent = root.tree.find(n => n.address === parentAddress)
            invariant(parent, `Can't change reference designator without parent`)
            await modifyPartDependency(parent.part.partNumber, {
              toModify: [{
                partNumber: node.part.partNumber,
                referenceDesignator: refDes || nullControlSeq
              }]
            })
          }}
        />
      }
    }
  ]

  const hierarchies = changeOrder.baseOrphans.map((orphan): RootInput => {
    const rootNode = orphan.tree.find(n => n.isTreeRoot)!
    const rootInCo = includedPartsByNumber[rootNode.part.partNumber]!
    const rootDiff = diffSummary[rootNode.part.partNumber]

    type NodeInfo = {
      dereferenced: boolean,
      updated: boolean,
      isNew: boolean,
      onlyBomChanges?: boolean
      isNewReference: boolean,
      previousVersion?: string
      previousVersionRange?: string
      previousQuantity?: number
      previousQuantityUnits?: string
      previousReferenceDesignator?: Maybe<string>
      previousLifecycle?: Maybe<string>
      previousThumbnail?: ThumbnailArtifact
      node: PartTreeNodeFragment,
      parent?: PartTreeNodeFragment
    }

    const rootInfo: NodeInfo = {
      node: rootNode,
      dereferenced: false,
      updated: Boolean(rootInCo?.updateTo),
      isNew: !rootInCo?.updateTo,
      isNewReference: false,
      onlyBomChanges: rootDiff?.onlyBomChanges,
      previousVersion: rootInCo?.updateTo?.version,
      previousLifecycle: rootInCo?.updateTo?.lifeCycle,
      previousThumbnail: getThumbnail(rootInCo?.updateTo?.artifacts)
    }


    // from the root, check each child for changes and add to diffTree
    // - if a version update in the change order, then add and add children recursively
    // - if a version update outside of the change order, or a newly pinned version then
    //   show the update but don't show it's children
    // - for removed references, show in red but no children
    // - for added references, show in green and add children
    let diffTree: NodeInfo[] = [rootInfo]

    const addUpdatedChildren = (parent: PartTreeNodeFragment) => {
      const parentInChangeOrder = includedPartsByNumber[parent.part.partNumber]
      const previousParent = parentInChangeOrder?.updateTo

      const previousDependenciesByPartNumber: Record<string, Pick<Dependency, 'toVersionRange' | 'quantity' | 'units' | 'section' | 'referenceDesignator'> & {
        to: Pick<Part, "partNumber" | "version" | 'publishId' | 'lifeCycle' | 'artifacts'>
      }> = {}
      for (const child of (previousParent?.dependencies || [])) {
        previousDependenciesByPartNumber[child.to.partNumber] = child
      }

      // need to eliminate this use of the tree in order to use the cache
      // but that will need us to potentially stitch together a tree using both part protos for
      // what currently is in production, and overlaying using dependencies in included parts
      const children = findChildren(orphan.tree, parent.hierarchy)

      const childrenWithChanges = new Set(
        diffSummary[parent.part.partNumber]
          ?.fields.dependencies.change.filter(c => c.wholeDependency || c.propertyChange)
          .map(c => c.partNumber)
      )

      // add updated and new part tree nodes
      for (const child of children) {
        const childInCo = includedPartsByNumber[child.part.partNumber]
        const diff = diffSummary[child.part.partNumber]


        const previousDep = previousDependenciesByPartNumber[child.part.partNumber]

        const info = {
          node: child,
          dereferenced: false,
          updated: Boolean(previousDep && (
            previousDep.to.publishId !== child.part.publishId ||
            previousDep.toVersionRange !== child.parentToNodeDependency?.toVersionRange ||
            childrenWithChanges.has(child.part.partNumber)
          )),
          onlyBomChanges: diff?.onlyBomChanges,
          isNew: Boolean(childInCo && !childInCo.updateTo),
          isNewReference: Boolean(parentInChangeOrder && !previousDep),
          previousVersion: previousDep?.to.version,
          previousVersionRange: previousDep?.toVersionRange,
          previousQuantity: previousDep?.quantity,
          previousReferenceDesignator: previousDep?.referenceDesignator,
          previousQuantityUnits: previousDep?.units,
          previousLifecycle: previousDep?.to.lifeCycle,
          previousThumbnail: getThumbnail(previousDep?.to.artifacts),
        }

        if (viewMode === 'full') {
          diffTree = [...diffTree, info]
          addUpdatedChildren(child)
        } else if (info.isNew || info.isNewReference || info.updated) {
          diffTree = [...diffTree, info]
          if ((childInCo && childInCo.version === child.part.version)) {
            addUpdatedChildren(child)
          }
        }
      }

      const childrenByPartNumber: Record<string, true> = {}
      for (const child of children) {
        childrenByPartNumber[child.part.partNumber] = true as const
      }

      // children are indexed starting at 1
      let deletedChildIndex = children.length + 2

      // add dereferenced tree nodes
      for (const dep of previousParent?.dependencies || []) {
        if (!childrenByPartNumber[dep.to.partNumber]) {
          diffTree = [...diffTree, {
            node: {
              isTreeRoot: false,
              // using the full fragment for a dereferenced
              // part would make the change order query massive
              part: {
                ...dep.to,
                metadata: {}
              } as unknown as PartTreeNodePartFragment,
              parentToNodeDependency: dep,
              hierarchy: `${parent.hierarchy}.${deletedChildIndex}`,
              address: `${parent.address}.${deletedChildIndex}`,
              partNumberAddress: `${parent.partNumberAddress}.${btoa(dep.to.partNumber)}`,
            } as PartTreeNodeFragment,
            dereferenced: true,
            updated: false,
            isNew: false,
            isNewReference: false,
            previousVersion: dep.to.version,
            previousLifecycle: dep.to.lifeCycle,
            previousThumbnail: getThumbnail(dep.to.artifacts),
          }]
          deletedChildIndex++
        }
      }
    }

    addUpdatedChildren(rootNode)

    const subjectParts = diffTree.map(nodeInfo => {
      const highlightColor = block(() => {
        if (nodeInfo.dereferenced) {
          return 'red'
        }
        if (nodeInfo.isNew) {
          return 'green'
        }
        if (nodeInfo.isNewReference) {
          return 'green'
        }
        if (nodeInfo.updated) {
          return 'yellow'
        }

        return 'none'
      })

      const leftMark = block(() => {
        if (nodeInfo.dereferenced) {
          return (
            <Tooltip2 content="Dereferenced Part">
              <div className="flex items-center justify-center w-5 h-5 bg-red-200 font-semibold rounded text-red-500 ml-2 cursor-default">
                D
              </div>
            </Tooltip2>
          )
        }
        if (nodeInfo.isNew) {
          return (
            <Tooltip2 content="Create New Part">
              <div className="flex items-center justify-center w-5 h-5 bg-green-200 font-semibold rounded text-green-500 ml-2 cursor-default">
                C
              </div>
            </Tooltip2>
          )
        }
        if (nodeInfo.isNewReference) {
          return (
            <Tooltip2 content="New Reference">
              <div className="flex items-center justify-center w-5 h-5 bg-green-200 font-semibold rounded text-green-500 ml-2 cursor-default">
                N
              </div>
            </Tooltip2>
          )
        }

        if (nodeInfo.updated && nodeInfo.onlyBomChanges) {
          return (
            <Tooltip2 content="Modify BOM Only">
              <div className="flex items-center justify-center pb-0.5 w-5 h-5 bg-yellow-200 font-semibold rounded text-yellow-500 ml-2 cursor-default">
                •
              </div>
            </Tooltip2>
          )
        }
        if (nodeInfo.updated) {
          return (
            <Tooltip2 content="Modify Part">
              <div className="flex items-center justify-center w-5 h-5 bg-yellow-200 font-semibold rounded text-yellow-500 ml-2 cursor-default">
                M
              </div>
            </Tooltip2>
          )
        }

        return <div className='w-7' />
      })

      return {
        hierarchy: nodeInfo.node.hierarchy,
        highlightColor: highlightColor as NodeHighlightColor | undefined,
        leftMark,
        tools: ({ controllerRoot }) => changeOrderComplete? undefined : <NodeTools
          nodeInfo={nodeInfo}
          changeOrder={changeOrder}
          orphanTree={orphan.tree}
          includedPartsByNumber={includedPartsByNumber}
          onInsert={() => {
            controllerRoot.setExpanded(nodeInfo.node.partNumberAddress, true)
            const newNode: InsertedNode = {
              hierarchy: `${nodeInfo.node.hierarchy}.${nextInsertId}`,
              rootAddress: rootNode.address,
            }
            setInsertedNodes([...insertedNodes, newNode])
            setNextInsertId(nextInsertId + 1)
          }}
        />
      } as SubjectPart
    })

    const insertedTreeNodes = insertedNodes
      .filter(node => node.rootAddress === rootNode.address)
      .map((n, index) => {
        const parentHierarchy = n.hierarchy.split('.').slice(0, -1).join('.')
        const parentNode = orphan.tree.find(node => node.hierarchy === parentHierarchy)

        if (!parentNode) throw new Error('Cannot insert part outside of an assembly')
        const siblings = findChildren(orphan.tree, parentNode.hierarchy)

        return {
          hierarchy: n.hierarchy,
          isTreeRoot: false,
          mainCell: <InsertPartPlaceholder
            key={n.hierarchy}
            siblings={siblings}
            hierarchy={n.hierarchy}
            availableParts={availableParts}
            parentNode={parentNode}
            changeOrder={changeOrder}
            onComplete={() => {
              setInsertedNodes(insertedNodes.filter(node => node.hierarchy !== n.hierarchy))
            }}
            onNewParts={() => {
              setInsertedNodes(insertedNodes.filter(node => node.hierarchy !== n.hierarchy))
              setShowCreatePart(parentNode.part.partNumber)
            }}
          />,
          sortOverride: -(insertedNodes.length - index),
          part: {
            partNumber: '',
            version: null,
            metadata: {}
          } as unknown as PartTreeNodePartFragment,
          address: `${parentNode.address}.999999${index}`,
          partNumberAddress: `${parentNode.partNumberAddress}.newpart${index}`
        }
      })

    const tree = [
      ...diffTree.map(info => {
        const { node } = info

        const withVersion = {
          ...node,
          part: {
            ...node.part,
            version: info.node.part.version
          },
          address: node.address,
          dereferenced: info.dereferenced,
          previousVersion: info.previousVersion,
          previousVersionRange: info.previousVersionRange,
          previousQuantity: info.previousQuantity,
          previousQuantityUnits: info.previousQuantityUnits,
          previousReferenceDesignator: info.previousReferenceDesignator,
          previousLifecycle: info.previousLifecycle,
          previousThumbnail: info.previousThumbnail,
        } as InputNode

        return withVersion
      }),
      ...insertedTreeNodes.map(node => ({
        ...node,
        address: node.hierarchy,
      })) as InputNode[]
    ]

    return {
      tree,
      subjectParts: [
        ...subjectParts,
        ...insertedTreeNodes.map(node => ({
          hierarchy: node.hierarchy,
          leftMark: <div className='w-7' />,
          highlightColor: 'green' as NodeHighlightColor,
          active: false,
          tools: () => <>
            <ToolButton
              tooltipContent='Remove Insert'
              onClick={(e) => {
                e.stopPropagation()
                setInsertedNodes(insertedNodes.filter(n => n.hierarchy !== node.hierarchy))
              }}
            >
              <TrashIcon />
            </ToolButton>
          </>
        }))
      ],
      initiallyExpandedNodes: 'smart',
    }
  })

  const controller = useHierarchyControllerFactory({
    roots: hierarchies,
    usageContext: {
      type: 'ChangeOrder',
      changeOrder: {
        number: changeOrder.number,
        includedParts: changeOrder.includedParts as IncludedPart[]
      }
    },
    columns: columns as PartHierarchyHeaderColumn[],
    omitColumns: [
      { key: 'lifeCycle', type: 'field' },
      { key: 'thumbnail', type: 'field' },
      { key: 'category', type: 'field' },
    ]
  })

  const handleCreateParts = async (deltas: PartDeltaInput[]) => {
    const createInPart = typeof showCreatePart === 'string'
    // This is terrible because not a transaction, but meh
    const { data, errors } = await createPartsMutation({
      variables: {
        changeOrderNumber: changeOrder.number,
        partDeltas: deltas
      },
      refetchQueries: createInPart ? [] : [{
        query: CHANGE_ORDER_TREE_QUERY,
        variables: {
          orderNumber: changeOrder.number,
          partContext
        }
      }]
    })
    if (!errors && createInPart) {
      await modifyPartDependency(showCreatePart, {
        toAdd: data?.addPartDeltas.map(d => {
          return {
            partNumber: d.partNumber,
            versionRange: '*',
            quantity: 1,
            section: 'Manual',
          }
        }),
      })
    }
  }

  return (
    <div>
      <div className='text-md font-semibold mb-4'>Context</div>
      <ContextSelector changeOrder={changeOrder} />
      <div className='text-xs mb-8 w-96 text-gray-700'>By setting the context for a change order, you can view changes as they relate to a single assembly</div>
      <div className="mb-4 flex gap-4 items-center justify-between">
        <ToggleSwitch
          options={[
            { value: 'changes', label: 'Changes Only' },
            { value: 'full', label: 'Full BOM' }
          ]}
          value={viewMode}
          onChange={setViewMode}
          width="wide"
        />
        {
          <div className='flex gap-2'>
          {!changeOrderComplete && <>
            <PartCombobox
              onSelect={p => {
                addChangeOrderPart(p.partNumber, p.version)
              }}
              omit={changeOrder.includedPartNumbers}
              placeholder='Add Parts to Change Order' />
            <ImportAssembly orderNumber={changeOrder.number} />
            <ToolButton
              writeOnly
              tooltipContent='Create Parts'
              onClick={() => setShowCreatePart(true)}>
              <StarIcon />
            </ToolButton>

          </>}
          <ToolButton
            testId="view-settings"
            menu={{
              options: [
                {
                  value: 'field.referenceDesignator',
                  display: 'Reference Designator',
                  active: activeColumns.includes('field.referenceDesignator')
                }
              ],
              onSelect: (value) => {
                setActiveColumns(prev => {
                  if (prev.includes(value)) {
                    return prev.filter(col => col !== value)
                  }
                  return [...prev, value]
                })
              }
            }}
          >
            <EyeIcon />
          </ToolButton>
          {showCreatePart &&
            <ConditionalModal className='w-fit mt-10 overflow-y-auto max-h-[calc(100vh-100px)]' onClose={() => setShowCreatePart(false)}>
              <CreatePart
                changeOrderNumber={changeOrder.number}
                onComplete={() => setShowCreatePart(false)}
                onCreate={async (partDeltas) => {
                  await handleCreateParts(partDeltas)
                  setShowCreatePart(false)
                }} />
            </ConditionalModal>
          }
          </div>
        }
      </div>
      <PartHierarchy hierarchyController={controller} />
    </div>
  )
})

type FindAncestorHierarchy = {
  hierarchy: string
}

const findChildren = <T extends FindAncestorHierarchy[]>(fullHierarchy: T, parentLevel: string) => {
  const hierarchy = parentLevel.split('.');
  const childFinder = new RegExp('^' + hierarchy.join('[.]') + '[.]\\d+$')

  const children = fullHierarchy
    .filter(p => childFinder.test(p.hierarchy))
  return children as T
}

type ContextSelectorProps = {
  changeOrder: ChangeOrder
}

const ContextSelector = ({ changeOrder }: ContextSelectorProps) => {
  const [partContext, setPartContext] = usePartContext()

  // Combine cache and change order parts into options for Combobox
  const options = changeOrder.includedParts.map(part => {
    return {
      id: part.partNumber,
      display: `#${part.partNumber} ${part.name || ''}`.trim()
    }
  })
  const allOptions = [...options, {
    id: '__TOP_LEVEL__',
    display: 'Top Level',
    primary: true
  }]

  const handleSelect = (id: string) => {
    if (id === '__TOP_LEVEL__') {
      setPartContext()
    } else {
      setPartContext(id)
    }
  }

  return <div className='flex gap-2 w-fit mb-4 text-sm'>
    <Combobox
      selectedId={partContext || '__TOP_LEVEL__'}
      onSelectId={handleSelect}
      options={allOptions}
      height={32}
      placeholder='Switch Context'
      testId='context-selector'
    />
    {partContext &&
      <button onClick={() => setPartContext()}>
        <XMarkIcon className='w-5' />
      </button>
    }
  </div>
}

export const usePartContext = () => {
  const params = useParams()
  const orgId = params.orgId!
  const orderNumber = Number.parseInt(params.orderNumber!)
  const [partContext, setPc] = useState(params.partContext)
  return useMemo(() => {
    const coreParams = { orgId, orderNumber, tab: 'changes' as const }
    return [
      partContext,
      (partNumber?: string) => {
        setPc(partNumber)
        setTimeout(() => {
          navigate(partNumber ?
            routes.changeOrderTabWithPartContext({ ...coreParams, partContext: partNumber }) :
            routes.changeOrderTab({ ...coreParams })
          )
        })
      }
    ] as const
  }, [orderNumber, partContext])
}

type InsertPartPlaceholderProps = {
  hierarchy: string
  availableParts: AvailablePart[]
  parentNode: PartTreeNodeFragment
  siblings: PartTreeNodeFragment[]
  changeOrder: ChangeOrder
  onComplete: () => void
  onNewParts: () => void
}

const InsertPartPlaceholder: React.FC<InsertPartPlaceholderProps> = ({
  hierarchy,
  availableParts,
  parentNode,
  changeOrder,
  onComplete,
  onNewParts,
  siblings
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedPartNumber, setSelectedPartNumber] = useState<string>()

  const { modifyPartDependency } = useModifyPartDependency(changeOrder)

  const { data } = useQuery<PartAncestors, PartAncestorsVariables>(PART_ANCESTORS_QUERY, {
    variables: { partId: parentNode.part.id },
  })

  const ancestors = data?.partById!.ancestors.map(a => a.part.partNumber)

  const error = block(() => {
    if (!ancestors) return
    if (!selectedPartNumber) return
    if (siblings.some(n => n.part.partNumber === selectedPartNumber)) {
      return 'Assembly already contains this part'
    }
    if (ancestors.includes(selectedPartNumber)) {
      return 'Cannot insert part that is an ancestor'
    }
    if (selectedPartNumber === parentNode.part.partNumber) {
      return 'An assembly cannot contain iteself'
    }
  })

  // Add useEffect to reset error state
  useEffect(() => {
    if (error && selectedPartNumber) {
      const timer = setTimeout(() => {
        setSelectedPartNumber(undefined)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [error, selectedPartNumber])

  useEffect(() => {
    if (!data) return
    if (!selectedPartNumber) return
    if (error || loading) return
    block(async () => {
      setLoading(true)
      await modifyPartDependency(
        parentNode.part.partNumber,
        {
          toAdd: [{
            partNumber: selectedPartNumber,
            versionRange: '*',
            quantity: 1,
            units: 'each',
            section: 'Manual',
            referenceDesignator: nullControlSeq
          }]
        }
      )
      setLoading(false)
      onComplete()
    })
  }, [data, selectedPartNumber, error, onComplete])

  const existingPartOptions = availableParts.map(part => ({
    id: part.partNumber,
    display: `#${part.partNumber} ${part.name || ''}`.trim(),
  }))
  const options = [
    ...existingPartOptions,
    {
      id: '___NEW___',
      display: 'Create Parts...',
      primary: true
    }
  ]

  if (loading) {
    return <LoadingSpinnerWithDelay size='xs' />
  }

  return <Combobox
    selectedId={null}
    onSelectId={(id: string) => {
      if (id === '___NEW___') {
        onNewParts()
        return
      }
      setSelectedPartNumber(id)
    }}
    height={32}
    frameless
    transparent
    noPadding
    options={options}
    placeholder="Select a part to insert..."
    testId={`insert-part-${hierarchy}`}
    error={error}
  />
}
