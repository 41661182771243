import { QuantityUnit } from 'src/shared/types'
import type { MapperConfig } from '../mapperConfigs'
import { DocumentIcon } from '@heroicons/react/24/outline'


// An attempt to make a standard mapping anyone could use
// if they just rearrange their spreadsheet
export const onboarding: MapperConfig = {
  name: 'Onboarding',
  type: 'Upload',
  orgIds: [],
  // orgIds: ['oxygen8-demo'],
  rootIsTopLevel: true,
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: []
  },
  standardizeBom: {
    columns: {
      summary: {
        value: '{{row.summary}}',
        type: 'simple'
      },
      hierarchy: {
        value: '1.{{row.hierarchy}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.part_number}}',
        type: 'simple'
      },
      cadRev: {
        value: '{{row.revision}}',
        type: 'simple'
      },
      categoryId: {
        value: '${row.part_number.slice(0,3)}',
        type: 'simple'
      },
      isOffTheShelf: {
        value: '{{row.off_the_shelf}}',
        type: 'simple'
      },
      name: {
        value: '{{row.name}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: [{
        conditions: [{
          type: 'empty',
          column: 'unit'
        }],
        onMatch: {
          type: 'quantity',
          value: 'each' as QuantityUnit,
        }
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.unit}}'
        }
      }],
    }
  }
}