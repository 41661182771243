import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Editor, mergeAttributes } from '@tiptap/core'
import Mention from '@tiptap/extension-mention'
import { ReactRenderer } from '@tiptap/react'
import tippy, { Instance } from 'tippy.js'
import { PartsCacheData, usePartsCache } from 'src/lib/usePartsCache'

import { SuggestionOptions } from '@tiptap/suggestion'
import { routes, useParams } from '@redwoodjs/router'
import keyBy from 'lodash.keyby'
import { useRightPanel } from '../RightPanel/RightPanel'
import { gHistory } from '@redwoodjs/router/dist/history'
import { AppContext, useAppContext } from 'src/lib/appContext'

// Adding code to the window because mentions are rendered
// using plain html onclick handlers
declare global {
  interface Window {
    redwoodNavigate: (e: Event, link: string) => void
  }
}

window.redwoodNavigate = (e: Event, link: string) => {
  e.preventDefault()
  gHistory.navigate(link)
}

// Add interfaces for MentionList props and items
interface MentionListProps {
  items: Array<{
    partNumber?: string;
    currentVersion?: {
      name?: string;
    };
    number?: number;
    name?: string;
    type: 'part' | 'changeOrder';
  }>;
  command: (props: { id: string; label: string }) => void;
  onKeyDown?: (props: KeyDownProps) => boolean;
}

interface KeyDownProps {
  event: KeyboardEvent;
}

// Update MentionList component with types
const MentionList = forwardRef(({ items, command }: MentionListProps, ref) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  useEffect(() => setSelectedIndex(0), [items])
  const selectItem = (index: number) => {
    const item = items[index]
    if (item) {
      if (item.type === 'part') {
        command({ id: item.partNumber!, label: `${item.partNumber}` })
      } else {
        command({ id: `co-${item.number}`, label: `CO-${item.number}` })
      }
    }
  }

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: KeyDownProps) => {
      console.log('on key down', event)
      if (event.key === 'ArrowUp') {
        setSelectedIndex((selectedIndex + items.length - 1) % items.length)
        return true
      }

      if (event.key === 'ArrowDown') {
        setSelectedIndex((selectedIndex + 1) % items.length)
        return true
      }

      if (event.key === 'Enter') {
        selectItem(selectedIndex)
        return true
      }

      return false
    },
  }))

  return (
    <div className="bg-white rounded-lg shadow-lg border border-gray-200 p-1 max-h-80 overflow-y-auto text-xs">
      {items.map((item, index: number) => (
        <button
          className={`w-full text-left px-3 py-2 rounded-md ${
            index === selectedIndex
              ? 'bg-blue-600 text-white'
              : 'hover:bg-gray-100'
          }`}
          key={item.type === 'part' ? item.partNumber : `co-${item.number}`}
          onClick={() => selectItem(index)}
        >
          {item.type === 'part' ? (
            <>#{item.partNumber} {item.currentVersion?.name || ''}</>
          ) : (
            <>#CO-{item.number} {item.name}</>
          )}
        </button>
      ))}
    </div>
  )
})

// Update the ReactRenderer type
interface MentionListRef {
  onKeyDown: (props: KeyDownProps) => boolean;
}

// Update getSuggestionHandler with proper types
const getSuggestionHandler = (
  partsCache: PartsCacheData,
  changeOrders: AppContext['changeOrders']
): Omit<SuggestionOptions, 'editor'> => {
  return {
    items: ({ query }) => {
      const parts = partsCache?.protos || []
      const allItems = [
        ...parts.map(part => ({
          ...part,
          type: 'part' as const
        })),
        ...(changeOrders || []).map(co => ({
          number: co.number,
          name: co.name,
          type: 'changeOrder' as const
        }))
      ]

      return allItems
        .filter(item => {
          if (item.type === 'part') {
            const searchStr = `${item.partNumber} ${item.currentVersion?.name || ''}`.toLowerCase()
            return searchStr.includes(query.toLowerCase())
          } else {
            const searchStr = `CO-${item.number} ${item.name}`.toLowerCase()
            return searchStr.includes(query.toLowerCase())
          }
        })
        .slice(0, 5)
    },
    render: () => {
      let component: ReactRenderer<MentionListProps, MentionListRef>
      let popup: Instance<any>[] | null = null

      return {
        onStart: (props) => {
          component = new ReactRenderer<MentionListProps, MentionListRef>(MentionList, {
            props: {
              ...props,
              onKeyDown: (keyProps: KeyDownProps) => true,
            },
            editor: props.editor,
          })

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect as () => DOMRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate(props) {
          component.updateProps(props)

          if (popup?.[0]) {
            popup[0].setProps({
              getReferenceClientRect: props.clientRect as () => DOMRect,
            })
          }
        },

        onKeyDown(props) {
          if (props.event.key === 'Escape') {
            popup?.[0]?.hide()
            return true
          }

          return component.ref?.onKeyDown(props)
        },

        onExit() {
          popup?.[0]?.destroy()
          component.destroy()
        },
      }
    },
  }
}

export interface HashPluginProps {
  editible?: boolean
  context?: {
    changeOrder?: {
      partNumbers: string[]
      number: number
    }
  }
}

export const useHashMentionPlugin = (config?: HashPluginProps) => {
  const { changeOrders } = useAppContext()
  const { data: partsCache } = usePartsCache()
  const { panelRoute } = useRightPanel()
  const orgId = useParams().orgId!
  const partNumbersLookup = keyBy(config?.context?.changeOrder?.partNumbers)

  const plugin = Mention.configure({
    HTMLAttributes: {
      class: 'inline-flex items-center font-medium text-blue-700 no-underline',
    },
    renderHTML ({ options, node }) {
      const isChangeOrder = node.attrs.id.startsWith('co-')

      if (isChangeOrder) {
        const orderNumber = parseInt(node.attrs.id.replace('co-', ''))
        const link = routes.changeOrder({ orgId, orderNumber })
        const co = changeOrders.find(c => c.number === orderNumber)
        const tooltip = `Change Order #${orderNumber} - ${co?.name} (${co?.state})`

        return [
          'a',
          mergeAttributes({
            href: link,
            onclick: `window.redwoodNavigate(event, '${link}')`,
            'data-tooltip': tooltip,
          }, options.HTMLAttributes),
          `#${node.attrs.label}`
        ]
      }

      // Existing part rendering logic
      const part = partsCache?.protos?.find(p => p.partNumber === node.attrs.id)
      const tooltip = `Part #${node.attrs.id}${part?.currentVersion?.name ? ` - ${part.currentVersion.name}` : ''}`

      if (config?.editible) {
        return [
          'span',
          mergeAttributes({
            'data-tooltip': tooltip,
          }, options.HTMLAttributes),
          `#${node.attrs.label}`
        ]
      }

      const link = partNumbersLookup[node.attrs.id] ?
        `${routes.changeOrderTab({
          orgId,
          orderNumber: config!.context!.changeOrder!.number,
          tab: 'changes'
        })}#part-change-${node.attrs.id}` :
        panelRoute('part', { partNumber: node.attrs.id })

      return [
        'a',
        mergeAttributes({
          href: link,
          onclick: `window.redwoodNavigate(event, '${link}')`,
          'data-tooltip': tooltip,
        }, options.HTMLAttributes),
        `#${node.attrs.label}`
      ]
    },
    suggestion: {
      ...getSuggestionHandler(partsCache, changeOrders),
      char: '#',
    }
  })

  return { plugin }
}
