type FindAncestorHierarchy = {
  hierarchy: string
}

export const sortHierarchy = <T extends FindAncestorHierarchy>(fullHierarchy: T[]) => {
  return ([...fullHierarchy]).sort((a, b) => {
    const fullA = a.hierarchy.split('.').map(Number)
    const fullB = b.hierarchy.split('.').map(Number)
    for (const [i, a] of fullA.entries()) {
      const b = fullB[i]

      // a is a descendant of b, put a after b
      if (b === undefined) return 1

      // a is in a lower numbered sibling tree, put a before b
      if (a < b) return -1

      // a is in a higher numbered sibling tree, put a after b
      if (a > b) return 1
    }

    // a is an ancestor of b, put a before b
    if (fullA.length < fullB.length) return -1
    return 0
  })
}


// find ancestors ordered by higher hierarchy first
export const findAncestors = <T extends FindAncestorHierarchy>(fullHierarchy: T[], childLevel: string, prop?: keyof T) => {
  const ancestors = fullHierarchy
    .filter(p => {
      const key = prop || 'hierarchy' as (keyof FindAncestorHierarchy)
      const value = p[key]
      if (typeof value !== 'string') {
        console.error(`Prop must be string prop:${String(key)}`, p)
        throw new Error(`Prop must be string prop:${String(key)}`)
      }
      const partLevel = value.split('.');
      const descendentOfPartFinder = new RegExp('^' + partLevel.join('[.]') + '[.]')
      const isAncestor = descendentOfPartFinder.test(childLevel)
      return isAncestor
    })
  return sortHierarchy(ancestors)
}

export const findAncestorsByHierarchy = <T extends FindAncestorHierarchy>(
  fullHierarchy: Record<string, T>,
  childLevel: string
) => {
  const childLevelList = childLevel.split('.')
  const ancestorLevels = childLevelList.reduce((levels, levelIndex, i) => {
    if (i === (childLevelList.length - 1)) return levels
    const lastLevel = levels[i - 1]
    if (!lastLevel) return [levelIndex]
    return [
      ...levels,
      `${lastLevel}.${levelIndex}`
    ]
  }, [] as string[])
  const ancestors = Object.values(fullHierarchy)
    .filter(p => {
      return (ancestorLevels.includes(p.hierarchy))
    })
  return sortHierarchy(ancestors)
}

export const findPartParent = <T extends FindAncestorHierarchy>(importedBom: T[], partHierarchy: string) => {
  const parentHierarchy = partHierarchy.split('.').slice(0, -1).join('.')

  return importedBom
    .find(p => parentHierarchy === p.hierarchy)
}

export const findPartChildren = <T extends FindAncestorHierarchy>(importedBom: T[], partHierarchy: string, prop?: keyof T) => {
  // e.g. 1, 2.5, 1.2.3
  const hierarchy = partHierarchy.split('.')

  // e.g. /^1[.]\d+$/, /^2[.]5[.]\d+$/, /^1[.]2[.]3[.]\d+$/
  const childFinder = new RegExp('^' + hierarchy.join('[.]') + '[.][^.]+$')
  return importedBom
    .filter(p => {
      const key = prop || 'hierarchy' as (keyof FindAncestorHierarchy)
      const value = p[key]
      if (typeof value !== 'string') {
        console.error(`Prop must be string prop:${String(key)}`, p)
        throw new Error(`Prop must be string prop:${String(key)}`)
      }
      return childFinder.test(value)
    })
}

export const findPartChildrenByAddress = <T extends FindAncestorHierarchy>(importedBom: T[], parentAddress: string) => {
  // e.g. 1, 2.5, 1.2.3
  const hierarchy = parentAddress.split('.')

  // e.g. /^1[.]\d+$/, /^2[.]5[.]\d+$/, /^1[.]2[.]3[.]\d+$/
  const childFinder = new RegExp('^' + hierarchy.join('[.]') + '[.]\\d+$')

  return importedBom
    .filter(p => childFinder.test(p.address))
}

export const partIsDescendent = (possibleAncestorHierarchy: string, possibleDescendentHierarchy: string) => {
  if (possibleAncestorHierarchy === possibleDescendentHierarchy) return false
  const hierarchy = possibleAncestorHierarchy.split('.')
  const rDescendant = new RegExp(`^${hierarchy.join('[.]')}`)

  return rDescendant.test(possibleDescendentHierarchy)
}
