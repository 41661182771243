import { useEditor, EditorContent, JSONContent, NodeViewWrapper } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import useFileUploader, { FileRef } from 'src/lib/hooks/useFileUploader'
import { useQuery } from '@redwoodjs/web'
import { gql } from '@apollo/client'
import React from 'react'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { NodeViewProps } from '@tiptap/core'
import type {
  RichContentGetFileQuery,
  RichContentGetFileQueryVariables
} from 'types/graphql'
import { ListBulletIcon, NumberedListIcon, PhotoIcon, CheckIcon, ArrowDownTrayIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { PaperClipIcon } from '@heroicons/react/20/solid'

import { useHashMentionPlugin } from './HashMention'
import useTooltips from './useTooltips'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Placeholder from '@tiptap/extension-placeholder'
import { Node } from '@tiptap/core'

// Declare the extension type
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    taskList: {
      toggleTaskList: () => ReturnType
    }
  }
}

export const QUERY_FILE = gql`
  query RichContentGetFileQuery($id: Int!) {
    getFile(id: $id) {
      id
      path
      url
      inlineUrl
      contentType
    }
  }
`

// Create a React component to render images
const ImageComponent = ({ node, deleteNode, updateAttributes, editor }: NodeViewProps) => {
  const fileId = node.attrs.fileId
  const [src, setSrc] = React.useState(node.attrs.src)
  const [isDragging, setIsDragging] = React.useState(false)
  const imageRef = React.useRef<HTMLImageElement>(null)
  const initialWidthRef = React.useRef<number>(0)
  const initialMouseXRef = React.useRef<number>(0)

  const { refetch: fetchFile } = useQuery<RichContentGetFileQuery, RichContentGetFileQueryVariables>(QUERY_FILE, {
    skip: true,
  })

  React.useEffect(() => {
    const loadImage = async () => {
      if (fileId) {
        try {
          const { data } = await fetchFile({ id: fileId })
          if (data?.getFile?.inlineUrl) {
            setSrc(data.getFile.inlineUrl)
          }
        } catch (error) {
          console.error('Error fetching image URL:', error)
        }
      }
    }

    loadImage()
  }, [fileId, fetchFile])

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!editor?.isEditable) return
    e.preventDefault()
    if (!imageRef.current) return

    setIsDragging(true)
    initialWidthRef.current = imageRef.current.width
    initialMouseXRef.current = e.clientX

    const handleMouseMove = (e: MouseEvent) => {
      if (!imageRef.current) return

      const delta = e.clientX - initialMouseXRef.current
      const newWidth = Math.max(100, initialWidthRef.current + delta)

      // Maintain aspect ratio by only setting width
      updateAttributes({ width: newWidth })
    }

    const handleMouseUp = () => {
      setIsDragging(false)
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return (
    <NodeViewWrapper>
      <div className="group relative inline-block">
        <img
          ref={imageRef}
          src={src}
          style={{ width: node.attrs.width }}
          className={`${node.attrs.class} ${isDragging ? 'select-none' : ''}`}
          alt={node.attrs.alt || ''}
        />
        {editor?.isEditable && (
          <>
            <button
              onClick={() => deleteNode()}
              className="absolute top-2 right-2 p-1 rounded bg-black/80 hover:bg-black/60 shadow-sm invisible group-hover:visible"
              title="Delete image"
            >
              <TrashIcon className="h-4 w-4 text-gray-400" />
            </button>
            <div
              onMouseDown={handleMouseDown}
              className="absolute top-1/2 -right-1 -translate-y-1/2 w-1.5 h-[70px] bg-gray-600/40 rounded-full shadow-[0_0_1px_rgba(255,255,255,0.5)] cursor-ew-resize invisible group-hover:visible"
            />
          </>
        )}
      </div>
    </NodeViewWrapper>
  )
}

// Modify the CustomImage extension to include width attribute
export const CustomImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fileId: {
        default: null,
        parseHTML: element => element.getAttribute('data-file-id'),
        renderHTML: attributes => {
          if (!attributes.fileId) {
            return {}
          }
          return {
            'data-file-id': attributes.fileId,
          }
        }
      },
      width: {
        default: undefined,
        parseHTML: element => element.style.width,
        renderHTML: attributes => {
          if (!attributes.width) {
            return {}
          }
          return {
            style: `width: ${attributes.width}px`
          }
        }
      }
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageComponent)
  }
})

// Create a React component to render file attachments
const FileComponent = ({ node, deleteNode, editor }: NodeViewProps) => {
  const fileId = node.attrs.fileId
  const [fileData, setFileData] = React.useState<{
    url?: string,
    name?: string
  }>({
    url: node.attrs.url,
    name: node.attrs.name
  })

  const { refetch: fetchFile } = useQuery<RichContentGetFileQuery, RichContentGetFileQueryVariables>(QUERY_FILE, {
    skip: true,
  })

  React.useEffect(() => {
    const loadFile = async () => {
      if (fileId) {
        try {
          const { data } = await fetchFile({ id: fileId })
          if (data?.getFile) {
            setFileData({
              url: data.getFile.url,
              name: data.getFile.path.split('/').pop() // Get filename from path
            })
          }
        } catch (error) {
          console.error('Error fetching file data:', error)
        }
      }
    }

    loadFile()
  }, [fileId, fetchFile])

  return (
    <NodeViewWrapper>
      <div className="group relative flex items-center gap-2 p-2 rounded-lg border border-gray-200 bg-gray-50 max-w-fit">
        <PaperClipIcon className="h-5 w-5 text-gray-500" />
        <span className="text-sm text-gray-700">{fileData.name}</span>
        {fileData.url && (
          <a
            href={fileData.url}
            download
            className="p-1 rounded hover:bg-gray-200"
            title="Download file"
          >
            <ArrowDownTrayIcon className="h-4 w-4 text-gray-500" />
          </a>
        )}
        {editor?.isEditable && (
          <button
            onClick={() => deleteNode()}
            className="p-1 rounded hover:bg-gray-200"
            title="Delete file"
          >
            <TrashIcon className="h-4 w-4 text-gray-500" />
          </button>
        )}
      </div>
    </NodeViewWrapper>
  )
}

// Create a CustomFile extension
const CustomFile = Node.create({
  name: 'file',
  group: 'block',
  atom: true,

  addAttributes() {
    return {
      fileId: {
        default: null
      },
      url: {
        default: null
      },
      name: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-file-id]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-file-id': HTMLAttributes.fileId }, '']
  },

  addNodeView() {
    return ReactNodeViewRenderer(FileComponent)
  }
})

export interface RichContentContext {
  changeOrder?: {
    partNumbers: string[]
    number: number
  }
}

export interface RichContentProps {
  content: string
  context?: RichContentContext
  onEditorChange?: (content: JSONContent) => void
  editorRef?: React.MutableRefObject<ReturnType<typeof useEditor> | null>
  enableTaskList?: boolean
  fontSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
}

export const RichContent = ({ content, context, onEditorChange, editorRef, enableTaskList = false, fontSize = 'sm' }: RichContentProps) => {
  let parsedContent = content
  try {
    parsedContent = JSON.parse(content)
  } catch (e) {
    parsedContent = content.replaceAll('\n', '<br>')
  }

  const mentionPlugin = useHashMentionPlugin({ context })
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: false,
      }),
      CustomImage.configure({
        HTMLAttributes: {
          class: 'rounded-lg max-w-full',
        },
      }),
      CustomFile,
      ...(enableTaskList ? [
        TaskList,
        TaskItem.configure({
          nested: true,
          HTMLAttributes: {
            class: 'task-item',
          },
          onReadOnlyChecked: (node: ProseMirrorNode, checked: boolean) => {
            let nodePos = -1
            editor?.state.doc.descendants((n, pos) => {
              if (n === node) {
                nodePos = pos
                return false
              }
            })

            if (nodePos > -1) {
              const tr = editor?.state.tr.setNodeMarkup(nodePos, null, {
                ...node.attrs,
                checked,
              })
              if (tr) {
                editor?.view.dispatch(tr)
                onEditorChange?.(editor?.getJSON() || { type: 'doc', content: [] })
              }
            }
            return true
          },
        }),
      ] : []),
      mentionPlugin.plugin
    ],
    content: parsedContent,
    editable: false,
  })

  // Update the ref whenever editor changes
  React.useEffect(() => {
    if (editorRef) {
      editorRef.current = editor
    }
  }, [editor, editorRef])

  useTooltips(editor)

  return (
    <EditorContent
      editor={editor}
      className={`prose max-w-none prose-img:my-2 text-${fontSize}`}
    />
  )
}

export interface RichContentEditorProps {
  onEditorChange?: (content: JSONContent) => void
  editorRef?: React.MutableRefObject<ReturnType<typeof useEditor> | null>
  context?: RichContentContext
  initialContent?: string
  enableTaskList?: boolean
  placeholder?: string
  testId?: string
  frameless?: boolean
  fontSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
}

// Update the Mention configuration to use Tailwind classes and trigger on #
export const RichContentEditor = ({
  onEditorChange,
  editorRef,
  context,
  initialContent,
  enableTaskList = false,
  placeholder,
  testId,
  frameless = false,
  fontSize = 'sm'
}: RichContentEditorProps) => {

  let parsedContent = initialContent || ''
  try {
    parsedContent = JSON.parse(initialContent || '')
  } catch (e) {
    parsedContent = (initialContent || '').replaceAll('\n', '<br>')
  }

  const mentionPlugin = useHashMentionPlugin({ editible: true, context })
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: false,
      }),
      CustomImage.configure({
        HTMLAttributes: {
          class: 'rounded-lg max-w-full',
        },
      }),
      CustomFile,
      ...(enableTaskList ? [
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
      ] : []),
      mentionPlugin.plugin,
      ...(placeholder ? [
        Placeholder.configure({
          placeholder,
          emptyEditorClass: 'is-editor-empty',
        }),
      ] : []),
    ],
    onUpdate: ({ editor }) => {
      onEditorChange?.(editor.getJSON())
    },
    editorProps: {
      attributes: {
        class: `${frameless ? '' : 'p-4'} min-h-[100px]`,
        'data-testid': testId || '',
      }
    },
    editable: true,
    content: parsedContent
  })

  useTooltips(editor)

  // Update the ref whenever editor changes
  React.useEffect(() => {
    if (editorRef) {
      editorRef.current = editor
    }
  }, [editor, editorRef])

  const { refetch: fetchFile } = useQuery<RichContentGetFileQuery, RichContentGetFileQueryVariables>(QUERY_FILE, {
    skip: true,
  })

  // Handle successful uploads
  const handleUploadsComplete = async (
    fileRefs: FileRef[] | undefined,
    failedUploads: any,
    failedConfirms: any
  ) => {
    if (fileRefs && fileRefs.length > 0) {
      fileRefs.forEach(async (file) => {
        try {
          const { data } = await fetchFile({
            id: file.id
          })

          if (data?.getFile) {
            if (data.getFile.contentType?.startsWith('image/')) {
              // Handle images as before
              editor?.chain().focus().setImage({
                src: data.getFile.inlineUrl,
                alt: `Uploaded image ${file.id}`,
              }).run()

              // Find and update the newly inserted image
              const tr = editor?.state.tr
              if (tr) {
                let found = false
                tr.doc.descendants((node, pos) => {
                  if (!found && node.type.name === 'image' && data?.getFile?.inlineUrl && node.attrs.src === data.getFile.inlineUrl) {
                    tr.setNodeAttribute(pos, 'fileId', file.id)
                    found = true
                  }
                })
                editor?.view.dispatch(tr)
              }
            } else {
              // Handle other file types
              const fileName = data.getFile.path.split('/').pop()
              editor?.chain().focus().insertContent({
                type: 'file',
                attrs: {
                  fileId: file.id,
                  url: data.getFile.url,
                  name: fileName
                }
              }).run()
            }
          }
        } catch (error) {
          console.error('Error fetching file details:', error)
        }
      })
    }

    if (failedUploads || failedConfirms) {
      console.error('Upload failures:', { failedUploads, failedConfirms })
    }
  }

  // Update validateFiles to accept all file types
  const validateFiles = async (files: File[]) => {
    const MAX_SIZE = 10 * 1024 * 1024 // 10MB

    const valid = files.every(file => {
      if (file.size > MAX_SIZE) {
        alert('File too large (max 10MB)')
        return false
      }
      return true
    })

    return valid
  }

  const {
    uploading,
    inputBindings,
    dragBindings,
    dragState,
    error,
    clearError
  } = useFileUploader(validateFiles, handleUploadsComplete)

  return (
    <div className={`${frameless ? '' : 'border border-gray-200 rounded-lg overflow-hidden'}`}>
      {/* Only show toolbar if not frameless */}
      {!frameless && (
        <div className="flex items-center gap-1 h-8 px-2 border-b border-gray-200 bg-gray-50">
          <button
            type='button'
            onClick={() => editor?.chain().focus().toggleBold().run()}
            className={`flex items-center justify-center h-6 w-6 rounded hover:bg-gray-200 ${
              editor?.isActive('bold') ? 'bg-gray-200' : ''
            }`}
          >
            B
          </button>
          <button
            type='button'
            onClick={() => editor?.chain().focus().toggleItalic().run()}
            className={`flex items-center justify-center h-6 w-6 rounded hover:bg-gray-200 ${
              editor?.isActive('italic') ? 'bg-gray-200' : ''
            }`}
          >
            I
          </button>
          {/* Add bullet list button */}
          <button
            type='button'
            onClick={() => editor?.chain().focus().toggleBulletList().run()}
            className={`flex items-center justify-center h-6 w-6 rounded hover:bg-gray-200 ${
              editor?.isActive('bulletList') ? 'bg-gray-200' : ''
            }`}
          >
            <ListBulletIcon className='h-4 w-4' />
          </button>
          {/* Add numbered list button */}
          <button
            type='button'
            onClick={() => editor?.chain().focus().toggleOrderedList().run()}
            className={`flex items-center justify-center h-6 w-6 rounded hover:bg-gray-200 ${
              editor?.isActive('orderedList') ? 'bg-gray-200' : ''
            }`}
          >
            <NumberedListIcon className='h-4 w-4' />
          </button>
          {/* Only show task list button if enabled */}
          {enableTaskList && (
            <button
              type='button'
              onClick={() => editor?.chain().focus().toggleTaskList().run()}
              className={`flex items-center justify-center h-6 w-6 rounded hover:bg-gray-200 ${
                editor?.isActive('taskList') ? 'bg-gray-200' : ''
              }`}
            >
              <CheckIcon className='h-4 w-4' />
            </button>
          )}
          {/* Image upload button */}
          <label
            className={`flex items-center justify-center h-6 w-6 rounded hover:bg-gray-200 cursor-pointer ${
              uploading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <PaperClipIcon className='h-4 w-4' />
            <input {...inputBindings} className="hidden" disabled={uploading} />
          </label>
        </div>
      )}

      {/* Editor with drop zone */}
      <div
        {...dragBindings}
        className={`relative`}
      >

        {uploading && (
          <div className="absolute inset-0 bg-white/50 flex items-center justify-center">
            <div className="animate-spin h-8 w-8 border-4 border-gray-200 rounded-full border-t-blue-500" />
          </div>
        )}

        {error && (
          <div className="bg-red-50 p-2 flex justify-between items-center">
            <p className="text-red-600 text-sm">{error.message}</p>
            <button
              onClick={clearError}
              className="text-red-600 hover:text-red-700"
            >
              ×
            </button>
          </div>
        )}

        {/* Editor */}
        <EditorContent
          editor={editor}
          className={`prose max-w-none prose-img:my-2 text-${fontSize}`}
        />
      </div>
    </div>
  )
}
