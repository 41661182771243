import { useMutation, useQuery, gql } from '@apollo/client'
import { SMART_ADD_PART_DELTA_MUTATION, CHANGE_ORDER_TREE_QUERY } from 'src/lib/queries'
import {
  AddPartDeltaMutation,
  AddPartDeltaMutationVariables,
  ChangeOrder,
  DependencySection,
  PartDeltaInput,
  PartDependenciesQuery,
  PartDependenciesQueryVariables
} from 'types/graphql'
import { reportMutationError } from 'src/lib/reportError'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import { nullControlSeq } from 'shared/types'
import invariant from 'tiny-invariant'
import { useParams } from '@redwoodjs/router'

// Query to get part dependencies and version information
const PART_DEPENDENCIES_QUERY = gql`
  query PartDependenciesQuery($partNumber: String!) {
    partProto(partNumber: $partNumber) {
      partNumber
      currentVersion {
        version
        dependencies {
          toPartNumber
          toVersionRange
          quantity
          units
          referenceDesignator
          section
        }
      }
    }
  }
`

type DependencyInput = {
  partNumber: string,
  versionRange?: string,
  quantity?: number,
  units?: string,
  section?: DependencySection,
  referenceDesignator?: string
}

type ModifyDependencyOptions = {
  toRemove?: string[], // part numbers to remove
  toAdd?: DependencyInput[],
  toModify?: DependencyInput[], // part numbers to modify with partial updates
}

/**
 * Hook for modifying part dependencies in a change order
 *
 * @param orderNumber The change order number
 * @param changeOrder The change order object
 * @returns A function to modify part dependencies
 */
export const useModifyPartDependency = (changeOrder: ChangeOrder) => {
  const { refetch, loading: queryLoading } = useQuery<PartDependenciesQuery, PartDependenciesQueryVariables>(PART_DEPENDENCIES_QUERY, {
    skip: true,
  })

  const { partContext } = useParams()

  const [addPartDeltaMutation, { loading: mutationLoading }] = useMutation<
    AddPartDeltaMutation,
    AddPartDeltaMutationVariables
  >(SMART_ADD_PART_DELTA_MUTATION)

  /**
   * Get dependencies from query result data
   */
  const extractDependencies = (data: PartDependenciesQuery) => {
    if (data.partProto?.currentVersion?.dependencies) {
      return data.partProto.currentVersion.dependencies.map(dep => ({
        partNumber: dep.toPartNumber,
        versionRange: dep.toVersionRange,
        quantity: dep.quantity,
        units: dep.units,
        referenceDesignator: dep.referenceDesignator || nullControlSeq,
        section: (dep.section || 'Manual') as DependencySection,
      }))
    }
    return []
  }

  /**
   * Modify part dependencies (add or remove)
   *
   * @param parentPart The parent part to modify dependencies for
   * @param options Options for modification (parts to add, remove, or modify)
   * @returns Promise that resolves when the operation is complete
   */
  const modifyPartDependency = async (
    parentPartNumber: string,
    options: ModifyDependencyOptions
  ) => {
    try {
      // Find parent in change order
      const includedParts = changeOrder.includedParts || []
      const parentInChangeOrder = includedParts.find(part => part.partNumber === parentPartNumber)

      const input: AddPartDeltaMutationVariables['input'] = []

      if (parentInChangeOrder) {
        // If parent is already in change order, get existing dependencies
        const dependencies = (parentInChangeOrder.dependencies || []).map(dep => ({
          partNumber: dep.to.partNumber,
          versionRange: dep.toVersionRange,
          quantity: dep.quantity,
          units: dep.units,
          referenceDesignator: dep.referenceDesignator || nullControlSeq,
          section: dep.section as DependencySection,
        }))

        // Start with existing dependencies
        let updatedDependencies = [...dependencies]

        // Remove dependencies if specified
        if (options.toRemove && options.toRemove.length > 0) {
          updatedDependencies = updatedDependencies.filter(
            dep => !options.toRemove?.includes(dep.partNumber)
          )
        }

        // Modify existing dependencies if specified
        if (options.toModify && options.toModify.length > 0) {
          updatedDependencies = updatedDependencies.map(dep => {
            const modifyInput = options.toModify?.find(mod => mod.partNumber === dep.partNumber)
            if (!modifyInput) return dep

            // Only update fields that are defined in the input
            return {
              ...dep,
              ...(modifyInput.versionRange !== undefined && { versionRange: modifyInput.versionRange }),
              ...(modifyInput.quantity !== undefined && { quantity: modifyInput.quantity }),
              ...(modifyInput.units !== undefined && { units: modifyInput.units }),
              ...(modifyInput.section !== undefined && { section: modifyInput.section }),
              ...(modifyInput.referenceDesignator !== undefined && {
                referenceDesignator: modifyInput.referenceDesignator || nullControlSeq
              }),
            }
          })
        }

        // Add new dependencies if specified
        if (options.toAdd && options.toAdd.length > 0) {
          // Filter out any dependencies that already exist
          const newDependencies = options.toAdd.filter(
            newDep => !updatedDependencies.some(dep => dep.partNumber === newDep.partNumber)
          )

          // Add the new dependencies with defaults for missing properties
          updatedDependencies = [
            ...updatedDependencies,
            ...newDependencies.map(dep => ({
              partNumber: dep.partNumber,
              versionRange: dep.versionRange || '*',
              quantity: dep.quantity || 1,
              units: dep.units || 'each',
              section: (dep.section || 'Manual') as DependencySection,
              referenceDesignator: dep.referenceDesignator || nullControlSeq
            }))
          ]
        }

        input.push({
          type: 'Patch',
          partNumber: parentPartNumber,
          version: parentInChangeOrder.version,
          part: {
            dependencies: updatedDependencies
          }
        })
      } else {
        // If parent is not in change order, fetch its dependencies directly
        const { data } = await refetch({ partNumber: parentPartNumber })
        invariant(data.partProto?.currentVersion)
        // Get the current version from the query result
        const currentVersion = data?.partProto?.currentVersion?.version

        if (!currentVersion) {
          console.error(`Could not find version for part ${parentPartNumber}`)
          return
        }

        // Extract dependencies from the query result
        const existingDependencies = extractDependencies(data)

        // Start with existing dependencies
        let updatedDependencies = [...existingDependencies]

        // Remove dependencies if specified
        if (options.toRemove && options.toRemove.length > 0) {
          updatedDependencies = updatedDependencies.filter(
            dep => !options.toRemove?.includes(dep.partNumber)
          )
        }

        // Modify existing dependencies if specified
        if (options.toModify && options.toModify.length > 0) {
          updatedDependencies = updatedDependencies.map(dep => {
            const modifyInput = options.toModify?.find(mod => mod.partNumber === dep.partNumber)
            if (!modifyInput) return dep

            // Only update fields that are defined in the input
            return {
              ...dep,
              ...(modifyInput.versionRange !== undefined && { versionRange: modifyInput.versionRange }),
              ...(modifyInput.quantity !== undefined && { quantity: modifyInput.quantity }),
              ...(modifyInput.units !== undefined && { units: modifyInput.units }),
              ...(modifyInput.section !== undefined && { section: modifyInput.section }),
              ...(modifyInput.referenceDesignator !== undefined && {
                referenceDesignator: modifyInput.referenceDesignator || nullControlSeq
              }),
            }
          })
        }

        // Add new dependencies if specified
        if (options.toAdd && options.toAdd.length > 0) {
          // Filter out any dependencies that already exist
          const newDependencies = options.toAdd.filter(
            newDep => !updatedDependencies.some(dep => dep.partNumber === newDep.partNumber)
          )

          // Add the new dependencies with defaults for missing properties
          updatedDependencies = [
            ...updatedDependencies,
            ...newDependencies.map(dep => ({
              partNumber: dep.partNumber,
              versionRange: dep.versionRange || '*',
              quantity: dep.quantity || 1,
              units: dep.units || 'each',
              section: (dep.section || 'Manual') as DependencySection,
              referenceDesignator: dep.referenceDesignator || nullControlSeq
            }))
          ]
        }

        input.push({
          type: 'Push',
          partNumber: parentPartNumber,
          version: calculateNextVersion(currentVersion),
          part: {
            dependencies: updatedDependencies
          }
        })
      }

      const allInput = [...input]

      // Execute the mutation if we have parts to modify
      if (allInput.length > 0) {
        const { errors } = await addPartDeltaMutation({
          variables: {
            changeOrderNumber: changeOrder.number,
            input: allInput,
            partContext
          }
        })

        if (errors) {
          reportMutationError({
            errors,
            variables: {
              changeOrderNumber: changeOrder.number,
              input: allInput
            },
            message: `Error modifying dependencies for ${parentPartNumber}`
          })
        }
      }
    } catch (error) {
      console.error(`Error modifying part dependencies:`, error)
    }
  }

  return {
    modifyPartDependency,
    loading: queryLoading || mutationLoading
  }
}