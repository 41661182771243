import React, { useEffect, useState } from 'react'
import { CellLoadingProps, CellSuccessProps, useQuery, useMutation, CellFailureProps } from '@redwoodjs/web'
import { AltiumLogo } from 'src/components/CadLogo'


import type {
  CheckNexarTokenQuery,
  AltiumBomQuery,
  AltiumBomQueryVariables,
  InitiateNexarAuth,
  InitiateNexarAuthVariables,
  AltiumDesignsQuery
} from 'types/graphql'
import * as ListBox from '../ListBox'
import { BomRow } from 'src/lib/mapping/1-fileToRows'
import Button from '../Button'
import { reportGenericError } from 'src/lib/reportError'

export const ALTIUM_DESIGNS_QUERY = gql`
  query AltiumDesignsQuery {
    altiumDesigns {
      id
      name
      partNumber
      releases {
        id
        releaseId
      }
    }
  }
`

export const QUERY = gql`
  query CheckNexarTokenQuery {
    nexarToken {
      id
      expiresAt
    }
  }
`

export const INITIATE_AUTH = gql`
  mutation InitiateNexarAuth($input: InitiateNexarAuthInput!) {
    initiateNexarAuth(input: $input) {
      url
    }
  }
`

export const ALTIUM_BOM_QUERY = gql`
  query AltiumBomQuery($input: AltiumBomInput!) {
    altiumBom(input: $input) {
      status
      data
    }
  }
`

type Props = {
  onBomChange: (rows: BomRow[] | null) => void
  onLoading: () => void
  orgId: string
  userId: number
}

type SuccessProps = CellSuccessProps<CheckNexarTokenQuery> & Props
type LoadingProps = CellLoadingProps<CheckNexarTokenQuery> & Props
type FailureProps = CellFailureProps<CheckNexarTokenQuery> & Props

export const Loading = (props: LoadingProps) => {
  return <AltiumImport {...props} cellLoading />
}

export const Failure = (props: FailureProps) => {
  const eventId = reportGenericError(props.error)
  return <div className='bg-red-200 p-2'>
    <div className='font-medium'>
      There was an error connecting with Altium
    </div>
    <div className='text-sm'>Contact Bomello support with error ID: <span className='font-bold'>{eventId}</span> to escalate a bug fix.</div>
  </div>
}

export const Success = (props: SuccessProps) => {
  return <AltiumImport {...props} />
}

type AltiumImportProps = Partial<CellSuccessProps<CheckNexarTokenQuery>> & Props & { cellLoading?: boolean }
const AltiumImport = ({ nexarToken, onBomChange, onLoading, orgId, userId, cellLoading }: AltiumImportProps) => {
  const [loading, setLoading] = useState(false)
  const [isLoadingDesigns, setIsLoadingDesigns] = useState(false)
  const [altiumDesigns, setAltiumDesigns] = useState<AltiumDesignsQuery['altiumDesigns'] | null>(null)

  // Check token status
  const {
    refetch: fetchDesignsData
  } = useQuery(ALTIUM_DESIGNS_QUERY, { skip: true })

  const [initiateAuth] = useMutation<InitiateNexarAuth, InitiateNexarAuthVariables>(INITIATE_AUTH)

  const handleAuth = async () => {
    try {
      setLoading(true)
      const { data } = await initiateAuth({
        variables: {
          input: {
            whenDoneRedirectUrl: window.location.href
          }
        }
      })

      if (data) {
        window.location.href = data.initiateNexarAuth.url
      }
    } catch (error) {
      console.error('Auth initiation failed:', error)
    } finally {
      setLoading(false)
    }
  }

  // Rest of your existing component code
  const displayValue = (id: string | null) => {
    if (!id || !altiumDesigns) return 'Select Altium Project'
    return altiumDesigns.find(d => d.id === id)!.name
  }

  const [documentId, setDocumentId] = useState<null | string>(null)
  const onChange = (id: string) => {
    setDocumentId(id)
    setVersionId(null)
    onBomChange(null)
  }

  const [versionId, setVersionId] = useState<null | string>(null)

  const selectedProject = altiumDesigns?.find(d => d.id === documentId)
  const releases = selectedProject?.releases

  const loadDesigns = async () => {
    setIsLoadingDesigns(true)
    try {
      const { data } = await fetchDesignsData()
      setAltiumDesigns(data.altiumDesigns)
    } catch (e) {
      throw e
    } finally {
      setIsLoadingDesigns(false)
    }
  }

  useEffect(() => {
    if (nexarToken?.id) {
      loadDesigns()
    }
  }, [nexarToken])

  useEffect(() => {
    let pollInterval: NodeJS.Timeout | null = null;
    let attempts = 0;
    const MAX_ATTEMPTS = 60; // 5 minutes worth of 5-second intervals

    const getBom = async (documentId: string, versionId: string) => {
      attempts++;
      if (attempts >= MAX_ATTEMPTS) {
        if (pollInterval) {
          clearInterval(pollInterval)
          pollInterval = null
        }
        onBomChange(null) // or handle timeout however you prefer
        return
      }

      onLoading()
      try {
        const { data: { altiumBom } } = await fetchAltiumBom({
          input: {
            id: versionId,
            partNumber: selectedProject!.partNumber,
            projectId: selectedProject!.id
          }
        })

        switch (altiumBom.status) {
          case 'complete':
            if (altiumBom.data) {
              const withMetadata = altiumBom.data.map(r => {
                return {
                  ...r,
                  __metadata: {
                    originalIndex: -1,
                    messages: []
                  }
                } as BomRow
              })
              onBomChange(withMetadata)
              if (pollInterval) {
                clearInterval(pollInterval)
                pollInterval = null
              }
            }
            break;

          case 'error':
            // console.error('Error fetching BOM:', altiumBom.error)
            // Could add specific error handling here
            break;

          case 'pending':
            break;
        }
      } catch (error) {
        console.error('Failed to fetch BOM:', error)
      }
    }

    if (documentId && versionId) {
      attempts = 0 // Reset attempts counter
      getBom(documentId, versionId)

      pollInterval = setInterval(() => {
        if (documentId && versionId) {
          getBom(documentId, versionId)
        }
      }, 5000)
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval)
      }
    }
  }, [documentId, versionId])

  const { refetch: fetchAltiumBom } = useQuery<AltiumBomQuery, AltiumBomQueryVariables>(
    ALTIUM_BOM_QUERY,
    { skip: true }
  )

  if (!cellLoading && !nexarToken?.id) {
    return (
      <div className="flex flex-col items-end p-4 gap-4">
        <AltiumLogo className='w-32'/>
        <Button
          onClick={handleAuth}
          disabled={loading}
        >
          Login to connect
        </Button>
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-4 items-end'>
      <ListBox.ListBox onChange={onChange} value={documentId}>
        {({ open }) =>
          <div className="relative h-full">
            <ListBox.Button
              className='py-2'
              displayValue={displayValue(documentId)}
              disabled={cellLoading || isLoadingDesigns}
            />
            <ListBox.Options open={open} align='right' className='text-sm'>
              {altiumDesigns?.map(d => (
                <ListBox.Option
                  key={d.id}
                  className='py-3'
                  value={d.id}
                  display={displayValue(d.id)}
                />
              ))}
            </ListBox.Options>
          </div>
        }
      </ListBox.ListBox>
      {
        <VersionInput
          releases={releases}
          versionId={versionId}
          setVersionId={setVersionId}
        />
      }
    </div>
  )
}

// VersionInput component remains the same
type AltiumRelease = {
  id: string
  releaseId: string
}

type VersionInputProps = {
  releases?: AltiumRelease[]
  setVersionId: (v: string) => void
  versionId: string | null
}

export const VersionInput = ({ releases, setVersionId, versionId }: VersionInputProps) => {
  const displayValue = (id: string | null) => {
    if (!id || !releases) return 'Select Project Release'
    return releases.find(d => d.id === id)!.releaseId
  }

  const onChange = (id: string) => {
    setVersionId(id)
  }

  return (
    <ListBox.ListBox onChange={onChange} value={versionId} disabled={!releases}>
      {({ open }) =>
        <div className="relative h-full">
          <ListBox.Button
            className='py-2'
            displayValue={displayValue(versionId)}
            disabled={!releases}
          />
          <ListBox.Options open={open} align='right' className='text-sm'>
            {(releases || []).map(d => (
              <ListBox.Option
                key={d.id}
                className='py-3'
                value={d.id}
                display={displayValue(d.id)}
              />
            ))}
            {releases?.length === 0 && <div className='py-3 px-4 text-gray-600 italic'>No Releases Found</div>}
          </ListBox.Options>
        </div>
      }
    </ListBox.ListBox>
  )
}
