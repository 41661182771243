import type { ChangeOrdersQuery, ChangeOrderState } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { routes, useParams, Link } from '@redwoodjs/router'
import { prettyDateTime } from 'src/lib/formatters'


import { ChevronRightIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import GenericFailure from '../Failure/Failure'
import { useAppContext } from 'src/lib/appContext'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import EmptyState from 'src/components/EmptyState/EmptyState'


export const QUERY = gql`
  query ChangeOrdersQuery {
    changeOrders {
      _id
      state
      name
      number
      created
      appliedAt
      branchLastUpdated
      creator {
        id
      }
      reviewers {
        anotherReviewRequested
        response
        role
        user {
          id
          name
        }
      }
      tasks {
        id
        status
        assignedTo {
          id
        }
      }
    }
  }
`


export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>


export const Failure = GenericFailure

type PageProps = {
  filter: ChangeOrderState[]
  relevantToMe?: boolean
}
export const Success = ({
  changeOrders,
  filter,
  relevantToMe = false
}: CellSuccessProps<ChangeOrdersQuery> & PageProps) => {
  const orgId = useParams().orgId!
  const { me } = useAppContext()

  const filtered = changeOrders
    .filter(co => {
      // First apply the state filter
      const stateMatches = filter.includes(co.state)

      // If relevantToMe is true, check if the user is involved
      if (relevantToMe) {
        const isCreator = co.creator?.id === me.id
        const isReviewer = co.reviewers.some(r => r.user.id === me.id)
        return stateMatches && (isCreator || isReviewer)
      }

      return stateMatches
    })
    .sort((a, b) => {
      if (filter[0] === 'Complete') {
        return (new Date(b.appliedAt!)!).getTime() - (new Date(a.appliedAt!)).getTime()!
      }
      return (new Date(b.created)!).getTime() - (new Date(a.created)).getTime()!
    })

  const getReviewStatus = (co: typeof changeOrders[0]) => {
    const reviewers = co.reviewers.filter(r => r.role === 'Reviewer')
    if (!reviewers.length) {
      return <>No reviewers assigned</>
    }

    const pendingReviewerCount = reviewers.filter(r =>
      r.response === 'Pending' || r.anotherReviewRequested
    ).length

    const myReview = co.reviewers.find(r => r.user.id === me.id)
    if (myReview?.anotherReviewRequested) {
      const extra = (pendingReviewerCount > 1) ?
        ` and ${pendingReviewerCount - 1} other reviewer${pendingReviewerCount > 2 ? 's' : ''}` : ''
      return <>Awaiting your rereview{extra}</>
    }
    if (myReview?.response === 'Pending') {
      const extra = (pendingReviewerCount > 1) ?
        ` and ${pendingReviewerCount - 1} other reviewer${pendingReviewerCount > 2 ? 's' : ''}` : ''
      return <>Awaiting your review {extra}</>
    }

    if (myReview?.response === 'RequestChanges') {
      return <>You requested changes</>
    }
    const remainingChangeRequestCount = reviewers.filter(r =>
      r.response === 'RequestChanges' && !r.anotherReviewRequested
    ).length
    if (remainingChangeRequestCount) {
      return <>{remainingChangeRequestCount} reviewer{remainingChangeRequestCount > 1 ? 's' : ''} requested changes</>
    }

    if (pendingReviewerCount > 0) {
      return <>Awaiting {pendingReviewerCount} reviewer{pendingReviewerCount > 1 ? 's' : ''}</>
    }
    const allApprove = reviewers.every(r => r.response === 'Approve')
    if (allApprove) {
      return <>Approved by all reviewers</>
    }

  }

  return filtered.length ? <ul className='mb-6'>
    {filtered.map((co) => {
      const updateMessage = () => {
        const updated = new Date(co.branchLastUpdated)
        if (co.appliedAt) {
          const appliedAt = prettyDateTime(new Date(co.appliedAt))
          return <>Applied <time dateTime={co.created}>{appliedAt}</time></>
        }
        return <>Updated <time dateTime={co.branchLastUpdated}>{prettyDateTime(updated)}</time></>
      }
      return <li key={co.number}>
          <Link
            className='block bg-white border border-gray-200 hover:border-blue-500 transition-colors duration-200 rounded-lg mb-3'
            to={routes.changeOrder({ orderNumber: co.number, orgId }) }>
            <div className="p-4">
              <div className="flex justify-between mb-2">
                <p className="text-md font-semibold text-gray-900">
                  #CO-{co.number} - {co.name}
                </p>
                <span className=" text-sm rounded-xl py-0.5 px-2 bg-yellow-200 text-yellow-800">{co.state}</span>
              </div>
              <div className='flex justify-between mt-2'>
                <div className="text-sm text-gray-500">
                  {(() => {
                    const reviewStatus = co.state === 'Review' && getReviewStatus(co)
                    const hasTasks = co.tasks.length > 0
                    return <>
                      {reviewStatus}
                      {reviewStatus && hasTasks && ' • '}
                      {hasTasks && (
                        <span>
                          {co.tasks.filter(t => ['Done', 'WontDo'].includes(t.status)).length}/{co.tasks.length} tasks completed
                          {(() => {
                            const assignedToMe = co.tasks.filter(t => t.assignedTo?.id === me.id).length
                            return assignedToMe > 0 ? ` (${assignedToMe} assigned to you)` : ''
                          })()}
                        </span>
                      )}
                    </>
                  })()}
                </div>
                <div className="text-xs text-gray-500">
                  {updateMessage()}
                </div>
              </div>
              </div>
          </Link>
        </li>
    })}
  </ul> : <EmptyState message="No change orders found" />
}
