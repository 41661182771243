import { useState } from 'react'
import { useAppContext } from 'src/lib/appContext'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import GenericFailure from '../Failure/Failure'
import { useParams } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'
import { reportMutationError } from 'src/lib/reportError'
import type {
  OrgSettingsIntegrationsQuery,
  DisconnectSlackOrgMutation,
  DisconnectSlackOrgMutationVariables,
  DisconnectSlackUserMutation,
  DisconnectSlackUserMutationVariables,
  TestSlackIntegrationMutation,
  TestSlackIntegrationMutationVariables,
} from 'types/graphql'

type SuccessProps = CellSuccessProps<OrgSettingsIntegrationsQuery>

const SLACK_REDIRECT_URI = process.env.SLACK_REDIRECT_URI;
const SLACK_CLIENT_ID = process.env.SLACK_CLIENT_ID

export const QUERY = gql`
  query OrgSettingsIntegrationsQuery {
    currentOrg {
      id
      slackIntegration {
        teamName
        incomingWebhookChannel
        connectedAt
      }
    }
  }
`

const DISCONNECT_SLACK_ORG = gql`
  mutation DisconnectSlackOrgMutation {
    disconnectSlackOrg
  }
`

const DISCONNECT_SLACK_USER = gql`
  mutation DisconnectSlackUserMutation {
    disconnectSlackUser {
      id
    }
  }
`

const TEST_SLACK_INTEGRATION = gql`
  mutation TestSlackIntegrationMutation($personal: Boolean!) {
    testSlackIntegration(personal: $personal)
  }
`

export const Loading = () => <div className='flex justify-center p-2'><LoadingSpinner/></div>

export const Empty = () => <div>No integration data found</div>

export const Failure = GenericFailure

const SLACK_REDIRECT_BASE = `${SLACK_REDIRECT_URI ?? 'app.bomello.com'}/oauth-slack` // Base redirect URL

interface SlackIntegrationProps {
  currentOrg: SuccessProps['currentOrg']
}

// Slack logo SVG component
const SlackLogo = ({ className = "w-6 h-6" }: { className?: string }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M6.194 14.644c0 1.16-.943 2.107-2.103 2.107-1.16 0-2.103-.947-2.103-2.107 0-1.16.943-2.106 2.103-2.106h2.103v2.106zm1.061 0c0-1.16.944-2.106 2.104-2.106 1.16 0 2.103.946 2.103 2.106v5.274c0 1.16-.943 2.107-2.103 2.107-1.16 0-2.104-.947-2.104-2.107v-5.274z" fill="#E01E5A"/>
    <path d="M9.359 6.097c-1.16 0-2.104-.946-2.104-2.106 0-1.16.944-2.107 2.104-2.107 1.16 0 2.103.947 2.103 2.107v2.106H9.36zm0 1.068c1.16 0 2.103.946 2.103 2.106 0 1.16-.943 2.107-2.103 2.107H4.09c-1.16 0-2.103-.947-2.103-2.107 0-1.16.943-2.106 2.103-2.106h5.27z" fill="#36C5F0"/>
    <path d="M17.806 9.27c0-1.16.943-2.106 2.103-2.106 1.16 0 2.103.946 2.103 2.107 0 1.16-.943 2.106-2.103 2.106h-2.103V9.271zm-1.062 0c0 1.16-.943 2.107-2.103 2.107-1.16 0-2.103-.947-2.103-2.107V3.991c0-1.16.943-2.107 2.103-2.107 1.16 0 2.103.947 2.103 2.107V9.27z" fill="#2EB67D"/>
    <path d="M14.641 17.818c1.16 0 2.103.947 2.103 2.107 0 1.16-.943 2.107-2.103 2.107-1.16 0-2.103-.947-2.103-2.107v-2.107h2.103zm0-1.067c-1.16 0-2.103-.947-2.103-2.107 0-1.16.943-2.107 2.103-2.107h5.268c1.16 0 2.103.947 2.103 2.107 0 1.16-.943 2.107-2.103 2.107h-5.268z" fill="#ECB22E"/>
  </svg>
)

const SlackIntegration = ({ currentOrg }: SlackIntegrationProps) => {
  const { orgId } = useParams()
  const { hasEscalatedPrivileges, membership } = useAppContext()

  // Initialize from GraphQL query data
  const [slackIntegration, setSlackIntegration] = useState({
    isConnected: !!currentOrg?.slackIntegration,
    teamName: currentOrg?.slackIntegration?.teamName || '',
    connectedAt: currentOrg.slackIntegration?.connectedAt,
    channel: currentOrg?.slackIntegration?.incomingWebhookChannel || 'N/A'
  })

  const [userSlackConnection, setUserSlackConnection] = useState({
    isConnected: !!membership?.slackUserId,
    slackUsername: membership?.slackUserId ? `<@${membership?.slackUserId}>` : ''
  })

  // Generate Slack OAuth URLs
  const orgSlackAuthUrl = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook%2Cchat%3Awrite&user_scope=&redirect_uri=${encodeURIComponent(`${SLACK_REDIRECT_BASE}/${orgId}`)}&client_id=${SLACK_CLIENT_ID}`
  const userSlackAuthUrl = `https://slack.com/oauth/v2/authorize?scope=chat%3Awrite&user_scope=identify&redirect_uri=${encodeURIComponent(`${SLACK_REDIRECT_BASE}/${orgId}/identify`)}&client_id=${SLACK_CLIENT_ID}`

  const [testSlackIntegration, { loading: testingIntegration }] = useMutation<
    TestSlackIntegrationMutation,
    TestSlackIntegrationMutationVariables
  >(
    TEST_SLACK_INTEGRATION,
    {
      onCompleted: (data) => {
        toast.success('Test message sent to Slack successfully')
      },
      onError: (error) => {
        toast.error(`Failed to send test message: ${error.message}`)
      }
    }
  )

  const testOrgIntegration = () => {
    testSlackIntegration({
      variables: { personal: false },
      onError: (error) => {
        reportMutationError({
          errors: error,
          message: 'Failed to test organization Slack integration'
        })
      }
    })
  }

  const testUserIntegration = () => {
    testSlackIntegration({
      variables: { personal: true },
      onError: (error) => {
        reportMutationError({
          errors: error,
          message: 'Failed to test user Slack integration'
        })
      }
    })
  }

  const [disconnectSlackOrg, { loading: disconnectOrgLoading }] = useMutation<
    DisconnectSlackOrgMutation,
    DisconnectSlackOrgMutationVariables
  >(
    DISCONNECT_SLACK_ORG,
    {
      onCompleted: () => {
        setSlackIntegration({...slackIntegration, isConnected: false})
        toast.success('Slack workspace disconnected successfully')
      },
      onError: (error) => {
        toast.error(`Failed to disconnect Slack workspace: ${error.message}`)
      },
      refetchQueries: [{ query: QUERY }]
    }
  )

  const [disconnectSlackUser, { loading: disconnectUserLoading }] = useMutation<
    DisconnectSlackUserMutation,
    DisconnectSlackUserMutationVariables
  >(
    DISCONNECT_SLACK_USER,
    {
      onCompleted: () => {
        setUserSlackConnection({...userSlackConnection, isConnected: false})
        toast.success('Your Slack account disconnected successfully')
      },
      onError: (error) => {
        toast.error(`Failed to disconnect your Slack account: ${error.message}`)
      },
      refetchQueries: [{ query: QUERY }]
    }
  )

  const disconnectWorkspace = () => {
    if (confirm('Are you sure you want to disconnect the Slack workspace? This will disable global Slack notifications.')) {
      disconnectSlackOrg({
        onError: (error) => {
          reportMutationError({
            errors: error,
            message: 'Failed to disconnect Slack workspace'
          })
        }
      })
    }
  }

  const disconnectUserAccount = () => {
    if (confirm('Are you sure you want to disconnect your Slack account? You will no longer receive personal notifications.')) {
      disconnectSlackUser({
        onError: (error) => {
          reportMutationError({
            errors: error,
            message: 'Failed to disconnect your Slack account'
          })
        }
      })
    }
  }

  return (
    <div className="space-y-6">
      {/* Organization Slack Integration */}
      {false && <div className="border rounded-md p-5">
          <h3 className="text-lg font-medium mb-2">Workspace Connection</h3>

          {slackIntegration.isConnected ? (
            <div className="space-y-2">
              <div className="flex items-center">
                <div className="bg-green-100 text-green-800 px-2 py-1 rounded text-sm font-medium mr-2">
                  Connected
                </div>
                <span className="text-gray-700">to <strong>{slackIntegration.teamName}</strong></span>
              </div>

              <p className="text-sm text-gray-600">
                Connected on {new Date(currentOrg?.slackIntegration!.connectedAt).toLocaleDateString()}
              </p>

              {slackIntegration.channel && (
                <p className="text-sm text-gray-600">
                  Notifications will be sent to <strong>{slackIntegration.channel}</strong>
                </p>
              )}

              <div className="flex gap-3 mt-3">
                {hasEscalatedPrivileges && (
                  <>
                    <button
                      className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition"
                      onClick={disconnectWorkspace}
                      disabled={disconnectOrgLoading}
                    >
                      {disconnectOrgLoading ? 'Disconnecting...' : 'Disconnect Workspace'}
                    </button>

                    <button
                      className="px-4 py-2 bg-brand-600 text-white rounded-md hover:bg-brand-700 transition flex items-center"
                      onClick={testOrgIntegration}
                      disabled={testingIntegration}
                    >
                      <SlackLogo className="w-5 h-5 mr-2" />
                      {testingIntegration ? 'Sending...' : 'Test Workspace Integration'}
                    </button>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              <p className="text-gray-600 mb-4">
                Connect your Slack workspace to receive organization-wide notifications.
              </p>

              {hasEscalatedPrivileges ? (
                <a
                  href={orgSlackAuthUrl}
                  className="inline-flex items-center px-4 py-2 bg-brand-600 text-white rounded-md hover:bg-brand-700 transition"
                >
                  <SlackLogo className="w-5 h-5 mr-2" />
                  Add to Slack
                </a>
              ) : (
                <p className="text-amber-600">
                  Only organization administrators can connect the Slack workspace.
                </p>
              )}
            </div>
          )}
        </div>
      }

      {/* User Slack Identity */}
      <div className="rounded-md">
        {userSlackConnection.isConnected ? (
          <div className="space-y-2">
            <div className="flex items-center">
              <div className="bg-green-100 text-green-800 px-2 py-1 rounded text-sm font-medium mr-2">
                Connected
              </div>
            </div>

            <div className="flex gap-3 mt-2">
              <button
                className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200 transition"
                onClick={disconnectUserAccount}
                disabled={disconnectUserLoading}
              >
                {disconnectUserLoading ? 'Disconnecting...' : 'Disconnect Your Account'}
              </button>

              <button
                className="px-4 py-2 bg-brand-600 text-white rounded-md hover:bg-brand-700 transition flex items-center"
                onClick={testUserIntegration}
                disabled={testingIntegration}
              >
                <SlackLogo className="w-5 h-5 mr-2" />
                {testingIntegration ? 'Sending...' : 'Test Your Integration'}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-gray-600 mb-4">
              Connect your Slack account to receive personal notifications.
            </p>
            <a
              href={userSlackAuthUrl}
              className="inline-flex items-center px-4 py-2 bg-brand-600 text-white rounded-md hover:bg-brand-700 transition"
            >
              <SlackLogo className="w-5 h-5 mr-2" />
              Connect Your Slack Account
            </a>
          </div>
        )}
      </div>

      {/* Removed the general test connection section as we now have specific test buttons for each integration */}
    </div>
  )
}

export const Success = ({ currentOrg }: SuccessProps) => {
  return (
    <div className="flex flex-col border p-5 rounded-md">
      <h2 className="text-lg font-semibold mb-2 flex items-center gap-2">
        <SlackLogo className="w-6 h-6" />
        Slack Integration
      </h2>
      <SlackIntegration currentOrg={currentOrg} />
    </div>
  )
}

