import type { QuantityUnit } from 'shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { DocumentIcon } from '@heroicons/react/24/outline'

export const googleDrive: MapperConfig = {
  name: 'Google Drive',
  type: 'GoogleDrive',
  orgIds: ['sagarobotics', 'sagarobotics-demo'],
  rootIsTopLevel: true,
  noProject: true,
  Logo: DocumentIcon,
  importOutputFields: {
    metadata: [
      'manufacturer',
      'manufacturer-part-number',
      'material',
      'standard',
      'cost',
      'total-cost',
      'onshape-link',
      'google-drive-link'
    ]
  },
  googleDrive: {
    firstRow: 9,
    workbook: 'Latest',
    extractRoot: {
      inRowRange: [0, 7],
      fields: [{
        outputFieldName: 'part_number',
        extractionType: 'cellRightOfTextIncluding',
        input: 'BOM Part Number'
      }, {
        outputFieldName: 'name',
        extractionType: 'cellRightOfTextIncluding',
        input: 'BOM Name'
      }]
    }
  },
  fileToRows: {
    xlsx: {
      enable: true,
      firstRow: 7,
      workbook: 'Latest',
      extractRoot: {
        inRowRange: [0, 7],
        fields: [{
          outputFieldName: 'part_number',
          extractionType: 'cellRightOfTextIncluding',
          input: 'BOM Part Number'
        }, {
          outputFieldName: 'name',
          extractionType: 'cellRightOfTextIncluding',
          input: 'BOM Name'
        }]
      }
    }
  },
  rowsToBom: [{
    type: 'FilterRemove',
    config: {
      conditions: [{
        type: 'empty',
        column: 'part_number',
      }]
    }
  }],
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {
        'manufacturer': {
          type: 'simple',
          value: '{{row.manufacturer}}',
        },
        'manufacturer-part-number': {
          type: 'simple',
          value: '{{row.manufacturer_part_number}}',
        },
        'material': {
          type: 'simple',
          value: '{{row.material}}',
        },
        'standard': {
          type: 'simple',
          value: '{{row.standard}}',
        },
        'cost': {
          type: 'simple',
          value: '${row.cost?.replace("kr", "NOK")}'
        },
        'total-cost': {
          type: 'simple',
          value: '${row.total_cost?.replace("kr", "NOK")}'
        },
        'onshape-link': {
          type: 'simple',
          value: '{{row.link_to_onshape_url}}',
        },
        'google-drive-link': {
          type: 'simple',
          value: '{{row.google_drive_reference_file_url}}',
        }
      },
      hierarchy: [{
        conditions: [{
          type: 'eq',
          column: '__isRoot',
          value: 'true'
        }],
        onMatch: {
          type: 'simple',
          value: '1'
        }
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '1.{{rowIndex}}'
        }
      }],
      partNumber: {
        type: 'simple',
        value: '{{row.part_number}}'
      },
      cadRev: {
        type: 'simple',
        value: '{{row.cad_revision}}'
      },
      categoryId: [{
        conditions: [{
          type: 'eq',
          column: '__isRoot',
          value: 'true'
        }],
        onMatch: {
          type: 'simple',
          value: 'P'
        }
      }, {
        conditions: [{
          type: 'startsWith',
          column: 'part_number',
          value: 'SR-'
        }],
        onMatch: {
          type: 'simple',
          value: 'SR'
        }
      }, {
        conditions: [{
          type: 'startsWith',
          column: 'part_number',
          value: 'F-'
        }],
        onMatch: {
          type: 'simple',
          value: 'F'
        }
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: 'other'
        }
      }],
      isOffTheShelf: [{
        conditions: [{
          type: 'startsWith',
          column: 'part_number',
          value: 'F-'
        }],
        onMatch: {
          type: 'simple',
          value: 'true'
        }
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: 'false'
        }
      }],
      name: {
        value: '{{row.name}}',
        type: 'simple'
      },
      quantity: [{
        conditions: [{
          type: 'eq',
          column: '__isRoot',
          value: 'true'
        }],
        onMatch: {
          type: 'simple',
          value: '1'
        }
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.quantity}}'
        }
      }],
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
      artifacts: [
        {
          importId: {
            value: 'GoogleDriveThumbnail',
            type: 'simple'
          },
          filename: {
            value: 'thumbnail.png',
            type: 'simple'
          },
          fileId: [{
            conditions: [{
              type: 'regex',
              column: 'thumbnail_image_img',
              value: '^\\d+$'
            }],
            onMatch: {
              type: 'simple',
              value: '{{row.thumbnail_image_img}}'
            }
          }, {
            default: true,
            onMatch: {
              type: 'simple',
              value: ''
            }
          }]
        }
      ]
    }
  }
}