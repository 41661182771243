import kebabCase from 'lodash.kebabcase'
import padStart from 'lodash.padstart'
import Mustache from 'mustache'
import { Brand } from '../util';
import type { MapExecutionConfig } from './mapperConfigs'
import type { BomRow } from './1-fileToRows'
import type { SelectedProject } from 'src/components/ProjectSelectCell'

Mustache.escape = function(text) {return text;};

export const renderTemplate = (template: string, data: Record<string, unknown>) => {
  const output = new Function(
    'kebabCase',
    'padStart',
    ...Object.keys(data),
    `return \`${template}\`;`
  )(
    kebabCase,
    padStart,
    ...Object.values(data)
  )

  // for compatibility
  return Mustache.render(output, data)
}

export const parseJsObjectString = (jsString: string) => {
  return new Function(
    `return ${jsString};`
  )()
}

export type UnchangedField = Brand<string, "UnchangedField">
export const UNCHANGED_FIELD: UnchangedField = 'UNCHANGED' as UnchangedField

export const renderRowValue = (
  config: MapExecutionConfig,
  row: BomRow,
  rowIndex: number,
  project: SelectedProject
): string => {
  if (config.type === 'empty') return ''
  if (config.type === 'ignore') return UNCHANGED_FIELD
  if (config.type === 'simple') {
    return renderTemplate(config.value, { row, rowIndex, project })
  }
  if (config.type === 'quantity') {
    return config.value
  }
  if (config.type === 'split') {
    const input = renderTemplate(config.input, { row, rowIndex, project })
    const parts = input.split(config.splitOn)
    return renderTemplate(config.value, { row, rowIndex, project, parts })
  }
  if (config.type === 'regex') {
    const input = renderTemplate(config.input, { row, rowIndex, project })
    const rMatch = new RegExp(config.regex)
    const allParts = input.match(rMatch) || []
    const match = allParts.slice(1)
    return renderTemplate(config.value, { row, rowIndex, project, match })
  }
  throw new Error('Unsupported config type for rendering')
}
