import { routes, navigate, useParams, useLocation } from '@redwoodjs/router'
import { ChevronDoubleRightIcon, ArrowsPointingOutIcon } from '@heroicons/react/20/solid'
import PartCell from '../PartCell'
import TaskCell from '../TaskCell'
import { createPortal } from 'react-dom'
import { useEffect, useState, MouseEvent, createContext, useContext, ReactNode } from 'react'
import ChangeOrderChangesCell from '../ChangeOrderChangesCell'

// Define available routes and their prop types
export const rightPanelRoutes = {
  part: {
    render: (params: { partNumber: string, version?: string, orderNumber?: number, partId: string }) => {
      // partId isn't used directly, but is pulled from hierarchy to highlight
      // the part in the right hand panel

      return <div className='px-12'>
      <PartCell
        isPanel
        partNumber={params.partNumber}
        version={params.version}
        orderNumber={params.orderNumber}
      /></div>
    },
    minWidth: 600,
    defaultWidth: 600,
    maxWidth: 1200,
  },
  task: {
    render: (params: { taskNumber: number }) => (
      <div className='px-12'><TaskCell
        taskNumber={params.taskNumber}
        isPanel
      /></div>
    ),
    minWidth: 500,
    defaultWidth: 500,
    maxWidth: 1000,
  },
  partChanges: {
    render: (params: { orderNumber: number, partNumber: string, partId: string }) => (
      <ChangeOrderChangesCell
        orderNumber={params.orderNumber}
        partNumber={params.partNumber}
        partId={params.partId}
        viewMode="unified"
      />
    ),
    minWidth: 500,
    defaultWidth: 500,
    maxWidth: 1200,
  },
  partChangesSplit: {
    render: (params: { orderNumber: number, partNumber: string, partId: string }) => (
      <ChangeOrderChangesCell
        orderNumber={params.orderNumber}
        partNumber={params.partNumber}
        partId={params.partId}
        viewMode="split"
      />
    ),
    minWidth: 1000,
    defaultWidth: 1000,
    maxWidth: 1600,
  }
  // Add more routes here as needed
} as const

export type RightPanelRouteName = keyof typeof rightPanelRoutes

export const PANEL_WIDTH = 500

type RightPanelContextType = {
  width: number
  setWidth: (width: number) => void
}

export const RightPanelContext = createContext<RightPanelContextType | undefined>(undefined)

export const RightPanelProvider = ({ children }: { children: ReactNode }) => {
  const [width, setWidth] = useState<number | null>(null)

  return (
    <RightPanelContext.Provider value={{
      width: width ?? PANEL_WIDTH,
      setWidth
    }}>
      {children}
    </RightPanelContext.Provider>
  )
}

export const useRightPanel = () => {
  const { search, pathname } = useLocation()
  const searchParams = new URLSearchParams(search)
  const panelParam = searchParams.get('rightPanel')
  const context = useContext(RightPanelContext)

  // we should through instead of using a default, but I can't for the life
  // of me figure out why this context would ever not be set since we guarentee
  // it in AppBodyCell
  // if (!context) {
  //   throw new Error('useRightPanel must be used within a RightPanelProvider')
  // }

  const { width, setWidth } = context || {
    width: PANEL_WIDTH,
    setWidth: () => {
      console.warn('Missed rightpanel setwidth')
    }
  }

  let routeName: RightPanelRouteName | undefined
  let params: any = undefined

  if (panelParam) {
    try {
      const parsed = JSON.parse(decodeURIComponent(panelParam))
      routeName = parsed.route
      params = parsed.params
    } catch (e) {
      console.error('Failed to parse rightPanel parameter:', e)
    }
  }

  const closePanel = () => {
    const searchParams = new URLSearchParams(search)
    searchParams.delete('rightPanel')
    const newUrl = searchParams.toString()
      ? `${pathname}?${searchParams.toString()}`
      : pathname
    navigate(newUrl)
  }

  return {
    width,
    setWidth,
    isOpen: Boolean(panelParam),
    routeName,
    params,
    closePanel,
    panelRoute: (route: RightPanelRouteName, params: any) => {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('rightPanel', encodeURIComponent(JSON.stringify({
        route,
        params
      })))
      return `${window.location.pathname}?${searchParams.toString()}`
    }
  }
}

const RightPanelContent = () => {
  const { routeName, params, setWidth, closePanel } = useRightPanel()
  const [isDragging, setIsDragging] = useState(false)
  const orgId = useParams().orgId!

  // Only proceed if we have a valid route
  if (!routeName || !rightPanelRoutes[routeName]) return null

  const route = rightPanelRoutes[routeName]
  const content = route.render(params)
  const minWidth = route.minWidth ?? 400
  const maxWidth = route.maxWidth ?? 1200



  const handleMouseDown = (e: MouseEvent) => {
    setIsDragging(true)
    window.dispatchEvent(new Event('rightpanel:dragstart'))
    e.preventDefault()
  }

  useEffect(() => {
    const handleMouseMove = (e: globalThis.MouseEvent) => {
      if (isDragging) {
        const newWidth = window.innerWidth - e.clientX
        setWidth(Math.max(minWidth, Math.min(maxWidth, newWidth)))
      }
    }

    const handleMouseUp = () => {
      setIsDragging(false)
      window.dispatchEvent(new Event('rightpanel:dragend'))
    }

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, minWidth, maxWidth])

  return (
    <>
      <div
        className={`absolute left-0 top-0 bottom-0 w-1 cursor-ew-resize ${
          isDragging ? 'bg-blue-500 opacity-50' : 'hover:bg-blue-500 hover:opacity-50'
        }`}
        onMouseDown={handleMouseDown}
      />
      <div className="flex gap-2 items-start p-4 bg-white">
        <button
          onClick={closePanel}
          className="hover:bg-gray-200 p-0.5 rounded"
          title="Close panel"
        >
          <ChevronDoubleRightIcon className="w-4 h-4 text-gray-500" />
        </button>
        {routeName && Object.hasOwn(routes, routeName) && <button
          onClick={() => navigate(routes[routeName as keyof typeof routes]({ orgId, ...params }))}
          className="hover:bg-gray-200 p-0.5 rounded"
          title="Open in full page"
        >
          <ArrowsPointingOutIcon className="w-4 h-4 text-gray-500" />
        </button>}
      </div>
      <div className='flex-1 overflow-y-auto'>
        {content}
      </div>
    </>
  )
}

const RightPanel = () => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const panelParam = searchParams.get('rightPanel')
  const [isVisible, setIsVisible] = useState(false)
  const { width, routeName, setWidth } = useRightPanel()

  const [isWidthTransitioning, setIsWidthTransitioning] = useState(false)

  useEffect(() => {
    if (panelParam) {
      requestAnimationFrame(() => setIsVisible(true))
    } else {
      setIsVisible(false)
    }
  }, [panelParam])

  // Update the width setting effect to use defaultWidth
  useEffect(() => {
    if (routeName) {
      setIsWidthTransitioning(true)
      const route = rightPanelRoutes[routeName]
      setWidth(route.defaultWidth)

      // Remove transition state after animation completes
      const timer = setTimeout(() => {
        setIsWidthTransitioning(false)
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [routeName])

  return createPortal(
    <div
      data-testid='right-panel'
      className={`fixed flex flex-col right-0 top-[64px] bottom-0 bg-white border-l border-gray-200 shadow-xl ${
        isVisible ? 'translate-x-0' : 'translate-x-full'
      }`}
      style={{
        width: width,
        zIndex: 50,
        transition: `${isWidthTransitioning ? 'width 200ms ease-in-out,' : ''} transform 300ms ease-in-out`
      }}
    >
      {routeName && rightPanelRoutes[routeName] && <RightPanelContent />}
    </div>,
    document.body
  )
}

export default RightPanel