import { MapperConfig } from './mapperConfigs'
import Mustache from 'mustache'
import { quantityUnits } from 'shared/types'
import type { ImportedPart } from "./3-standardizeBom"
import { AppContext } from '../appContext'
import {
  rValidPartNumber
} from 'shared/partNumbers'
import type { SelectedProject } from 'src/components/ProjectSelectCell'
import { UNCHANGED_FIELD } from './lib'
import keyBy from 'lodash.keyby'
import { findAncestors, findAncestorsByHierarchy } from 'api/src/lib/bom'

type Extra = {
  selectedProject: SelectedProject
  appContext: AppContext
}

// Does not escape and chars when they are read from rows
Mustache.escape = function(text) {return text;};

export type Invalid = {
  issues: { message: string }[]
  part: ImportedPart
  rowIndex: number
}

const validateBom: (config: MapperConfig, bom: ImportedPart[], extra: Extra) => Invalid[]
  = (config: MapperConfig, bom: ImportedPart[], extra: Extra) => {
  if (bom.length === 0) return []
  const categories = extra.appContext.partCategories
  const byHierarchy = keyBy(bom, 'hierarchy')
  const circularReferences = bom.flatMap(part => {
    const ancestors = findAncestorsByHierarchy(byHierarchy, part.hierarchy)
    const self = ancestors.filter(a => a.partNumber === part.partNumber)
    return self
  })

  // used for finding duplicate siblings
  let referenceCheck: Record<string, ImportedPart> = {}

  return bom.map((part, index) => {
    const issues = []
    if (isNaN(part.quantity)) {
      issues.push({
        message: 'Quantity is invalid'
      })
    }
    if (!quantityUnits.includes(part.units)) {
      issues.push({
        message: `Quantity unit: "${part.units}" is invalid, must be one of ${quantityUnits.map(q => `"${q}"`).join(', ')}`
      })
    }
    if (part.categoryId === '') {
      issues.push({
        message: `Part category ID is empty`
      })
    } else if (part.categoryId !== UNCHANGED_FIELD && !categories.some(c => c.id === part.categoryId)) {
      issues.push({
        message: `Part category with ID: ${part.categoryId} does not exist`
      })
    }

    if (part.partNumber === '') {
      issues.push({
        message: `Part number is empty`
      })
    } else if (!rValidPartNumber.test(part.partNumber)) {
      issues.push({
        message: `Part number is invalid`
      })
    }
    // TODO also test for the schema

    if (part.hierarchy === '') {
      issues.push({
        message: `Hierarchy is empty`
      })
    } else if (!/^(0|[1-9]\d*)(\.(0|[1-9]\d*))*$/.test(part.hierarchy)) {
      issues.push({
        message: `Hierarchy "${part.hierarchy}" is invalid`
      })
    }

    if (part.name === '') {
      issues.push({
        message: `Name is empty`
      })
    }

    const checkKey = [
      ...part.hierarchy.split('.').slice(0, -1),
      part.partNumber
    ].join('.')

    const duplicateSibling = referenceCheck[checkKey]
    if (duplicateSibling) {
      issues.push({
        message: `Duplicate part ${part.partNumber} in assembly (Already referenced on row #${duplicateSibling.__metadata.originalIndex})`
      })
    }
    referenceCheck = {
      ...referenceCheck,
      [checkKey]: part
    }

    const cReferences = circularReferences.filter(r => r.partNumber === part.partNumber)
    if (cReferences.length) {
      issues.push({
        message: 'Has circular references'
      })
    }

    return {
      part,
      issues,
      rowIndex: part.__metadata.originalIndex ?? index
    }
  }).filter(i => i.issues.length > 0)
}

export default validateBom
