import OrgSettingsUsersCell from "src/components/OrgSettingsUsersCell"
import { MetaTags } from '@redwoodjs/web'

const OrgSettingsUsersPage = () => {
  return (
    <>
      <MetaTags title="User Management" description="Organization User Management" />
      <OrgSettingsUsersCell/>
    </>
  )
}

export default OrgSettingsUsersPage
