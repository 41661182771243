import { useLocation, Redirect } from '@redwoodjs/router'
import { useAuth } from '../../auth'
import LoadingSpinner from 'src/components/Loading'
import { useClerk } from '@clerk/clerk-react'
import Button from 'src/components/Button'
import { AuthPageContainer } from 'src/components/auth/Common'
import CustomSignup from 'src/components/auth/Signup'
import CustomLogin from 'src/components/auth/Login'

const HomePage = () => {
  const { isAuthenticated, loading, error, logOut } = useAuth()
  const { loaded } = useClerk()
  const { pathname, search } = useLocation()
  const redirect = (new URLSearchParams(search)).get("redirectTo")

  const body = () => {
    if (loading || !loaded) return <LoadingSpinner />
    if (error) return <div className='flex gap-6 flex-col w-80 bg-white p-6 rounded-md'>
      <div className='text-lg font-medium text-gray-700'>Authentication Error</div>
      <div className='text-sm'>There was an error validating your user account, contact <a className='text-blue-600' href='mailto:support@bomello.com'>support@bomello.com</a> if the issue persists.</div>
      <Button onClick={() => logOut()}>Try Again</Button>
    </div>
    if (isAuthenticated) return <Redirect to={redirect || '/'} />
    return pathname === '/signup' ?
      <CustomSignup redirectUrl={redirect} key='signup'/> :
      <CustomLogin redirectUrl={redirect} key='signin'/>
  }

  return (
    <AuthPageContainer>
      {body()}
    </AuthPageContainer>
  )
}

export default HomePage
