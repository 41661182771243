import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { ComponentType } from 'react'

/**
 * Example usage:
 *
 * 1. As a Higher Order Component (HOC):
 * ```
 * const MyComponent = ({ data }) => {
 *   return <div>{data.map(item => <div key={item.id}>{item.name}</div>)}</div>
 * }
 *
 * export default withErrorBoundary(MyComponent)
 * ```
 *
 * 2. As a Component:
 * ```
 * const MyComponent = () => {
 *   return (
 *     <ErrorBoundary>
 *       <YourComponent />
 *     </ErrorBoundary>
 *   )
 * }
 * ```
 */

type ErrorFallbackProps = {
  error: Error
  eventId: string
}

const DefaultErrorFallback = ({ error, eventId }: ErrorFallbackProps) => {
  return (
    <div className='bg-red-100 border border-red-200 rounded-md p-4'>
      <div className='text-lg font-medium text-red-900 mb-2'>
        An error occurred
      </div>
      <div className='text-sm text-red-700'>
        Contact support with error ID: <span className='font-mono font-medium'>{eventId}</span>
      </div>
      {process.env.NODE_ENV === 'development' && (
        <div className='mt-2 text-sm text-red-600 font-mono'>
          {error.message}
        </div>
      )}
    </div>
  )
}

type ErrorBoundaryProps = {
  children: React.ReactNode
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return (
    <SentryErrorBoundary
      fallback={(props) => <DefaultErrorFallback {...props} />}
    >
      {children}
    </SentryErrorBoundary>
  )
}

export const withErrorBoundary = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  return function WithErrorBoundaryWrapper(props: P) {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    )
  }
}