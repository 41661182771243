import { useState, useReducer, useRef } from 'react';
import keyBy from 'lodash.keyby'
import uniqBy from 'lodash.uniqby';

import type {
  PartQuery,
  PartQueryVariables,
  Project,
  User,
  ChangeOrderLog,
  AddToChangeOrderMutation,
  AddToChangeOrderMutationVariables,
} from 'types/graphql'
import { DocumentIcon, TagIcon } from '@heroicons/react/24/outline'
import { ArrowTopRightOnSquareIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { type ChangeOrderLogPayload } from 'shared/types'
import { CellSuccessProps, useMutation } from '@redwoodjs/web'

import { Sources } from '../Sources';
import { reportMutationError } from 'src/lib/reportError'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import ChangeOrderSelectCell from 'src/components/ChangeOrderSelectCell'
import { routes, navigate, useParams, Link } from '@redwoodjs/router';
import { prettyDate } from 'src/lib/formatters'
import * as ListBox from '../ListBox';
import { Overview } from '../PartOverview/PartOverview';
import { Artifacts, getThumbnail, isViewable } from '../Artifacts/Artifacts'
import { useHierarchyControllerFactory, HierarchyMode, addVersionWarnings, RootInput } from '../PartHierarchy/PartHierarchy';
import WhereUsed from './WhereUsed';
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import PartSettingsDialog from './PartSettings'
import { ConditionalModal } from '../Modal';
import CreatePartCell from '../CreatePartCell';
import { getMapper } from 'src/lib/mapping'

import { StatusDot } from '../LifecycleStatus/LifecycleStatus';

import FullScreenHierarchy from './FullScreenHierarchy'
import GenericFailure from '../Failure/Failure'

// to guarentee fragments
import 'src/lib/queries'
import Thumbnail from '../ThumbnailViewer';
import { MetadataPanel } from 'src/components/Metadata'
import { buildGenericHierarchyTree, getInstanceQuantity } from './aggregation';
import { block } from 'api/src/shared/functional';
import invariant from 'tiny-invariant';


export const QUERY = gql`
  query PartQuery($partNumber: String!, $version: String, $orderNumber: Int) {
    partProto(partNumber: $partNumber) {
      id
      project {
        id
        name
        link
        defaultMapper
      }
      category {
        id
        name
        label
      }
      inChangeOrders {
        number
        name
        state
        appliedAt
      }
      partNumber
      currentVersion {
        version
        publishId
        lifeCycle
      }
      owner {
        id
        name
      }
      categoryId
      protoUpdatedBy {
        name
      }
      instance(version: $version, branch: $orderNumber) {
        id
        name
        cadRev
        branch
        sources {
          id
          url
          comment
          distributorId
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          perQuantity
          perQuantityUnit
          stock
        }
        summary
        version
        lifeCycle
        isRoot
        isOffTheShelf
        partNumber
        tree {
          ...PartTreeNodeFragment
        }
        inChangeOrder {
          name
          state
        }
        metadata
        integrationType
        documentType
        externalDocRef
        artifacts {
          id
          filename
          fileId
          isThumbnail
          file {
            confirmed
            path
            url
            inlineUrl
            contentType
            size
            timestamp
          }
        }
        dependencies {
          section
          to {
            id
            proto {
              id
              partNumber
              currentVersionString
            }
            branch
            partNumber
            name
            version
          }
          toVersionRange
          quantity
          units
          referenceDesignator
        }
      }
      forkFrom {
        partNumber
        version
      }
      instances {
        id
        publishId
        version
        branch
        inChangeOrder {
          name
          state
        }
      }
    }
  }
`

export const ADD_TO_CHANGE_ORDER_MUTATION = gql`
mutation AddToChangeOrderMutation ($changeOrderNumber: Int!, $input: [PartDeltaInput!]!) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, allowDuplicates: true, input: $input) {
    partNumber
  }
}
`

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center' />

export const Empty = () => <div>Empty</div>

export const Failure = GenericFailure

// Add interface for Success component props
interface SuccessProps extends CellSuccessProps<PartQuery, PartQueryVariables> {
  isPanel?: boolean
}

// Update Success component to include isPanel prop
export const Success = ({ isPanel = false, ...props }: SuccessProps) => {
  return <Part isPanel={isPanel} {...props} />
}

// Update Part component interface
interface PartProps extends CellSuccessProps<PartQuery, PartQueryVariables> {
  isPanel?: boolean
}

const Part = ({
  partProto,
  isPanel = false,
}: PartProps) => {
  if (!partProto) throw new Error('No part found')
  const { partNumber, instances, instance, inChangeOrders, owner, currentVersion } = partProto;

  const params = useParams()
  const orgId = params.orgId!

  const [partSettingsOpen, setPartSettingsOpen] = useState(false)
  const [forkPartOpen, setForkPartOpen] = useState(false)
  const handleMoreSelect = (option: 'PartSettings' | 'PartCompare' | 'Fork') => {
    if (option === 'PartSettings') {
      setPartSettingsOpen(true)
      setForkPartOpen(false)
    }
    else if (option === 'PartCompare') {
      navigate(
        routes.partCompare({
          basePartNumber: partNumber,
          incomingPartNumber: partNumber,
          baseVersion: instance.version,
          orgId,
          incomingVersion: currentVersion!.version!
        })
      )
    }
    else if (option === 'Fork') {
      setPartSettingsOpen(false)
      setForkPartOpen(true)
    }
  }

  const forkPrefix = '__fork__' as const;
  const forkSeparator = '~~~' as const;
  const handleVersionSelection = (newVersion: string) => {
    if (newVersion === '__full__') {
      navigate(routes.partHistory({ partNumber, orgId }))
    }
    else if (newVersion.startsWith('__co__::')) {
      const orderNumber = newVersion.split('::')[1]!
      navigate(routes.partWithBranch({ partNumber, orderNumber, orgId }))
    }
    else if (newVersion.startsWith(forkPrefix)) {
      const [partNumber, version] = newVersion.substring(forkPrefix.length).split(forkSeparator) as [string, string]
      navigate(routes.partWithVersion({ partNumber, version, orgId }))
    }
    else if (newVersion === instance.version) {
      navigate(routes.part({ partNumber, orgId }))
    }
    else {
      navigate(routes.partWithVersion({ partNumber, version: newVersion, orgId }))
    }
  }

  const versionSelectValue = instance.branch === -1 ? `Version ${instance.version}` : `Change Order #${instance.branch} ${instance.inChangeOrder?.name}`
  const instanceToVersionValue = (i: typeof instance) => {
    if (i.branch !== -1) return `__co__::${i.branch}`
    return i.version
  }

  const liveInstances = instances.filter(p => {
    return p.branch === -1 || p.inChangeOrder?.state === 'Draft' || p.inChangeOrder?.state === 'Review'
  })
  liveInstances.sort((a, b) => {
    const aPublished = typeof a.publishId === 'number'
    const bPublished = typeof b.publishId === 'number'
    if (aPublished && !bPublished) return 1
    if (!aPublished && bPublished) return -1
    if (!aPublished && !bPublished) return b.branch - a.branch
    return (b.publishId as number) - (a.publishId as number)
  })


  const appliedChangeOrders = inChangeOrders.filter(c => c.state === 'Complete')
  const lastPublish = appliedChangeOrders.reduce<{appliedAt: string | null, orderNumber: number, orderName: string}>((latest, co) => {
    if (!latest.appliedAt || (co.appliedAt && co.appliedAt > latest.appliedAt)) {
      return {
        appliedAt: co.appliedAt ?? null,
        orderNumber: co.number,
        orderName: co.name
      }
    }
    return latest
  }, { appliedAt: null, orderNumber: 0, orderName: '' })

  const lastPublishMessage = () => {
    if (!lastPublish.appliedAt) {
      return <>Part not published since initial import</>
    }

    const date = prettyDate(new Date(lastPublish.appliedAt))
    return <>{date} in <a className='text-sm text-blue-600 underline' href={routes.changeOrderTab({ orgId, orderNumber: lastPublish.orderNumber, tab: 'changes' })}>
      #{lastPublish.orderNumber} {lastPublish.orderName}
    </a></>
  }

  const openChangeOrders = inChangeOrders.filter(c => c.state !== 'Complete' && c.state !== 'Cancelled')
  const changeOrdersList = openChangeOrders.length === 0 ?
    <div className='text-sm text-gray-800'>This part is not in any change orders</div> :
    <>{openChangeOrders.map(c => {
      return <a key={c.number} className='text-sm text-blue-600 underline' href={routes.changeOrderTab({ orgId, orderNumber: c.number, tab: 'changes' })}>
        #CO-{c.number} {c.name}
      </a>
    })}</>

  const [addToChangeOrderMutation] = useMutation<AddToChangeOrderMutation, AddToChangeOrderMutationVariables>(ADD_TO_CHANGE_ORDER_MUTATION)

  interface ChangeOrderWithNumber {
    number: number
  }
  const addToChangeOrder = async (c: ChangeOrderWithNumber, input: AddToChangeOrderMutationVariables['input']) => {
    const variables: AddToChangeOrderMutationVariables = {
      changeOrderNumber: c.number,
      input
    }
    const { errors } = await addToChangeOrderMutation({
      variables: variables,
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error adding part to change order`
      })
      return
    }
  }
  const handleAddToChangeOrder = async (c: ChangeOrderWithNumber) => {
    await addToChangeOrder(c, [{
      type: 'Push',
      partNumber,
      version: calculateNextVersion(currentVersion!.version!),
    }])

    navigate(`${routes.changeOrderTab({ orgId, orderNumber: c.number, tab: 'changes' })}#part-change-${partNumber}`)
  }


  const handleCreateChangeOrder = () => {
    navigate(routes.changeOrderCreate({ orgId, withPartNumber: partNumber }))
  }

  const subjectParts = addVersionWarnings(instance.tree)

  const materialsParam = new URLSearchParams(location.search).has('materials')
  const [_, rerender] = useReducer(x => x + 1, 0)

  const setMaterialsParam = (v: string | null) => {
    const searchParams = new URLSearchParams(location.search)
    if (v) { searchParams.set('materials', v) }
    else { searchParams.delete('materials') }
    history.replaceState(null, "", searchParams.size ? `${location.pathname}?${searchParams.toString()}` : location.pathname)
    rerender()
  }

  const fullScreen = Boolean(materialsParam)

  const [hierarchyMode, setHierarchyMode] = useState<HierarchyMode>('hierarchy')

  const treeRootNode = instance.tree.find(n => n.isTreeRoot)
  invariant(treeRootNode)
  const roots = hierarchyMode === 'hierarchy' ? [{
    tree: instance.tree,
    subjectParts,
    initiallyExpandedNodes: [treeRootNode.partNumberAddress]
  }] : block(() => {
    const tree = buildGenericHierarchyTree(instance.tree[0]!, instance.tree)
    const treeRoot = tree.value.part
    const flattened = uniqBy(instance.tree, n => `${n.part.partNumber}:::${n.part.version}`)

    return flattened.map(({part}) => {
      const wasRoot = part.partNumber === treeRoot.partNumber && part.version === treeRoot.version
      const { quantity: [qMin, qMax], units, error } = getInstanceQuantity(tree, part.partNumber, part.version)
      return {
        tree: [{
          address: '',
          partNumberAddress: '',
          parentToNodeDependency: {
            section: 'Manual',
            toVersionRange: '*',
            units: error ? '' : units,
            quantity: error ?
              'Error' :
              (wasRoot ? 1 : (qMin === qMax ? qMin : `${qMin}-${qMax}`))
          },
          part,
          hierarchy: '1',
          isTreeRoot: true,
        }],
        initiallyExpandedNodes: []
      } as RootInput
    })
  })

  const hierarchyController = useHierarchyControllerFactory({
    hierarchyMode,
    onHierarchyModeClick(mode) {
      setHierarchyMode(mode)
    },
    onToolbarModeChange(mode) {
      if (mode === 'agg') {
        if (isPanel) {
          navigate(routes.part({
            partNumber,
            orgId,
            materials: 'true',
            ...(instance.branch !== -1 ? { orderNumber: instance.branch } : {}),
            ...(instance.branch === -1 ? { version: instance.version } : {})
          }))
        } else {
          setMaterialsParam('true')
        }
      }
    },
    onToggleFullscreen() {
      if (isPanel) {
        navigate(routes.part({
          partNumber,
          orgId,
          materials: 'true',
          ...(instance.branch !== -1 ? { orderNumber: instance.branch } : {}),
          ...(instance.branch === -1 ? { version: instance.version } : {})
        }))
      } else {
        if (materialsParam) return setMaterialsParam(null)
        setMaterialsParam('true')
      }
    },
    showAggregations: fullScreen && hierarchyMode === 'hierarchy',
    fullScreen,
    roots,
    useUrlForFilters: !isPanel
  })

  return (<>
    {forkPartOpen &&
      <ConditionalModal className='w-fit mt-10' onClose={() => setForkPartOpen(false)}>
        <CreatePartCell variant='fork' forkPart={partProto} onComplete={() => setForkPartOpen(false)} />
      </ConditionalModal>
    }
    {partSettingsOpen &&
      <PartSettingsDialog open={partSettingsOpen} onClose={() => setPartSettingsOpen(false)} partProto={partProto} refetchQueries={[QUERY]} />
    }
    <div className={`flex flex-col gap-2`} data-testid='part'>
      <div className='flex flex-col'>
        {/* Header */}
        <div className={`px-2 py-6 border-b border-gray-300 rounded-t-lg flex flex-col gap-5 text-sm`}>
          <div className='grow flex flex-col gap-2'>
            <div className='flex flex-row gap-2 items-center'>
              <TagIcon className='h-3 w-3' /><div>{partProto.category.name}</div>
            </div>
            <div className='flex flex-row items-center gap-2'>
              <div>
                <StatusDot lifeCycle={instance.lifeCycle} />
              </div>
              <div className='text-xl flex-1 text-gray-900'><span className='font-semibold'>#{partNumber}</span> {instance.name}</div>
            </div>
          </div>
          <div className='flex gap-6'>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Owner
              </div>
              <div className='text-sm text-gray-800'>
                {owner.name}
              </div>
            </div>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Last Published
              </div>
              <div className='text-sm text-gray-800'>
                {lastPublishMessage()}
              </div>
            </div>
            <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
              <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                Change Orders
              </div>
              {changeOrdersList}
            </div>
            {partProto.forkFrom &&
              <div className='flex flex-col gap-0.5 pr-5 max-w-sm'>
                <div className='flex items-center gap-1 text-sm font-semibold text-gray-700'>
                  Forked From
                </div>
                <div className='text-sm text-gray-800'>
                  <Link to={routes.partWithVersion({ partNumber: partProto.forkFrom.partNumber, version: partProto.forkFrom.version, orgId })} className='underline text-blue-700 font-sans'>
                    #{partProto.forkFrom.partNumber} v{partProto.forkFrom.version}
                  </Link>
                </div>
              </div>
            }
          </div>
          <div className='flex items-center gap-3 -mx-2'>
            <ListBox.ListBox value={instanceToVersionValue(instance)} onChange={handleVersionSelection}>
              {({ open }) => (<>
                <div className="relative">
                  <ListBox.Button className='' displayValue={versionSelectValue} />
                  <ListBox.Options align='left' open={open} className='text-xs'>
                    <div className='border-b border-gray-200'>
                      {liveInstances.map(i =>
                        {
                          const value = instanceToVersionValue(i)
                          if (i.branch === -1) return <ListBox.Option value={value} display={`Version ${i.version}`} key={value} />
                          return <ListBox.Option value={value} display={`Change Order #${i.branch} ${i.inChangeOrder?.name}`} key={value} />
                        }
                      )}
                    </div>
                    <ListBox.Option value={'__full__'} display={`View Full History`} key={'__full__'} />
                  </ListBox.Options>
                </div>
              </>
              )}
            </ListBox.ListBox>
            <ChangeOrderSelectCell
              disabledChangeOrders={inChangeOrders.map(o => o.number)}
              className=''
              placeholder='Add to Change Order'
              onCreate={handleCreateChangeOrder}
              onSelect={handleAddToChangeOrder} />
            <ListBox.ListBox value='' onChange={handleMoreSelect}>
              {({ open }) => (<>
                <div className="relative">
                  <ListBox.Button displayValue={`More`} />
                  <ListBox.Options align='left' open={open} className='text-xs'>
                    <ListBox.Option value='PartSettings' display={`Part Settings`} key={'PartSettings'} />
                    <ListBox.Option value='PartCompare' display={`Compare`} key={'PartCompare'} />
                    <ListBox.Option value='Fork' display={`Fork`} key={'Fork'} />
                  </ListBox.Options>
                </div>
              </>
              )}
            </ListBox.ListBox>
          </div>
        </div>
        {/* Body */}
        <div className='flex flex-col gap-10 px-2 pt-6 pb-10'>
          <div className='flex gap-2'>
            <div className='w-1/2'>
              <div className='text-md text-gray-900 font-medium h-10'>Overview</div>
              <Overview part={instance} compact/>
            </div>
            <Thumbnail artifact={getThumbnail(instance.artifacts)} className='w-96 h-80 p-3 my-3 ml-auto'/>
          </div>
          <div>
            <MetadataPanel
              bigTitle
              part={instance}
              edit={instance.branch !== -1 ? {
                partNumber,
                changeOrderNumber: instance.branch
              } : undefined}
            />
          </div>
          {partProto.project ? <ProjectSection orgId={orgId} project={partProto.project} /> : null}
          <div>
            <div className='text-md text-gray-900 font-medium mb-4'>Artifacts</div>
            <Artifacts partNumber={partNumber} part={instance} />
          </div>
          {
            instance.dependencies.length ?
              <div className='flex flex-col gap-6'>
                <div className='flex gap-2 flex-col'>
                  <div className='text-md flex-1 text-gray-900 font-medium'>Materials</div>
                    <FullScreenHierarchy
                      flatExportOption={hierarchyMode === 'hierarchy'}
                      hierarchyController={hierarchyController}
                    />
                </div>
              </div>
              : null}
          {instance.sources.length ? <Sources compact={isPanel} sources={instance.sources} /> : null}
          <div>
            <div className='text-md text-gray-900 font-medium mb-4'>
              Where Used
            </div>
            <div className='mb-4 -mx-1'>
              <WhereUsed highlightedPartNumber={instance.partNumber} />
            </div>
          </div>

        </div>
      </div>
    </div>
  </>
  )
}

type ProjectProps = {
  project: Pick<Project, 'id' | 'name' | 'link' | 'defaultMapper'>
  orgId: string
}
const ProjectSection: React.FC<ProjectProps> = ({
  project,
  orgId
}) => {
  const mapper = getMapper(orgId, project.defaultMapper)
  const Logo = mapper?.Logo || DocumentIcon
  const fileBox = <div className='-ml-1 relative border border-gray-300 rounded-xl items-center px-8 py-6 inline-flex gap-4 flex-col'>
    <div className='w-28 h-12 flex items-center justify-center'>
      <Logo className='max-h-12 max-w-28' />
    </div>
    <div className='text-gray-700 flex gap-3 items-center'>
      {project.name}
    </div>
    {project.link ?
      <ArrowTopRightOnSquareIcon className='absolute right-2 top-2 h-5 w-5' /> : null
    }
  </div>
  const fileButton = project.link ?
    <a target='_blank' href={project.link}>{fileBox}</a> :
    <div>{fileBox}</div>
  return <div className='flex-1'>
    <div className='text-md text-gray-900 font-medium mb-4'>Project</div>
    <div className='text-sm text-gray-700 mb-4'>
      This part is a project root assembly
    </div>
    {fileButton}
  </div>
}
