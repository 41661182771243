
import {
  Part,
  PartProto,
} from 'types/graphql'

import { useState } from 'react'
import classNames from 'classnames'
import { usePartsCache } from 'src/lib/usePartsCache'
import { inputClasses } from 'src/components/Input'
import { optionsClasses, ComboboxOption, ComboboxOptionV2, ComboboxOptions, ComboboxOptionsV2, ComboboxButton, ComboboxButtonV2 } from 'src/components/ListBox'

import { Combobox, Transition } from '@headlessui/react'
import { Combobox as ComboboxV2, ComboboxInput as ComboboxInputV2 } from 'headlessuinext'


export type PartDetail = Pick<Part, 'partNumber' | 'version' | 'name' | 'isRoot'> & {
  proto: Pick<PartProto, 'currentVersionString'>
}

type PartSelectProps = {
  className?: string
  disabled?: boolean
  placeholder?: string
  value?: string
  sort?: (a: PartDetail, b: PartDetail) => number
  defaultValue?: string
  omit?: string[]
  extraParts?: PartDetail[]
  omitTopLevel?: boolean
  onSelect: (part: PartDetail) => void
  useHeadlessV2?: boolean
}

const PartSelect = ({
  disabled,
  sort,
  className,
  omit,
  placeholder,
  onSelect,
  value,
  extraParts,
  omitTopLevel,
  useHeadlessV2,
  defaultValue, }: PartSelectProps) => {


  const { data } = usePartsCache()
  const partProtos = data?.protos

  const [query, setQuery] = useState('')
  const [opened, setOpened] = useState(false)
  extraParts = extraParts || []
  const mainBranchParts = partProtos?.filter(p => {
    if (!p.currentVersion) return false
    if (extraParts?.some(extra => extra.partNumber === p.partNumber)) return false
    return true
  }) || []
  const mainParts: PartDetail[] = mainBranchParts.map(proto => {
    return {
      partNumber: proto.partNumber,
      version: proto.currentVersion.version,
      name: proto.currentVersion.name,
      isRoot: proto.currentVersion.isRoot,
      proto
    }
  })

  const allParts = [...extraParts, ...mainParts]

  const insensitiveQuery = query.toLowerCase()
  const filteredParts = allParts.filter(p => {
    if (omit && omit.includes(p.partNumber)) return false
    if (omitTopLevel && p.isRoot) return false

    return p.partNumber.toLowerCase().includes(insensitiveQuery) ||
      p.name?.toLowerCase().includes(insensitiveQuery)
  })

  if (sort) {
    filteredParts.sort(sort)
  }

  const chosenPart = value ? allParts.find(p => p.partNumber === value) : undefined
  const defaultPart = defaultValue ? allParts.find(p => p.partNumber === defaultValue) : undefined
  const handleSelect = (p: PartDetail) => {
    onSelect(p)
  }

  //const T = useHeadlessV2 ? TransitionV2 : Transition
  // I'm not sure what is going wrong here, it is not getting the ref or something in the V2 version
  const T = useHeadlessV2 ? React.Fragment : Transition

  const CBox = useHeadlessV2 ? ComboboxV2 : Combobox
  const CInput = useHeadlessV2 ? ComboboxInputV2 : Combobox.Input
  const CButton = useHeadlessV2 ? ComboboxButtonV2 : ComboboxButton;
  const COptions = useHeadlessV2 ? ComboboxOptionsV2 : ComboboxOptions
  const COption = useHeadlessV2 ? ComboboxOptionV2 : ComboboxOption

  const oClasses = classNames(
    optionsClasses,
    'left-0',
    useHeadlessV2 && 'w-96 text-xs'
  )

  return (
    <div className={`relative ${className || ''}`}>
      <CBox as='div' defaultValue={defaultPart} value={chosenPart} disabled={disabled} onChange={handleSelect}>
        <CInput
          className={inputClasses}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(part: PartDetail) => {
            return opened ? query : (chosenPart?.partNumber || '')
          }}
          placeholder={placeholder || 'Type to select part'} />
        <CButton />
        <T
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          beforeEnter={() => {
            setOpened(true)
          }}
          // afterEnter={() => setQuery('b')}
          afterLeave={() => {
            setQuery('')
            setOpened(false)
          }}
        >
          <COptions anchor={{
            to: 'bottom start',
            gap: '4px'
          }} align='left' className={oClasses}>
            {filteredParts.map((part) => (
              <COption key={part.partNumber} value={part} className='max-w-lg'>
                <div className='flex flex-col text-xs' data-testid={`part-select-option-${part.partNumber}`}>
                  <div className='font-semibold'>
                    {part.partNumber}
                  </div>
                  <div>
                    {part.name}
                  </div>
                </div>
              </COption>
            ))}
          </COptions>
        </T>
      </CBox>
    </div>
  )
}

export default PartSelect
