import { useLocation, Redirect } from '@redwoodjs/router'
import { useAuth } from '../../auth'
import LoadingSpinner from 'src/components/Loading'
import ForgotPassword from 'src/components/auth/ForgotPassword'
import { AuthPageContainer } from 'src/components/auth/Common'

const ForgotPasswordPage = () => {
  const { isAuthenticated, loading } = useAuth()
  const { search } = useLocation()
  const redirect = (new URLSearchParams(search)).get("redirectTo")

  if (loading) {
    return (
      <AuthPageContainer>
        <LoadingSpinner />
      </AuthPageContainer>
    )
  }

  if (isAuthenticated) {
    return <Redirect to={redirect || '/'} />
  }

  return (
    <AuthPageContainer>
      <ForgotPassword redirectUrl={redirect} />
    </AuthPageContainer>
  )
}

export default ForgotPasswordPage

