import Sentry from 'src/lib/sentry'
import { RedwoodProvider } from '@redwoodjs/web'
import possibleTypes from 'src/graphql/possibleTypes'
import { RedwoodApolloProvider, GraphQLClientConfigProp } from '@redwoodjs/web/apollo'
import { ApolloLink } from "@apollo/client"
import { Theme } from '@radix-ui/themes'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'

import './index.css'
import { useState, useEffect } from 'react'
import { createNetworkStatusNotifier, NetworkStatus } from 'react-apollo-network-status'
import { isTest } from './lib/mode'
import React from 'react'
import { createPortal } from 'react-dom'


let notifierLink: ApolloLink, useApolloNetworkStatus: () => NetworkStatus;
let networkStatus: NetworkStatus | undefined = undefined
let useNetworkIdle: () => 'idle' | 'loading'
if (isTest) {
  const notifier = createNetworkStatusNotifier()
  notifierLink = notifier.link;
  useApolloNetworkStatus = notifier.useApolloNetworkStatus;

  const currentlyIdle = (status: NetworkStatus) => status.numPendingQueries === 0 && status.numPendingMutations === 0

  let wasIdle = true;
  const networkIdle = () => {
    const isIdle = !networkStatus || currentlyIdle(networkStatus)
    const restingIdle = wasIdle && isIdle

    wasIdle = isIdle
    return restingIdle
  }
  //@ts-ignore
  window.networkIdle = networkIdle
  useNetworkIdle = () => {
    const [idleCount, setIdleCount] = useState(0)
    const status = useApolloNetworkStatus()

    useEffect(() => {
      if (currentlyIdle(status) && idleCount < 1) {
        setIdleCount(1)
      }
      else {
        setIdleCount(0)
      }
    }, [currentlyIdle(status)])

    return idleCount > 0 ? 'idle' : 'loading'
  }
}


const App = () => {
  const clientConfig: GraphQLClientConfigProp = {
    cacheConfig: {
      possibleTypes: possibleTypes.possibleTypes,
      typePolicies: {
        ChangeOrder: {
          fields: {
            includedParts: {
              merge(existing, incoming) {
                return incoming
              }
            },
            baseOrphans: {
              merge(existing, incoming) {
                return incoming
              }
            }
          }
        }
      }
    },
    connectToDevTools: true,
    link: (links) => {
      const orgId = location.pathname.match(/\/([^/]*)/)?.[1]
      const orgContextLink = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            'bomello-org-id': orgId,
          }
        }));
        return forward(operation)
      });

      return ApolloLink.from([orgContextLink, ...(notifierLink ? [notifierLink] : []), ...links.map(({ link }) => link)])
    }
  }
  return <Theme accentColor="yellow" className='themeContainer'>
    <Sentry.ErrorBoundary fallback={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider
            useAuth={useAuth}
            graphQLClientConfig={clientConfig}
          >
            <NetworkReader>
              <Routes key='stable' />
              <div id="mainPortal" key='stable'></div>
            </NetworkReader>
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </Sentry.ErrorBoundary>
  </Theme>
}

export default App

const ScreenCover = () =>
  createPortal(
    <div className='relative !fixed inset-0 bg-white z-[1000]'></div>,
    document.body
  )

const NetworkReader = (props: any) => {
  if (isTest) {
    networkStatus = useApolloNetworkStatus();
    const idleStatus = useNetworkIdle()
    return <>
      <div key='test-body' data-testid={`status-${idleStatus}`} className={idleStatus === 'loading' ? 'hidden' : ''}>
        {props.children}
        {idleStatus === 'loading' && <>
          <ScreenCover/>
          <div className='h-[10000px]'/>
        </>}
      </div>
    </>
  }
  return props.children
}
