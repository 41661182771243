import { useEffect, useState } from 'react'
import Button from 'src/components/Button'
import Excel from 'exceljs'
import type { BomRowMetadata, BomRow } from 'src/lib/mapping/1-fileToRows'
import snakeCase from 'lodash.snakecase'
import type { ExtractRootConfig, MapperConfig } from 'src/lib/mapping/mapperConfigs'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useFileUploaderSimple } from 'src/lib/hooks/useFileUploaderSimple'

declare global {
  interface Window {
    gapi: any
    google: any
  }
}

type GoogleToken = {
  access_token: string
  expires_in: number
  expires_at: number
  scope: string
  token_type: string
}

type GoogleDriveXlsxImportCellProps = {
  mapper: MapperConfig
  onLoading: (loading: boolean) => void
  onBomChange: (bom: BomRow[] | null) => void
}

type SelectedSheet = {
  id: string
  name: string
  iconUrl: string
}

const SCOPES = 'https://www.googleapis.com/auth/drive.readonly'
const TOKEN_STORAGE_KEY = 'google_drive_token'

type HyperlinkValue = string | { target: string }

const getHyperlinkTarget = (hyperlink: unknown): string | undefined => {
  if (typeof hyperlink === 'string') {
    return hyperlink
  }
  if (hyperlink && typeof hyperlink === 'object' && 'target' in hyperlink) {
    return (hyperlink as { target: string }).target
  }
  return undefined
}

// Add helper function for root extraction
const extractRootFields = (
  worksheet: Excel.Worksheet,
  config: ExtractRootConfig
): BomRow => {
  const [startRow, endRow] = config.inRowRange
  const extractedFields: Record<string, string> = {
    __isRoot: 'true'
  }

  // For each row in the range
  for (let row = startRow; row <= endRow; row++) {
    const rowData = worksheet.getRow(row + 1)
    if (!rowData) continue

    // For each column, look for matching text
    rowData.eachCell((cell, col) => {
      if (!cell.value) return
      const cellText = cell.value.toString().trim()
      if (!cellText) return

      // Check if this cell matches any of our search fields
      for (const field of config.fields) {
        if (field.extractionType === 'cellRightOfTextIncluding' &&
            cellText.includes(field.input)) {
          // Get the value from the cell to the right
          const rightCell = rowData.getCell(col + 1)
          if (rightCell?.value) {
            extractedFields[field.outputFieldName] = rightCell.value.toString().trim()
          }
        }
      }
    })
  }

  return {
    ...extractedFields,
    __metadata: {
      originalIndex: 0,
      messages: []
    }
  } as BomRow
}

// Add helper to convert base64 to File
const base64ToFile = (base64: string, filename: string): File => {
  const arr = base64.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const GoogleDriveXlsxImportCell = ({ mapper, onLoading, onBomChange }: GoogleDriveXlsxImportCellProps) => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [tokenClient, setTokenClient] = useState<any>(null)
  const [tokenError, setTokenError] = useState<string | null>(null)
  const [selectedSheet, setSelectedSheet] = useState<SelectedSheet | null>(null)
  const [gapiInitialized, setGapiInitialized] = useState(false)

  const { upload } = useFileUploaderSimple()

  useEffect(() => {
    // Load the Google API client library
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/api.js'
    script.onload = () => {
      window.gapi.load('client:picker', initializeGapiClient)
    }
    document.body.appendChild(script)

    // Load the Google Identity Services library
    const scriptGsi = document.createElement('script')
    scriptGsi.src = 'https://accounts.google.com/gsi/client'
    scriptGsi.onload = initializeGsiClient
    document.body.appendChild(scriptGsi)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(scriptGsi)
    }
  }, [])

  // Separate useEffect for token checking after GAPI is initialized
  useEffect(() => {
    if (gapiInitialized) {
      checkExistingToken()
    }
  }, [gapiInitialized])

  const checkExistingToken = () => {
    const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY)
    if (storedToken && window.gapi?.client) {
      try {
        const token: GoogleToken = JSON.parse(storedToken)
        // Check if token is expired (with 5 minute buffer)
        if (token.expires_at > Date.now() + 5 * 60 * 1000) {
          window.gapi.client.setToken(token)
          setIsSignedIn(true)
          setTokenError(null)
        } else {
          // Token expired
          localStorage.removeItem(TOKEN_STORAGE_KEY)
          setTokenError('Token expired, please sign in again')
          setIsSignedIn(false)
        }
      } catch (err) {
        console.error('Error parsing stored token:', err)
        localStorage.removeItem(TOKEN_STORAGE_KEY)
      }
    }
  }

  const initializeGapiClient = async () => {
    try {
      await window.gapi.client.init({
        apiKey: process.env.GOOGLE_API_KEY,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
      })
      setGapiInitialized(true)
    } catch (err) {
      console.error('Error initializing GAPI client:', err)
      setTokenError('Failed to initialize Google API')
    }
  }

  const initializeGsiClient = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: process.env.GOOGLE_CLIENT_ID,
      scope: SCOPES,
      callback: handleTokenResponse,
      prompt: 'consent',  // Always ask for consent to get refresh token
    })
    setTokenClient(client)
  }

  const handleTokenResponse = async (resp: any) => {
    if (resp.error) {
      setTokenError(resp.error)
      throw resp
    }

    // Add expiration time and save token
    const token: GoogleToken = {
      ...resp,
      expires_at: Date.now() + (resp.expires_in * 1000)
    }
    localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(token))

    // Set the token in the gapi client
    window.gapi.client.setToken(token)

    setIsSignedIn(true)
    setTokenError(null)
    showPicker()
  }

  const showPicker = () => {
    onLoading(true)

    // Load the picker API if not already loaded
    window.gapi.load('picker', () => {
      const token = window.gapi.client.getToken()
      if (!token) {
        console.error('No access token available')
        return
      }

      const docsView = new window.google.picker.DocsView()
        .setIncludeFolders(true)
        .setSelectFolderEnabled(false)
        .setMimeTypes('application/vnd.google-apps.spreadsheet')

      const picker = new window.google.picker.PickerBuilder()
        .addView(docsView)
        .setOAuthToken(token.access_token)
        .setDeveloperKey(process.env.GOOGLE_API_KEY)
        .setCallback(pickerCallback)
        .build()
      picker.setVisible(true)
    })
  }

  const pickerCallback = async (data: any) => {
    if (data.action === 'picked') {
      const fileId = data.docs[0].id
      setSelectedSheet({
        id: fileId,
        name: data.docs[0].name,
        iconUrl: data.docs[0].iconUrl
      })

      try {
        // Export the sheet as XLSX
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
          {
            headers: {
              Authorization: `Bearer ${window.gapi.client.getToken().access_token}`,
            },
          }
        )

        if (!response.ok) {
          throw new Error('Failed to export spreadsheet')
        }

        // Get the array buffer directly from the response
        const arrayBuffer = await response.arrayBuffer()

        // Load the workbook using exceljs
        const workbook = new Excel.Workbook()
        await workbook.xlsx.load(arrayBuffer)

        // Get the specified worksheet or the first one
        const worksheet = mapper.googleDrive?.workbook
          ? workbook.getWorksheet(mapper.googleDrive.workbook)
          : workbook.worksheets[0]

        if (!worksheet) {
          throw new Error('Worksheet not found')
        }

        // Handle root extraction if configured
        let rootRow: BomRow | undefined
        if (mapper.googleDrive?.extractRoot) {
          rootRow = extractRootFields(worksheet, mapper.googleDrive.extractRoot)
        }

        // Get headers from the first row
        const firstRowNumber = mapper.googleDrive?.firstRow || 1
        const headerRow = worksheet.getRow(firstRowNumber)
        const headers: string[] = []
        headerRow.eachCell((cell) => {
          headers.push(snakeCase(cell.value?.toString() || ''))
        })

        // Process data rows
        const bomData: BomRow[] = []
        const images = worksheet.getImages() || []
        const files: File[] = []

        // Create a map of native coordinates to image
        const imagesByPosition = new Map(
          images.map(image => [
            `${image.range?.tl?.nativeRow || 0},${image.range?.tl?.nativeCol || 0}`,
            image
          ])
        )

        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber <= firstRowNumber) return

          const rowData: Record<string, string> = {}

          // Process all cells
          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const header = headers[colNumber - 1]
            if (!header) return

            // Check if there's an image at this cell's position
            const image = imagesByPosition.get(`${cell.row-1},${cell.col-1}`)

            // ExcelJS types incorrectly define imageId as string, but it can be number
            // We also need to check for 0 since it's a valid image index
            if (image?.imageId != null) {
              try {
                // parseInt handles both string and number inputs
                const imageData = workbook.getImage(parseInt(image.imageId.toString()))
                if (imageData?.buffer) {
                  const base64 = `data:image/${imageData.extension};base64,${imageData.buffer.toString('base64')}`
                  const file = base64ToFile(base64, `image.${imageData.extension}`)
                  files.push(file)

                  // Store the index in a temporary field
                  rowData[`${header}__img_index`] = `${files.length - 1}`
                }
              } catch (err) {
                console.error(`Error processing image at row ${rowNumber}, col ${colNumber}:`, err)
              }
            }

            // Handle regular cell value
            const value = cell.value?.toString() || ''
            rowData[header] = value

            // Handle hyperlinks
            if (cell.hyperlink) {
              const target = getHyperlinkTarget(cell.hyperlink)
              if (target) {
                rowData[`${header}_url`] = target
              }
            }
          })

          bomData.push({
            ...rowData,
            __metadata: {
              originalIndex: rowNumber,
              messages: []
            }
          } as BomRow)
        })

        // Add root row if it exists
        const finalBomData = rootRow ? [rootRow, ...bomData] : bomData

        if (files.length) {
          // Upload files and get refs
          const fileRefs = await upload(files)

          // Update BOM data with file IDs
          const processedBomData = finalBomData.map(row => {
            const newRow = { ...row }

            // Look for all __img_index fields
            Object.keys(row).forEach(key => {
              if (key.endsWith('__img_index')) {
                const fileIndex = parseInt(row[key] as string)
                const baseFieldName = key.replace('__img_index', '')

                // If we have a valid file ref, set it in the _img field
                if (fileRefs[fileIndex]) {
                  newRow[`${baseFieldName}_img`] = fileRefs[fileIndex].id.toString()
                }

                // Remove the temporary index field
                // delete newRow[key]
              }
            })

            return newRow
          })

          onBomChange(processedBomData)
        } else {
          onBomChange(finalBomData)
        }

        onLoading(false)

      } catch (err) {
        console.error('Error processing spreadsheet:', err)
        onBomChange(null)
        setSelectedSheet(null)
        onLoading(false)
      }
    } else if (data.action === 'cancel') {
      onLoading(false)
    }
  }

  const handleClearSheet = () => {
    setSelectedSheet(null)
    onBomChange(null)
  }

  const handleAuthClick = () => {
    if (!isSignedIn) {
      tokenClient.requestAccessToken()
    } else if (!selectedSheet) {
      showPicker()
    }
  }

  return (
    <div className="space-y-2 flex justify-end">
      {tokenError && (
        <div className="text-sm text-red-600">
          {tokenError}
        </div>
      )}
      {selectedSheet ? (
        <div className="flex items-center gap-2 p-2 border rounded-md bg-white">
          <img
            src={selectedSheet.iconUrl}
            alt="Google Sheets"
            className="w-5 h-5"
          />
          <span className="flex-grow text-sm font-medium text-gray-900">
            {selectedSheet.name}
          </span>
          <button
            onClick={handleClearSheet}
            className="p-1 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      ) : (
        <Button onClick={handleAuthClick}>
          {isSignedIn ? 'Select Google Sheet' : 'Connect Google Drive'}
        </Button>
      )}
    </div>
  )
}

export default GoogleDriveXlsxImportCell