import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef } from 'react'

import { navigate, routes, useParams } from '@redwoodjs/router'
import { baseColumns, PartHierarchyHeaderColumn } from './columns';

import {
  HierarchyTreeRootNode,
  Aggregator,
  buildAggregationTree,
  SumAggregator,
  MaxAggregator,
  AndAggregator,
  buildHierarchyTree,
  AggregationRootNode,
  NumericTarget,
  BooleanTarget,
  AggregatorTarget
} from 'src/components/PartCell/aggregation';
import type { ExchangeRates } from 'api/src/lib/exchangeRates'
import { useAppContext } from 'src/lib/appContext'

import type {
  PartTreeNodeFragment,
  PartTreeNodePartFragment,
  AddPartHierarchyToChangeOrderMutation,
  AddPartHierarchyToChangeOrderMutationVariables,
  PartCacheQuery,
  Maybe
} from 'types/graphql'

import { ReactNode, useState } from 'react'
import { IncludedPart } from '../ChangeOrderTreeCell'
import keyBy from 'lodash.keyby'
import calculateNextVersion from 'src/lib/calculateNextVersion'
import { useMutation } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'
import { resolveMetadataObj, useMetadataSchema, MetadataValue } from 'src/lib/metadata';
import useHierarchyFilters from './useHierarchyFilters'
import { allFilters } from '../PartColumnSelector/PartFilterSelector'
import { AnyFilter } from 'src/shared/types';
import { usePartsCache } from 'src/lib/usePartsCache';
import { ThumbnailArtifact } from '../ThumbnailViewer';
import { findPartChildren } from 'api/src/lib/bom';
import invariant from 'tiny-invariant';


export interface InputNode extends PartTreeNodeFragment {
  sortOverride?: number,
  mainCell?: React.ReactNode
  dereferenced?: boolean
  previousVersion?: string
  previousVersionRange?: string
  previousQuantity?: number
  previousQuantityUnits?: string
  previousReferenceDesignator?: string
  previousLifecycle?: Maybe<string>
  previousThumbnail?: ThumbnailArtifact
}

interface ControllerPart extends PartTreeNodePartFragment {
  resolvedMetadata: ReturnType<typeof resolveMetadataObj>
}
export interface ControllerTreeNode extends InputNode {
  proto?: Maybe<NonNullable<NonNullable<PartCacheQuery['partProtosCache']>['protos']>[number]>
  part: ControllerPart
  filterRemove?: boolean
}

export type NodeHighlightColor = 'none' | 'red' | 'green' | 'yellow'

export type SubjectPart = {
  hierarchy: string
  message?: ReactNode
  highlightColor?: NodeHighlightColor
  leftMark?: ReactNode
  tools?: (input: { controllerRoot: ControllerRoot }) => ReactNode
}

export interface RootInput {
  tree: InputNode[]
  subjectParts?: SubjectPart[]
  // should always be built from rootpart?
  initiallyExpandedNodes?: string[] | 'smart' | 'all'
}

export type ColumnType = 'field' | 'metadata' | 'aggregation'

export type { PartHierarchyHeaderColumn } from './columns'

export const ADD_TO_CHANGE_ORDER_MUTATION = gql`
mutation AddPartHierarchyToChangeOrderMutation ($changeOrderNumber: Int!, $input: [PartDeltaInput!]!) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, allowDuplicates: true, input: $input) {
    partNumber
  }
}
`

export type HierarchyMode = 'hierarchy' | 'flat'

export interface HierarchyControllerInput {
  columns?: PartHierarchyHeaderColumn[]
  omitColumns?: Pick<PartHierarchyHeaderColumn, 'key' | 'type'>[]
  showAggregations?: boolean
  fullScreen?: boolean
  hierarchyMode?: HierarchyMode
  onToggleFullscreen?: () => void
  onToolbarModeChange?: (mode: ToolBarMode) => void
  onHierarchyModeClick?: (mode: HierarchyMode) => void
  roots: RootInput[]
  usageContext?: {
    type: 'ChangeOrder',
    changeOrder: {
      number: number
      includedParts: IncludedPart[]
    }
  }
  hideFilterUi?: boolean
  useUrlForFilters?: boolean
  initialFilters?: AnyFilter[]
}

export interface ControllerRoot extends RootInput {
  tree: ControllerTreeNode[]
  subjectParts: SubjectPart[]
  rootIndex: number
  expandedNodes: Record<string, boolean>
  setExpanded: (id: string, expanded: boolean) => void
  selectedNodes: string[]
  setSelectedNodes: ((nodes: string[]) => void)
  hierarchyTree: HierarchyTreeRootNode
  aggregations: AggregationRootNode
  rootNode: ControllerTreeNode
}

export type ActivatableAggregator = Aggregator & { isActive?: boolean, index: number }
export type EditableAggregator = ActivatableAggregator & { isDirty?: boolean, isNew?: boolean, savedIndex?: number }

type SortBy = {
  key: string
  type: ColumnType
  direction: 'asc' | 'desc'
}

export interface HierarchyController extends Omit<HierarchyControllerInput, 'columns'> {
  roots: ControllerRoot[]
  hierarchyMode: HierarchyMode
  allColumns: PartHierarchyHeaderColumn[]
  filteredColumns: PartHierarchyHeaderColumn[]
  aggregators: ActivatableAggregator[]
  setAggregators: Dispatch<SetStateAction<ActivatableAggregator[]>>

  targetCurrency?: AggregatorTarget
  setTargetCurrency: Dispatch<SetStateAction<NumericTarget | undefined>>

  savedAggregators: Aggregator[]
  exchangeRates: ExchangeRates

  selectable: boolean
  setSelectable: ((flag: boolean) => void)
  addSelectionToChangeOrder: (changeOrderNumber: number) => Promise<void>
  createChangeOrderWithSelection: () => void

  fullScreen: boolean
  showAggregations: boolean

  selectedAgg: EditableAggregator | null
  setSelectedAgg: (aggIndex?: number | null) => void
  createLocalAggregator: () => void
  toolbarMode: ToolBarMode
  setToolbarMode: (mode: ToolBarMode) => void

  unselectAll: () => void
  selectAll: () => void

  resetSort: () => void
  setSortBy: (sortBy: SortBy) => void
  sortBy: SortBy
  sortColumn: PartHierarchyHeaderColumn
  filterController: ReturnType<typeof useHierarchyFilters>
}

const mergeIntoBaseColumns = (passedColumns?: PartHierarchyHeaderColumn[]): PartHierarchyHeaderColumn[] => {
  return baseColumns.map(c => {
    if (!passedColumns) return c
    const conf = passedColumns.find(conf => conf.type === c.type && conf.key === c.key)
    if (!conf) return c
    return {
      ...c,
      ...conf
    }
  })
}

export type ToolBarMode = 'agg' | null

export const useHierarchyControllerFactory = (properties: HierarchyControllerInput): HierarchyController => {
  const orgId = useParams().orgId!
  const appContext = useAppContext()
  const { data } = usePartsCache()
  const protosByPn = keyBy(data?.protos, 'partNumber')
  const { fullScreen, showAggregations, omitColumns, useUrlForFilters, initialFilters } = properties

  const filterController = useHierarchyFilters({ useURL: useUrlForFilters, initialFilters })

  const metadataSchema = useMetadataSchema()!
  const metadataCols: PartHierarchyHeaderColumn[] = Object.entries(metadataSchema).map(([key, m]) => {
    return {
      name: m.displayName,
      key: key,
      align: 'left',
      type: 'metadata',
      fullKey: `metadata.${key}`,
      valueType: m.type,
      width: Math.max(10 * m.displayName.length, 100),
      displayFn(n) {
        return n.part.resolvedMetadata[key]?.displayValue || '-'
      },
      filterValue(n) {
        return n.part.resolvedMetadata[key]?.entry || null
      },
      sortFn(nodeA, nodeB) {
        // pretty dumb, but could be specified in the metadata resolver
        // to sort different types
        const a = nodeA.part.resolvedMetadata[key]?.displayValue || '-'
        const b = nodeB.part.resolvedMetadata[key]?.displayValue || '-'
        if (a > b) return -1
        if (a < b) return 1
        return 0
      }
    }
  })

  // Analyze roots to determine the maximum number of sources
  const sourceCols: PartHierarchyHeaderColumn[] = useMemo(() => {
    // Find the maximum number of sources across all parts in all roots
    let maxSourceCount = 0;
    properties.roots.forEach(root => {
      root.tree.forEach(node => {
        if (node.part.sources && node.part.sources.length > maxSourceCount) {
          maxSourceCount = node.part.sources.length;
        }
      });
    });

    // Define the source properties we want to display as columns
    const sourceProperties = [
      { key: 'distributor', name: 'Distributor', valueType: 'String' },
      { key: 'distributorSku', name: 'SKU', valueType: 'String' },
      { key: 'price', name: 'Price', valueType: 'Number' },
      { key: 'leadTimeDays', name: 'Lead Time', valueType: 'Number' },
      { key: 'stock', name: 'Stock', valueType: 'Number' },
      { key: 'url', name: 'URL', valueType: 'URL' },
    ];

    // Create columns for each source property for each source position
    const columns: PartHierarchyHeaderColumn[] = [];

    for (let sourceIndex = 0; sourceIndex < maxSourceCount; sourceIndex++) {
      sourceProperties.forEach(prop => {
        columns.push({
          name: `Source ${sourceIndex + 1} ${prop.name}`,
          key: `source${sourceIndex}_${prop.key}`,
          align: 'left',
          type: 'field',
          fullKey: `part.sources[${sourceIndex}].${prop.key}`,
          valueType: prop.valueType,
          width: getSourceColumnWidth(prop.key),
          showNonFullscreen: false,
          displayFn(node) {
            const source = node.part.sources && node.part.sources[sourceIndex];
            if (!source) return '-';

            if (prop.key === 'distributor') {
              return source.distributor ? source.distributor.name : '-';
            }

            const value = source[prop.key];
            if (value === null || value === undefined) return '-';

            if (prop.key === 'price' && source.priceCurrency) {
              return `${value} ${source.priceCurrency}`;
            }

            if (prop.key === 'leadTimeDays') {
              return `${value} days`;
            }

            return String(value);
          },
          filterValue(node) {
            const source = node.part.sources && node.part.sources[sourceIndex];
            if (!source) return '';

            if (prop.key === 'distributor') {
              return source.distributor ? source.distributor.name : null;
            }

            return source[prop.key] !== undefined ? source[prop.key] : null;
          },
          sortFn(nodeA, nodeB) {
            const sourceA = nodeA.part.sources && nodeA.part.sources[sourceIndex];
            const sourceB = nodeB.part.sources && nodeB.part.sources[sourceIndex];

            const valueA = sourceA ? (prop.key === 'distributor' ?
              (sourceA.distributor ? sourceA.distributor.name : '') :
              sourceA[prop.key]) : '';

            const valueB = sourceB ? (prop.key === 'distributor' ?
              (sourceB.distributor ? sourceB.distributor.name : '') :
              sourceB[prop.key]) : '';

            // Handle numeric sorting
            if (prop.valueType === 'Number') {
              const numA = typeof valueA === 'number' ? valueA : Number.MIN_SAFE_INTEGER;
              const numB = typeof valueB === 'number' ? valueB : Number.MIN_SAFE_INTEGER;
              return numB - numA; // Descending order for numbers
            }

            // String comparison for other types
            const strA = valueA !== null && valueA !== undefined ? String(valueA) : '';
            const strB = valueB !== null && valueB !== undefined ? String(valueB) : '';

            if (strA > strB) return -1;
            if (strA < strB) return 1;
            return 0;
          }
        });
      });
    }

    return columns;
  }, [properties.roots]);

  const allColumns = [...mergeIntoBaseColumns(properties.columns), ...metadataCols, ...sourceCols]
  const filteredColumns = [...allColumns].filter(col => {
    if (col.hide) return false
    if (omitColumns?.find(c => c.key === col.key && c.type === col.type)) {
      return false
    }
    return (fullScreen || col.showNonFullscreen)
  })

  const defaultSort: SortBy = {
    key: 'partNumberName',
    type: 'field',
    direction: 'asc'
  }
  const [sortBy, setSortBy] = useState<SortBy>(defaultSort)
  const sortColumn = allColumns.find(c => c.key === sortBy.key && c.type === sortBy.type)!

  const { aggregationConfigs } = appContext.currentOrg
  const exchangeRates = appContext.currentOrg.exchangeRates as ExchangeRates


  const savedAggregators = useMemo(() =>
    (aggregationConfigs || []).map(c => {
      const reducerInput = {
        metadataSchema,
        name: c.name,
        exchangeRates,
        metadata: c.metadata as NonNullable<typeof c.metadata>,
        sources: c.sources as NonNullable<typeof c.sources>
      }
      if (c.reducer === 'Sum') {
        const targetType = c.targetType as NumericTarget
        return SumAggregator({
          targetType,
          ...reducerInput
        })
      }
      if (c.reducer === 'Max') {
        const targetType = c.targetType as NumericTarget
        return MaxAggregator({
          targetType,
          ...reducerInput
        })
      }
      if (c.reducer === 'And') {
        const targetType = c.targetType as unknown as BooleanTarget
        return AndAggregator({
          targetType,
          ...reducerInput
        })
      }
      throw new Error('No reducer known for ' + c.reducer)
    }
    ) ?? [],
    [aggregationConfigs]
  )


  type ExpandedNodes = Record<number, Record<string, boolean>>

  // this should include a calculation on whether there is a filter in
  // hierarchy view, but the order of things here would mean a refactor
  // to do that correctly
  const initiallyExpandedNodes = properties.roots.reduce((rootsExpanded, root, i) => {
    if (root.initiallyExpandedNodes === 'smart') {
      // Only expand nodes with fewer than 20 children
      const hash: Record<string, boolean> = {}
      root.tree.forEach(node => {
        const children = findPartChildren(root.tree, node.partNumberAddress, 'partNumberAddress')
        if (children.length < 20) {
          hash[node.partNumberAddress] = true
        }
      })
      return {
        ...rootsExpanded,
        [i]: hash
      }
    }

    if (root.initiallyExpandedNodes === 'all') {
      // Expand all nodes
      const hash: Record<string, boolean> = {}
      root.tree.forEach(node => {
        hash[node.partNumberAddress] = true
      })
      return {
        ...rootsExpanded,
        [i]: hash
      }
    }

    const hash = Object.fromEntries((root.initiallyExpandedNodes || []).map(h => [h, true]))
    return {
      ...rootsExpanded,
      [i]: hash
    }
  }, {} as ExpandedNodes)
  const [expandedNodesByRoot, setExpandedByRoot] = useState<ExpandedNodes>(initiallyExpandedNodes)

  // Add a ref to track seen root addresses
  const seenRootAddresses = useRef<Set<string>>(new Set())

  // Add useEffect to handle new roots
  useEffect(() => {
    const nextExpanded = { ...expandedNodesByRoot }
    let hasChanges = false

    properties.roots.forEach((root, i) => {
      const rootNode = root.tree.find(n => n.isTreeRoot)!
      const rootAddress = rootNode.partNumberAddress

      // Skip if we've already processed this root
      if (seenRootAddresses.current.has(rootAddress)) {
        return
      }

      // Mark this root as seen
      seenRootAddresses.current.add(rootAddress)
      hasChanges = true

      if (root.initiallyExpandedNodes === 'smart') {
        // Only expand nodes with fewer than 20 children
        const hash: Record<string, boolean> = {}
        root.tree.forEach(node => {
          const children = findPartChildren(root.tree, node.partNumberAddress, 'partNumberAddress')
          if (children.length < 20) {
            hash[node.partNumberAddress] = true
          }
        })
        nextExpanded[i] = hash
      } else if (root.initiallyExpandedNodes === 'all') {
        // Expand all nodes
        const hash: Record<string, boolean> = {}
        root.tree.forEach(node => {
          hash[node.partNumberAddress] = true
        })
        nextExpanded[i] = hash
      } else if (Array.isArray(root.initiallyExpandedNodes)) {
        // Expand specific nodes
        nextExpanded[i] = Object.fromEntries(
          root.initiallyExpandedNodes.map(h => [h, true])
        )
      }
    })

    // Only update state if we found new roots
    if (hasChanges) {
      setExpandedByRoot(nextExpanded)
    }
  }, [properties.roots]) // Dependencies array includes roots


  const [selectMode, setSelectMode] = useState(false)

  type SelectedNodes = Record<number, string[]>
  const [selectedNodesByRoot, setSelectedByRoot] = useState<SelectedNodes>({})

  const selectAll = () => {
    const allNodes = properties.roots.reduce((allNodes, root, i) => {
      const nodeIndexes = root.tree.flatMap(n => {
        return n.hierarchy
      })
      return {
        ...allNodes,
        [i]: nodeIndexes
      }
    }, {} as SelectedNodes)
    setSelectedByRoot(allNodes)
  }
  const unselectAll = () => setSelectedByRoot({})

  const [aggregators, setAggregators] = useState<ActivatableAggregator[]>(
    (savedAggregators ?? []).map((a, index) => ({ ...a, index, savedIndex: index, isActive: true }))
  )

  const [targetCurrency, setTargetCurrency] = useState<NumericTarget | undefined>()

  const handleSetSelectNodesMode = (flag: boolean) => {
    if (!flag) {
      unselectAll()
      setSelectMode(false)
      return
    }

    selectAll()
    setSelectMode(true)
  }

  const [addToChangeOrderMutation] = useMutation<
    AddPartHierarchyToChangeOrderMutation,
    AddPartHierarchyToChangeOrderMutationVariables
  >(ADD_TO_CHANGE_ORDER_MUTATION)

  const addToChangeOrder = async (changeOrderNumber: number, input: AddPartHierarchyToChangeOrderMutationVariables['input']) => {
    const variables: AddPartHierarchyToChangeOrderMutationVariables = {
      changeOrderNumber,
      input
    }
    const { errors } = await addToChangeOrderMutation({
      variables: variables,
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error adding part to change order`
      })
      return
    }
  }

  const appliedFilters = filterController.filters.map(f => {
    return {
      ...f,
      column: allColumns.find(c => c.fullKey === f.key)!,
      filterSpec: allFilters.find(filter => filter.key === f.type)!
    }
  })

  const roots = properties.roots.map((root, rootIndex) => {
    const rootNode = root.tree.find(n => n.isTreeRoot)!

    const calcFilter = (controllerNode: ControllerTreeNode) => {
      let filterRemove = false
      for (const filter of appliedFilters) {
        filterRemove = filterRemove || filter.filterSpec.filterRemoveNode(filter.column.filterValue(controllerNode), filter.value)
      }
      return filterRemove
    }

    const controllerTree = root.tree.map(node => {

      const nodeWithExtra: ControllerTreeNode = {
        ...node,
        proto: protosByPn[node.part.partNumber],
        part: {
          ...node.part,
          resolvedMetadata: resolveMetadataObj(metadataSchema, node.part.metadata as Record<string, MetadataValue>),
        },
      }
      return {
        ...nodeWithExtra,
        filterRemove: calcFilter(nodeWithExtra),
      }
    })

    let filterAncestors: Record<string, boolean> = {}
    const treeByLevel = keyBy(controllerTree, 'hierarchy')
    controllerTree.forEach(n => {
      if (!n.filterRemove) {
        const levels = n.hierarchy.split('.')
        for (let i = 1; i < levels.length; i++) {
          const ancestorLevel = levels.slice(0, i).join('.')
          const ancestorNode = treeByLevel[ancestorLevel]!
          treeByLevel[ancestorLevel] = {
            ...ancestorNode,
            filterRemove: false,
          }
          // expand ancestors if filter and they match
          if (filterController.filters.length > 0) {
            filterAncestors[ancestorLevel] = true
          }
        }
      }
    })

    const finalTree = Object.values(treeByLevel)

    const controllerRoot = finalTree.find(n => n.isTreeRoot)!

    /*
    const groupsWithSelection = keyBy(
      finalTree.filter(n => {
        return (!n.filterRemove && n.parentToNodeDependency?.groupId)
      })
      , n => n.parentToNodeDependency?.groupId
    )
    */

    const selectedNodes = selectedNodesByRoot[rootIndex]?.filter(n => {
      /*
      if (groupsWithSelection[n]) {
        return true
      }
      */
      return !treeByLevel[n]?.filterRemove
    }) || []
    const setSelectedNodes = (nodes: string[]) => {
      setSelectedByRoot({
        ...selectedNodesByRoot,
        [rootIndex]: nodes
      })
    }
    const selectedByLevel = keyBy(selectedNodes)
    const filteredHierarchy = selectMode ? controllerTree.filter(n => selectedByLevel[n.hierarchy]) : controllerTree
    const hierarchyTree = buildHierarchyTree(controllerRoot, filteredHierarchy)

    const aggregations = buildAggregationTree(hierarchyTree, aggregators, { targetOverride: targetCurrency })

    return {
      ...root,
      rootIndex,
      rootNode: controllerRoot,
      expandedNodes: expandedNodesByRoot[rootIndex] || {},
      setExpanded: (id: string, expanded: boolean) => {
        setExpandedByRoot({
          ...expandedNodesByRoot,
          [rootIndex]: {
            ...expandedNodesByRoot[rootIndex],
            [id]: expanded
          }
        })
      },
      hierarchyTree,
      tree: finalTree,
      treeByLevel,
      aggregations,
      onHierarchyModeClick: properties.onHierarchyModeClick,
      selectedNodes: selectedNodes ?? [],
      setSelectedNodes: setSelectedNodes,
      subjectParts: root.subjectParts ?? [],
      rootPart: rootNode,
    }
  }).sort((rootA, rootB) => {
    // Check for sortOverride on either root node
    if (rootA.rootNode.sortOverride !== undefined || rootB.rootNode.sortOverride !== undefined) {
      const aSort = rootA.rootNode.sortOverride ?? Number.MAX_SAFE_INTEGER
      const bSort = rootB.rootNode.sortOverride ?? Number.MAX_SAFE_INTEGER
      return aSort - bSort
    }

    // Handle sort direction before the default sort
    if (sortBy.direction === 'asc') {
      return -1 * sortColumn.sortFn(rootA.rootNode, rootB.rootNode)
    }
    return sortColumn.sortFn(rootA.rootNode, rootB.rootNode)
  })

  useEffect(() => {
    // if no filters, we don't change expanded state
    if (filterController.filters.length === 0) {
      return
    }

    const nextExpanded = roots.reduce((expandedByRoot, root, i) => {
      let expandedNodes = root.expandedNodes
      root.tree.forEach(n => {
        if (!n.filterRemove) {
          const levels = n.partNumberAddress.split('.')
          for (let i = 1; i < levels.length; i++) {
            const ancestorAddress = levels.slice(0, i).join('.')
            // expand ancestors of matching nodes
            expandedNodes[ancestorAddress] = true
          }
        }
      })
      return {
        ...expandedByRoot,
        [i]: expandedNodes
      }
    }, expandedNodesByRoot)
    setExpandedByRoot(nextExpanded)
  }, [filterController.filters, properties.hierarchyMode, properties.roots.length])

  const getSelectedUniqueParts = () => {
    let selectPartsByPn: Record<string, PartTreeNodeFragment> = {}
    for (const root of roots) {
      const selectedByLevel = keyBy(root.selectedNodes)
      const filteredHierarchy = selectMode ? root.tree.filter(n => selectedByLevel[n.hierarchy]) : root.tree
      const selected = keyBy(filteredHierarchy, 'part.partNumber')
      selectPartsByPn = {
        ...selectPartsByPn,
        ...selected,
      }
    }
    return selectPartsByPn
  }

  const createChangeOrderWithSelection = () => {
    const selectPartsByPn = getSelectedUniqueParts()
    const opaquePartNumbers = Object.keys(selectPartsByPn).map(pn => btoa(pn)).join(',')

    navigate(routes.changeOrderCreate({ orgId, withPartNumbers: opaquePartNumbers }))
  }

  const addSelectionToChangeOrder = async (changeOrderNumber: number) => {
    const selectPartsByPn = getSelectedUniqueParts()
    const pushes = Object.values(selectPartsByPn).map(node => {
      return {
        type: 'Push' as const,
        partNumber: node.part.partNumber,
        version: calculateNextVersion(node.part.proto.currentVersionString!),
      }
    })

    await addToChangeOrder(changeOrderNumber, pushes)

    navigate(`${routes.changeOrderTab({ orgId, orderNumber: changeOrderNumber, tab: 'changes' })}`)
  }

  const [mode, setToolbarMode] = useState<ToolBarMode>(null)
  const [selectedAggIdx, setSelectedAggIdx] = useState<number | null>(null)

  useEffect(() => {
    if (!showAggregations) {
      setToolbarMode(null)
      setSelectedAggIdx(null)
    }
  }, [showAggregations])

  useEffect(() => {
    if (mode === 'agg' && aggregators.length === 0) {
      createLocalAggregator()
    }
  }, [mode, aggregators])

  const nameSeed = useRef<number>(0)

  const createLocalAggregator = () => {
    const newAgg: EditableAggregator = {
      ...SumAggregator({
        name: `New Aggregator ${++nameSeed.current}`,
        exchangeRates,
        targetType: {
          type: 'Price',
          unit: 'USD'
        },
        metadata: [],
        sources: []
      }),
      isDirty: true,
      isNew: true,
      isActive: true,
      index: aggregators.length,
    }

    setToolbarMode('agg')
    setAggregators(aggregators.concat(newAgg))
    setSelectedAggIdx(aggregators.length)
  }

  const getSelectedAgg = () => selectedAggIdx === null ? null
    : aggregators[selectedAggIdx] as EditableAggregator
  const selectedAgg = getSelectedAgg()

  const setSelectedAgg = (aggIndex?: number | null) => {
    setSelectedAggIdx(aggIndex ?? null)
  }

  const { columns, ...passThroughProps } = properties
  return Object.seal({
    ...passThroughProps,
    roots,
    hierarchyMode: properties.hierarchyMode || 'hierarchy',
    exchangeRates: exchangeRates as ExchangeRates,
    allColumns,
    filteredColumns,
    sortBy,
    sortColumn,
    setSortBy,
    resetSort() {
      setSortBy(defaultSort)
    },
    fullScreen: Boolean(fullScreen),
    showAggregations: Boolean(properties.showAggregations),
    onToggleFullscreen: properties.onToggleFullscreen,
    selectedAgg,
    setSelectedAgg,
    savedAggregators,
    selectable: selectMode,
    setSelectable: handleSetSelectNodesMode,
    createLocalAggregator,
    toolbarMode: fullScreen ? mode : null,
    setToolbarMode(mode) {
      setToolbarMode(mode)
      if (properties.onToolbarModeChange) {
        properties.onToolbarModeChange(mode)
      }
    },
    aggregators: aggregators,
    setAggregators,
    targetCurrency,
    setTargetCurrency,
    addSelectionToChangeOrder,
    createChangeOrderWithSelection,
    selectAll,
    unselectAll,
    filterController,
  })
}

type HierarchyContextInput = {
  hierarchyController: HierarchyController,
  root: HierarchyController['roots'][number],
  columnWidths: Record<string, number>
  nameColumnWidth: number
} | null
export const HierarchyControllerContext = createContext<HierarchyContextInput>(null)
export const useHierarchyContext = () => {
  const context = useContext(HierarchyControllerContext)
  invariant(context, 'Hierarchy context hook needs to be in a provider')
  return context
}

/**
 * Returns the appropriate column width for a source property based on its type
 * @param propertyKey The key of the source property
 * @param defaultWidth Default width to use if no specific width is defined
 * @returns The column width in pixels
 */
export const getSourceColumnWidth = (propertyKey: string, defaultWidth = 120): number => {
  const widthMap: Record<string, number> = {
    'distributor': 150,
    'distributorSku': 120,
    'price': 100,
    'leadTimeDays': 100,
    'stock': 80,
    'url': 150,
    'comment': 200,
    'perQuantity': 90,
    'perQuantityUnit': 90,
    'priority': 70
  }

  return widthMap[propertyKey] || defaultWidth
}

export const quantityAndUnit = (quantity?: number, units?: string) => {
  if (typeof quantity === 'undefined') return '-'
  units = units || 'each'
  const unit = (['each', 'units']).includes(units) ? '' : ` ${units}`
  return `${quantity}${unit}`
}
