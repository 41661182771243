import { MetaTags } from '@redwoodjs/web'
import { useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { useState } from 'react'
import Button from 'src/components/Button'
import { reportMutationError } from 'src/lib/reportError'
import { QUERY as CATEGORY_QUERY } from 'src/components/OrgSettingsCategoriesCell'

const RESET_ORG_DEMO_DATA_MUTATION = gql`
  mutation ResetOrgDemoDataMutation($input: ResetOrgDemoDataInput!) {
    resetOrgDemoData(input: $input)
  }
`

const OrgSettingsDemoDataPage = () => {
  const [message, setMessage] = useState<string | null>(null)
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null)

  const [resetDemoData, { loading }] = useMutation(RESET_ORG_DEMO_DATA_MUTATION)

  const handleGenerateDemoData = async () => {
    if (confirm('Are you sure you want to generate demo data? This will replace any existing data.')) {
      setMessage(null)
      try {
        const variables = {
          input: {
            wipeOnly: false,
          },
        }
        const { errors, data } = await resetDemoData({
          variables,
          refetchQueries: [ { query: CATEGORY_QUERY } ]
        })

        // Check for errors in the response
        if (errors) {
          reportMutationError({
            errors,
            variables,
            message: 'Error generating demo data'
          })
          setMessage(`Error: ${errors.map(e => e.message).join(', ')}`)
          setMessageType('error')
        } else if (data) {
          setMessage('Demo data has been generated successfully')
          setMessageType('success')
        }
      } catch (error) {
        console.error('Exception during demo data generation:', error)
        setMessage(`Error: ${error.message || 'Unknown error occurred'}`)
        setMessageType('error')
      }
    }
  }

  const handleResetDemoData = async () => {
    if (confirm('Are you sure you want to clear your workspace? This action cannot be undone.')) {
      setMessage(null)
      try {
        const variables = {
          input: {
            wipeOnly: true,
          },
        }
        const { errors, data } = await resetDemoData({
          variables,
        })

        // Check for errors in the response
        if (errors) {
          reportMutationError({
            errors,
            variables,
            message: 'Error clearing workspace'
          })
          setMessage(`Error: ${errors.map(e => e.message).join(', ')}`)
          setMessageType('error')
        } else if (data) {
          setMessage('Your workspace has been cleared successfully')
          setMessageType('success')
        }
      } catch (error) {
        console.error('Exception during workspace clearing:', error)
        setMessage(`Error: ${error.message || 'Unknown error occurred'}`)
        setMessageType('error')
      }
    }
  }

  return (
    <>
      <MetaTags title="Demo Data Management" description="Organization demo data management page" />

      <div className="container mx-auto px-4 py-6">
        <h1 className="text-2xl font-bold mb-6">Demo Data Management</h1>

        {message && (
          <div className={`mb-4 p-3 rounded ${messageType === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            {message}
          </div>
        )}

        <div className="bg-white shadow rounded-lg p-6">
          <p className="text-gray-700 mb-4">
            Quickly populate your workspace with sample data or clear it to start fresh.
          </p>

          <div className="space-y-6">
            <div className="flex flex-col border p-5 rounded-md">
              <h2 className="text-lg font-semibold mb-2">Generate Demo Data</h2>
              <p className="text-gray-600 mb-3">
                Populate your workspace with a sample Bill of Materials (BOM) including parts, suppliers, and their relationships to help you explore and test the system. This will replace any existing data.
              </p>
              <div className="flex">
                <Button
                  variant="primary"
                  onClick={handleGenerateDemoData}
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Generate Demo Data'}
                </Button>
              </div>
            </div>

            <div className="flex flex-col border p-5 rounded-md">
              <h2 className="text-lg font-semibold mb-2">Clear Workspace</h2>
              <p className="text-gray-600 mb-3">
                Clear all data from your workspace and return to a fresh state. Users and their roles will not be deleted.
              </p>
              <div className="flex">
                <Button
                  variant="red"
                  onClick={handleResetDemoData}
                  disabled={loading}
                >
                  {loading ? 'Processing...' : 'Clear Workspace'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrgSettingsDemoDataPage
