import * as ListBox from 'src/components/ListBox'
import { MetadataType } from 'types/graphql'
import Input, { NumberInput } from '../Input'
import { MetadataValue } from 'src/lib/metadata'
import { useLifeCycleStages, resolveLifeCycle } from 'src/lib/lifecycle'
import { StatusDot } from '../LifecycleStatus/LifecycleStatus'
import { FilterType } from 'src/shared/types'
import { useAppContext } from 'src/lib/appContext'

/**
 * Sanitizes a string by removing invisible Unicode characters and normalizing whitespace
 * Handles characters like U+200E (Left-to-Right Mark) and other control characters
 */
export const sanitizeString = (str: string): string => {
  if (!str) return str

  // Remove Unicode control characters, zero-width spaces, and directional marks
  // U+200E is Left-to-Right Mark
  // U+200B is Zero Width Space
  // U+200C is Zero Width Non-Joiner
  // U+200D is Zero Width Joiner
  // U+2028 is Line Separator
  // U+2029 is Paragraph Separator
  // U+FEFF is Zero Width No-Break Space
  return str
    .replace(/[\u200E\u200B\u200C\u200D\u2028\u2029\uFEFF\u0000-\u001F]/g, '')
    .trim()
    .normalize('NFC') // Normalize to composed form
}
type FilterValue = number | string | boolean
type NodeValue = MetadataValue | number[] | null
type Filter = {
  key: FilterType
  display: string
  forType: (MetadataType | 'LifeCycle' | 'ChangeOrder' | 'Owner')[]
  filterRemoveNode: (nodeValue: NodeValue, filterAgainst: MetadataValue) => boolean
  Input: React.FC<{
    value: FilterValue
    valueType: MetadataType | 'LifeCycle' | 'ChangeOrder' | 'Owner'
    onChange: (v: FilterValue) => void
  }>
}

const inputClassName = 'text-xs h-6 px-2'

export const allFilters: Filter[] = [{
  key: 'Contains',
  display: 'Contains',
  forType: ['String', 'URL'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (filterAgainst === '') return false
    if (nodeValue === null) return true
    const value = sanitizeString(nodeValue as string).toLowerCase()
    const filterValue = sanitizeString(filterAgainst as string).toLowerCase()
    return !value.includes(filterValue)
  },
  Input({ value, onChange }) {
    return <Input inputClassName={inputClassName} value={value as string} onChange={onChange} />
  }
}, {
  key: 'NotContains',
  display: 'Does Not Contain',
  forType: ['String', 'URL'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (filterAgainst === '') return false
    if (nodeValue === null) return false
    const value = sanitizeString(nodeValue as string).toLowerCase()
    const filterValue = sanitizeString(filterAgainst as string).toLowerCase()
    return value.includes(filterValue)
  },
  Input({ value, onChange }) {
    return <Input inputClassName={inputClassName} value={value as string} onChange={onChange} />
  }
}, {
  key: 'Equals',
  display: 'Equals',
  forType: ['Number', 'String', 'URL'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (filterAgainst === '') return false
    if (nodeValue === null) return true

    const sanitizedNodeValue = sanitizeString(nodeValue.toString()).toLowerCase()
    const sanitizedFilterValue = sanitizeString(filterAgainst.toString()).toLowerCase()

    return sanitizedNodeValue !== sanitizedFilterValue
  },
  Input({ value, onChange, valueType }) {
    if (valueType === 'String' || valueType === 'URL') {
      return <Input inputClassName={inputClassName} value={value as string} onChange={onChange} />
    }
    return <NumberInput value={value as number} onChange={onChange} />
  }
}, {
  key: 'NotEquals',
  display: 'Does Not equal',
  forType: ['Number', 'String', 'URL'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (filterAgainst === '') return false
    if (nodeValue === null) return false

    const sanitizedNodeValue = sanitizeString(nodeValue.toString()).toLowerCase()
    const sanitizedFilterValue = sanitizeString(filterAgainst.toString()).toLowerCase()

    return sanitizedNodeValue === sanitizedFilterValue
  },
  Input({ value, onChange, valueType }) {
    if (valueType === 'String' || valueType === 'URL') {
      return <Input inputClassName={inputClassName} value={value as string} onChange={onChange} />
    }
    return <NumberInput value={value as number} onChange={onChange} />
  }
}, {
  key: 'EqualsLifeCycle',
  display: 'Equals',
  forType: ['LifeCycle'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (nodeValue === null) return false
    const value = nodeValue as string
    const filterValue = filterAgainst as string
    return value !== filterValue
  },
  Input({ value, onChange }) {
    return <LifeCycleInput defaultValue={value as string} onChange={onChange} />
  }
}, {
  key: 'NotEqualsLifeCycle',
  display: 'Does Not equal',
  forType: ['LifeCycle'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (nodeValue === null) return false
    const value = nodeValue as string
    const filterValue = filterAgainst as string
    return value === filterValue
  },
  Input({ value, onChange }) {
    return <LifeCycleInput defaultValue={value as string} onChange={onChange} />
  }
}, {
  key: 'InChangeOrder',
  display: 'In',
  forType: ['ChangeOrder'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (filterAgainst === null) return false
    const orderNumbers = nodeValue as number[]
    const filterValue = filterAgainst as number
    return !orderNumbers.includes(filterValue)
  },
  Input({ value, onChange }) {
    return <ChangeOrderInput defaultValue={value as number} onChange={onChange} />
  }
}, {
  key: 'NotInChangeOrder',
  display: 'Not In',
  forType: ['ChangeOrder'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (filterAgainst === null) return false
    const orderNumbers = nodeValue as number[]
    const filterValue = filterAgainst as number
    return orderNumbers.includes(filterValue)
  },
  Input({ value, onChange }) {
    return <ChangeOrderInput defaultValue={value as number} onChange={onChange} />
  }
}, {
  key: 'IsOwner',
  display: 'Is',
  forType: ['Owner'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (filterAgainst === null) return false
    const ownerId = nodeValue as string
    const filterValue = filterAgainst as string
    return ownerId !== filterValue
  },
  Input({ value, onChange }) {
    return <UserInput defaultValue={value as string} onChange={onChange} />
  }
}, {
  key: 'IsNotOwner',
  display: 'Is Not',
  forType: ['Owner'],
  filterRemoveNode(nodeValue, filterAgainst) {
    if (filterAgainst === null) return false
    const ownerId = nodeValue as string
    const filterValue = filterAgainst as string
    return ownerId === filterValue
  },
  Input({ value, onChange }) {
    return <UserInput defaultValue={value as string} onChange={onChange} />
  }
}, {
  key: 'True',
  display: 'Is True',
  forType: ['Boolean'],
  filterRemoveNode(nodeValue, filterAgainst) {
    return !Boolean(nodeValue)
  },
  Input({ value, onChange }) {
    return null
  }
}, {
  key: 'NotTrue',
  display: 'Is Not True',
  forType: ['Boolean'],
  filterRemoveNode(nodeValue, filterAgainst) {
    return Boolean(nodeValue)
  },
  Input({ value, onChange }) {
    return null
  }
}]

type PartFilterSelectProps = {
  onChange: (o: string) => void
  value: string
  forType: MetadataType
}

export const PartFilterSelect: React.FC<PartFilterSelectProps> = ({ onChange, value, forType }) => {
  const possibleFilters = allFilters.filter(f => f.forType.includes(forType))

  const display = possibleFilters.find(o => o.key === value)!.display
  return <ListBox.ListBox onChange={onChange} value={value}>
    {({ open }) =>
      <div className="relative text-xs">
        <ListBox.Button className='text-xs' size='sm' displayValue={display} />
        <ListBox.Options open={open} align='left' className='text-xs'>
          {possibleFilters.map(o => (
            <ListBox.Option key={o.key} className='py-3' value={o.key} display={o.display} />
          ))}
        </ListBox.Options>
      </div>
    }
  </ListBox.ListBox>
}

const LifeCycleInput: React.FC<{ defaultValue: string, onChange: (v: string) => void }>
  = ({ defaultValue, onChange }) => {
  const stages = useLifeCycleStages()
  const Display = ({ lifeCycle }: { lifeCycle: string | null | undefined }) =>
    <div className='flex items-center gap-2 text-xs'>
      <StatusDot size='sm' lifeCycle={lifeCycle} />
      <div>{resolveLifeCycle(stages, lifeCycle).name}</div>
    </div>

  return <ListBox.ListBox defaultValue={defaultValue} onChange={onChange} >
  {({ open }) =>
    <div className={`relative text-xs`}>
      <ListBox.UncontrolledButton size='sm' className='text-xs' displayFunction={s => <Display lifeCycle={s.value} />} />
      <ListBox.Options open={open} align='right'>
        {stages.map(s =>
          <ListBox.Option key={s.key} className='py-3' value={s.key}
            display={<Display lifeCycle={s.key} />}
          />
        )}
      </ListBox.Options>
    </div>
  }
</ListBox.ListBox>
}

const ChangeOrderInput: React.FC<{ defaultValue: number, onChange: (v: number) => void }>
  = ({ defaultValue, onChange }) => {
  const { changeOrders } = useAppContext()
  const Display = ({ number }: { number: number | null | undefined }) => {
    const co = changeOrders.find(c => c.number === number)
    if (!co) return 'Select Change Order'
    return `#${co.number} - ${co.name}`
  }
  const availableCos = changeOrders.filter(c => c.state === 'Review' || c.state === 'Draft')

  return <ListBox.ListBox defaultValue={defaultValue} onChange={onChange} >
  {({ open }) =>
    <div className={`relative text-xs`}>
      <ListBox.UncontrolledButton size='sm' className='text-xs' displayFunction={s => <Display number={s.value} />} />
      <ListBox.Options open={open} align='right'>
        {availableCos.map(s =>
          <ListBox.Option key={s.number} className='py-3' value={s.number}
            display={<Display number={s.number} />}
          />
        )}
      </ListBox.Options>
    </div>
  }
</ListBox.ListBox>
}

const UserInput: React.FC<{ defaultValue: string, onChange: (v: string) => void }>
  = ({ defaultValue, onChange }) => {
  const { currentOrg: { members } } = useAppContext()
  const Display = ({ id }: { id: string | null | undefined }) => {
    const member = members.find(m => m.user.id === id)
    if (!member) return 'Select User'
    return member.user.name
  }

  return <ListBox.ListBox defaultValue={defaultValue} onChange={onChange} >
  {({ open }) =>
    <div className={`relative text-xs`}>
      <ListBox.UncontrolledButton size='sm' className='text-xs' displayFunction={s => <Display id={s.value} />} />
      <ListBox.Options open={open} align='right'>
        {members.map(m =>
          <ListBox.Option key={m.user.id} className='py-3' value={m.user.id}
            display={<Display id={m.user.id} />}
          />
        )}
      </ListBox.Options>
    </div>
  }
</ListBox.ListBox>
}
