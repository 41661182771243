import React, { useContext, useEffect, useState } from 'react'
import type { PropsWithChildren } from 'react'
import type {
  AppBodyQuery,
  AppBodyQueryVariables,
  UserViewMutation,
  UserViewMutationVariables,
  UserPageViewMutation,
  UserPageViewMutationVariables
} from 'types/graphql'

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import AppContext, { useAppContextMaybe, useAppContextValue } from 'src/lib/appContext'
import { CellFailureProps, useMutation, type CellSuccessProps } from '@redwoodjs/web'

import { MetadataSchemaContext, ChangeOrderSchemaContext, MetadataSchema, useMetadataSchemaMaybe } from 'src/lib/metadata'
import NavBar from './NavBar'
import { useParams, useRouteName, useLocation, navigate, routes } from '@redwoodjs/router'
import Sentry from 'src/lib/sentry'
import { usePartsCache, PartsCacheContext } from 'src/lib/usePartsCache'
import PartPanel, { RightPanelContext, RightPanelProvider } from 'src/components/RightPanel/RightPanel'
import { useRightPanel } from '../RightPanel/RightPanel'
import { APP_BODY_QUERY } from 'src/lib/queries'
import { PartsCacheProvider } from 'src/lib/usePartsCache'
import OnboardingModal from 'src/components/OnboardingModal'
import { useOnboarding } from 'src/lib/useOnboarding'

export const QUERY = APP_BODY_QUERY

export const VIEW_MUTATION = gql`
  mutation UserViewMutation {
    updateUserLastLoggedInView
  }
`

export const PAGE_VIEW_MUTATION = gql`
  mutation UserPageViewMutation ($input: RegisterPageViewInput!) {
    registerPageView(input: $input)
  }
`

// TODO: Switch Navbar to a cell - it is not necessary for
// this to be a cell, it only serves to pass navbar its
// data right now

export const Loading = ({ children, width }: PropsWithChildren<{ width?: 'full' }>) => {
  return <AppBody width={width}>{children}</AppBody>
}

export const Success = ({
  children,
  width,
  ...props
}: PropsWithChildren<CellSuccessProps<AppBodyQuery, AppBodyQueryVariables>> & { width?: 'full' }) => {
  return <AppBody width={width} data={props}
    metadataSchema={props.currentOrg.metadataSchema as MetadataSchema}
    changeOrderMetadataSchema={props.currentOrg.changeOrderMetadataSchema as MetadataSchema}
  >
    {children}
  </AppBody>
}

//The changes to the apollo cache mean a few weird states occur without the org param set.
//There is probably a better way to handle that than cycling through the router
export const Failure = (fail: CellFailureProps) => {
  const message = fail.error?.message
  const handledAuthErrors = [
    'You must be a member of an organization',
    'You do not have access to this organization',
  ]
  if (handledAuthErrors.some(e => message?.includes(e))) {
    navigate(routes.noOrg({}), { replace: true })
    return;
  }
  if (message === "Organization not specified") {
    navigate(routes.root())
    return;
  }
  throw fail
}

type Width = 'default' | 'full' | 'panel' | 'panel-full'

type AppBodyProps = PropsWithChildren<{
  data?: CellSuccessProps<AppBodyQuery, AppBodyQueryVariables> | undefined
  metadataSchema?: MetadataSchema,
  changeOrderMetadataSchema?: MetadataSchema,
  width?: Width
}>

const AppBody = ({ children, data, metadataSchema, changeOrderMetadataSchema, width }: AppBodyProps) => {
  const routeName = useRouteName()
  const { partNumber, orgId } = useParams()

  // Onboarding modal logic
  const { showOnboarding, closeOnboarding } = useOnboarding({
    orgCreatedAt: data?.currentOrg?.createdAt,
    isFreemium: data?.currentOrg?.isFreemium,
    userId: data?.me?.id
  })

  const [userView] = useMutation<
    UserViewMutation,
    UserViewMutationVariables
  >(VIEW_MUTATION)
  const [pageView] = useMutation<
    UserPageViewMutation,
    UserPageViewMutationVariables
  >(PAGE_VIEW_MUTATION)

  useEffect(() => {
    (async () => {
      try {
        const d = await userView()
      } catch (e) {
        Sentry.captureException(e)
      }
    })()
  }, [])

  return <AppContext.Provider value={useAppContextValue(data)}>
    <MetadataSchemaContext.Provider value={metadataSchema as MetadataSchema}>
      <ChangeOrderSchemaContext.Provider value={changeOrderMetadataSchema as MetadataSchema}>
        <PartsCacheProvider orgId={orgId!}>
          <RightPanelProvider>
            <NavBar me={data?.me} currentOrg={data?.currentOrg} key='stable' />
            <AppBodyGuarenteeContext>
              <BodyWithContexts
                children={children}
                routeName={routeName}
                partNumber={partNumber}
                pageView={pageView}
                me={data?.me}
              />
              <OnboardingModal
                isOpen={showOnboarding}
                onClose={closeOnboarding}
              />
            </AppBodyGuarenteeContext>
          </RightPanelProvider>
        </PartsCacheProvider>
      </ChangeOrderSchemaContext.Provider>
    </MetadataSchemaContext.Provider>
  </AppContext.Provider>
}

type BodyWithContextsProps = {
  children: React.ReactNode
  routeName: string | undefined
  partNumber: string | undefined
  pageView: (options: { variables: { input: { type: 'PartDetail'; partNumber: string } } }) => Promise<any>
  me: NonNullable<AppBodyQuery['me']>
}
const BodyWithContexts: React.FC<BodyWithContextsProps> = ({ children, routeName, partNumber, pageView, me }) => {
  const { width: panelWidth, isOpen } = useRightPanel()
  const [isDragging, setIsDragging] = useState(false)
  const { refresh: refreshPartsCache } = usePartsCache()

  useEffect(() => {
    (async () => {
      if (isOpen && routeName === 'part' && partNumber) {
        try {
          await pageView({
            variables: {
              input: {
                type: 'PartDetail' as const,
                partNumber
              }
            }
          })
        } catch (e) {
          Sentry.captureException(e)
        }
      }
    })()
  }, [isOpen, routeName, partNumber, pageView])

  // Listen for drag state changes from RightPanel
  useEffect(() => {
    const handleDragStart = () => setIsDragging(true)
    const handleDragEnd = () => setIsDragging(false)

    window.addEventListener('rightpanel:dragstart', handleDragStart)
    window.addEventListener('rightpanel:dragend', handleDragEnd)

    return () => {
      window.removeEventListener('rightpanel:dragstart', handleDragStart)
      window.removeEventListener('rightpanel:dragend', handleDragEnd)
    }
  }, [])

  useEffect(() => {
    // refresh protos on route change
    console.log('Refresh parts cache because route change')
    refreshPartsCache()
  }, [routeName])

  return <><main
    className={`overflow-y-auto fixed top-16 bottom-0 left-0 flex flex-col items-center ${!isDragging ? 'transition-[right] duration-300 ease-in-out' : ''
      }`}
    style={{ right: isOpen ? panelWidth : 0 }}
  >
    <div className='w-full min-h-full'>
      {children}
    </div>
  </main>
    {<PartPanel />}
  </>
}


const AppBodyGuarenteeContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const appContext = useAppContextMaybe()
  const rightPanelContext = useContext(RightPanelContext)
  const partsCacheContext = useContext(PartsCacheContext)

  // other contexts can sometimes be loading even if they are derived from appContext
  const metadataContext = useMetadataSchemaMaybe()
  if (!appContext || !metadataContext || !rightPanelContext || !partsCacheContext || !partsCacheContext.cache) {
    return <LoadingSpinner className='flex p-10 items-center justify-center' />
  }
  return children
}
