import { block } from 'api/src/shared/functional'
import { ControllerTreeNode } from '../PartHierarchy'
import { Tooltip2 } from '../Tooltip'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'

interface Props {
  node: ControllerTreeNode
  readOnly?: boolean
  onRefDesChange?: (refDes: string) => Promise<void>
}

const RefDesCell: React.FC<Props> = ({ node, onRefDesChange, readOnly }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [pillRef, setPillRef] = useState<HTMLDivElement | null>(null)
  const [localRefDes, setLocalRefDes] = useState<string | null | undefined>(
    node.parentToNodeDependency?.referenceDesignator
  )

  const hasChange = block(() => {
    if (!node.previousVersion) {
      return false
    }

    const currentRefDes = localRefDes
    const previousRefDes = node.previousReferenceDesignator

    // Refdes can be empty string, null or undefined
    if (!currentRefDes && !previousRefDes) return false

    return currentRefDes !== previousRefDes
  })

  const tooltipContent = hasChange
    ? `${node.previousReferenceDesignator || 'None'} → ${localRefDes || 'None'}`
    : localRefDes || (readOnly ? 'No Reference Designator' : 'Click to add Reference Designator')

  if (!node.parentToNodeDependency) {
    return null
  }

  return (
    <Tooltip2 content={tooltipContent}>
      <div
        ref={setPillRef}
        onClick={(e) => {
          e.stopPropagation()
          setIsEditing(true)
        }}
        className={`
          text-xs
          w-fit px-2 py-0.5 rounded-xl
          ${readOnly ? '' : 'cursor-pointer hover:ring-2 ring-blue-500 hover:border-transparent'}
          ${hasChange
            ? 'bg-yellow-100  border border-yellow-300 text-yellow-800'
            : 'bg-gray-100  border border-gray-300 text-gray-600'
          }
        `}
      >
        {hasChange ? 'Change' : 'No Change'}
      </div>
      {isEditing && (
        <RefDesInput
          referenceElement={pillRef}
          initialValue={localRefDes || ''}
          onClose={() => setIsEditing(false)}
          onChange={async (value) => {
            setLocalRefDes(value)
            if (onRefDesChange) {
              onRefDesChange(value)
            }
            setIsEditing(false)
          }}
        />
      )}
    </Tooltip2>
  )
}

interface RefDesInputProps {
  referenceElement: HTMLDivElement | null
  initialValue: string
  onClose: () => void
  onChange: (value: string) => Promise<void>
}

const RefDesInput: React.FC<RefDesInputProps> = ({
  referenceElement,
  initialValue,
  onClose,
  onChange
}) => {
  const [value, setValue] = useState(initialValue)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, -24] } },
      { name: 'flip', options: { fallbackPlacements: [] } },
      { name: 'preventOverflow', options: { mainAxis: false, altAxis: false } }
    ],
  })

  const handleSubmit = async () => {
    await onChange(value)
  }

  return createPortal(
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      className="z-[1000]"
    >
      <div className='bg-white shadow-md rounded'>
        <input
          autoFocus
          type="text"
          className='w-32 px-2 py-1 text-sm rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
            if (e.key === 'Escape') {
              onClose()
            }
          }}
          onBlur={handleSubmit}
        />
      </div>
    </div>,
    document.body
  )
}

export default RefDesCell
