import { registerFragment } from '@redwoodjs/web/apollo'
export const dependencyDisplayFragment = registerFragment(gql`
  fragment DependencyDisplayFragment on Dependency {
    section
    referenceDesignator
    quantity
    units
    toVersionRange
    to {
      id
      proto {
        id
        currentVersionString
        category {
          id
          name
        }
      }
      name
      partNumber
      nodeType
      version
      publishId
      partNumber
      lifeCycle
      branch
    }
  }
`)

export const changesPartFragment = registerFragment(gql`
  fragment ChangesPartFragment on Part {
    id
    isRoot
    isOffTheShelf
    partNumber
    nodeType
    name
    version
    lifeCycle
    summary
    changeMessage
    transitionPlan
    cadRev
    isRoot
    isOffTheShelf
    metadata
    ancestors {
      part {
        id
        partNumber
        lifeCycle
      }
    }
    artifacts {
      id
      isThumbnail
      filename
      fileId
      file {
        id
        url
        inlineUrl
        contentType
        size
        timestamp
      }
    }
    dependencies {
      section
      referenceDesignator
      quantity
      units
      toVersionRange
      to {
        id
        name
        partNumber
        version
        publishId
        partNumber
        lifeCycle
        branch
        artifacts {
          id
          isThumbnail
          filename
          fileId
          file {
            id
            url
            inlineUrl
            contentType
            size
            timestamp
          }
        }
      }
    }
    sources {
      id
      url
      comment
      distributorId
      distributor {
        id
        name
      }
      priority
      distributorSku
      price
      priceCurrency
      leadTimeDays
      perQuantity
      perQuantityUnit
      stock
    }
    updateToPublishId
  }
`)

export const CHANGE_ORDER_CHANGES_QUERY = gql`
query ChangeOrderChangesQuery ($orderNumber: Int!, $partNumber: String!, $partId: ID) {
  currentOrg {
    name
    id
    metadataSchema
    defaultPartVersion
    distributors {
      id
      name
    }
  }
  partById (id: $partId) {
    ...ChangesPartFragment
  }
  partProto(partNumber: $partNumber) {
    id
      partNumber
      currentVersionString
      category {
        id
        name
      }
      currentVersion {
        ...ChangesPartFragment
      }
      currentPublishId
    }
  changeOrder (number: $orderNumber) {
    _id
    state
    number
    includedParts(partNumber: $partNumber) {
      ...ChangesPartFragment
      updateTo {
        ...ChangesPartFragment
      }
    }
  }
}
`

export const CHANGE_ORDER_TREE_QUERY = gql`
query ChangeOrderTreeQuery ($orderNumber: Int!, $partContext: String) {
  currentOrg {
    name
    id
    metadataSchema
    defaultPartVersion
    distributors {
      id
      name
    }
  }
  changeOrder (number: $orderNumber) {
    _id
    state
    number
    name
    description
    reviewers {
      user {
        name
      }
    }
    baseOrphans(partContext: $partContext) {
      ...ChangeOrderBasePartFragment
    }
    includedParts {
      ...ChangeOrderIncludedPartFragment
    }
  }
}
`

export const changeOrderIncludedPartFragment = registerFragment(gql`
  fragment ChangeOrderIncludedPartFragment on Part {
      id
      partNumber
      nodeType
      name
      version
      lifeCycle
      summary
      changeMessage
      transitionPlan
      cadRev
      isRoot
      isOffTheShelf
      metadata
      ancestors {
        part {
          id
          partNumber
          lifeCycle
        }
      }
      artifacts {
        id
        isThumbnail
        filename
        fileId
        file {
          id
          url
          inlineUrl
          contentType
          size
          timestamp
        }
      }
      dependencies {
        ...DependencyDisplayFragment
      }
      sources {
        id
        url
        comment
        distributorId
        distributor {
          id
          name
        }
        priority
        distributorSku
        price
        priceCurrency
        leadTimeDays
        perQuantity
        perQuantityUnit
        stock
      }
      updateTo {
        id
        partNumber
        name
        isRoot
        isOffTheShelf
        version
        lifeCycle
        summary
        changeMessage
        cadRev
        metadata
        nodeType
        ancestors {
          part {
            id
            partNumber
            lifeCycle
          }
        }
        artifacts {
          id
          isThumbnail
          filename
          fileId
          file {
            id
            url
            inlineUrl
            contentType
            size
            timestamp
          }
        }
        sources {
          id
          url
          comment
          distributorId
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          perQuantity
          perQuantityUnit
          stock
        }
        dependencies {
          ...DependencyDisplayFragment
          to {
            id
            artifacts {
              id
              isThumbnail
              filename
              fileId
              file {
                id
                url
                inlineUrl
                contentType
                size
                timestamp
              }
            }
          }
        }
      }
      updateToPublishId
      proto {
        id
        partNumber
        currentVersionString
        category {
          id
          name
        }
        currentPublishId
      }
    }
`)

export const changeOrderBaseUpdatePartFragment = registerFragment(gql`
  fragment ChangeOrderBaseUpdatePartFragment on Part {
    id
    name
    partNumber
    summary
    version
    lifeCycle
    publishId
    proto {
      id
      currentVersionString
      category {
        id
        name
      }
    }
    tree {
      ...PartTreeNodeDependencyFragment
    }
  }
`)

export const changeOrderBasePartFragment = registerFragment(gql`
  fragment ChangeOrderBasePartFragment on Part {
    ...ChangeOrderBaseUpdatePartFragment
    tree {
      ...PartTreeNodeFragment
    }
  }
`)


export const EXPORT_CHANGE_ORDER_QUERY = gql`
query ExportChangeOrderQuery ($orderNumber: Int!, $partContext: String) {
  currentOrg {
    name
    id
    metadataSchema
    defaultPartVersion
    distributors {
      id
      name
    }
  }
  changeOrder (number: $orderNumber) {
    _id
    state
    number
    name
    description
    reviewers {
      user {
        name
      }
    }
    baseOrphans(partContext: $partContext) {
      name
      partNumber
      summary
      version
      lifeCycle
      publishId
      proto {
        id
        currentVersionString
        category {
          id
          name
        }
      }
      dependencies {
        ...DependencyDisplayFragment
      }
      tree {
        ...PartTreeNodeFragment
      }
    }
    includedParts(partContext: $partContext) {
      id
      partNumber
      nodeType
      name
      version
      lifeCycle
      summary
      changeMessage
      transitionPlan
      cadRev
      isRoot
      isOffTheShelf
      metadata
      ancestors {
        part {
          id
          partNumber
          lifeCycle
        }
      }
      artifacts {
        id
        isThumbnail
        filename
        fileId
        file {
          id
          url
          inlineUrl
          contentType
          size
          timestamp
        }
      }
      dependencies {
        ...DependencyDisplayFragment
      }
      sources {
        id
        url
        comment
        distributorId
        distributor {
          id
          name
        }
        priority
        distributorSku
        price
        priceCurrency
        leadTimeDays
        perQuantity
        perQuantityUnit
        stock
      }
      updateTo {
        id
        nodeType
        partNumber
        name
        isRoot
        isOffTheShelf
        version
        lifeCycle
        summary
        changeMessage
        cadRev
        metadata
        ancestors {
          part {
            id
            partNumber
            lifeCycle
          }
        }
        artifacts {
          id
          isThumbnail
          filename
          fileId
          file {
            id
            url
            inlineUrl
            contentType
            size
            timestamp
          }
        }
        sources {
          id
          url
          comment
          distributorId
          distributor {
            id
            name
          }
          priority
          distributorSku
          price
          priceCurrency
          leadTimeDays
          perQuantity
          perQuantityUnit
          stock
        }
        dependencies {
          ...DependencyDisplayFragment
          to {
            id
            nodeType
            artifacts {
              id
              isThumbnail
              filename
              fileId
              file {
                id
                url
                inlineUrl
                contentType
                size
                timestamp
              }
            }
          }
        }
      }
      updateToPublishId
      proto {
        id
        partNumber
        currentVersionString
        category {
          id
          name
        }
        currentPublishId
      }
    }
  }
}
`

export const CHANGE_ORDER_QUERY = gql`
  query FindChangeOrderQuery($orderNumber: Int!) {
    me {
      id
      isSuperUser
    }
    users {
      id
      name
      email
    }
    currentOrg {
      id
      me {
        role
      }
      userGroups {
        id
        name
        members { userId }
      }
      lifeCycleStages {
        key
        name
        color
        isEndOfLife
      }
    }

    changeOrder: changeOrder(number: $orderNumber) {
      ...ChangeOrderReviewersFragment
      _id
      state
      mergeConflicts
      invalidLifeCycle
      highestLifeCycle
      metadata
      number
      name
      description
      includedPartNumbers
      creator {
        id
      }
      tasks {
        id
        number
        title
        status
        priority
        createdAt
        updatedAt
        assignedTo {
          id
          name
        }
        comments {
          id
        }
      }
      reviewers {
        response
        anotherReviewRequested
        role
        user {
          id
          name
        }
      }
      workflow {
        groupIds
        rules {
          condition {
            type
            stage
          }
          effect {
            type
            groupId
          }
        }
      }
      reviewerGroups {
        groupId
      }
      log {
        user {
          name
        }
        children {
          action
          partNumber
          payload
        }
        id
        action
        createdAt
        payload
        partNumber
      }
    }
  }
`

export const partTreeNodePartFragment = registerFragment(gql`
  fragment PartTreeNodePartFragment on Part {
    id
    nodeType
    name
    cadRev
    version
    lifeCycle
    summary
    partNumber
    metadata
    publishId
    isOffTheShelf
    isRoot
    sources {
      id
      url
      comment
      distributorId
      distributor {
        id
        name
      }
      priority
      distributorSku
      price
      priceCurrency
      leadTimeDays
      perQuantity
      perQuantityUnit
      stock
    }
    artifacts {
      id
      fileId
      filename
      isThumbnail
      file {
        id
        url
        inlineUrl
        contentType
        size
        confirmed
        path
        timestamp
      }
    }
    proto {
      id
      currentVersionString
      category {
        id
        name
      }
    }
  }
`)

export const partTreeNodeDependencyFragment = registerFragment(gql`
  fragment PartTreeNodeDependencyFragment on PartTreeNode {
    hierarchy
    address
    partNumberAddress
    isTreeRoot
    parentToNodeDependency {
      quantity
      units
      section
      toVersionRange
      referenceDesignator
    }
    part {
      id
    }
  }
`)

export const partTreeNodeFragment = registerFragment(gql`
  fragment PartTreeNodeFragment on PartTreeNode {
    ...PartTreeNodeDependencyFragment
    part {
      ...PartTreeNodePartFragment
    }
  }
`)

export const ADD_CHANGE_ORDER_COMMENT_MUTATION = gql`
mutation AddChangeOrderCommentMutation ($orderNumber: Int!, $message: String!, $reviewType: ChangeOrderResponse) {
  commentOnChangeOrder(number: $orderNumber, input: { message: $message, reviewType: $reviewType }) {
    payload
  }
}
`

export const SET_CHANGE_ORDER_STATE_MUTATION = gql`
mutation SetChangeOrderStateMutation ($orderNumber: Int!, $state: ChangeOrderState!, $message: String) {
  setChangeOrderState(number: $orderNumber, input: { state: $state, message: $message }) {
    state
  }
}
`

export const APP_BODY_QUERY = gql`
  query AppBodyQuery {
    currentOrg {
      id
      isFreemium
      createdAt
      metadataSchema
      changeOrderMetadataSchema
      members {
        user {
          id
          name
        }
      }
      distributors {
        id
        name
      }
      lifeCycleStages {
        key
        name
        color
        isEndOfLife
      }
      defaultPartVersion
      aggregationConfigs {
        name
        reducer
        targetType {
          type
          unit
        }
        metadata {
          key
          multiplyByQuantity
        }
        sources {
          key
          multiplyByQuantity
        }
      }
      manualExportMapper
      exchangeRates
    }
    partNumberSchemas {
      key
      templateConfig
      categories {
        id
        name
      }
    }
    partCategories {
      id
      name
      label
      useRange
      schema {
        key
        template
        templateConfig
      }
    }
    me {
      id
      name
      latestNotification
      email
      notificationsReadAt
      isSuperUser
      defaultOrgId
      memberOf {
        orgId
        role
        slackUserId
      }
    }
    changeOrders {
      _id
      state
      name
      number
    }
  }
`

export const PART_ANCESTORS_QUERY = gql`
  query PartAncestors($partId: ID!) {
    partById(id: $partId) {
      id
      partNumber
      version
      dependencies {
        quantity
        units
        toVersionRange
        referenceDesignator
        section
        to {
          id
          partNumber
          version
        }
      }
      ancestors {
        part {
          id
          partNumber
        }
      }
    }
  }
`

export const EDIT_PART_MUTATION = gql`
mutation EditPartMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
  $name: String
  $cadRev: String
  $summary: String
  $isRoot: Boolean
  $isOffTheShelf: Boolean
  $changeMessage: String
  $transitionPlan: String
  $artifacts: [ArtifactFieldInput!]
  $metadata: JSON
  $dependencies: [DependencyInput!]
  $nodeType: NodeType
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: [{
    type: Patch
    partNumber: $partNumber
    version: $version
    part: {
      summary: $summary
      isRoot: $isRoot
      name: $name
      cadRev: $cadRev
      nodeType: $nodeType
      isOffTheShelf: $isOffTheShelf
      transitionPlan: $transitionPlan
      changeMessage: $changeMessage
      artifacts: $artifacts
      metadata: $metadata
      dependencies: $dependencies
    }
  }]) {
    partNumber
  }
}
`

export const ADD_PART_DELTA_MUTATION = gql`
mutation AddPartDeltaMutation (
  $changeOrderNumber: Int!
  $input: [PartDeltaInput!]!
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: $input) {
    id
    partNumber
  }
}
`

export const SMART_ADD_PART_DELTA_MUTATION = gql`
mutation AddPartDeltaMutationWithCache (
  $changeOrderNumber: Int!
  $partContext: String
  $input: [PartDeltaInput!]!
) {
  addPartDeltasWithCache(changeOrderNumber: $changeOrderNumber, input: $input) {
    modifiedParts {
      ...ChangeOrderIncludedPartFragment
      ...ChangeOrderBasePartFragment
    }
    changeOrder {
      _id
      includedParts {
        id
      }
      baseOrphans (partContext: $partContext) {
        ...ChangeOrderBaseUpdatePartFragment
      }
    }
  }
}
`

export const REMOVE_PART_MUTATION = gql`
  mutation RemovePart($changeOrderNumber: Int!, $input: RemovePartInput!, $partContext: String) {
    removePart(changeOrderNumber: $changeOrderNumber, input: $input) {
      modifiedParts {
        ...ChangeOrderIncludedPartFragment
        ...ChangeOrderBasePartFragment
      }
      changeOrder {
        _id
        state
        number
        name
        description
        reviewers {
          user {
            name
          }
        }
        baseOrphans(partContext: $partContext) {
          ...ChangeOrderBaseUpdatePartFragment
        }
        includedParts {
            id
            partNumber
            version
          }
        }
    }
  }
`
