import { useState, useEffect, useCallback } from 'react'

const ONBOARDING_SHOWN_KEY = 'onboarding_shown'

// Module-level singleton state
let globalShowOnboarding = false;
const listeners: Set<(value: boolean) => void> = new Set();

// Global state management functions
const setGlobalShowOnboarding = (value: boolean) => {
  globalShowOnboarding = value;
  // Notify all listeners
  listeners.forEach(listener => listener(value));
};

type UseOnboardingProps = {
  orgCreatedAt?: string
  isFreemium?: boolean
  userId?: string
}

export const useOnboarding = ({ orgCreatedAt, isFreemium, userId }: UseOnboardingProps = {}) => {
  // Local state that syncs with global state
  const [showOnboarding, setLocalShowOnboarding] = useState(globalShowOnboarding);

  // Register this component as a listener for global state changes
  useEffect(() => {
    const listener = (value: boolean) => {
      setLocalShowOnboarding(value);
    };

    listeners.add(listener);
    return () => {
      listeners.delete(listener);
    };
  }, []);

  // Initialize onboarding state if needed
  useEffect(() => {
    if (!orgCreatedAt || !userId || !isFreemium) return;

    // Check if we've already shown the onboarding for this org to this user
    const shownKey = `${ONBOARDING_SHOWN_KEY}_${userId}`;
    const hasShownOnboarding = localStorage.getItem(shownKey);

    if (hasShownOnboarding) return;

    // Check if org was created less than 5 minutes ago
    const createdDate = new Date(orgCreatedAt);
    const now = new Date();
    const fiveMinutesInMs = 5 * 60 * 1000;
    const isNewOrg = now.getTime() - createdDate.getTime() < fiveMinutesInMs;

    if (isNewOrg) {
      setGlobalShowOnboarding(true);
    }
  }, [orgCreatedAt, userId, isFreemium]);

  // Wrapper for setting global state
  const setShowOnboarding = useCallback((value: boolean) => {
    setGlobalShowOnboarding(value);
  }, []);

  // Close onboarding and save to localStorage
  const closeOnboarding = useCallback(() => {
    if (userId) {
      // Mark this onboarding as shown for this user
      const shownKey = `${ONBOARDING_SHOWN_KEY}_${userId}`;
      localStorage.setItem(shownKey, 'true');
    }
    setGlobalShowOnboarding(false);
  }, [userId]);

  return {
    showOnboarding,
    setShowOnboarding,
    closeOnboarding
  };
};
