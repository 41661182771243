import {
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

type PopoverPlacement = 'right' | 'left' | 'top' | 'bottom'

interface HoverPopoverProps {
  trigger: React.ReactNode
  content: React.ReactNode | (() => React.ReactNode)
  className?: string
  popoverWidth?: number
  popoverHeight?: number
  offset?: number
  triggerWidth?: number
  direction?: PopoverPlacement
popoverClassName?: string
popoverStyle?: React.CSSProperties
contentClassName?: string
contentStyle?: React.CSSProperties
}

const CLOSE_TIMEOUT = 60

export default function HoverPopover({
  trigger,
  content,
  className,
  popoverWidth = 384, // w-96 in Tailwind's default config
  popoverHeight = 320, // h-80 in Tailwind
  offset = 8,
  triggerWidth = 24,
  direction = 'right',
  popoverClassName,
  popoverStyle,
  contentClassName,
  contentStyle,
}: HoverPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const closeTimeoutRef = useRef<NodeJS.Timeout>();
  const [position, setPosition] = useState({ top: 0, left: 0, transform: 'translateY(-50%)' });
  const triggerRef = useRef<HTMLDivElement>(null);

  const PADDING = 24; // p-3 equals 0.75rem which is 12px
  useEffect(() => {
    if (isOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      let topPos = rect.top + rect.height / 2 + window.scrollY;
      let leftPos = rect.right + offset + window.scrollX;
      let transform = 'translateY(-50%)';

      const isVertical = direction === 'top' || direction === 'bottom';

      if (isVertical) {
        // Vertical positioning logic
        leftPos = rect.left + (rect.width / 2) + window.scrollX;
        transform = 'translateX(-50%)';

        if (direction === 'top') {
          topPos = rect.top - offset - popoverHeight - PADDING + window.scrollY;

          // Check if it overflows top
          if (topPos < window.scrollY) {
            // Fall back to bottom
            topPos = rect.bottom + offset + window.scrollY;
          }
        } else {
          topPos = rect.bottom + offset + window.scrollY;
          // Check if it overflows bottom
          if (topPos + popoverHeight > window.innerHeight + window.scrollY) {
            // Fall back to top
            topPos = rect.top - offset - popoverHeight - PADDING + window.scrollY;
          }
        }
      } else {
        // Horizontal positioning logic
        if (direction === 'left') {
          leftPos = rect.left - offset - popoverWidth - PADDING + window.scrollX;
          // Check if it overflows left
          if (leftPos < 0) {
            // Fall back to right
            leftPos = rect.right + offset + window.scrollX;
          }
        } else {
          // Check if it overflows right
          if (leftPos + popoverWidth > window.innerWidth) {
            // Fall back to left
            leftPos = rect.left - offset - popoverWidth - PADDING + window.scrollX;
          }
        }
      }

      setPosition({ top: topPos, left: leftPos, transform });
    }
  }, [isOpen, offset, popoverWidth, popoverHeight, triggerWidth, direction]);

  return (
    <>
      <div
        ref={triggerRef}
        className={classNames("inline-block", className)}
        onMouseEnter={() => {
          if (closeTimeoutRef.current) {
            clearTimeout(closeTimeoutRef.current);
          }
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          closeTimeoutRef.current = setTimeout(() => setIsOpen(false), CLOSE_TIMEOUT);
        }}
      >
        {trigger}
      </div>
      {/* Hack to preload the thumbnail image */}
      <div className='hidden'>
      {typeof content === 'function' ? content() : content}
      </div>

      {isOpen &&
        createPortal(
          <div
            style={{
              position: 'absolute',
              top: position.top,
              left: position.left,
              transform: position.transform,
              ...popoverStyle
            }}
            className={twMerge("bg-white shadow-lg p-3 z-[9999]", popoverClassName)}
            // onMouseEnter={() => {
            //   if (closeTimeoutRef.current) {
            //     clearTimeout(closeTimeoutRef.current);
            //   }
            //   setIsOpen(true);
            // }}
            // onMouseLeave={() => {
            //   closeTimeoutRef.current = setTimeout(() => setIsOpen(false), CLOSE_TIMEOUT);
            // }}
          >
            <div
              className={contentClassName}
              style={{
                width: popoverWidth,
                height: popoverHeight,
                ...contentStyle
              }}
            >
              {typeof content === 'function' ? content() : content}
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
