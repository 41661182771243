import { useState } from 'react'
import type {
  OrgSettingsUsersQuery,
  InviteToOrganizationMutation,
  InviteToOrganizationMutationVariables,
  UserRole,
  RemoveFromOrganizationMutation,
  RemoveFromOrganizationMutationVariables
} from 'types/graphql'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import * as Form from 'src/components/Form'
import Button from 'src/components/Button'
import * as ListBox from 'src/components/ListBox'
import { reportMutationError } from 'src/lib/reportError'
import { useAppContext } from 'src/lib/appContext'

export const QUERY = gql`
  query OrgSettingsUsersQuery {
    currentOrg {
      id
      name
      me {
        role
      }
      members {
        role
        user {
          id
          name
          email
        }
      }
      pendingInvites {
        id
        email
        role
        expiresAt
        createdAt
      }
    }
  }
`

export const Loading = () => <div className='flex justify-center p-2'><LoadingSpinner /></div>

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'
export const Failure = GenericFailure

const INVITE_MUTATION = gql`
  mutation InviteToOrganizationMutation($input: InviteToOrganizationInput!) {
    inviteToOrganization(input: $input)
  }
`
const REMOVE_USER_MUTATION = gql`
  mutation RemoveFromOrganizationMutation($input: RemoveFromOrganizationInput!) {
    removeFromOrganization(input: $input)
  }
`

export const Success = ({ currentOrg }: CellSuccessProps<OrgSettingsUsersQuery>) => {
  const { hasEscalatedPrivileges } = useAppContext()
  const [inviteEmail, setInviteEmail] = useState('')
  const [inviteRole, setInviteRole] = useState<UserRole>('ReadWrite')
  const myRole = currentOrg.me!.role

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const emailError = inviteEmail && !isValidEmail(inviteEmail)
    ? 'Please enter a valid email address'
    : null

  const [invite, { loading, error }] = useMutation<
    InviteToOrganizationMutation,
    InviteToOrganizationMutationVariables
  >(INVITE_MUTATION)

  const [removeUser, { loading: removeLoading, error: removeError }] = useMutation<RemoveFromOrganizationMutation, RemoveFromOrganizationMutationVariables>(REMOVE_USER_MUTATION)

  const handleInvite = async () => {
    const variables = {
      input: {
        email: inviteEmail,
        role: inviteRole
      }
    }

    const { errors } = await invite({
      variables,
      refetchQueries: [{ query: QUERY }]
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: 'Error inviting user'
      })
    } else {
      setInviteEmail('')
    }
  }

  // Check if current user can remove a member based on roles
  const canRemoveUser = (memberRole: UserRole) => {
    if (memberRole === 'Owner') return false
    if (myRole === 'Owner') return true
    if (myRole === 'Admin' && memberRole !== 'Admin') return true
    return false
  }

  const handleRemoveUser = async (userId: string) => {
    if (confirm('Are you sure you want to remove this user from the organization?')) {
      const variables = {
        input: {
          userId: parseInt(userId, 10)
        }
      }

      try {
        const { errors } = await removeUser({
          variables,
          refetchQueries: [{ query: QUERY }]
        })

        if (errors) {
          reportMutationError({
            errors,
            variables,
            message: 'Error removing user'
          })
        }
      } catch (error) {
        console.error('Failed to remove user:', error)
      }
    }
  }

  return (
    <div className='pb-12 flex flex-col gap-5'>
      <div className='bold text-2xl'>
        Organization Members
      </div>

      <div className='border rounded-md p-4'>
        <table className='w-full'>
          <thead>
            <tr className='text-left border-b'>
              <th className='pb-2 w-[30%]'>Name</th>
              <th className='pb-2 w-[35%]'>Email</th>
              <th className='pb-2 w-[15%]'>Role</th>
              <th className='pb-2 w-[20%]'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentOrg.members.map(member => (
              <tr key={member.user.id} className='border-b last:border-0'>
                <td className='py-3'>{member.user.name}</td>
                <td className='py-3'>{member.user.email}</td>
                <td className='py-3'>{member.role}</td>
                <td className='py-3'>
                  {canRemoveUser(member.role as UserRole) && (
                    <Button
                      variant='red'
                      size='sm'
                      onClick={() => handleRemoveUser(member.user.id)}
                      disabled={removeLoading}
                    >
                      Remove
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {removeError && (
          <div className='mt-2 text-red-600'>{removeError.message}</div>
        )}
      </div>

      {Boolean(currentOrg.pendingInvites?.length) && (
        <div className='border rounded-md p-4'>
          <div className='font-medium mb-4'>Pending Invites</div>
          <table className='w-full'>
            <thead>
              <tr className='text-left border-b'>
                <th className='pb-2 w-[45%]'>Email</th>
                <th className='pb-2 w-[25%]'>Role</th>
                <th className='pb-2 w-[30%]'>Expires</th>
              </tr>
            </thead>
            <tbody>
              {currentOrg.pendingInvites.map(invite => {
                const isExpired = new Date(invite.expiresAt) < new Date()
                return (
                  <tr key={invite.id} className='border-b last:border-0'>
                    <td className='py-3'>{invite.email}</td>
                    <td className='py-3'>{invite.role}</td>
                    <td className='py-3'>
                      <div className="flex items-center gap-2">
                        <span>{new Date(invite.expiresAt).toLocaleDateString()}</span>
                        {isExpired && (
                          <span className="text-xs px-2 py-0.5 bg-red-100 text-red-800 rounded">
                            Expired
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {hasEscalatedPrivileges && (
        <div className='border rounded-md p-4'>
          <div className='font-medium mb-4'>Invite New Member</div>
          <div className='flex gap-4 items-end'>
            <div className='flex-1'>
              <Form.Label>Email</Form.Label>
              <Form.TextField
                controlled
                value={inviteEmail}
                onChange={e => setInviteEmail(e.target.value)}
                placeholder="user@example.com"
                autoComplete="new-email"
                data-1p-ignore="true"
                data-lpignore="true"
              />
            </div>
            <div>
              <Form.Label>Role</Form.Label>
              <ListBox.ListBox
                value={inviteRole}
                onChange={(value) => setInviteRole(value as UserRole)}
                as='div'
              >
                <ListBox.Button
                  displayValue={inviteRole.replace(/([A-Z])/g, ' $1').trim()}
                  className="w-32"
                />
                <ListBox.Options align='left'>
                  <ListBox.Option value="ReadOnly" display="Read Only" />
                  <ListBox.Option value="ReadWrite" display="Read Write" />
                  <ListBox.Option value="Admin" display="Admin" />
                </ListBox.Options>
              </ListBox.ListBox>
            </div>
            <Button
              variant='primary'
              onClick={handleInvite}
              disabled={loading || !inviteEmail || !!emailError}
            >
              Send Invite
            </Button>
          </div>
          {error && (
            <div className='mt-2 text-red-600'>{error.message}</div>
          )}
        </div>
      )}
    </div>
  )
}
