import { Tooltip2 } from 'src/components/Tooltip'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from 'headlessuinext'
import React from 'react'
import { useAppContext } from 'src/lib/appContext'
import { CheckIcon } from '@heroicons/react/20/solid'

interface MenuOption {
  disabled?: boolean
  display: React.ReactNode
  value: string
  active?: boolean
}

interface MenuProps {
  options: MenuOption[]
  onSelect: (value: string) => void
}

interface ToolButtonProps {
  writeOnly?: boolean
  children: React.ReactNode
  tooltipContent?: React.ReactNode
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right'
  disabled?: boolean
  testId?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  menu?: MenuProps
  rightText?: string
}

const buttonClasses = `
  inline-flex items-center justify-center
  rounded-md font-normal shadow-xs
  focus-visible:outline focus-visible:outline-2
  focus-visible:outline-offset-2 border
  focus-visible:outline-brand-500
  bg-white text-gray-900 hover:bg-gray-50 border-gray-300
  px-1 h-6 text-xs
  disabled:cursor-not-allowed disabled:bg-gray-50
  disabled:text-gray-500 disabled:border-gray-200
`

const ToolButton: React.FC<ToolButtonProps> = ({
  children,
  writeOnly,
  tooltipContent,
  tooltipPlacement = 'top',
  disabled,
  testId,
  onClick,
  menu,
  rightText,
}) => {

  const appContext = useAppContext()
  if (writeOnly && appContext?.isReadOnlyUser) {
    disabled = true
    tooltipContent = 'Disabled for read-only'
  }

  const buttonContent = (
    <>
      <div className='w-4 h-4 flex items-center justify-center'>{children}</div>
      {rightText && <span className="ml-2 mr-1 whitespace-nowrap">{rightText}</span>}
    </>
  )

  const button = menu ? (
    <Listbox value="" onChange={menu.onSelect}>
      {({ open }) => (
        <div className="relative inline-block">
          <ListboxButton
            className={buttonClasses}
            disabled={disabled}
            data-testid={testId}
          >
            {buttonContent}
          </ListboxButton>
          <ListboxOptions className={`
            absolute right-0 z-50 mt-1 text-xs bg-white
            rounded-md shadow-lg ring-1 ring-black ring-opacity-5
            focus:outline-none min-w-[200px] whitespace-nowrap
          `}>
            {menu.options.map((option, index) => (
              <ListboxOption
                key={index}
                value={option.value}
                disabled={option.disabled}
                className={`
                  relative cursor-pointer select-none py-2 px-4
                  text-gray-900 whitespace-nowrap
                  data-[disabled]:opacity-50
                  data-[disabled]:cursor-not-allowed
                  data-[focus]:bg-brand-500
                  data-[focus]:text-white
                `}
              >
                <div className="flex items-center">
                  <span className="flex-grow">{option.display}</span>
                  {option.active && (
                    <CheckIcon className="h-4 w-4" />
                  )}
                </div>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      )}
    </Listbox>
  ) : (
    <button
      type="button"
      className={buttonClasses}
      disabled={disabled}
      data-testid={testId}
      onClick={onClick}
    >
      {buttonContent}
    </button>
  )

  if (tooltipContent) {
    return (
      <Tooltip2
        content={tooltipContent}
        placement={tooltipPlacement}
      >
        {button}
      </Tooltip2>
    )
  }

  return button
}

export default ToolButton
