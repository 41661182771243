import type { TasksQuery } from 'types/graphql'
import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { routes, useParams, Link, useLocation } from '@redwoodjs/router'
import { prettyDateTime } from 'src/lib/formatters'
import GenericFailure from '../Failure/Failure'
import { useAppContext } from 'src/lib/appContext'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import {
  TicketIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline'
import Button from 'src/components/Button'
import { RichContent, RichContentEditor } from 'src/components/RichContent/RichContent'
import { useMutation } from '@redwoodjs/web'
import { useRef, useCallback, useEffect, useState } from 'react'
import type { Editor } from '@tiptap/react'
import { debounce } from 'lodash'
import StateSelect from './StateSelect'
import UserSelect from './UserSelect'
import { Form, Submit } from 'src/components/Form'
import { QUERY as TASKS_QUERY } from 'src/components/TasksCell'
import { Menu } from '@headlessui/react'
import { navigate } from '@redwoodjs/router'
import { useRightPanel } from 'src/components/RightPanel/RightPanel'
import { reportMutationError } from 'src/lib/reportError'

export const QUERY = gql`
  query TaskQuery($taskNumber: Int!) {
    task(number: $taskNumber) {
      id
      number
      title
      body
      status
      priority
      createdAt
      updatedAt

      createdBy {
        id
        name
      }
      assignedTo {
        id
        name
      }
      assignedChangeOrder {
        number
        name
      }
      linkedChangeOrders {
        number
        name
      }
      linkedProtos {
        partNumber
        currentVersion {
          name
        }
      }
      comments {
        id
        body
        createdAt
        author {
          id
          name
        }
      }
    }
  }
`

const UPDATE_TASK_MUTATION = gql`
  mutation TaskCellUpdateTaskMutation($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      number
      title
      body
      status
      priority
      updatedAt
    }
  }
`

const ADD_COMMENT_MUTATION = gql`
  mutation AddTaskCommentMutation($input: AddTaskCommentInput!) {
    addTaskComment(input: $input) {
      id
      body
      createdAt
      author {
        id
        name
      }
    }
  }
`

const DELETE_TASK_MUTATION = gql`
  mutation DeleteTaskMutation($id: Int!) {
    deleteTask(id: $id)
  }
`

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

export const Failure = GenericFailure

interface LinkedChangeOrder {
  number: number
  name: string
}

interface LinkedProto {
  partNumber: string
  currentVersion?: {
    name: string
  }
}

interface Comment {
  id: string
  body: string
  createdAt: string
  author: {
    id: string
    name: string
  }
}

interface TaskProps {
  task: {
    id: string
    number: number
    title: string
    body: string
    status: string
    priority: string
    createdAt: string
    updatedAt: string
    createdBy: {
      id: string
      name: string
    }
    assignedTo?: {
      id: string
      name: string
    }
    assignedChangeOrder?: {
      number: number
      name: string
    }
    linkedChangeOrders: LinkedChangeOrder[]
    linkedProtos: LinkedProto[]
    comments: Comment[]
  }
  isPanel?: boolean
}

interface CellSuccessProps {
  task: TaskProps['task']
  isPanel?: boolean
}

const Task = ({ task, isPanel }: TaskProps) => {
  const { me } = useAppContext()
  const orgId = useParams().orgId!
  const rightPanel = useRightPanel()
  const editorRef = useRef<Editor | null>(null)
  const commentEditorRef = useRef<Editor | null>(null)

  const [updateTask] = useMutation(UPDATE_TASK_MUTATION)
  const [addComment] = useMutation(ADD_COMMENT_MUTATION)
  const [deleteTask] = useMutation(DELETE_TASK_MUTATION)

  const [title, setTitle] = useState(task.title)

  const saveContent = useCallback(async () => {
    if (!editorRef.current) return

    try {
      const { errors } = await updateTask({
        variables: {
          input: {
            id: task.id,
            body: JSON.stringify(editorRef.current.getJSON())
          }
        }
      })

      if (errors) {
        reportMutationError({
          errors,
          variables: {
            input: {
              id: task.id,
              body: JSON.stringify(editorRef.current.getJSON())
            }
          },
          message: 'Failed to update task content'
        })
      }
    } catch (error) {
      console.error('Error updating task:', error)
    }
  }, [task.id, updateTask])

  const debouncedSave = useCallback(
    debounce(() => {
      saveContent()
    }, 1000),
    [saveContent]
  )

  const saveTitle = useCallback(async (newTitle: string) => {
    try {
      const { errors } = await updateTask({
        variables: {
          input: {
            id: task.id,
            title: newTitle
          }
        }
      })

      if (errors) {
        reportMutationError({
          errors,
          variables: {
            input: {
              id: task.id,
              title: newTitle
            }
          },
          message: 'Failed to update task title'
        })
      }
    } catch (error) {
      console.error('Error updating task title:', error)
    }
  }, [task.id, updateTask])

  const debouncedSaveTitle = useCallback(
    debounce((newTitle: string) => {
      saveTitle(newTitle)
    }, 1000),
    [saveTitle]
  )

  // Cleanup the debounced function when component unmounts
  useEffect(() => {
    return () => {
      debouncedSave.cancel()
      debouncedSaveTitle.cancel()
    }
  }, [debouncedSave, debouncedSaveTitle])

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value
    setTitle(newTitle)
    debouncedSaveTitle(newTitle)
  }

  const handleDelete = async () => {
    if (confirm('Are you sure you want to delete this task?')) {
      try {
        const { errors } = await deleteTask({
          variables: { id: parseInt(task.id) },
          refetchQueries: [{ query: TASKS_QUERY }],
        })

        if (errors) {
          reportMutationError({
            errors,
            variables: { id: parseInt(task.id) },
            message: 'Failed to delete task'
          })
          return
        }

        if (rightPanel.isOpen &&
            rightPanel.routeName === 'task' &&
            rightPanel.params?.taskNumber === task.number) {
          rightPanel.closePanel()
        }

        if (window.location.pathname.includes(`/task/${task.number}`)) {
          navigate(routes.home({ orgId }))
        }
      } catch (error) {
        console.error('Error deleting task:', error)
      }
    }
  }

  return (
    <div className="flex flex-col gap-8 max-w-5xl pb-12">
      {/* Header Section */}
      <div>
        <div className="flex items-center gap-3">
          <div className="flex-1">
            <div className="flex items-center gap-2">
              <div className="text-2xl text-gray-900 whitespace-nowrap">#T-{task.number}</div>
              <input
                type="text"
                value={title}
                onChange={handleTitleChange}
                className="text-2xl text-gray-900 bg-transparent border-none focus:outline-none focus:ring-0 w-full"
                placeholder="No name"
              />
            </div>
          </div>

          <div className="relative">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="p-1 hover:bg-gray-100 rounded-md">
                <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" />
              </Menu.Button>
              <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleDelete}
                      className={`${
                        active ? 'bg-red-50 text-red-700' : 'text-red-600'
                      } group flex w-full items-center px-4 py-2 text-sm`}
                    >
                      Delete Task
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>

      {/* Status and Metadata */}
      <div className="flex flex-col gap-2">

        <div className="flex items-center gap-2 text-sm text-gray-700">
          <span className="font-medium">State</span>
          <div className="w-52">
            <StateSelect taskId={task.id} currentState={task.status} />
          </div>
        </div>

        <div className="flex items-center gap-2 text-sm text-gray-700">
          <span className="font-medium">Assigned To</span>
          <div className="w-52">
            <UserSelect
              taskId={task.id}
              currentUserId={task.assignedTo?.id || null}
            />
          </div>
        </div>
        <div className="flex items-center gap-2 text-sm text-gray-700 h-8">
          <span className="font-medium">Created By</span>
          <span className=''>{task.createdBy.name}</span>
        </div>
      </div>
      <div className='-mx-1'>
        <RichContentEditor
          initialContent={task.body}
          onEditorChange={debouncedSave}
          editorRef={editorRef}
          placeholder="Add task description..."
          testId="task-body-editor"
        />
      </div>


      {/* Linked Items */}
      {/* {(task.linkedChangeOrders.length > 0 || task.linkedProtos.length > 0) && (
        <div className="flex flex-col gap-2">
          <h2 className="text-xl text-gray-900">Linked Items</h2>
          <div className="flex flex-wrap gap-2">
            {task.linkedChangeOrders.map((co) => (
              <Link
                key={co.number}
                to={routes.changeOrder({ orderNumber: co.number, orgId })}
                className="text-blue-600 hover:text-blue-800"
              >
                CO #{co.number} - {co.name}
              </Link>
            ))}
            {task.linkedProtos.map((proto) => (
              <Link
                key={proto.partNumber}
                to={routes.part({ partNumber: proto.partNumber, orgId })}
                className="text-blue-600 hover:text-blue-800"
              >
                {proto.partNumber} - {proto.currentVersion?.name}
              </Link>
            ))}
          </div>
        </div>
      )} */}

      {/* Comments Section */}
      <div className="flex flex-col gap-4">
        <div className="text-lg text-gray-900">Comments</div>
        {task.comments.length > 0 && <div className="space-y-4">
          {task.comments.map((comment) => (
            <div key={comment.id} className="flex flex-col gap-1 border-b border-gray-100 pb-4">
              <div className="flex items-center gap-2">
                <span className="font-medium text-sm">{comment.author.name}</span>
                <span className="text-xs text-gray-500">
                  {prettyDateTime(new Date(comment.createdAt))}
                </span>
              </div>
              <div className=" text-gray-700">
                <RichContent content={comment.body} fontSize="sm" />
              </div>
            </div>
          ))}
        </div>
}

        <Form
          onSubmit={async () => {
            if (!commentEditorRef.current) return

            try {
              const { errors } = await addComment({
                variables: {
                  input: {
                    taskNumber: task.number,
                    body: JSON.stringify(commentEditorRef.current.getJSON())
                  }
                },
                refetchQueries: [
                  { query: QUERY, variables: { taskNumber: task.number } },
                  { query: TASKS_QUERY }
                ]
              })

              if (errors) {
                reportMutationError({
                  errors,
                  variables: {
                    input: {
                      taskNumber: task.number,
                      body: JSON.stringify(commentEditorRef.current.getJSON())
                    }
                  },
                  message: 'Failed to add comment'
                })
                return
              }

              commentEditorRef.current.commands.clearContent()
            } catch (error) {
              console.error('Error adding comment:', error)
            }
          }}
        >
          <div className="">
            <RichContentEditor
              editorRef={commentEditorRef}
              placeholder="Write a comment..."
              enableTaskList={false}
              frameless={true}
              fontSize="sm"
            />
            <div className="flex justify-end mt-2">
              <Submit variant="primary" size="sm">
                Add Comment
              </Submit>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export const Success = ({ task, isPanel }: CellSuccessProps) => {
  return <Task task={task} isPanel={isPanel} />
}