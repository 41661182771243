import { PrivateSet, Set, Router, Route, navigate, routes, Redirect, useParams } from '@redwoodjs/router'

import AppLayout, { FullWidthLayout } from 'src/layouts/AppLayout'
import OrgSettingsLayout from 'src/layouts/OrgSettingsLayout'

import { useAuth } from './auth'
import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import LegacySignUpPage from './pages/LegacySignUpPage/LegacySignUpPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import type { GetMeQuery } from 'types/graphql'

const GET_ME_QUERY = gql`
  query GetMeQuery {
    me {
      defaultOrgId
      memberOf {
        orgId
      }
    }
  }
`

const RedirectRoot: React.FC = () => {
  const { data } = useQuery<GetMeQuery>(GET_ME_QUERY)
  useEffect(() => {
    // Check if the URL contains a redirectTo parameter
    const urlParams = new URLSearchParams(window.location.search);
    const redirectTo = urlParams.get('redirectTo');

    // If redirectTo exists and contains accept-invite, don't perform automatic navigation
    if (redirectTo && redirectTo.includes('accept-invite')) {
      return;
    }

    if (data) {
      if (data.me.defaultOrgId) {
        // can't use naviage due to apollo orgid being outside of router
        window.location.href = routes.home({ orgId: data?.me.defaultOrgId })
        navigate(routes.home({ orgId: data?.me.defaultOrgId }), { replace: true })
      }
      else if (data.me.memberOf.length) {
        navigate(routes.home({ orgId: data?.me.memberOf[0]!.orgId }), { replace: true })
      }
      else {
        navigate(routes.noOrg({}), { replace: true })
      }
    }
  }, [data])
  return <></>
}
const RedirectToParts: React.FC = () => {
  const orgId = useParams().orgId!
  return <Redirect to={routes.parts({ orgId })} options={{ replace: true }} />
}

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/signup" page={LoginPage} name="signup" />
      <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
      <Route path="/signupcontact" page={LegacySignUpPage} name="signupcontact" />
      <PrivateSet unauthenticated='login'>
        <Route path="/" page={RedirectRoot} name="root" />
        <Route path="/onboard" page={NoOrgPage} name="noOrg" />
        <Route path="/accept-invite/{token:String}" page={AcceptInvitePage} name="acceptInvite" />
        <Route path="/superuser/view" page={SuperUserPage} name="superUser" />
        <Route path="/superuser/org/{orgId:String}" page={SuperUserPage} name="superUserOrg" />
        <Route path="/nexarCallback" page={NexarCallbackPage} name="nexarCallback" />
        <Route path="/oauth-slack/{orgId:String}" page={OauthSlackPage} name="oauthSlack" />
        <Route path="/oauth-slack/{orgId:String}/identify" page={OauthSlackPage} name="oauthSlackIdentify" />
        <Set wrap={FullWidthLayout}>
          <Set wrap={OrgSettingsLayout}>
            <Route path="/{orgId:String}/settings" page={OrgSettingsPage} name="orgSettings" />
            <Route path="/{orgId:String}/settings/users" page={OrgSettingsUsersPage} name="orgSettingsUsers" />
            <Route path="/{orgId:String}/settings/categories" page={OrgSettingsCategoriesPage} name="orgSettingsCategories" />
            <Route path="/{orgId:String}/settings/distributors" page={OrgSettingsDistributorsPage} name="orgSettingsDistributors" />
            <Route path="/{orgId:String}/settings/lifecycle" page={OrgSettingsLifecyclePage} name="orgSettingsLifecycle" />
            <Route path="/{orgId:String}/settings/demo-data" page={OrgSettingsDemoDataPage} name="orgSettingsDemoData" />
            <Route path="/{orgId:String}/settings/integrations" page={OrgSettingsIntegrationsPage} name="orgSettingsIntegrations" />
          </Set>
          <Route path="/{orgId:String}/parts" page={AllPartsPage} name="parts" />
          <Route path="/{orgId:String}/parts/flat" page={AllPartsPage} name="partsFlat" />
        </Set>
        <Set wrap={AppLayout}>
          <Route path="/{orgId:String}" page={HomePage} name="home" />

          <Route path="/{orgId:String}/notifications" page={NotificationsPage} name="notificatins" />
          <Route path="/{orgId:String}/parts/view/{partNumber:String}" page={PartPage} name="part" />
          <Route path="/{orgId:String}/parts/view/{partNumber:String}/history" page={PartHistoryPage} name="partHistory" />

          {/* The following routes don't work when you reload in dev, but they work on Netlify  */}
          <Route path="/{orgId:String}/parts/view/{partNumber:String}/version/{version:String}" page={PartPage} name="partWithVersion" />
          <Route path="/{orgId:String}/parts/view/{partNumber:String}/change-order/{orderNumber:Number}" page={PartPage} name="partWithBranch" />
          <Route path="/{orgId:String}/parts/compare/{basePartNumber:String}/{baseVersion:String}/to/{incomingPartNumber:String}/{incomingVersion:String}" page={PartComparePage} name="partCompare" />

          <Route path="/{orgId:String}/change-orders/{state}" page={ChangeOrdersPage} name="changeOrders" />
          <Route path="/{orgId:String}/change-order/create" page={CreateChangeOrderPage} name="changeOrderCreate" />
          <Route path="/{orgId:String}/change-order/{orderNumber:Int}" page={ChangeOrderPage} name="changeOrder" />
          <Route path="/{orgId:String}/change-order/{orderNumber:Int}/{tab:String}" page={ChangeOrderPage} name="changeOrderTab" />
          <Route path="/{orgId:String}/change-order/{orderNumber:Int}/{tab:String}/{partContext:String}" page={ChangeOrderPage} name="changeOrderTabWithPartContext" />
          <Route path="/{orgId:String}/tasks" page={TasksPage} name="tasks" />
          <Route path="/{orgId:String}/task/{taskNumber:Int}" page={TaskPage} name="task" />
        </Set>
      </PrivateSet>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
