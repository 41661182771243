import React, { useEffect, useState } from 'react'
import { CellSuccessProps, CellLoadingProps, CellFailureProps, useQuery } from '@redwoodjs/web'

import type {
  WindchillEndPartsQuery,
  WindchillBomQuery,
  WindchillBomQueryVariables
} from 'types/graphql'
import * as ListBox from '../ListBox'
import { BomRow } from 'src/lib/mapping/1-fileToRows'
import { reportGenericError } from 'src/lib/reportError'

export const QUERY = gql`
  query WindchillEndPartsQuery {
    windchillEndParts {
      partId
      name
      number
      version
      state
    }
  }
`

export const WINDCHILL_BOM_QUERY = gql`
  query WindchillBomQuery($input: WindchillPartInput!) {
    windchillBom(input: $input)
  }
`

type Props = {
  onBomChange: (rows: BomRow[] | null) => void
  onLoading: () => void
}

type SuccessProps = CellSuccessProps<WindchillEndPartsQuery> & Props
type LoadingProps = CellLoadingProps<WindchillEndPartsQuery> & Props
type FailureProps = CellFailureProps<WindchillEndPartsQuery> & Props

export const Failure = (props: FailureProps) => {
  const eventId = reportGenericError(props.error)
  return <div className='bg-red-200 p-2'>
    <div className='font-medium'>
      There was an error connecting with Windchill
    </div>
    <div className='text-sm'>Contact Bomello support with error ID: <span className='font-bold'>{eventId}</span> to escalate a bug fix.</div>
  </div>
}

export const Loading = (props: LoadingProps) => {
  return <WindchillImport {...props} />
}

export const Success = (props: SuccessProps) => {
  return <WindchillImport {...props} />
}

type WindchillImportProps = Partial<CellSuccessProps<WindchillEndPartsQuery>> & Props
const WindchillImport = ({ windchillEndParts, onBomChange, onLoading }: WindchillImportProps) => {
  const displayValue = (partId: string | null) => {
    if (!partId) return 'Select Windchill Part'
    const part = windchillEndParts?.find(p => p.partId === partId)
    return part ? `${part.number} - ${part.name}` : ''
  }

  const [selectedPartId, setSelectedPartId] = useState<string | null>(null)

  useEffect(() => {
    const getBom = async (partId: string) => {
      onLoading()
      const bom = await refetch({
        input: {
          partId
        }
      })
      const withMetadata = bom.data.windchillBom.map(r => {
        return {
          ...r,
          __metadata: {
            originalIndex: -1,
            messages: []
          }
        } as BomRow
      })
      onBomChange(withMetadata)
    }
    if (selectedPartId) {
      getBom(selectedPartId)
    }
  }, [selectedPartId])

  const { data, loading, refetch } = useQuery<WindchillBomQuery, WindchillBomQueryVariables>(
    WINDCHILL_BOM_QUERY,
    {
      skip: true,
    }
  )

  return (
    <div className='flex flex-col gap-4 items-end'>
      <ListBox.ListBox onChange={setSelectedPartId} value={selectedPartId}>
        {({ open }) =>
          <div className="relative h-full">
            <ListBox.Button
              className='py-2'
              displayValue={displayValue(selectedPartId)}
              disabled={!windchillEndParts}
            />
            <ListBox.Options open={open} align='right' className='text-sm'>
              {windchillEndParts?.map(part => (
                <ListBox.Option
                  key={part.partId}
                  className='py-3'
                  value={part.partId}
                  display={displayValue(part.partId)}
                />
              ))}
            </ListBox.Options>
          </div>
        }
      </ListBox.ListBox>
    </div>
  )
}