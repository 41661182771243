import { MetadataSchema, MetadataValue, resolveMetadataObj } from 'src/lib/metadata'
import type { ExchangeRates } from 'api/src/lib/exchangeRates'
import { AggregationReducer, massConversionRates, MassUnit, QuantityUnit } from 'shared/types'
import { ControllerTreeNode } from '../PartHierarchy'
import { Dependency, NodeType, Part } from 'types/graphql'
import { block } from 'api/src/shared/functional'

type HierarchyTreeNode = HierarchyTreeRootNode | HierarchyTreePartNode | HierarchyTreeGroupNode
export type HierarchyTreeChildNode = HierarchyTreePartNode | HierarchyTreeGroupNode
//type HierarchyTreeConcreteNode = HierarchyTreePartNode | HierarchyTreeRootNode
export type HierarchyTreePartNode = {
  kind: 'part'
  key: string
  children: HierarchyTreeChildNode[]
  value: ControllerTreeNode
}
export type HierarchyTreeGroupNode = {
  kind: 'group'
  key: string
  children: HierarchyTreeChildNode[]
  value: ControllerTreeNode
}
export type HierarchyTreeRootNode = {
  kind: 'root'
  key: '1',
  children: HierarchyTreeChildNode[]
  value: ControllerTreeNode
}

// Conversion rates for length units to meters (base unit)
const lengthConversionRates = {
  type: 'length',
  base: 'm',
  rates: {
    'm': 1,           // Base unit
    'cm': 100,        // 1 m = 100 cm
    'mm': 1000,       // 1 m = 1000 mm
    'μm': 1000000,    // 1 m = 1,000,000 μm
    'ft': 3.28084,    // 1 m = 3.28084 ft
    'in': 39.3701,    // 1 m = 39.3701 inches
    'barleycorn': 118.110236, // 1 m = 118.110236 barleycorns (1 barleycorn = 1/3 inch)
  }
} as const

// Conversion rates for volume units to cubic meters (base unit)
const volumeConversionRates = {
  type: 'volume',
  base: 'm³',
  rates: {
    'm³': 1,              // Base unit
    'cm³': 1000000,       // 1 m³ = 1,000,000 cm³
    'l': 1000,           // 1 m³ = 1000 l
    'ml': 1000000,       // 1 m³ = 1,000,000 ml
    'in³': 61023.7,      // 1 m³ = 61,023.7 in³
    'drop': 20000000,    // 1 m³ = ~20,000,000 drops (approximate)
  }
} as const

const conversionsByScale = {
  each: { type: 'each', base: 'each' },
  none: { type: 'each', base: 'each' },
  volume: volumeConversionRates,
  length: lengthConversionRates,
  mass: massConversionRates,
}

type LengthUnit = keyof typeof lengthConversionRates.rates
type VolumeUnit = keyof typeof volumeConversionRates.rates

const invertRate = (rate: number): number => 1 / rate

const toStandardQuantity = (value: number, unit: QuantityUnit | null | undefined): number => {
  if (!unit || unit === 'each' as QuantityUnit) {
    return value
  }

  // Handle length units
  if (Object.keys(lengthConversionRates.rates).includes(unit)) {
    const rate = lengthConversionRates.rates[unit as LengthUnit]
    return value * invertRate(rate)
  }

  // Handle volume units
  if (Object.keys(volumeConversionRates.rates).includes(unit)) {
    const rate = volumeConversionRates.rates[unit as VolumeUnit]
    return value * invertRate(rate)
  }

  // Handle mass units
  if (Object.keys(massConversionRates.rates).includes(unit)) {
    const rate = massConversionRates.rates[unit as MassUnit]
    return value * invertRate(rate)
  }

  // Default case - return original value if unit not recognized
  return value
}

const findScale = (unit: QuantityUnit) => {
  if (unit === 'each') return 'each'
  if (Object.keys(lengthConversionRates.rates).includes(unit)) {
    return lengthConversionRates.type
  }
  if (Object.keys(massConversionRates.rates).includes(unit)) {
    return massConversionRates.type
  }
  if (Object.keys(volumeConversionRates.rates).includes(unit)) {
    return volumeConversionRates.type
  }
  // shouldn't happen but probably loading or something
  return 'none'
}
const isSameScale = (unitA: QuantityUnit, unitB: QuantityUnit) => {
  return findScale(unitA) === findScale(unitB)
}

type QuantityWithUnitsSuccessNode = {
  error?: undefined
  units?: string
  quantity: [number, number]
}
type QuantityWithUnitsErrorNode = {
  error: true
  units?: undefined
  quantity: [0, 0]
}
type QuantityWithUnits = QuantityWithUnitsErrorNode | QuantityWithUnitsSuccessNode

export const getInstanceQuantity = (
  node: HierarchyTreeNode | MinimalTreeNode,
  partNumber: string,
  version: string,
): QuantityWithUnits => {
  let nodeValue: [number, number]
  const thisDep = node.value?.parentToNodeDependency
  const currentUnits = thisDep?.units
  let contributor = false

  if (node.value
    && thisDep
    && node.value.part.partNumber === partNumber
    && node.value.part.version === version) {
    contributor = true
    nodeValue = [thisDep.quantity, thisDep.quantity]
  }
  else {
    nodeValue = [0, 0]
  }

  let childrenValues: QuantityWithUnitsSuccessNode[]
  {
    const children = node.children.map(child =>
      getInstanceQuantity(child, partNumber, version)
    )
    if (children.some(c => c.error)) {
      return { error: true, quantity: [0, 0] }
    }
    childrenValues = children as QuantityWithUnitsSuccessNode[]
  }

  const allUnits = new Set(childrenValues.filter(c => c.units).map(c => c.units))

  if (contributor && currentUnits) {
    allUnits.add(currentUnits)
  }

  const unitsArray = Array.from(allUnits) as QuantityUnit[]

  if (unitsArray.length > 1 && !unitsArray.every(u => isSameScale(unitsArray[0]!, u))) {
    return { error: true, quantity: [0, 0] }
  }

  //this should be the result of the units reduction of the allUnits
  const targetUnits = unitsArray[0]

  if (!targetUnits) {
    return { quantity: [0, 0], units: undefined }
  }

  const scaledTarget = block(() => {
    if (unitsArray.length > 1) {
      nodeValue[0] = toStandardQuantity(nodeValue[0], targetUnits);
      nodeValue[1] = toStandardQuantity(nodeValue[1], targetUnits);

      const scale = findScale(targetUnits)
      return conversionsByScale[scale].base
    }
    else return targetUnits
  })

  const { childrenMin, childrenMax } = reduceChildValues(
    node.kind !== 'group' ? 'sum' : 'range',
    childrenValues.map(v => scaledTarget !== v.units ? [
      toStandardQuantity(v.quantity[0], v.units as QuantityUnit),
      toStandardQuantity(v.quantity[1], v.units as QuantityUnit)
    ] : v.quantity),
    ([min, max]) => ({ min, max })
  )

  const quantity = thisDep?.quantity ?? 1


  return {
    quantity: [
      ((childrenMin || 0) * quantity) + nodeValue[0],
      ((childrenMax || 0) * quantity) + nodeValue[1]
    ],
    units: scaledTarget,
  }
}

export const buildHierarchyTree = (root: ControllerTreeNode, inputHierarchy: ControllerTreeNode[]): HierarchyTreeRootNode => {
  //const groupMap: {[groupName: string]: HierarchyTreeGroupNode} = {}
  const rootNode: HierarchyTreeRootNode = {
    kind: 'root',
    key: '1',
    value: {
      ...root
    },
    children: inputHierarchy.filter(h =>
      h.hierarchy.startsWith("1.") && h.hierarchy.split('.').length === 2
    )
    .reduce((acc, child) => {
      //const groupId = child.parentToNodeDependency?.groupId
      const node: HierarchyTreeChildNode = {
        kind: child.part.nodeType === 'Part' ? 'part' : 'group',
        key: child.hierarchy,
        value: child,
        children: []
      }
      acc.push(node)
      return acc
    }, [] as HierarchyTreeChildNode[])
  }

  //const nodeMap = new Map<string, HierarchyTreeConcreteNode>()
  const nodeMap = new Map<string, HierarchyTreeNode>()
  rootNode.children.forEach(n => {
    nodeMap.set(n.key, n)
  })

  inputHierarchy.filter(n => !n.isTreeRoot).forEach(hierarchyPart => {
    const key = hierarchyPart.hierarchy
    const node = nodeMap.get(key)
    //this was in the root node initialization loop and should not skipped
    if (node) return;

    const parentNodeKey = key.substring(0, key.lastIndexOf("."))
    const parentConcreteNode = nodeMap.get(parentNodeKey)!

    const newNode: HierarchyTreeChildNode = {
      kind: hierarchyPart.part.nodeType === 'Part' ? 'part' : 'group',
      key,
      value: hierarchyPart,
      children: []
    }
    nodeMap.set(key, newNode)

    parentConcreteNode.children.push(newNode)
  })

  return rootNode
}
export type PartListNode = {
  hierarchy: string
  isTreeRoot?: boolean
  part: Pick<Part, 'nodeType' | 'partNumber' | 'version'>,
  parentToNodeDependency?: Pick<Dependency, 'quantity' | 'units' | 'toVersionRange'> | null
}
export type GenericTreeNode<T extends PartListNode> = {
  parent?: GenericTreeNode<T>
  children: GenericTreeNode<T>[]
  value: T
  //interop with old tree nodes
  kind: 'root' | 'part' | 'group'
}
type MinimalTreeNode = GenericTreeNode<PartListNode>

export function buildGenericHierarchyTree<T extends PartListNode>(root: T, inputHierarchy: T[]): GenericTreeNode<T> {
  // Create a map to store all nodes for easy lookup
  const nodeMap = new Map<string, GenericTreeNode<T>>()
  nodeMap.set('1', {
    kind: 'root',
    value: root,
    children: []
  })

  // First pass: create all nodes and store in map
  inputHierarchy.forEach(part => {
    const node: GenericTreeNode<T> = {
      kind: part.part.nodeType === 'Part' ? 'part' : 'group',
      value: part,
      children: []
    }
    nodeMap.set(part.hierarchy, node)
  })

  // Second pass: build the tree structure
  inputHierarchy.forEach(part => {
    const key = part.hierarchy
    if (!part.isTreeRoot) {
      const parentKey = key.substring(0, key.lastIndexOf("."))
      const parentNode = nodeMap.get(parentKey)
      const currentNode = nodeMap.get(key)

      if (parentNode && currentNode) {
        currentNode.parent = parentNode
        parentNode.children.push(currentNode)
      }
    }
  })

  return nodeMap.get('1')!
}

export type SumFieldConfig<T extends string> = {
  key: T
  multiplyByQuantity?: boolean | null
}

type ChildValues = {
  min?: number,
  max?: number,
}
function sumChildValues<T>(children: T[], getChildValues: (child: T) => ChildValues) {
  const [childrenMin, childrenMax] = children
    .map(getChildValues)
    .reduce(([accMin, accMax], {min, max}) => {
      return [accMin + (min || 0), accMax + (max || 0)]
    }, [0, 0])
  return { childrenMin, childrenMax }
}

function maxChildValues<T>(children: T[], getChildValues: (child: T) => ChildValues) {
  const [childrenMin, childrenMax] = children
    .map(getChildValues)
    .reduce(([accMin, accMax], { min, max }) => {
      let nextMin: undefined | number = accMin
      let nextMax: undefined | number = accMax
      if (typeof min !== 'undefined') {
        nextMin = typeof accMin === 'undefined' ? min : Math.max(accMin, min)
      }

      if (typeof max !== 'undefined') {
        nextMax = typeof accMax === 'undefined' ? max : Math.max(accMax, max)
      }

      return [
        nextMin,
        nextMax
      ]
    }, [undefined, undefined] as (number | undefined)[]);
  return { childrenMin, childrenMax }
}

function rangeChildValues<T>(children: T[], getChildValues: (child: T) => ChildValues) {
  if (!children.length) {
    return { childrenMin: undefined, childrenMax: undefined }
  }

  const values = children.map(getChildValues)

  const [childrenMin, childrenMax] = values.reduce(([accMin, accMax], {min, max}) => {
    let nextMin: undefined | number = accMin
    let nextMax: undefined | number = accMax
    if (typeof min !== 'undefined') {
      nextMin = typeof accMin === 'undefined' ? min : Math.min(accMin, min)
    }

    if (typeof max !== 'undefined') {
      nextMax = typeof accMax === 'undefined' ? max : Math.max(accMax, max)
    }

    return [
      nextMin,
      nextMax
    ]
  }, [undefined, undefined] as (number | undefined)[])

  return { childrenMin, childrenMax }
}

function reduceChildValues<T>(operation: 'sum' | 'range', children: T[], getChildValues: (child: T) => ChildValues) {
  if (operation === 'sum') return sumChildValues(children, getChildValues)
  if (operation === 'range') return rangeChildValues(children, getChildValues)
  throw new Error('Unknown operation')
}

const getConvertedCurrencyValue = (exchangeRates: ExchangeRates | null | undefined, currentValue: { unit: string, value: number }, targetType: PriceTarget) => {
  if (!exchangeRates) return undefined
  const currentUnit = currentValue.unit
  const targetUnit = targetType.unit

  if (currentUnit === targetUnit) {
    return currentValue.value
  }

  // NonUSD to USD
  if (targetUnit === 'USD') {
    const targetRate = exchangeRates.rates[currentValue.unit]
    if (!targetRate) return undefined
    //console.log(`${currentValue.unit} to USD `, {targetRate: invertRate(targetRate), original: targetRate})
    return currentValue.value * invertRate(targetRate)
  }
  // USD to NonUSD
  else if (currentUnit === 'USD') {
    const targetRate = exchangeRates.rates[targetUnit]
    if (!targetRate) return undefined
    //console.log(`USD to ${targetType.unit} `, {targetRate})
    return currentValue.value * targetRate
  }
  // NonUSD_A to NonUSD_B through USD
  else {
    const currentRate = exchangeRates.rates[currentUnit]
    if (!currentRate) return undefined
    const standardizationRate = invertRate(currentRate)

    const targetRate = exchangeRates.rates[targetUnit]
    if (!targetRate) return undefined

    //console.log(`${currentUnit} to USD to ${targetUnit} `, {standardizationRate, targetRate})
    return currentValue.value * standardizationRate * targetRate
  }
}

const getConvertedMassValue = (currentValue: { unit: MassUnit, value: number }, targetType: MassTarget) => {
  const currentUnit = currentValue.unit, targetUnit = targetType.unit;

  if (targetUnit === 'kg') {
    const targetRate = massConversionRates.rates[currentValue.unit]
    return currentValue.value * invertRate(targetRate)
  } else if (currentUnit === 'kg') {
    const targetRate = massConversionRates.rates[targetUnit]
    return currentValue.value * targetRate
  } else {
    const currentRate = massConversionRates.rates[currentUnit]
    const standardizationRate = invertRate(currentRate)

    const targetRate = massConversionRates.rates[targetUnit]

    return currentValue.value * standardizationRate * targetRate
  }
}

const SumFailNode = [0, 0, { type: 'failed' }, {}] as ReturnType<SumAggregator['getValues']>

type SumAggregatorProps = {
  name: string
  targetType: NumberTarget | MassTarget | PriceTarget
  exchangeRates: ExchangeRates | null | undefined
  metadata: SumFieldConfig<string>[]
  sources: SumFieldConfig<string>[]
}
export const SumAggregator = ({...rest}: SumAggregatorProps): SumAggregator => ({
  name: rest.name,
  targetType: rest.targetType,
  exchangeRates: rest.exchangeRates,
  metadataConfig: rest.metadata,
  sourcesConfig: rest.sources,
  reducer: 'Sum',
  getValues(node, children, opts) {

    const { name, exchangeRates, metadataConfig: metadataCfg, sourcesConfig: sourcesCfg } = this

    // only works on currency right now
    const targetType = opts.targetOverride?.type === this.targetType.type ? opts.targetOverride : this.targetType

    if (children.find(c => c.values[name]?.[2]?.type === 'failed')) {
      return SumFailNode
    }

    let scalableNodeMin = 0
    let scalableNodeMax = 0
    let unscalableNodeMin = 0
    let unscalableNodeMax = 0

    let conversionFrom: Extract<ConversionNote, { type: 'success' }>['from'] = new Set()

    const quantity = node.value?.parentToNodeDependency?.quantity ?? 1
    const quantityUnits = node.value?.parentToNodeDependency?.units

    // converts things like ft to a standard, in that case m
    // so that it can be multiplied by things in a standard way
    const standardQuantity = toStandardQuantity(quantity, quantityUnits as QuantityUnit)

    if (node.kind !== 'group') {
      const metadata = node.value?.part.metadata
      if (metadata) {
        const metadataObj = node.value?.part.resolvedMetadata
        for (const cfg of metadataCfg) {
          const metadataValue = metadataObj[cfg.key]
          if (!metadataValue) continue

          let value: number = NaN;

          if (targetType.type === 'Number' && metadataValue.type === 'Number') {
            value = metadataValue.entry
          }
          if (metadataValue.type === 'Price' && targetType.type === 'Price') {
            const { entry } = metadataValue

            const convertedValue = getConvertedCurrencyValue(exchangeRates, entry, targetType)
            if (convertedValue === undefined) return SumFailNode
            value = convertedValue

            if (entry.unit !== targetType.unit) {
              conversionFrom.add(entry.unit)
            }
          }
          if (metadataValue.type === 'Mass' && targetType.type === 'Mass') {
            value = getConvertedMassValue(metadataValue.entry, targetType)
          }


          if (value && isFinite(value)) {
            if (cfg.multiplyByQuantity) {
              scalableNodeMin += (quantity * value)
              scalableNodeMax += (quantity * value)
            }
            else {
              unscalableNodeMin += value
              unscalableNodeMax += value
            }
          }
        }
      }

      const sources = node.value?.part.sources
      if (sources) {
        for (const cfg of sourcesCfg) {
          let srcMin: number | undefined = undefined;
          let srcMax: number | undefined = undefined;

          for (const s of sources) {
            if (cfg.key === 'leadTimeDays' && typeof s.leadTimeDays === 'number') {
              srcMin = typeof srcMin === 'undefined' ? s.leadTimeDays : Math.min(srcMin, s.leadTimeDays)
              srcMax = typeof srcMax === 'undefined' ? s.leadTimeDays : Math.max(srcMax, s.leadTimeDays)
            }

            if (cfg.key !== 'price' || !s.priceCurrency || typeof s.price !== 'number' || targetType.type !== 'Price') continue

            const converted = getConvertedCurrencyValue(exchangeRates, {value: s.price, unit: s.priceCurrency}, targetType)
            if (converted === undefined) return SumFailNode
            if (!isSameScale(s.perQuantityUnit as QuantityUnit, quantityUnits as QuantityUnit)) {
              return SumFailNode
            }
            const standardSourceQuantity = toStandardQuantity(s.perQuantity, s.perQuantityUnit as QuantityUnit)
            const standardPrice = converted / standardSourceQuantity

            if (s.priceCurrency !== targetType.unit) {
              conversionFrom.add(s.priceCurrency)
            }

            srcMin = srcMin ? Math.min(srcMin, standardPrice) : standardPrice
            srcMax = srcMax ? Math.max(srcMax, standardPrice) : standardPrice
          }

          if (cfg.multiplyByQuantity) {
            scalableNodeMin += standardQuantity * (srcMin ?? 0)
            scalableNodeMax += standardQuantity * (srcMax ?? 0)
          }
          else {
            unscalableNodeMin += (srcMin ?? 0)
            unscalableNodeMax += (srcMax ?? 0)
          }
        }
      }
    }

    const nodeMin = unscalableNodeMin + scalableNodeMin
    const nodeMax = unscalableNodeMax + scalableNodeMax

    const scalableChildren = reduceChildValues(node.kind !== 'group' ? 'sum' : 'range',
      children, childNode => ({
        min: childNode.values[name]![3]!.scalableMin,
        max: childNode.values[name]![3]!.scalableMax,
      }))

    const {
      childrenMin: unscalableChildrenMin,
      childrenMax: unscalableChildrenMax,
    } = reduceChildValues(node.kind !== 'group' ? 'sum' : 'range',
      children, childNode => ({
        min: childNode.values[name]![3]!.unscalableMin,
        max: childNode.values[name]![3]!.unscalableMax,
      }))

    const scalableChildrenMin = (scalableChildren.childrenMin || 0) * quantity
    const scalableChildrenMax = (scalableChildren.childrenMax || 0) * quantity

    const childrenMin = scalableChildrenMin + (unscalableChildrenMin || 0)
    const childrenMax = scalableChildrenMax + (unscalableChildrenMax || 0)

    const combinedMin = nodeMin + childrenMin
    const combinedMax = nodeMax + childrenMax

    const unscalableMin: number = (unscalableChildrenMin || 0) + unscalableNodeMin
    const unscalableMax: number = (unscalableChildrenMax || 0) + unscalableNodeMax
    const scalableMin: number = scalableChildrenMin + scalableNodeMin
    const scalableMax: number = scalableChildrenMax + scalableNodeMax

    const usedConverisons = children.reduce((acc, c) => {
      const [_0, _1, note] = c.values[name]!;
      if (note?.type === 'success') {
        for (const u of note.from) acc.add(u)
      }
      return acc
    }, conversionFrom)

    const note: ConversionNote = !usedConverisons.size ? undefined : {
      type: 'success',
      from: usedConverisons,
    }

    return [
      Math.round((combinedMin + Number.EPSILON) * 100) / 100,
      Math.round((combinedMax + Number.EPSILON) * 100) / 100,
      note,
      {
        quantity,
        nodeMin,
        nodeMax,
        childrenMin,
        childrenMax,
        combinedMin,
        combinedMax,
        unscalableMin,
        unscalableMax,
        scalableMin,
        scalableMax,
      }
    ]
  }
})

const MaxFailNode = [0, 0, { type: 'failed' }, {}] as ReturnType<MaxAggregator['getValues']>

export const MaxAggregator = ({...rest}: SumAggregatorProps): MaxAggregator => ({
  name: rest.name,
  targetType: rest.targetType,
  exchangeRates: rest.exchangeRates,
  metadataConfig: rest.metadata,
  sourcesConfig: rest.sources,
  reducer: 'Max',
  getValues(node, children, opts) {
    const { name, exchangeRates, metadataConfig: metadataCfg, sourcesConfig: sourcesCfg } = this
    // only works on currency right now
    const targetType = opts.targetOverride?.type === this.targetType.type ? opts.targetOverride : this.targetType

    if (children.find(c => c.values[name]?.[2]?.type === 'failed')) {
      return MaxFailNode
    }

    let nodeLower: number | undefined
    let nodeHigher: number | undefined

    let conversionFrom: Extract<ConversionNote, { type: 'success' }>['from'] = new Set()

    const quantity = node.value?.parentToNodeDependency?.quantity ?? 1

    let nodeMetadataValue: number | undefined
    if (node.kind !== 'group') {
      const metadata = node.value?.part.metadata
      if (metadata) {
        const metadataObj = node.value?.part.resolvedMetadata
        for (const cfg of metadataCfg) {
          const metadataValue = metadataObj[cfg.key]
          if (!metadataValue) continue

          let value: number = NaN;

          if (targetType.type === 'Number' && metadataValue.type === 'Number') {
            value = metadataValue.entry
          }
          if (metadataValue.type === 'Price' && targetType.type === 'Price') {
            const { entry } = metadataValue

            const convertedValue = getConvertedCurrencyValue(exchangeRates, entry, targetType)
            if (convertedValue === undefined) return MaxFailNode
            value = convertedValue

            if (entry.unit !== targetType.unit) {
              conversionFrom.add(entry.unit)
            }
          }
          if (metadataValue.type === 'Mass' && targetType.type === 'Mass') {
            value = getConvertedMassValue(metadataValue.entry, targetType)
          }


          if (value && isFinite(value)) {
            // if (cfg.multiplyByQuantity) {
            //   scalableNodeMin += (quantity * value)
            //   scalableNodeMax += (quantity * value)
            // }
            // else {

            // there is no range here since metadata is one value
            nodeMetadataValue = typeof nodeMetadataValue === 'number' ? Math.max(nodeMetadataValue, value) : value
            // }
          }
        }
      }

      nodeLower = nodeMetadataValue
      nodeHigher = nodeMetadataValue

      const sources = node.value?.part.sources
      if (sources) {
        let srcLower: number | undefined = undefined
        let srcUpper: number | undefined = undefined

        for (const cfg of sourcesCfg) {
          for (const s of sources) {
            if (cfg.key === 'leadTimeDays' && typeof s.leadTimeDays === 'number') {
              srcLower = typeof srcLower === 'undefined' ? s.leadTimeDays : Math.min(srcLower, s.leadTimeDays)
              srcUpper = typeof srcUpper === 'undefined' ? s.leadTimeDays : Math.max(srcUpper, s.leadTimeDays)
              continue
            }

            if (cfg.key !== 'price' || !s.priceCurrency || typeof s.price !== 'number' || targetType.type !== 'Price') continue

            const converted = getConvertedCurrencyValue(exchangeRates, {value: s.price, unit: s.priceCurrency}, targetType)
            if (converted === undefined) return MaxFailNode

            if (s.priceCurrency !== targetType.unit) {
              conversionFrom.add(s.priceCurrency)
            }

            srcLower = srcLower ? Math.min(srcLower, converted) : converted
            srcUpper = srcUpper ? Math.max(srcUpper, converted) : converted
          }

          if (typeof srcLower === 'number') {
            nodeLower = typeof nodeLower === 'number' ? Math.max(srcLower, nodeLower) : srcLower
          }
          if (typeof srcUpper === 'number') {
            nodeHigher = typeof nodeHigher === 'number' ? Math.max(srcUpper, nodeHigher) : srcUpper
          }
        }
      }
    }


    // for children, we get a range if the node is a group, or
    // otherwise find the max
    const {
      childrenMin: childrenLower,
      childrenMax: childrenUpper,
      // not sure if range is correct, it should act like sources
    } = node.kind === 'group' ? reduceChildValues('range',
      children, childNode => ({
        min: childNode.values[name]![3]!.combinedMin,
        max: childNode.values[name]![3]!.combinedMax,
      })
    ) : maxChildValues(children, childNode => ({
      min: childNode.values[name]![3]!.combinedMin,
      max: childNode.values[name]![3]!.combinedMax,
    }))

    let combinedMin = childrenLower
    let combinedMax = childrenUpper

    if (typeof nodeLower !== 'undefined') {
      combinedMin = typeof childrenLower === 'number' ? Math.max(childrenLower, nodeLower) : nodeLower
    }

    if (typeof nodeHigher !== 'undefined') {
      combinedMax = typeof childrenUpper === 'number' ? Math.max(childrenUpper, nodeHigher) : nodeHigher
    }

    const usedConverisons = children.reduce((acc, c) => {
      const [_0, _1, note] = c.values[name]!;
      if (note?.type === 'success') {
        for (const u of note.from) acc.add(u)
      }
      return acc
    }, conversionFrom)

    const note: ConversionNote = !usedConverisons.size ? undefined : {
      type: 'success',
      from: usedConverisons,
    }

    return [
      Math.round((combinedMin || 0 + Number.EPSILON) * 100) / 100,
      Math.round((combinedMax || 0 + Number.EPSILON) * 100) / 100,
      note,
      {
        quantity,
        combinedMin,
        combinedMax,
      }
    ]
  }
})

export type AndFieldConfig<T extends string> = {
  key: T
}
type AndAggregatorProps = {
  name: string
  targetType: BooleanTarget
  exchangeRates: ExchangeRates | null | undefined
  metadata: AndFieldConfig<string>[]
  sources: AndFieldConfig<string>[]
}
export const AndAggregator = ({...rest}: AndAggregatorProps): BooleanAggregator => ({
  name: rest.name,
  targetType: rest.targetType,
  exchangeRates: rest.exchangeRates,
  metadataConfig: rest.metadata,
  sourcesConfig: rest.sources,
  reducer: 'And',
  getValues(node, children, opts) {
    const { name, metadataConfig: metadataCfg, sourcesConfig: sourcesCfg } = this

    let nodeMetadataValue = true
    const metadata = node.value?.part.metadata || {}
    const metadataObj = node.value?.part.resolvedMetadata
    for (const cfg of metadataCfg) {
      const metadataValue = metadataObj?.[cfg.key]
      if (metadataValue?.type && metadataValue.type !== 'Boolean') throw new Error('AND Aggregations only support boolean')
      nodeMetadataValue = Boolean(nodeMetadataValue && metadataValue?.entry)
    }

    // no sources keys are boolean just yet
    /*
    const sources = node.value?.part.sources
    if (sources) {
      for (const cfg of sourcesCfg) {
      }
    }
    */

    const childrenValue = children.reduce((output, child) => {
      const values = child.values as { [aggName: string]: ReturnType<BooleanAggregator['getValues']> }
      return values[name]![0] && output
    }, true)

    return [childrenValue && nodeMetadataValue]
  }
})

type MassTarget = {
  type: 'Mass'
  unit: MassUnit
}
export type BooleanTarget = {
  type: 'Boolean'
  unit: never
}
type PriceTarget = {
  type: 'Price'
  unit: string
}
type NumberTarget = {
  type: 'Number'
  unit: never
}
export type NumericTarget = NumberTarget | PriceTarget | MassTarget
export type AggregatorTarget = NumberTarget | PriceTarget | MassTarget | BooleanTarget

type ConversionNote = undefined | {
  type: 'failed'
} | {
  type: 'success',
  from: Set<string>
}

type SumCalculationDetails = {
  quantity: number
  combinedMin: number
  combinedMax: number
  nodeMin: number
  nodeMax: number
  childrenMin: number
  childrenMax: number
  scalableMin: number
  scalableMax: number
  unscalableMin: number
  unscalableMax: number
}

type MaxCalculationDetails = {
  combinedMin?: number
  combinedMax?: number
}

interface AggregatorBase {
  name: string
  reducer: AggregationReducer
  targetType: AggregatorTarget
  exchangeRates: ExchangeRates | null | undefined
  metadataConfig: SumFieldConfig<string>[]
  sourcesConfig: SumFieldConfig<string>[]
}

type SumAggregatorValues = [number, number, ConversionNote, SumCalculationDetails]
export interface SumAggregator extends AggregatorBase {
  getValues(
    node: HierarchyTreeNode,
    children: SumAggregationNode[],
    opts: AggBuildOpts
  ): SumAggregatorValues
}

type MaxAggregatorValues = [number, number, ConversionNote, MaxCalculationDetails]
export interface MaxAggregator extends AggregatorBase {
  getValues(
    node: HierarchyTreeNode,
    children: MaxAggregationNode[],
    opts: AggBuildOpts
  ): MaxAggregatorValues
}

type BooleanAggregatorValues = [boolean]
export interface BooleanAggregator extends AggregatorBase {
  getValues(
    node: HierarchyTreeNode,
    children: BooleanAggregationNode[],
    opts: AggBuildOpts
  ): [boolean]
}

export type Aggregator = BooleanAggregator | MaxAggregator | SumAggregator

interface BaseAggregationNode {
  kind: 'root' | 'part' | 'group'
  values: { [aggName: string]: MaxAggregatorValues | SumAggregatorValues | BooleanAggregatorValues }
  children: BaseAggregationNode[]
  groupId?: string
}

interface SumAggregationNode extends BaseAggregationNode {
  values: { [aggName: string]: SumAggregatorValues }
  children: SumAggregationNode[]
}

interface MaxAggregationNode extends BaseAggregationNode {
  values: { [aggName: string]: MaxAggregatorValues }
  children: MaxAggregationNode[]
}

interface BooleanAggregationNode extends BaseAggregationNode {
  values: { [aggName: string]: BooleanAggregatorValues }
  children: BooleanAggregationNode[]
}

export interface AggregationRootNode extends BaseAggregationNode {
  kind: 'root'
  groupId?: undefined
  aggregators: Aggregator[]
  nodeMap: Map<string, AggregationNode>
  getPartNode: (hierarchy: string) => AggregationPartNode | undefined
  //getGroupNode: (parentKey: string, groupId: string) => AggregationGroupNode | undefined
  opts: AggBuildOpts
}

interface AggregationPartNode extends BaseAggregationNode {
  kind: 'part'
  //groupId?: undefined
}
interface AggregationGroupNode extends BaseAggregationNode {
  kind: 'group'
  //groupId: string
}

export type AggregationNode = AggregationRootNode | AggregationPartNode | AggregationGroupNode

type GetAggNode<T extends HierarchyTreeNode> =
  T extends HierarchyTreeRootNode ? AggregationRootNode :
  T extends HierarchyTreePartNode ? AggregationPartNode :
  AggregationGroupNode
const isRootNode = (node: HierarchyTreeNode): node is HierarchyTreeRootNode => node.kind === 'root'
const isPartNode = (node: HierarchyTreeNode): node is HierarchyTreePartNode => node.kind === 'part'

function aggTreeWalker<T extends HierarchyTreeNode> (
  node: T,
  aggregators: Aggregator[],
  opts: AggBuildOpts,
  lookupMap?: Map<string, AggregationNode>,
): GetAggNode<T> {
  const usedMap = lookupMap ?? new Map<string, AggregationNode>()
  const children = node.children.map(n => aggTreeWalker(n, aggregators, opts, usedMap))

  const values = aggregators.reduce((acc, aggregator) => {
    acc[aggregator.name] = aggregator.getValues(node, children, opts)
    return acc
  }, {} as Record<string, ReturnType<Aggregator['getValues']>>)

  const { filterEmpty } = opts

  const childrenNodes = filterEmpty ? children.filter(c =>
    Object.values(c.values).some(v => v[0] !== 0 || v[1] !== 0))
    : children

  if (isRootNode(node)) {
    const aggNode: AggregationRootNode = {
      kind: 'root',
      values,
      children: childrenNodes,
      aggregators,
      nodeMap: usedMap,
      getPartNode: (hierarchy: string) => usedMap.get(hierarchy) as AggregationPartNode,
      /*
      getGroupNode: (parentKey: string, groupId: string) =>
        usedMap.get(`parent:${parentKey}:group:${groupId}`) as AggregationGroupNode,
      */
      opts
    }
    usedMap.set('1', aggNode)
    return aggNode as GetAggNode<T>;
  }
  else if (isPartNode(node)) {
    const aggNode: AggregationPartNode = {
      kind: 'part',
      values,
      //children,
      children: childrenNodes
    }
    usedMap.set(node.key, aggNode)
    //usedMap.set(node.value?.hierarchy, aggNode)
    return aggNode as GetAggNode<T>;
  }
  else {
    const aggNode: AggregationGroupNode = {
      kind: 'group',
      //groupId: node.groupId,
      values,
      children: childrenNodes,
    }
    usedMap.set(node.key, aggNode)
    //usedMap.set(`parent:${node.parentKey}:group:${node.groupId}`, aggNode)
    return aggNode as GetAggNode<T>;
  }
}

type AggBuildOpts = {
  filterEmpty?: boolean
  targetOverride?: AggregatorTarget
}
export function buildAggregationTree(node: HierarchyTreeRootNode, aggregators: Aggregator[], opts: AggBuildOpts = {}): AggregationRootNode {
  return aggTreeWalker(node, aggregators, opts)
}
