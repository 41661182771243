import { useLayoutEffect } from 'react'
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from 'headlessuinext'
import { useState, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import LoadingSpinner from '../Loading'
import { AnchorProps } from 'headlessuinext/dist/internal/floating'

// Add input classes constant
const inputClasses = `
  w-full rounded px-3 py-2 ring-1
  ring-gray-200 focus:outline-none focus:ring-2
  focus:ring-brand-500 disabled:bg-gray-100
`

interface ComboboxOption {
  id: string
  display: string
  color?: string
  // will be in its own section and always shown
  primary?: boolean
}

type ComboboxProps = {
  selectedId: string | null,
  onSelectId: (id: string) => void
  options: ComboboxOption[]
  autoFocus?: boolean
  placeholder?: string
  testId?: string
  frameless?: boolean
  transparent?: boolean
  height?: number
  color?: string
  noSort?: boolean
  noPadding?: boolean
  error?: string
  confirm?: string
  noPill?: boolean
  onClose?: () => void
  disabled?: boolean
  anchor?: AnchorProps
  optionsLoading?: boolean
}
export default ({
  selectedId,
  onSelectId,
  options,
  autoFocus,
  placeholder,
  testId,
  frameless,
  noPill,
  transparent = false,
  height = 40,
  color = '#ec4899',
  noSort = false,
  noPadding = false,
  anchor = 'top',
  error,
  confirm,
  onClose,
  disabled,
  optionsLoading
}: ComboboxProps) => {
  const pillRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [badgeWidth, setBadgeWidth] = useState(0)
  testId = testId || 'combobox'

  useLayoutEffect(() => {
    if (!pillRef.current) return
    const { width } = pillRef.current?.getBoundingClientRect()
    setBadgeWidth(width);
  }, [selectedId, pillRef.current]);

  const [query, setQuery] = useState('')
  const selectedOption = options.find(o => o.id === selectedId)
  const pillColor = selectedOption?.color || color

  //fires on empty and selection
  const onChange = async (value: string | null) => {
    setQuery('')
    if (value) {
      onSelectId(value)
    }
  }

  const handleBlur = () => {
    setQuery('')
    if (onClose) {
      onClose()
    }
  }

  const filteredOptions = options
    .filter(o => {
      if (o.primary) return true
      if (!query) return true
      return o.display.toLowerCase().includes(query.toLowerCase())
    })
    .sort((a, b) => {
      if (noSort) return 0
      if (a.primary) return -1
      if (b.primary) return 1
      if (a.display > b.display) return -1
      if (a.display < b.display) return 1
      return 0
    })
    .slice(0, 40)


  const className = twMerge(
    inputClasses,
    'bg-transparent cursor-pointer transition-all whitespace-nowrap',
    frameless && 'ring-0 focus:ring-0',
    transparent && 'bg-transparent hover:bg-white/20',
    noPadding && 'px-0',
    error && 'placeholder-red-500',
    confirm && 'placeholder-green-800'
  )

  return (
    <Combobox
      as='div'
      autoFocus={autoFocus}
      className={twMerge(
        'grow relative w-full text-xs hover:bg-blue-50 data-[open]:bg-blue-50 cursor-pointer min-w-52',
        transparent && 'hover:bg-transparent data-[open]:bg-transparent'
      )}
      style={{
        height
      }}
      disabled={disabled}
      immediate
      value={selectedId}
      data-testid={`${testId}`}
      onChange={onChange}
      onClose={onClose}>
      {({ open }) =>
        <>
          <ComboboxInput
            disabled={disabled}
            ref={inputRef}
            autoFocus={autoFocus}
            placeholder={error || confirm || placeholder}
            className={className}
            style={{
              height,
              paddingLeft: badgeWidth ? `${12 + badgeWidth}px` : 'revert-layer'
            }}
            value={query}
            onChange={s => setQuery(s.target.value)}
            onBlur={handleBlur}/>
          {!noPill && selectedOption &&
            <div
              ref={pillRef}
              onClick={() => inputRef.current?.focus()}
              className='rounded text-white px-2 absolute top-1 bottom-1 left-1 flex items-center'
              style={{ backgroundColor: pillColor }}>
              {selectedOption.display.length < 30 ? selectedOption.display : `${selectedOption.display.slice(0, 30)}...`}
            </div>
          }
          {(open) &&
            <ComboboxOptions
              static
              anchor={anchor}
              className='bg-white mt-1 rounded-md absolute z-[80] w-[var(--input-width)] text-gray-800 shadow-md text-xs overflow-y-auto [--anchor-max-height:15rem]'
            >
              {optionsLoading && <div className='pt-2 pb-3 px-2 flex'>
                <LoadingSpinner size='xs' />
              </div>}
              {!optionsLoading && filteredOptions.map((option) => (
                <ComboboxOption
                  value={option.id}
                  key={option.id}
                  data-testid={`${testId}.option.${option.id}`}
                  className={`data-[focus]:text-white cursor-pointer p-3 flex items-center data-[focus]:bg-brand-500`}
                >
                  {option.display}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          }
        </>
      }
    </Combobox>
  )
}
