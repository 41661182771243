import classNames from 'classnames';
import type {
  FindChangeOrderQuery,
  FindChangeOrderQueryVariables,
  AddChangeOrderCommentMutation,
  AddChangeOrderCommentMutationVariables,
  ChangeOrderResponse,
  UpdateChangeOrderMutation,
  UpdateChangeOrderMutationVariables,
} from 'types/graphql'

import { Fragment, useState, useContext, useRef } from 'react'

import { reportMutationError } from 'src/lib/reportError';
import { routes } from '@redwoodjs/router'
import { Link } from '@redwoodjs/router'
import { Popover, Transition } from '@headlessui/react'
import {
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import { type CellSuccessProps, useMutation } from '@redwoodjs/web'

import { PopoverButton } from 'src/components/Button';
import Reviewers, { WorkflowContext, changeOrdersReviewersFragment } from './Reviewers'
import ActivityTab from './ActivityTab'
import Button from 'src/components/Button'
import { Form, TextArea, Submit, RadioField, TextField } from 'src/components/Form';
import { ConditionalModal } from 'src/components/Modal'
import ExportChangeOrderCell from 'src/components/ExportChangeOrderCell'
import AppContext from 'src/lib/appContext';
import { useLifeCycleStages } from "src/lib/lifecycle"

import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import { RichContent, RichContentEditor } from 'src/components/RichContent'

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center'/>

export const Empty = () => <div>Empty</div>

import GenericFailure from '../Failure/Failure'
import { ADD_CHANGE_ORDER_COMMENT_MUTATION, CHANGE_ORDER_QUERY } from 'src/lib/queries';
import { ChangeOrderMetadataPanel } from '../Metadata';
export const Failure = GenericFailure

export const QUERY = CHANGE_ORDER_QUERY

type SuccessProp = CellSuccessProps<FindChangeOrderQuery, FindChangeOrderQueryVariables>
type COProp = SuccessProp['changeOrder']
type CurrentOrgProp = SuccessProp['currentOrg']
type AllUsers = SuccessProp['users']

type ChangeOrderProps = {
  tab: string
  orgId: string
  children: React.ReactNode
} & CellSuccessProps<FindChangeOrderQuery, FindChangeOrderQueryVariables>
export const Success = ({
  users: allUsers,
  currentOrg,
  changeOrder,
  me,
  tab,
  orgId,
  children
}: ChangeOrderProps) => {
  const { data: coFrag } = changeOrdersReviewersFragment.useRegisteredFragment(changeOrder._id)
  const [showExport, setShowExport] = useState(false)
  const isComplete = changeOrder.state === 'Complete' || changeOrder.state === 'Cancelled'
  const hasEscalatedPrivileges = me.isSuperUser || currentOrg.me?.role === 'Owner' || currentOrg.me?.role === 'Admin'

  const lifeCycleStages = useLifeCycleStages()

  return (
    <WorkflowContext.Provider value={{
      changeOrder: coFrag,
      allUsers,
      allGroups: currentOrg.userGroups,
      reviewers: changeOrder.reviewers,
      groupConfigs: changeOrder.reviewerGroups,
      workflow: changeOrder.workflow,
      lifeCycleStages
    }}>
      <div className='flex flex-col gap-2 pt-12'>
        <div className='flex gap-2 mb-8 justify-end'>
          {
            !isComplete ? <>
              <Button onClick={() => setShowExport(true)}>Export</Button>
              <LeaveReview orderNumber={changeOrder.number} />
            </> : <>
              <Button onClick={() => setShowExport(true)}>Export</Button>
            </>
          }
          {showExport &&
            <ConditionalModal className='!w-[30rem]' onClose={() => setShowExport(false)}>
              <ExportChangeOrderCell orderNumber={changeOrder.number} onComplete={() => setShowExport(false)}/>
            </ConditionalModal>
          }
        </div>
        <Name name={changeOrder.name} orderNumber={changeOrder.number}/>

        <div className=''>
          <div className='flex gap-8 mb-12 min-h-36'>
            <Description description={changeOrder.description} orderNumber={changeOrder.number}/>
            <div className='w-72 min-h-48'>
              <div className='font-medium mb-3'>
                Status
              </div>
              <span className='-m-1 text-sm rounded-xl py-1 px-3 bg-yellow-100 text-yellow-800'>
                {changeOrder.state}
              </span>
              <Reviewers changeOrder={changeOrder} loggedInId={me.id}/>
              <ChangeOrderMetadataPanel changeOrderNumber={changeOrder.number} metadataJSON={changeOrder.metadata} editMode='edit'
                renderWithEditHandle={(editButton, body) => <>
                  <div className='font-medium mt-4 mb-2 flex gap-2 items-center'>
                    Properties {editButton}
                  </div>
                  {body}
                </>
              }/>
            </div>
          </div>
          <ChangeOrderBody orgId={orgId} tab={tab} hasEscalatedPrivileges={hasEscalatedPrivileges}
            changeOrder={changeOrder}
            currentOrg={currentOrg}
            allUsers={allUsers}>{children}</ChangeOrderBody>

        </div>

      </div>
    </WorkflowContext.Provider>

  )
}

type ChangeOrderBodyProps = {
  changeOrder: COProp,
  currentOrg: CurrentOrgProp,
  allUsers: AllUsers,
  hasEscalatedPrivileges: boolean
  tab: string,
  orgId: string,
  children: React.ReactNode
}

const ChangeOrderBody = ({ changeOrder, currentOrg, hasEscalatedPrivileges, tab, orgId, children }: ChangeOrderBodyProps) => {
  const baseClassName = 'py-2 px-3 cursor-pointer border-x border-t inline-block -mb-0.5'
  const activeButtonClass = classNames(
    baseClassName,
    'rounded-t border-gray-200 bg-white'
  )
  const buttonClassName = classNames(
    baseClassName,
    'border-transparent'
  )

  const completedTasks = changeOrder.tasks?.filter(task => ['Done', 'WontDo'].includes(task.status)).length || 0
  const totalTasks = changeOrder.tasks?.length || 0
  const taskCount = totalTasks > 0 ? ` (${completedTasks}/${totalTasks})` : ''

  const content = tab === 'activity' ? <ActivityTab changeOrder={changeOrder} hasEscalatedPrivileges={hasEscalatedPrivileges} /> :
    children
  return <>
    <div className='mb-8 pt-4'>
      <div className='relative border-b border-gray-200'>
        <Link
          className={tab === 'activity' ? activeButtonClass : buttonClassName}
          to={routes.changeOrder({ orgId, orderNumber: changeOrder.number })}>Activity</Link>
        <Link
          className={tab === 'changes' ? activeButtonClass : buttonClassName}
          to={routes.changeOrderTab({ orgId, orderNumber: changeOrder.number, tab: 'changes' })}>Changes</Link>
        <Link
          className={tab === 'tasks' ? activeButtonClass : buttonClassName}
          to={routes.changeOrderTab({ orgId, orderNumber: changeOrder.number, tab: 'tasks' })}>Tasks{taskCount}</Link>
      </div>
    </div>
    {content}
  </>
}

export const UPDATE_CHANGE_ORDER_MUTATION = gql`
mutation UpdateChangeOrderMutation ($description: String, $name: String, $orderNumber: Int!) {
  updateChangeOrder(number: $orderNumber, input: { description: $description, name: $name }) {
    number
  }
}
`

type DescriptionProps = {
  description: string
  orderNumber: number
}
const Description: React.FC<DescriptionProps> = ({ description, orderNumber }) => {
  const appContext = useContext(AppContext)
  const [editMode, setEditMode] = useState(false)
  const [updateChangeOrder, { loading }] = useMutation<UpdateChangeOrderMutation, UpdateChangeOrderMutationVariables>(UPDATE_CHANGE_ORDER_MUTATION)
  const editorRef = useRef<Editor | null>(null)

  const descriptionSubmit = async () => {
    if (!editorRef.current) return

    const {errors} = await updateChangeOrder({
      variables: {
        orderNumber,
        description: JSON.stringify(editorRef.current.getJSON())
      },
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables: {
          orderNumber,
          description: editorRef.current.getJSON()
        },
        message: 'Failed to update change order description'
      })
      return
    }
    setEditMode(false)
  }

  if (!editMode) {
    return <div className='flex-1'>
      <div className='font-medium mb-2 flex items-center'>
        Description
        {appContext?.canEdit &&
          <button className='ml-2' onClick={() => setEditMode(true)}>
            <PencilSquareIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
          </button>
        }
      </div>
      <div className='whitespace-pre-line'>
        <RichContent
          enableTaskList
          content={description}
          editorRef={editorRef}
          onEditorChange={descriptionSubmit} />
      </div>
    </div>
  }

  return <Form className='flex-1' onSubmit={descriptionSubmit}>
    <div className='font-medium mb-2 flex items-center'>
      Description
    </div>
    <RichContentEditor enableTaskList editorRef={editorRef} initialContent={description} />
    <div className='flex items-end justify-end gap-3 mt-4'>
      <Button onClick={() => setEditMode(false)}>Cancel</Button>
      <Submit disabled={loading} variant='primary'>Save</Submit>
    </div>
  </Form>
}

type NameProps = {
  name: string
  orderNumber: number
}
const Name: React.FC<NameProps> = ({ name, orderNumber }) => {
  const appContext = useContext(AppContext)
  const [editMode, setEditMode] = useState(false)
  const [updateChangeOrder, { loading }] = useMutation<UpdateChangeOrderMutation, UpdateChangeOrderMutationVariables>(UPDATE_CHANGE_ORDER_MUTATION)

  type FormData = {
    name: string
  }
  const nameSubmit = async ({ name }: FormData) => {
    const {errors} = await updateChangeOrder({
      variables: {
        orderNumber,
        name
      },
      refetchQueries: [QUERY],
      awaitRefetchQueries: true
    })
    if (errors) {
      reportMutationError({
        errors,
        variables: {
          orderNumber,
          name
        },
        message: 'Failed to update change order name'
      })
      return
    }
    setEditMode(false)
  }

  if (!editMode) {
    return <div className='flex flex-1 gap-2 items-center'>
      <span className='text-2xl'>
        #CO-{orderNumber} {name}
      </span>
      {appContext?.canEdit &&
        <button onClick={() => setEditMode(true)}>
          <PencilSquareIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
        </button>
      }
    </div>
  }

  return <Form className='flex flex-1 gap-2 items-center' onSubmit={nameSubmit}>
    <span className='text-2xl'>
      #{orderNumber}
    </span>
    <TextField className='flex-1 -my-1' name='name' defaultValue={name} />
    <Button onClick={() => setEditMode(false)}>Cancel</Button>
    <Submit disabled={loading} variant='primary'>Save</Submit>
  </Form>
}

type LeaveReviewProps = {
  orderNumber: number
}
const LeaveReview: React.FC<LeaveReviewProps> = ({ orderNumber }) => {
  const [addReview] = useMutation<
    AddChangeOrderCommentMutation,
    AddChangeOrderCommentMutationVariables
  >(ADD_CHANGE_ORDER_COMMENT_MUTATION)
  const body = ({ close }: { close: () => void }) => {
    type ReviewForm = {
      review: 'RequestChanges' | 'Approve'
      content: string
    }
    const submitReview = async (result: ReviewForm) => {
      const variables = {
        orderNumber,
        message: result.content,
        reviewType: result.review as ChangeOrderResponse
      }
      const { errors } = await addReview({
        variables,
        refetchQueries: [QUERY],
        awaitRefetchQueries: true
      })
      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Error submitting review`
        })
        return
      }
      close()
    }
    return <Form onSubmit={submitReview}>
      <div className='flex flex-col gap-6 w-96'>
        <div className='text-lg'>Leave a review</div>
        <TextArea name='content' className='w-100' />
        <fieldset className='flex flex-col gap-4'>
          <div className="flex items-start gap-x-4 cursor-pointer">
            <RadioField
              required
              id="review-approve"
              name="review"
              value='Approve'
              className="h-4 w-4 mt-2 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
            />
            <label htmlFor="review-approve" className="flex-1 block text-sm leading-6 text-gray-900 cursor-pointer">
              Approve
              <div className=" text-gray-500">
                The changes look good and can be applied
              </div>
            </label>
          </div>
          <div className="flex items-start gap-x-4 cursor-pointer">
            <RadioField
              required
              id="review-request-changes"
              name="review"
              value='RequestChanges'
              className="h-4 w-4 mt-2 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
            />
            <label htmlFor="review-request-changes" className="flex-1 block text-sm leading-6 text-gray-900 cursor-pointer">
              Request Changes
              <div className=" text-gray-500">
                There are changes or problems that need addressing before the change order can be applied
              </div>
            </label>
          </div>
        </fieldset>
        <Submit variant='primary'>Submit</Submit>
      </div>
    </Form>
  }
  return (
    <Popover className="relative">
      <PopoverButton variant='primary'>
        Review Changes
      </PopoverButton>

      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel className="py-4 px-6 border border-gray-200 rounded-md bg-white absolute right-0 mt-2 flex w-screen max-w-max z-50">
          {body}
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
