import { Container as TooltipContainer, Message as TooltipMessage } from 'src/components/Tooltip'
import { ArrowPathIcon, MapPinIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

// Types
interface VersionIconProps {
  versionRange: string | undefined
  version: string
  className?: string
}

interface IconBaseProps {
  className?: string
}

// Constants
export const alwaysUpdateText = 'Always Update'

// Helper functions
export function getVersionTypeText(versionRange: string | undefined, version: string): string | undefined {
  if (!versionRange) {
    return undefined
  }

  if (versionRange === '*') {
    return alwaysUpdateText
  }

  return pinnedText(version)
}

export function pinnedText(version: string): string {
  return `Pinned to v${version}`
}

// Always Update Icon Components
export function AlwaysUpdateIconRaw({ className }: IconBaseProps) {
  return (
    <ArrowPathIcon className={classNames('w-3 text-gray-600', className)} />
  )
}

export function AlwaysUpdateIcon({ className }: IconBaseProps) {
  return (
    <TooltipContainer>
      <TooltipMessage>
        {alwaysUpdateText}
      </TooltipMessage>
      <AlwaysUpdateIconRaw className={className}/>
    </TooltipContainer>
  )
}

// Pinned Icon Components
export function PinnedIconRaw({ className }: IconBaseProps) {
  return (
    <MapPinIcon className={classNames('w-3 text-gray-600 -mt-0.5', className)} />
  )
}

export function PinnedIcon({ version, className }: { version: string; className?: string }) {
  return (
    <TooltipContainer>
      <TooltipMessage>
        {pinnedText(version)}
      </TooltipMessage>
      <PinnedIconRaw className={className}/>
    </TooltipContainer>
  )
}

// Main Version Icon Components
export function VersionIconRaw({ versionRange, className }: Omit<VersionIconProps, 'version'>) {
  if (!versionRange) {
    return null
  }

  if (versionRange === '*') {
    return <AlwaysUpdateIconRaw className={className} />
  }

  return <PinnedIconRaw className={className} />
}

export function VersionIcon({ versionRange, version, className }: VersionIconProps) {
  if (!versionRange) {
    return null
  }

  if (versionRange === '*') {
    return <AlwaysUpdateIcon className={className} />
  }

  return <PinnedIcon version={version} className={className} />
}
