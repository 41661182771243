import type { NoOrgQuery, NoOrgQueryVariables } from 'types/graphql'
import { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { navigate, routes } from '@redwoodjs/router'
import { useAuth } from 'src/auth'
import { useDebounce } from 'src/lib/hooks/useDebounce'

import type {
  CellSuccessProps,
} from '@redwoodjs/web'

export const QUERY = gql`
  query NoOrgQuery {
    me {
      defaultOrgId
      memberOf {
        orgId
        role
      }
    }
  }
`

const CREATE_ORGANIZATION = gql`
  mutation CreateOrganizationSelfServe($input: CreateOrgSelfServeInput!) {
    createOrganizationSelfServe(input: $input) {
      id
      name
    }
  }
`

const CHECK_ORG_ID_AVAILABILITY = gql`
  query IsOrgIdAvailable($id: String!) {
    isOrgIdAvailable(id: $id)
  }
`

type AvailabilityStatus = 'Available' | 'Taken' | 'Invalid' | 'Checking' | null

export const Success = ({ me }: CellSuccessProps<NoOrgQuery, NoOrgQueryVariables>) => {
  if (me.memberOf.length || me.defaultOrgId) {
    navigate(routes.root())
    return null
  }

  const { logOut } = useAuth()
  const [isCreating, setIsCreating] = useState(false)
  const [orgName, setOrgName] = useState('')
  const [orgSlug, setOrgSlug] = useState('')
  const [slugManuallyEdited, setSlugManuallyEdited] = useState(false)
  const [availabilityStatus, setAvailabilityStatus] = useState<AvailabilityStatus>(null)

  const debouncedOrgSlug = useDebounce(orgSlug, 500)

  const [checkOrgIdAvailability] = useLazyQuery(
    CHECK_ORG_ID_AVAILABILITY,
    {
      onCompleted: (data) => {
        setAvailabilityStatus(data.isOrgIdAvailable)
      },
      fetchPolicy: 'network-only',
    }
  )

  const [createOrganization, { loading: isSubmitting, error }] = useMutation(CREATE_ORGANIZATION, {
    onCompleted: (data) => {
      navigate(routes.home({ orgId: data.createOrganizationSelfServe.id }))
    },
    onError: (error) => {
      console.error('Failed to create organization:', error)
    }
  })

  useEffect(() => {
    if (!debouncedOrgSlug) {
      setAvailabilityStatus(null)
      return
    }

    // Check if the slug meets the minimum requirements
    if (debouncedOrgSlug.length < 3) {
      setAvailabilityStatus('Invalid')
      return
    }

    // Check if the slug contains only valid characters
    if (!/^[a-z0-9-]+$/.test(debouncedOrgSlug)) {
      setAvailabilityStatus('Invalid')
      return
    }

    // If all local validations pass, check server-side availability
    setAvailabilityStatus('Checking')
    checkOrgIdAvailability({
      variables: { id: debouncedOrgSlug }
    })
  }, [debouncedOrgSlug, checkOrgIdAvailability])

  const handleCreateOrg = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!orgSlug) {
      // Generate a slug from the org name if not provided
      const generatedSlug = orgName.toLowerCase().replace(/[^a-z0-9-]/g, '-')
      setOrgSlug(generatedSlug)

      // Check if the generated slug is valid
      if (generatedSlug.length < 3) {
        return // Don't submit if invalid
      }
    }

    // Don't submit if the slug is invalid or unavailable
    if (availabilityStatus !== 'Available') {
      return
    }

    await createOrganization({
      variables: {
        input: {
          id: orgSlug,
          name: orgName,
        }
      }
    })
  }

  const getAvailabilityStatusColor = () => {
    switch (availabilityStatus) {
      case 'Available':
        return 'text-green-600'
      case 'Taken':
        return 'text-red-600'
      case 'Invalid':
        return 'text-red-600'
      case 'Checking':
        return 'text-gray-400'
      default:
        return ''
    }
  }

  const getAvailabilityStatusIcon = () => {
    switch (availabilityStatus) {
      case 'Available':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-600" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
        )
      case 'Taken':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-600" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
          </svg>
        )
      case 'Invalid':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-600" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
          </svg>
        )
      case 'Checking':
        return (
          <svg className="animate-spin h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        )
      default:
        return null
    }
  }

  const getAvailabilityStatusMessage = () => {
    switch (availabilityStatus) {
      case 'Available':
        return 'This organization ID is available'
      case 'Taken':
        return 'This organization ID is already taken'
      case 'Invalid':
        return 'Must be at least 3 characters and only contain lowercase letters, numbers, and hyphens'
      case 'Checking':
        return 'Checking availability...'
      default:
        return ''
    }
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Welcome! Let's get you started
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          You currently don't belong to any organization
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <button
                onClick={() => setIsCreating(true)}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-500 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                Create a new organization
              </button>
            </div>
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or</span>
              </div>
            </div>
            <div className="text-center">
              <div className="text-sm font-medium text-gray-700">
                Join an existing organization
              </div>
              <div className="mt-4 p-4 bg-gray-50 rounded-md">
                <p className="text-sm text-gray-700">
                  Already part of a team? Contact your organization administrator for an invitation. You'll receive an email with instructions to join their workspace.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 text-center">
          <button
            onClick={() => logOut()}
            className="text-xs text-gray-400 hover:text-gray-600 focus:outline-none focus:underline"
          >
            Log out
          </button>
        </div>
      </div>

      <Dialog
        open={isCreating}
        onClose={() => setIsCreating(false)}
        className="fixed z-10 inset-0 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="relative bg-white rounded-lg max-w-md w-full mx-4 p-8">
            <Dialog.Title className="text-xl font-semibold text-gray-900">
              Set up your organization
            </Dialog.Title>


            <form onSubmit={handleCreateOrg} className="mt-6">
              <div>
                <label htmlFor="orgName" className="block text-sm font-medium text-gray-700">
                  Organization name
                </label>
                <div className="mt-1">
                  <div className="flex items-center w-full overflow-hidden border border-gray-300 rounded-md focus-within:ring-1 focus-within:ring-brand-500 focus-within:border-brand-500 bg-white">
                    <div className="flex items-center pl-3 pr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      name="orgName"
                      id="orgName"
                      value={orgName}
                      onChange={(e) => {
                        setOrgName(e.target.value)
                        if (!slugManuallyEdited) {
                          setOrgSlug(e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, '-'))
                        }
                      }}
                      className="flex-1 min-w-0 block w-full py-2 px-1 border-0 focus:ring-0 focus:outline-none sm:text-sm"
                      placeholder="Enter organization name"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <label htmlFor="orgSlug" className="block text-sm font-medium text-gray-700">
                  Organization URL
                </label>
                <div className="mt-1">
                  <div className="flex items-center w-full overflow-hidden border border-gray-300 rounded-md focus-within:ring-1 focus-within:ring-brand-500 focus-within:border-brand-500 bg-white">
                    <div className="flex items-center pl-3 pr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                      </svg>
                    </div>
                    <div className="flex items-center flex-grow">
                      <span className="text-gray-500 text-sm py-2 pl-1">bomello.com/</span>
                      <input
                        type="text"
                        name="orgSlug"
                        id="orgSlug"
                        value={orgSlug}
                        onChange={(e) => {
                          setSlugManuallyEdited(true)
                          setOrgSlug(e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, '-'))
                        }}
                        className="flex-1 min-w-0 block w-full py-2 pr-3 border-0 focus:ring-0 focus:outline-none sm:text-sm"
                        placeholder="your-org-name"
                        pattern="[a-z0-9-]{3,}"
                        title="Must be at least 3 characters and only contain lowercase letters, numbers, and hyphens"
                        required
                      />
                      <div className="flex items-center pr-2">
                        {orgSlug && getAvailabilityStatusIcon()}
                      </div>
                    </div>
                  </div>
                  <div className="mt-1">
                    <div className="flex flex-col space-y-1">
                      <p className="text-xs text-gray-500">
                        This creates your organization's unique URL.
                      </p>
                      {availabilityStatus && (
                        <p className={`text-xs ${getAvailabilityStatusColor()}`}>
                          {getAvailabilityStatusMessage()}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {error && (
                <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md">
                  <p className="text-sm text-red-600">
                    {error.message}
                  </p>
                </div>
              )}

              <div className="mt-4 p-4 bg-gradient-to-r from-brand-50 to-blue-50 border border-brand-100 rounded-md shadow-sm">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-brand-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-brand-800">Start your 15-day free trial today!</h3>
                    <p className="mt-1 text-sm text-brand-700">
                      Begin creating immediately. <span className="font-semibold">No credit card required.</span>
                    </p>
                  </div>
                </div>
              </div>

              {false &&
                <div className="mt-4 p-3 bg-gray-50 border border-gray-200 rounded-md">
                  <p className="text-xs text-gray-600">
                    By creating an organization, you agree to our <a href="#" className="text-brand-600 hover:text-brand-500">Terms of Service</a> and <a href="#" className="text-brand-600 hover:text-brand-500">Privacy Policy</a>.
                  </p>
                </div>
              }

              <div className="mt-8 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsCreating(false)}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || availabilityStatus !== 'Available'}
                  className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-brand-500 border border-transparent rounded-md hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50 shadow-sm"
                >
                  {isSubmitting ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Creating...
                    </>
                  ) : (
                    <>
                      Get started now
                      <svg xmlns="http://www.w3.org/2000/svg" className="ml-1.5 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                      </svg>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
