import { createContext, useContext } from 'react';
import Sentry from 'src/lib/sentry'

import type { MetadataType } from 'types/graphql'
import type { MetadataSchema } from 'api/src/services/organizations/organizations'
import {
  type MetadataUnion,
  type MassType,
  type PriceType,
  type TimeType,
  type StringType,
  type BooleanType,
  type NumberType,
  type URLType,
  type Currency,
  currencySymbols,
  standardCurrencies,
} from 'shared/types'
import keyBy from 'lodash.keyby';
import { humanBoolean, prettyDate } from './formatters';

export type { MetadataSchema }

export type ResolvedMetadata = MetadataUnion & { key: string, displayValue: string }

export type MetadataValue = MetadataUnion['entry']

export const resolveMetadata = (schema: any, metadata: Record<string, MetadataValue>) => {
  if (!schema) return []
  return Object.entries(metadata).map(([key, value]) => {
    const schemaEntry = (schema as MetadataSchema)[key];

    if (!schemaEntry) {
      throw new Error(`Unknown metadata type: ${key}`)
    }

    let displayValue = ''

    if (schemaEntry.type === 'Boolean') {
      displayValue = humanBoolean(value as boolean)
    }
    if (schemaEntry.type === 'Mass') {
      const v = value as MassType['entry']
      displayValue = `${v.value} ${v.unit}`
    }
    if (schemaEntry.type === 'Price') {
      const v = value as PriceType['entry']
      displayValue = `${v.value} ${v.unit}`
    }
    if (schemaEntry.type === 'Time') {
      const v = value as TimeType['entry']
      displayValue = prettyDate(v)
    }
    if (schemaEntry.type === 'Number' ||
      schemaEntry.type === 'String' ||
      schemaEntry.type === 'URL') {
        const v = value as (StringType | NumberType | URLType)['entry']
        displayValue = String(v).trim()
      }

    return {
      ...schemaEntry,
      key,
      entry: value,
      displayValue
    } as ResolvedMetadata
  })
}

export const resolveMetadataObj = (schema: any, metadata: Record<string, MetadataValue>) => {
  if (!schema) return {}
  const resolved = resolveMetadata(schema, metadata)
  return keyBy(resolved, 'key')
}

type SearchMetadataInput = {
  schema: any
  checkValue: (value: string) => boolean
  metadata: Record<string, MetadataValue>
}

export const searchMetadata = ({ schema, checkValue, metadata }: SearchMetadataInput) => {
  const matches = Object.entries(metadata).map(([key, value]) => {
    if (typeof value !== 'string') return
    if (checkValue(value)) {
      const schemaEntry = (schema as MetadataSchema)[key];

      if (!schemaEntry) {
        throw new Error(`Unknown metadata type: ${key}`)
      }
      return {
        ...schemaEntry,
        key,
        entry: value
      } as Extract<ResolvedMetadata, { type: 'String' }>
    }
  })
  return matches.filter(Boolean) as Extract<ResolvedMetadata, { type: 'String' }>[]
}

export const unresolveMetadata = (resolvedMetadata: ResolvedMetadata[]) => {
  return resolvedMetadata.reduce((output, resolved) => {
    return {
      ...output,
      [resolved.key]: resolved.entry
    }
  }, {} as  Record<string, MetadataValue>)
}

export const castBomMetadataToMetadata = (schema: any, metadata: Record<string, string>) => {
  return unresolveMetadata(castBomMetadataToResolvedMetadata(schema, metadata))
}

export const parseCurrencyString = (input: string): { value: number; unit: Currency } => {
  try {
    // Look for standard 3-letter currency code first (before modifying input)
    const currencyMatch = input.match(/\b([A-Z]{3})\b/)
    const currencyFromCode = currencyMatch?.[1] && standardCurrencies.includes(currencyMatch[1] as Currency)
      ? currencyMatch[1] as Currency
      : null

    // Look for currency symbols (before modifying input)
    let currencyFromSymbol: Currency | null = null

    // Multi-character symbols first
    const multiCharSymbols = Object.entries(currencySymbols)
      .filter(([symbol]) => symbol.length > 1)
    for (const [symbol, currency] of multiCharSymbols) {
      if (input.includes(symbol)) {
        currencyFromSymbol = currency as Currency
        break
      }
    }

    // Then single-character symbols
    if (!currencyFromSymbol) {
      const singleCharSymbols = Object.entries(currencySymbols)
        .filter(([symbol]) => symbol.length === 1)
      for (const [symbol, currency] of singleCharSymbols) {
        if (input.includes(symbol)) {
          currencyFromSymbol = currency as Currency
          break
        }
      }
    }

    // Handle European number format (1.234,56) - convert to US format
    let cleanInput = input.match(/\d+\.\d+,\d+/)
      ? input.replace(/\./g, '').replace(',', '.')
      : input.replace(/[,']/g, '') // Otherwise just remove commas and apostrophes

    // Replace spaces between digits with empty string
    cleanInput = cleanInput.replace(/(\d)\s+(?=\d)/g, '$1')

    // Add leading zero to decimal points
    cleanInput = cleanInput.replace(/(?:^|[^\d])\./, '0.')

    // Extract the first number with optional decimal point, including scientific notation
    const numberMatch = cleanInput.match(/[-+]?\d*\.?\d+(?:[eE][-+]?\d+)?/)
    const value = numberMatch ? Math.abs(parseFloat(numberMatch[0])) : 0

    if (Number.isNaN(value)) {
      throw new Error(`Could not parse number from input: ${input}`)
    }

    // Use detected currency or default to USD
    const unit = currencyFromCode || currencyFromSymbol || 'USD'
    return { value, unit }
  } catch (error) {
    Sentry.captureException(error)
    return { value: 0, unit: 'USD' }
  }
}

export const castBomMetadataToResolvedMetadata = (schema: any, metadata: Record<string, string>) => {
  if (!schema) return []
  return Object.entries(metadata).map(([key, value]) => {
    const schemaEntry = (schema as MetadataSchema)[key]

    if (!schemaEntry) {
      throw new Error(`Unknown metadata type: ${key}`)
    }

    let entry
    if (schemaEntry.type === 'Boolean') {
      entry = /true/gi.test(value)
    }
    if (schemaEntry.type === 'Number') {
      entry = Number(value)
    }

    if (schemaEntry.type === 'Mass') {
      const rValueUnit = /^(?<value>\d+\.?\d*)-(?<unit>\w+)$/
      const result = value.match(rValueUnit)?.groups

      const outputValue = Number(result?.value)
      const outputUnit = result?.unit

      if (!result?.value || Number.isNaN(outputValue)) {
        throw new Error(`Error casting mass value for input: ${value}`)
      }
      if (!outputUnit) {
        throw new Error(`Error casting mass unit for input: ${value}`)
      }
      entry = { value: outputValue, unit: outputUnit }
    }

    if (schemaEntry.type === 'Price') {
      entry = parseCurrencyString(value)
    }

    if (schemaEntry.type === 'URL' || schemaEntry.type === 'String') {
      entry = value
    }

    // Time not implemented yet
    // if (schemaEntry.type === 'Time')

    return {
      ...schemaEntry,
      key,
      entry
    } as ResolvedMetadata
  })
}

export const metadataTypes = [
  'String', 'Boolean', 'Number', 'URL', 'Price', 'Mass', 'Time', 'Option'
] as const as MetadataType[]


export const MetadataSchemaContext = createContext<MetadataSchema | undefined>(undefined);
export const ChangeOrderSchemaContext = createContext<MetadataSchema | undefined>(undefined);

type MetadataContextType = 'changeOrder' | 'part'

export const useMetadataSchema = (type?: MetadataContextType) => {
  return useContext(type === 'changeOrder' ? ChangeOrderSchemaContext : MetadataSchemaContext)!
}

export const useMetadataSchemaMaybe = (type?: MetadataContextType) => {
  return useContext(type === 'changeOrder' ? ChangeOrderSchemaContext : MetadataSchemaContext)
}

export const useMetadataResolver = (type?: MetadataContextType) => {
  const schema = useMetadataSchema(type);
  return [(metadata: any) => resolveMetadata(schema, metadata), schema!] as const;
}

export const useMetadataTabularizer = (type?: MetadataContextType) => {
  const schema = useMetadataSchema(type);
  return (metadata: any) => tabularizeMetadata(schema!, metadata)
}

export const tabularizeMetadata = (schema: MetadataSchema, metadata: any) => {
  const resolved = resolveMetadataObj(schema, metadata)
  return Object.entries(schema).reduce((acc, [key, def]) => {
    const displayName = def.displayName.replace(' ', '-')
    //acc[`Metadata-${displayName}-Type`] = def.type;

    if (def.type === 'Mass' || def.type === 'Price') {
      const m = resolved[key] as Extract<ResolvedMetadata, { type: 'Mass' | 'Price' }> | undefined;
      acc[`${displayName}-Value`] = m?.entry.value.toString() ?? ''
      acc[`${displayName}-Units`] = m?.entry.unit ?? ''
    }
    else {
      const m = resolved[key] as Exclude<ResolvedMetadata, { type: 'Mass' | 'Price' }> | undefined;
      acc[`${displayName}`] = m?.entry.toString() ?? ''
    }

    return acc
  }, {} as Record<string, string>)
}
