import { MetaTags } from '@redwoodjs/web'
import { NavLink, routes, useParams, Link } from '@redwoodjs/router'

import { ButtonLink } from 'src/components/Button'
import ChangeOrdersCell from "src/components/ChangeOrdersCell"
import { ChangeOrderState } from 'types/graphql'
import { startCase } from 'lodash'

const StateFilters: Record<string, ChangeOrderState[]> = {
  'in-progress': ['Draft', 'Review'],
  complete: ['Complete'],
  cancelled: ['Cancelled']
}

function isStateFilter(filter: string): filter is keyof typeof StateFilters {
  return (filter in StateFilters);
}

const ChangeOrdersPage = () => {
  const orgId = useParams().orgId!
  const state = useParams().state!
  const filter = isStateFilter(state) ? state : 'draft';

  return (
    <>
      <MetaTags title="Change Orders" description="ChangeOrders page" />
      <div className='max-w-[1200px] mx-auto px-6 pb-12 pt-6'>
        <div className=''>
          <div className='flex pt-4 pb-8'>
            <nav className='flex gap-2 *:inline-block *:text-gray-700'>
              {Object.entries(StateFilters).map(([state]) =>
                <li key={state}>
                  <NavLink
                    className='pr-2 pl-2 align-middle'
                    activeClassName='font-semibold'
                    to={routes.changeOrders({state, orgId})}>{startCase(state)}
                    </NavLink>
                </li>
              )}
            </nav>
            <div className='ml-auto'>
              <ButtonLink writeOnly variant='primary' to={routes.changeOrderCreate({ orgId })}>
                Create Change Order
              </ButtonLink>
            </div>
          </div>
            <ChangeOrdersCell filter={StateFilters[filter]!}/>
        </div>
      </div>
  </>)
}

export default ChangeOrdersPage
