import {
  CreatePartQuery,
  ForkPartMutation,
  ForkPartMutationVariables,
  ForkPartInput,
  PartDeltaInput,
  PartQuery,
} from 'types/graphql'

import PartNumberInput from '../PartNumberInput/PartNumberInput'

import * as HeadlessForm from '@redwoodjs/forms'
import { useWatch } from '@redwoodjs/forms'
import { useMutation, CellSuccessProps, CellFailureProps } from '@redwoodjs/web'
import { reportMutationError } from 'src/lib/reportError'

import { partNumberFromBlockValues, getSchemaFromCategory } from 'shared/partNumbers'

import { useAppContext } from 'src/lib/appContext'
import { useState, useRef } from 'react'

import * as Form from 'src/components/Form'
import Button from '../Button'
import { TrashIcon } from '@heroicons/react/24/outline'
import { FailureErrorBar } from '../Failure/Failure'
import { routes, navigate, useParams } from '@redwoodjs/router';
import Combobox from '../Combobox/Combobox'

export const QUERY = gql`
  query CreatePartQuery {
    partCategories {
      id
      name
      label
      useRange
      schema {
        key
        template
        templateConfig
      }
    }
  }
`

export type CreatePartProps = {
  onComplete: (partNumber?: string) => void
  onCreate?: (deltas: PartDeltaInput[]) => void
} & ({
  variant?: undefined,
  changeOrderNumber: number,
  forkPart?: undefined
} | {
  variant: 'fork',
  changeOrderNumber?: undefined,
  forkPart: NonNullable<PartQuery['partProto']>
})

export const Loading = ({ ...props }: CreatePartProps) => {
  return <CreatePart {...props} partCategories={[]} />
}

export const Failure = (props: CreatePartProps & CellFailureProps) => {
  return <FailureErrorBar userErrorMessage='There was an error loading the create part dialog' {...props}>
    <CreatePart {...props} partCategories={[]} />
  </FailureErrorBar>
}

export const Success = ({ ...props }: CreatePartProps & CellSuccessProps<CreatePartQuery>) => {
  return <CreatePart {...props} />
}

const FORK_PART_MUTATION = gql`
mutation ForkPartMutation ($input: ForkPartInput!) {
  forkPart(input: $input) {
    partNumber
  }
}`

type PartRow = {
  rowIndex: number
  categoryId: null | string
}

const CreatePart: React.FC<CreatePartProps & CellSuccessProps<CreatePartQuery>> = ({ onComplete, onCreate, partCategories, ...rest }) => {
  const orgId = useParams().orgId!;
  const { variant, changeOrderNumber, forkPart } = rest;

  const partId = useRef(0)
  // defaultPartVersion
  const [initialCategoryId, setInitialCategoryId] = useState<string | null>(forkPart?.categoryId || null)
  const [partRows, setPartRows] = useState<PartRow[]>([{ rowIndex: partId.current, categoryId: initialCategoryId }])
  const [createLoading, setCreateLoading] = useState(false)
  const [forkPartMutation, { loading: forkLoading, error: forkError }] = useMutation<ForkPartMutation, ForkPartMutationVariables>(FORK_PART_MUTATION)


  const appContext = useAppContext()

  const formMethods = HeadlessForm.useForm<NewPartParams[]>()

  type NewPartParams = {
    name: string
    categoryId: string
    blockValues: Record<string, number | string>
  }

  const getPartNumber = (blockValues: Record<string, string | number>, categoryId: string) => {
    const { partCategories } = appContext

    const partCategory = partCategories.find(c => c.id === categoryId)
    if (!partCategory) throw Error('No category found to generate part number')
    const schema = getSchemaFromCategory(partCategory)

    const { missingBlocks, partNumber } = partNumberFromBlockValues(blockValues, schema)

    if (missingBlocks.length === 0) return { missingSequenceBlocks: [], partNumber }

    if (missingBlocks.some(b => b.config.type !== 'sequence')) {
      throw new Error(`There are missing part number blocks that cannot be generated, ${JSON.stringify(missingBlocks, null, 2)}`)
    }

    const sequenceBlocks = missingBlocks.filter(block => block.config.type === 'sequence')
    if (sequenceBlocks.length !== 1) {
      throw new Error(`Cannot generate part number with no sequence blocks or more than one sequence block, sequence blocks: ${sequenceBlocks.length}`)
    }
    return { missingSequenceBlocks: sequenceBlocks, partNumber }
  }

  const handleSubmit = async (newParts: NewPartParams[]) => {
    if (variant === 'fork') {
      const input = newParts[0]!
      const { categoryId } = partRows[0]!
      const { blockValues, name } = input

      if (!categoryId) throw new Error('No part category ID')
      const { partNumber } = getPartNumber(blockValues, categoryId)

      const forkInput: ForkPartInput = {
        partNumber: forkPart.partNumber,
        version: forkPart.instance.version,
        newCategoryId: categoryId !== forkPart.categoryId ? categoryId : undefined,
        newPartName: name ?? undefined,
        newPartNumber: partNumber
      }
      const variables: ForkPartMutationVariables = {
        input: forkInput
      }
      const { data, errors } = await forkPartMutation({
        variables,
      })

      if (errors) {
        reportMutationError({
          errors,
          variables,
          message: `Error forking part`
        })
      }
      else {
        navigate(
          routes.part({
            orgId,
            partNumber: data?.forkPart.partNumber!
          })
        )
      }
    }
    else {
      const partDeltas = partRows
        .map(row => {
          const { categoryId } = row
          const formData = newParts[row.rowIndex]

          if (!formData) throw new Error('Part undefined in form')
          if (!categoryId) throw new Error('Missing category')

          const { blockValues, name } = formData
          const { missingSequenceBlocks, partNumber } = getPartNumber(blockValues, categoryId)

          const partInput: PartDeltaInput = {
            partNumber,
            generateSequenceBlock: Boolean(missingSequenceBlocks.length > 0),
            version: appContext.currentOrg.defaultPartVersion,
            categoryId,
            type: 'Create',
            part: {
              name: name
            }
          }
          return partInput
        })
        setCreateLoading(true)
        onCreate && await onCreate(partDeltas)
        setCreateLoading(false)
    }
  }

  const renderPartRows = () => {

    if (variant === 'fork') {
      const categoryId = partRows[0]!.categoryId
      const handleCategorySelect = (categoryId: string) => {
        const part = partRows[0]!
        console.log('setting part rows', partRows, [{
          ...part,
          categoryId: categoryId
        }])
        setPartRows([{
          ...part,
          categoryId: categoryId
        }])
      }
      return <NewPartRow
        autoFocus
        formMethods={formMethods}
        rowIndex={0}
        setCategoryId={handleCategorySelect}
        onRowRemove={() => { }}
        categoryId={categoryId || forkPart.categoryId}
        noTrash={true}
        defaultNameValue={`${forkPart.instance.name!} (Fork)`}
        namePlaceholder='Forked Part Name' />
    }
    else {
      return partRows.map((row, i) => {
        const { rowIndex, categoryId } = row
        const handleCategorySelect = (categoryId: string) => {
          setPartRows(partRows.map(p => {
            if (p.rowIndex === rowIndex) {
              return {
                ...p,
                categoryId
              }
            }
            return p
          }))
        }
        return <NewPartRow
          key={i}
          autoFocus={i === (partRows.length - 1)}
          formMethods={formMethods}
          rowIndex={row.rowIndex}
          setCategoryId={handleCategorySelect}
          onRowRemove={() => setPartRows(partRows.filter((r) => row.rowIndex !== r.rowIndex))}
          trackPosition
          noTrash={partRows.length === 1}
          categoryId={categoryId || initialCategoryId} />
      })
    }
  }

  const missingCategories = partRows.some(p => !p.categoryId)

  const renderButtons = () => {
    return <>
      <Button type='button' onClick={() => onComplete()} tabIndex={-1}>Cancel</Button>
      <Button type='submit' disabled={createLoading || missingCategories} variant='primary' className='ml-auto'>{variant === 'fork' ? 'Fork' : 'Create'}</Button>
    </>
  }

  const renderTitle = () => {
    return <>
      <div className='text-xl'>{variant === 'fork' ? 'Fork Part' : 'Create New Parts'}</div>
      {!variant &&
        <AddPartButton setInitialCategoryId={setInitialCategoryId} partId={partId} setPartRows={setPartRows} partRows={partRows} />
      }
    </>
  }

  return (
    <Form.Form<NewPartParams[]> className='flex flex-col gap-4 p-3' onSubmit={handleSubmit} formMethods={formMethods}>
      <div className='my-2 flex justify-between'>
        {renderTitle()}
      </div>
      <div className={`grid gap-4 grid-cols-[400px_auto_auto_auto]`}>
        <div className='pb-2'><Form.Label name='name'> Name </Form.Label></div>
        <div className='pb-2'><Form.Label>Part Category</Form.Label></div>
        <div className='pb-2'><Form.Label name='partNumber'> Part Number </Form.Label></div>
        <div></div>
        {
          renderPartRows()
        }
      </div>
      <div className='flex gap-2 mt-4'>
        {renderButtons()}
      </div>
    </Form.Form>
  )
}

type NewPartRowProps = {
  autoFocus: boolean
  rowIndex: number
  setCategoryId: (id: string) => void
  categoryId: string | null
  onRowRemove: () => void
  formMethods: HeadlessForm.UseFormReturn<Record<string, string>[], any, undefined>
  defaultNameValue?: string
  namePlaceholder?: string
  noTrash?: boolean
}
const NewPartRow: React.FC<NewPartRowProps> = ({
  autoFocus,
  rowIndex,
  categoryId,
  setCategoryId,
  onRowRemove,
  formMethods,
  defaultNameValue,
  namePlaceholder,
  noTrash,
}) => {
  const appContext = useAppContext()
  const categoryOptions = appContext.partCategories.map(c => {
    return {
      id: c.id,
      display: c.name
    }
  })

  const handleSelectCategoryId = (cid: string) => {
    console.log('selected id', cid)
    setCategoryId(cid)
  }

  return <>
    <Form.TextField
      autoFocus={autoFocus}
      key={`${rowIndex}.name`}
      placeholder={namePlaceholder ?? 'Select part name'}
      defaultValue={defaultNameValue}
      name={`${rowIndex}.name`}
      required
      className='*:h-10'
      autoComplete='off' />
    <Combobox
      selectedId={categoryId || null}
      onSelectId={handleSelectCategoryId}
      testId={`createPartCategory`}
      placeholder='Select Category'
      options={categoryOptions} />
    <PartNumberInput categoryId={categoryId} namePrefix={`${rowIndex}.blockValues`} formMethods={formMethods} />
    {!noTrash &&
      <button key={`${rowIndex}.remove`} type='button' onClick={onRowRemove}>
        <TrashIcon className='h-5 w-5' />
      </button>
    }
  </>
}

type AddPartProps = {
  partId: React.MutableRefObject<number>
  setInitialCategoryId?: (id: string | null) => void
  setPartRows: (value: React.SetStateAction<PartRow[]>) => void
  partRows: PartRow[]
}
const AddPartButton: React.FC<AddPartProps> = ({
  partId,
  setInitialCategoryId,
  setPartRows,
  partRows
}) => {

  const lastCategorySelected = Object.values(partRows).reduce((lastCategory, row) => {
    return row?.categoryId ? row.categoryId : lastCategory
  }, undefined as undefined | string)

  return <Button onClick={() => {
    partId.current += 1
    const nextCategoryId = lastCategorySelected || null
    if (setInitialCategoryId) setInitialCategoryId(nextCategoryId)
    setPartRows([...partRows, { rowIndex: partId.current, categoryId: nextCategoryId }])
  }}>Add Part</Button>
}
