import { Organization, User } from 'types/graphql'
import Logo from 'src/components/Logo'
import { routes, useParams, useLocation, navigate } from '@redwoodjs/router'
import { NavLink, Link } from '@redwoodjs/router'
import { useAuth } from 'src/auth'
import * as ListBox from 'src/components/ListBox'
import { BellIcon, Cog6ToothIcon, ClockIcon } from '@heroicons/react/24/outline'
import SearchBarCell from 'src/components/SearchBarCell'
import { createPortal } from 'react-dom'
import { useOnboarding } from 'src/lib/useOnboarding'

type NavBarProps = {
  me?: Pick<User, 'latestNotification' | 'notificationsReadAt' | 'name' | 'isSuperUser' | 'id'>
  currentOrg?: Pick<Organization, 'isFreemium' | 'createdAt'>
}
const NavBar: React.FC<NavBarProps> = ({
  me, currentOrg
}) => {
  const orgId = useParams().orgId!

  const { pathname } = useLocation()
  const partsActive = /^\/[^\/]+\/parts/.test(pathname)
  const coActive = /^\/[^\/]+\/change-order/.test(pathname)
  const tasksActive = /^\/[^\/]+\/tasks?/.test(pathname)

  const { setShowOnboarding } = useOnboarding({
    orgCreatedAt: currentOrg?.createdAt,
    isFreemium: currentOrg?.isFreemium,
    userId: me?.id
  })

  // Calculate remaining days in free trial
  const calculateRemainingDays = () => {
    if (!currentOrg?.createdAt) return 0;

    const createdDate = new Date(currentOrg.createdAt);
    const trialEndDate = new Date(createdDate);
    trialEndDate.setDate(trialEndDate.getDate() + 15); // 15-day trial

    const today = new Date();
    const remainingTime = trialEndDate.getTime() - today.getTime();
    const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));

    return Math.max(0, remainingDays); // Don't show negative days
  };

  const trialDaysRemaining = calculateRemainingDays();

  const navLinkClass = 'pl-2 pr-2 box-border relative self-stretch flex items-center'

  const lastNotificationCheck = me?.notificationsReadAt
  const latestNotification = me?.latestNotification

  const newNotifications = latestNotification &&
    (!lastNotificationCheck || new Date(latestNotification) > new Date(lastNotificationCheck))

  const { logOut } = useAuth();

  const handleUserMenuAction = (action: string) => {
    if (action === 'logout') logOut(() => {
      navigate(routes.root({}))
    })
    if (action === 'superuser') navigate(routes.superUserOrg({ orgId }))
    if (action === 'scheduleTime') window.open('https://meetings.hubspot.com/sfatoohi', '_blank')
  }

  // used instead of bottom border which would move the nav text upward
  const underline = <div className='absolute border-b-4 border-purple-300 bottom-0 left-0 right-0'></div>
  return (
    <>
      {/* Spacer div to prevent content jump */}
      <div className='h-16'></div>

      {createPortal(
        <nav className="px-6 bg-[#016dc3] border-b-1 border-gray-200 flex items-center gap-4 h-16 text-white fixed top-0 left-0 z-[51]" style={{ width: 'calc(100vw)' }}>
          <a href={routes.home({ orgId })}><Logo className='inline mr-4 text-white w-8 h-8' /></a>
          <NavLink to={routes.parts({ orgId })} activeClassName='' className={navLinkClass}>
            Parts
            {partsActive && underline}
          </NavLink>
          <NavLink to={routes.changeOrders({ orgId, state: 'in-progress' })} activeClassName='' className={navLinkClass}>
            Change Orders
            {coActive && underline}
          </NavLink>
          {/* <NavLink to={routes.tasks({ orgId })} activeClassName='' className={navLinkClass}>
            Tasks
            {tasksActive && underline}
          </NavLink> */}
          <div className='flex-1 flex justify-end mr-4'>
            <SearchBarCell />
          </div>
          {currentOrg?.isFreemium && (
            <div className="mr-4">
              <div
                className="flex items-center px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs font-medium cursor-pointer hover:bg-yellow-200 transition-colors"
                onClick={() => setShowOnboarding(true)}
              >
                <ClockIcon className="h-3.5 w-3.5 mr-1" />
                {trialDaysRemaining > 0 ? (
                  <span>Trial: <span className="font-bold">{trialDaysRemaining}</span> {trialDaysRemaining === 1 ? 'day' : 'days'} left</span>
                ) : (
                  <span>Trial ended</span>
                )}
              </div>
            </div>
          )}
          <div className='ml-auto flex items-center'>
            <div className='flex items-center mr-4 gap-3'>
              <a href={routes.notificatins({ orgId })} className='cursor-pointer flex gap-0.5 items-center'>
                {newNotifications && <svg className="h-2 w-2 fill-purple-400" viewBox="0 0 6 6" aria-hidden="true">
                  <circle cx={3} cy={3} r={3} />
                </svg>}
                <BellIcon className='h-5 text-white' />
              </a>
              <Link to={routes.orgSettings({ orgId })} className='cursor-pointer flex gap-0.5 items-center'>
                <Cog6ToothIcon className='h-5 text-white' />
              </Link>
            </div>
            <div className='flex items-center rounded-full w-7 h-7 justify-center bg-purple-300'>
              <div>
                {me?.name[0]!.toUpperCase()}
              </div>
            </div>
            <ListBox.ListBox value='' onChange={handleUserMenuAction}>
              {({ open }) => (<>
                <div className="relative">
                  <ListBox.PlainButton className='text-white' displayValue={me?.name || ''} />
                  <ListBox.Options open={open} className='text-black text-xs'>
                    {me?.isSuperUser ? <ListBox.Option value='superuser' display={`Super User`} key='superuser' /> : null}
                    {currentOrg?.isFreemium &&
                      <ListBox.Option value='scheduleTime' display={'Schedule time with Bomello'} key='scheduleTime' />
                    }
                    <ListBox.Option value='logout' display={`Logout`} key='logout' />
                  </ListBox.Options>
                </div>
              </>
              )}
            </ListBox.ListBox>
          </div>
        </nav>,
        document.body
      )}
    </>
  )
}

export default NavBar
