import { useState, useReducer } from 'react'
import { reportMutationError } from 'src/lib/reportError'
import {
  ChangeOrderChangesQuery,
  ChangeOrderChangesQueryVariables,
  EditPartMutationVariables,
  EditPartVersionMutation,
  EditPartVersionMutationVariables,
  EditLifeCycleMutation,
  EditLifeCycleMutationVariables,
  Distributor,
  Part,
  ChangeOrderProtoMutation,
  ChangeOrderProtoMutationVariables,
} from 'types/graphql'
import invariant from 'tiny-invariant'

import { ConditionalModal } from '../Modal'
import * as Form from 'src/components/Form'
import * as ListBox from 'src/components/ListBox'

import type { CellSuccessProps } from '@redwoodjs/web'
import { useParams, Link } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import Button, { EditButton } from '../Button'
import { DependencyChanges } from 'src/components/Dependencies'
import { ArtifactChanges } from '../Artifacts/Artifacts'
import { OverviewChanges, OverviewField } from '../PartOverview/PartOverview'
import { SourceChanges } from 'src/components/Sources/Sources'
import { calculateChange, PartChangePart, PartDiffSummary } from './calculateAllChanges'
import { uiVersionParts, isVersionIncrement } from 'src/lib/version'
import RemovePart from 'src/components/RemovePart'
import MergeConflictHandler from './MergeConflict'
import { LoadingSpinnerWithDelay as LoadingSpinner } from 'src/components/Loading'
import { ExclamationTriangleIcon, TagIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { StatusDot } from '../LifecycleStatus/LifecycleStatus'
import { useLifeCycleStages, resolveLifeCycle } from "src/lib/lifecycle"
import { QUERY as PART_VERSION_SELECT_CELL } from '../PartVersionSelectCell'

import { CHANGE_ORDER_CHANGES_QUERY, CHANGE_ORDER_TREE_QUERY, EDIT_PART_MUTATION } from 'src/lib/queries'
import GenericFailure from '../Failure/Failure'
import { TemplateBlockConfigs } from 'shared/partNumbers'
import { useAppContext } from 'src/lib/appContext'
import { ControlledCategorySelect } from '../PartCategorySelect'
import { withErrorBoundary } from 'src/components/ErrorBoundary'
import { block, letF } from 'api/src/shared/functional'
import { MetadataChanges } from 'src/components/Metadata'
import { useRightPanel } from '../RightPanel/RightPanel'
import Sentry from 'src/lib/sentry'
import { ThumbnailChanges } from './ThumbnailChanges'

export const Failure = GenericFailure

export const QUERY = CHANGE_ORDER_CHANGES_QUERY

export type ChangeOrderContextInfo = {
  isPartInOrder: (partNumber: string) => PartDiffSummary | undefined
}

export const beforeQuery = ({ ...variables }: ChangeOrderChangesQueryVariables) => {
  return { variables, fetchPolicy: 'cache-first', }
}

export const Loading = () => <LoadingSpinner className='flex p-10 items-center justify-center' />

type ChangeOrderChanges = CellSuccessProps<ChangeOrderChangesQuery, ChangeOrderChangesQueryVariables> & {
  partNumber: string
  version: string
  viewMode: ViewMode
}

type ViewMode = 'unified' | 'split'

const ChangeOrderChangesCell = ({ changeOrder: co, currentOrg, partNumber, viewMode, partProto: proto, partById }: ChangeOrderChanges) => {
  const [renderId, rerender] = useReducer((s) => s + 1, 0)
  const stages = useLifeCycleStages()
  if (!proto) {
    throw new Error('No proto found')
  }

  interface CO {
    number: number
    state: string
  }
  const changeOrder: CO = co

  // Will never return null, but the query
  // needs to accept a null partId for refetching
  // the query for this cell
  invariant(partById)

  // This is the exact part chosen when clicking the hierarchy
  const selectedPart = partById
  const changeOrderPart = co.includedParts.find(p => p.partNumber === partNumber)

  const isNewPart = changeOrderPart && !changeOrderPart.updateTo
  const isOldVersion = !isNewPart && changeOrderPart?.id !== selectedPart.id
  const isCurrentVersion = !isNewPart && selectedPart.version === proto.currentVersionString
  const isNewChangeOrderVersion = changeOrderPart && changeOrderPart.id === selectedPart.id

  const [editPartProtoMutation] = useMutation<ChangeOrderProtoMutation, ChangeOrderProtoMutationVariables>(EDIT_PROTO_MUTATION)

  const editPartProto = async (partNumber: string, input: { categoryId: string }) => {
    const variables: ChangeOrderProtoMutationVariables = {
      partNumber,
      input: {
        categoryId: input.categoryId
      }
    }
    const { errors } = await editPartProtoMutation({
      variables
    })
    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating part proto to category: ${input.categoryId}`
      })
    }
  }

  const appContext = useAppContext()

  const changeOrderComplete = changeOrder.state === 'Complete' || changeOrder.state === 'Cancelled'

  const editableCategories = appContext.partNumberSchemas.flatMap(schema => {
    const templateConfig = schema.templateConfig as TemplateBlockConfigs
    const isCategoryEditable = Object.values(templateConfig).every(block => {
      return !['categoryNumber', 'categoryId'].includes(block.type)
    })
    if (isCategoryEditable) return schema.categories
    return []
  })

  // overarching function for calculating changes - should be refactored to pass changes to
  // components instead of duplicating their logic
  const partDiff = block(() => {
    if (!changeOrderPart) return
    return calculateChange(currentOrg, changeOrderPart)
  })

  const headPart = block(() => {
    if (partDiff) return partDiff.headPart
    return partById
  })
  const incomingPart = partDiff ? partDiff.incomingPart : null

  invariant(incomingPart || headPart)
  // const { headPart, incomingPart, proto } = partDiff
  const headerPartName = incomingPart?.name || headPart?.name

  const validDependencyState = block(() => {
    if (!incomingPart) return true
    const thisLifeCycle = resolveLifeCycle(stages, incomingPart.lifeCycle)
    return thisLifeCycle.isEndOfLife || incomingPart.dependencies.every(d =>
      resolveLifeCycle(stages, d.to.lifeCycle).stageIndex >= thisLifeCycle.stageIndex
    )
  })

  const changeOrderContext: ChangeOrderContextInfo = {
    isPartInOrder(partNumber) {
      return false
      // return diffByPn[partNumber]
    }
  }

  const header = block(() => {
    if (partDiff?.type === 'create') {
      return { color: 'bg-green-100 border border-green-200 text-green-600', action: 'Create' }
    }
    if (isNewChangeOrderVersion) return { color: 'bg-yellow-100 border border-yellow-300 text-yellow-800', action: 'Update' }
    if (isCurrentVersion) return { color: 'bg-gray-100 border border-gray-200 text-gray-600', action: 'No New Version' }
    if (isOldVersion) return { color: 'bg-gray-100 border border-gray-200 text-gray-600', action: 'Old Version' }

    Sentry.captureException(new Error(`Unknown part state`), {
      extra: {
        partNumber: proto.partNumber,
        partDiff: partDiff
      }
    })
    return {
      color: 'bg-gray-100 border border-gray-200 text-gray-600',
      action: 'Unknown'
    }
  })

  const category = () => {
    if (partDiff?.type !== 'create' || !editableCategories.find(ec => ec.id === proto.category.id) || changeOrderComplete) {
      return <><TagIcon className='h-3 w-3' /><div>{proto.category.name}</div></>
    }
    return <><TagIcon className='h-3 w-3' />
      <ControlledCategorySelect
        size='sm'
        keepInternalState
        showOnly={editableCategories.map(c => c.id)}
        categories={appContext.partCategories}
        value={proto.category.id}
        onCategoryIdChange={c => editPartProto(proto.partNumber, { categoryId: c })}
      />
    </>
  }

  const part = <div className={`flex flex-col scroll-mt-32 mb-8`} key={proto.partNumber} id={`part-change-${proto.partNumber}`}>

    {/* Header Section */}
    <div className={` flex gap-4 flex-col`}>
      {/* Controls Section */}
      {/* MOVE TO HIERARCHY VIEW */}
      {/* <div className='flex justify-between items-center'>
            <div className='flex gap-2'>
              {removeButton(partDiff, changeOrderComplete)}
              {partDiff.type !== 'create' &&
                <Link to={routes.part({ orgId, partNumber: incomingPart.partNumber })} className='leading-none'>
                  <Button size='sm' tabIndex={-1}> View Release Part </Button>
                </Link>
              }
            </div>
            <Popover className="relative">
              <Popover.Button>
                <Button type='button' className='leading-none px-[2px]' tabIndex={-1} size='sm'>
                  <EllipsisVerticalIcon className='w-4'/>
                </Button>
              </Popover.Button>
              <Popover.Panel className='absolute *:py-2 *:pl-3 *:pr-3 *:cursor-pointer text-xs z-30 w-max mt-1 max-h-96 rounded-md bg-white py-1
                shadow-lg ring-1 ring-black ring-opacity-5
                focus:outline-none
                right-0'>
                {proto.partNumber !== partContext ?
                  <button type='button' className='leading-none hover:bg-brand-500 hover:text-white' onClick={() => setPartContext(proto.partNumber)}>
                    Set as change order context
                  </button>
                  :
                  <button type='button' className='leading-none hover:bg-brand-500 hover:text-white' onClick={() => setPartContext()}>
                    Unset as change order context
                  </button>
                }
              </Popover.Panel>
            </Popover>
          </div> */}
      <div className={`flex items-start gap-1 leading-none px-8 pb-8 pt-1`}>
        <div className='flex-1 flex flex-col items-center text-gray-900 gap-2'>
          <div className='mb-2'>
            <span className={`text-sm ${header.color} rounded-lg px-2 py-1`}>{header.action}</span>
          </div>
          <div className='flex gap-1 text-gray-700 text-xs items-center'>
            {category()}
          </div>
          <div className={`text-sm text-center`}>
            <span className={`font-semibold`}>#{proto.partNumber} - </span>
            <span className='text-center'>{headerPartName ?? ''}</span>
          </div>
        </div>
      </div>
    </div>

    <>
      {!changeOrderComplete && incomingPart &&
        <MergeConflictHandler changeOrderNumber={changeOrder.number} proto={proto} headPart={headPart} incomingPart={incomingPart} onComplete={rerender} />
      }
      {!validDependencyState &&
        <div className='-mb-4 mx-2 mt-1 p-2 flex flex-col gap-2'>
          <div className='flex items-center gap-4 bg-red-100 rounded-xl p-4 py-2 text-xs text-gray-800 border-red-200 border'>
            <ExclamationTriangleIcon className='w-6 mt-0.5 text-red-500' />
            <div className='font-medium'>
              A part included in this assembly is behind the lifecycle status of the assembly. This change order cannot be applied until all the child parts are at the same status or higher.
            </div>
          </div>
        </div>
      }
      <div className='px-8'>
        <PartChange
          key={proto.partNumber + ":" + renderId}
          edit={{
            changeOrderNumber: changeOrder.number
          }}
          changeOrderComplete={changeOrderComplete}
          currentOrg={currentOrg}
          partDiff={partDiff}
          proto={proto}
          distributors={currentOrg.distributors}
          // TODO P1: Lookup head part instead of version in main
          headPart={headPart}
          partId={partById.id}
          changeOrderContext={changeOrderContext}
          forceRerender={rerender}
          viewMode={viewMode}
        />
      </div>
    </>
  </div>

  return <>
    <div className='flex flex-col gap-4'>
      {part}
    </div>
  </>
}

export const Success = withErrorBoundary(ChangeOrderChangesCell)

export const EDIT_PROTO_MUTATION = gql`
mutation ChangeOrderProtoMutation (
  $partNumber: String!
  $input: UpdatePartProtoInput!
) {
  updatePartProto(partNumber: $partNumber, input: $input) {
    partNumber
  }
}
`

type PartChangeProps = {
  changeOrderComplete: boolean
  distributors: Distributor[]
  headPart?: PartChangePart
  partDiff?: PartDiffSummary
  partId: string
  proto: {
    partNumber: string
  }
  edit?: {
    changeOrderNumber: number
  }
  hideVersion?: boolean
  currentOrg: ChangeOrderChanges['currentOrg']
  changeOrderContext?: ChangeOrderContextInfo,
  forceRerender?: () => void
  viewMode: ViewMode,
  hideSwitch?: boolean
}

type ChangeMessageProps = {
  edit?: {
    partNumber: string
    changeOrderNumber: number
  }
  headPart?: PartChangePart | null
  incomingPart: PartChangePart
  changeOrderComplete: boolean
  isExpanded: boolean
  viewMode: 'unified' | 'split'
}

const ChangeMessageSection: React.FC<ChangeMessageProps> = ({
  edit,
  headPart,
  incomingPart,
  changeOrderComplete,
  isExpanded,
  viewMode
}) => {
  return <div className='flex'>
    {viewMode === 'split' && headPart && <>
      <div className='flex-1'></div>
      <div className='border-l border-gray-200 mx-10'></div>
    </>}
    <div className='flex pt-2 flex-1 flex-col justify-between gap-4'>
      <OverviewField
        editMode={changeOrderComplete ? undefined : 'edit'}
        fieldName='changeMessage'
        label='Change Message'
        inputType={'textarea'}
        nextVersion={incomingPart.version}
        empty={`No change message written`}
        edit={edit}
        value={incomingPart.changeMessage} />
      {headPart && (isExpanded || incomingPart.transitionPlan) &&
        <OverviewField
          editMode={changeOrderComplete ? undefined : 'edit'}
          fieldName='transitionPlan'
          label='Transition Plan'
          inputType={'textarea'}
          nextVersion={incomingPart.version}
          empty={`No transition plan`}
          edit={edit}
          value={incomingPart.transitionPlan} />
      }
    </div>
  </div>
}

export const PartChange: React.FC<PartChangeProps> = ({
  changeOrderComplete,
  partId,
  edit,
  distributors,
  headPart,
  proto,
  hideVersion,
  partDiff,
  currentOrg,
  changeOrderContext,
  forceRerender,
  viewMode,
  hideSwitch
}) => {

  const incomingPart = partDiff?.incomingPart
  const childEdit = edit ? {
    partNumber: proto.partNumber,
    changeOrderNumber: edit.changeOrderNumber
  } : undefined

  const { panelRoute } = useRightPanel()

  const versionSection = () => {
    if (hideVersion) return null
    return <VersionChanges
      currentOrg={currentOrg}
      proto={proto}
      headPart={headPart}
      incomingPart={incomingPart}
      changeOrderComplete={changeOrderComplete}
      onChange={forceRerender}
      edit={childEdit}
      viewMode={viewMode}
    />
  }

  const thumbnailHasChange = incomingPart && partDiff && partDiff.fields.thumbnail.hasChange

  return <div>
    <div className='pb-5 flex flex-col pt-6'>
      {/* View Mode Control */}
      {!hideSwitch && headPart && incomingPart &&
        <div className='flex justify-center mb-4'>
          <div className='bg-gray-100 rounded flex w-36 p-0.5 h-6 gap-1 text-xs shadow-xs ring-1 ring-inset ring-gray-200'>
            <Link
              to={panelRoute('partChanges', { orderNumber: edit?.changeOrderNumber, partNumber: incomingPart.partNumber, partId })}
              className={`flex-1 text-center flex items-center justify-center py-0.5
                ${viewMode === 'unified' ? 'pointer-events-none bg-white rounded' : ''}`}
            >
              Unified
            </Link>
            <Link
              to={panelRoute('partChangesSplit', { orderNumber: edit?.changeOrderNumber, partNumber: incomingPart.partNumber, partId })}
              className={`flex-1 text-center flex items-center justify-center py-0.5
                ${viewMode === 'split' ? 'pointer-events-none bg-white rounded' : ''}`}
            >
              Split
            </Link>
          </div>
        </div>
      }

      {/* Version */}
      {versionSection()}

      <div className='flex flex-col gap-4'>
        {incomingPart && <ChangeMessageSection
          edit={childEdit}
          headPart={headPart}
          incomingPart={incomingPart}
          changeOrderComplete={changeOrderComplete}
          isExpanded
          viewMode={viewMode}
        />}

        <ThumbnailChanges
          viewMode={viewMode}
          isExpanded
          headPart={headPart}
          partDiff={partDiff}
          edit={childEdit}
          changeOrderComplete={changeOrderComplete}
        />

        {/* Overview */}
        <OverviewChanges
          viewMode={viewMode}
          isExpanded
          changeOrderComplete={changeOrderComplete}
          incomingPart={incomingPart}
          headPart={headPart}
          edit={childEdit} />
        <MetadataChanges
          viewMode={viewMode}
          isExpanded
          headPart={headPart}
          incomingPart={incomingPart}
          partDiff={partDiff}
          edit={childEdit}
          changeOrderComplete={changeOrderComplete}
        />
        <ArtifactChanges
          viewMode={viewMode}
          isExpanded
          headPart={headPart}
          partDiff={partDiff}
          changeOrderNumber={edit?.changeOrderNumber}
          changeOrderComplete={changeOrderComplete} />
          <SourceChanges
            viewMode={viewMode}
            isExpanded
            distributors={distributors}
            changeOrderComplete={changeOrderComplete}
            changeOrderNumber={edit?.changeOrderNumber}
            headPart={headPart}
            partDiff={partDiff}
          />
        {/* BOM */}
        <DependencyChanges
          viewMode={viewMode}
          isExpanded
          headPart={headPart}
          partDiff={partDiff}
          changeOrderComplete={changeOrderComplete}
          changeOrderContext={changeOrderContext}
        />
      </div>
    </div>
  </div>
}

const EDIT_LIFECYCLE_MUTATION = gql`
mutation EditLifeCycleMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
  $lifeCycle: String!
) {
  addPartDeltasReturnChangeOrder(changeOrderNumber: $changeOrderNumber, input: [{
    type: Patch
    partNumber: $partNumber
    version: $version
    part: {
      lifeCycle: $lifeCycle
    }
  }]) {
    ...ChangeOrderReviewersFragment
  }
}
`

type StatusProps = {
  changeOrderNumber?: number
  part: PartChangePart
  className?: string
  disabled: boolean
}
export const LifecycleStatus: React.FC<StatusProps> = ({ changeOrderNumber, part, disabled, className }) => {
  const stages = useLifeCycleStages()
  const { partContext } = useParams()

  const [updateLifeCycle, { loading, error }] = useMutation<EditLifeCycleMutation, EditLifeCycleMutationVariables>(EDIT_LIFECYCLE_MUTATION)

  const handleLifeCycleChange = async (newKey: string) => {
    if (typeof changeOrderNumber !== 'number') {
      throw new Error('Cannot change lifecycle without change order')
    }
    if (newKey === part.lifeCycle) return

    const variables: EditLifeCycleMutationVariables = {
      changeOrderNumber,
      partNumber: part.partNumber,
      version: part.version,
      lifeCycle: newKey
    }

    const { errors } = await updateLifeCycle({
      variables,
      refetchQueries: [
        { query: QUERY, variables: { orderNumber: changeOrderNumber } },
        { query: CHANGE_ORDER_TREE_QUERY, variables: { orderNumber: changeOrderNumber, partContext } },
      ],
      awaitRefetchQueries: true,
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating part lifeCycle with ${newKey}`
      })
    }
  }

  const Display = ({ lifeCycle }: { lifeCycle: string | null | undefined }) =>
    <div className='flex items-center gap-2 text-sm'>
      <StatusDot size='sm' lifeCycle={lifeCycle} />
      <div>{resolveLifeCycle(stages, lifeCycle).name}</div>
    </div>

  if (disabled) return <div className={`'mr-2 ${className ?? ''}`}> <Display lifeCycle={part.lifeCycle} /> </div>

  return <ListBox.ListBox disabled={disabled} defaultValue={part.lifeCycle} onChange={handleLifeCycleChange} >
    {({ open }) =>
      <div className={`relative h-full text-xs ${className}`}>
        <ListBox.UncontrolledButton size='sm' displayFunction={s => <Display lifeCycle={s.value} />} />
        <ListBox.Options open={open} align='right'>
          {stages?.map(s =>
            <ListBox.Option key={s.key} className='py-3' value={s.key}
              display={<Display lifeCycle={s.key} />}
            />
          )}
        </ListBox.Options>
      </div>
    }
  </ListBox.ListBox>
}

export const EDIT_PART_VERSION_MUTATION = gql`
mutation EditPartVersionMutation (
  $changeOrderNumber: Int!
  $partNumber: String!
  $version: String!
) {
  addPartDeltas(changeOrderNumber: $changeOrderNumber, input: [{
    type: Version
    partNumber: $partNumber
    version: $version
  }]) {
    partNumber
  }
}
`

type VersionChangesProps = {
  edit?: {
    changeOrderNumber: number
  }
  changeOrderComplete: boolean
  currentOrg: ChangeOrderChanges['currentOrg']
  headPart?: PartChangePart | null
  incomingPart?: PartChangePart
  proto: {
    partNumber: string
  }
  onChange?: () => void
  viewMode: 'unified' | 'split'
}
const VersionChanges: React.FC<VersionChangesProps> = ({
  currentOrg,
  headPart,
  incomingPart,
  edit,
  changeOrderComplete,
  onChange,
  viewMode,
  proto: { partNumber }
}) => {
  const headVersion = headPart?.version
  const incomingVersion = incomingPart?.version

  const [editing, setEditing] = useState(false)

  const [editVersionMutation, { loading, error }] = useMutation<EditPartVersionMutation, EditPartVersionMutationVariables>(EDIT_PART_VERSION_MUTATION)

  type VersionForm = { version: string }
  const onVersionEditSubmit = async ({ version: incomingVersion }: VersionForm) => {
    if (!edit) return
    const { changeOrderNumber } = edit
    const version = incomingVersion.toLowerCase();
    const variables: EditPartMutationVariables = {
      version,
      changeOrderNumber,
      partNumber
    }
    const { data, errors } = await editVersionMutation({
      variables,
      refetchQueries: [
        { query: QUERY, variables: { orderNumber: changeOrderNumber, partNumber } },
        { query: PART_VERSION_SELECT_CELL, variables: { partNumber } }
      ],
      awaitRefetchQueries: true
    })

    if (errors) {
      reportMutationError({
        errors,
        variables,
        message: `Error updating part version with value ${variables.version}`
      })
    }

    if (!errors) {
      setEditing(false);
      onChange?.()
    }
  }

  const renderVersion = (part: PartChangePart, showEdit: boolean) => {
    return <div className='flex flex-1 gap-2 text-md text-gray-900 font-medium mb-6'>
      {editing && showEdit ?
        <>
          <Form.Form<VersionForm> className='flex flex-col gap-2' onSubmit={onVersionEditSubmit}>
            <Form.TextField name="version" autoFocus autoComplete='off'
              compact leadingAddOn='Version' inputClassName='pl-20 !text-lg'
              placeholder={incomingVersion}
              required
              validation={{
                required: true,
                validate: {
                  isVersion: (newVersion) => {
                    const parts = uiVersionParts(newVersion)
                    if (!parts) {
                      return 'Version must be dot seprated alpha numeric characters'
                    }
                    const expectedSegments = (currentOrg.defaultPartVersion ?? '1.0.0').split(".").length
                    if (parts.segments.length !== expectedSegments) {
                      return `Version must have ${expectedSegments} segments`
                    }
                  },
                  isIncrement: (newVersion) => {
                    if (headVersion) {
                      const valid = isVersionIncrement(headVersion, newVersion)
                      if (!valid) {
                        return 'Version must be higher than previous version'
                      }
                    }
                  }
                }
              }}
            />
            <Form.BasicFormError error={error} />
            <Form.FieldError name='version' chill />
            <div className='flex gap-2'>
              <Button type='button' onClick={() => setEditing(false)}>Cancel</Button>
              <Button type='submit' variant='primary'>Save</Button>
            </div>
          </Form.Form>
          <div className='-mr-1'>
            <LifecycleStatus disabled={!showEdit} changeOrderNumber={edit?.changeOrderNumber} part={part} className='ml-auto' />
          </div>
        </>
        :
        <div className='flex flex-1 items-center text-sm'>
          <div className='flex-1 flex items-center gap-2 font-semibold'>
            <span>Version {part.version}</span>
            {showEdit && <EditButton size={4} onClick={() => setEditing(true)} testId='version' />}
          </div>
          <div className={showEdit ? '-mr-1' : ''}>
            <LifecycleStatus disabled={!showEdit} changeOrderNumber={edit?.changeOrderNumber} part={part} />
          </div>
        </div>
      }
    </div>
  }

  if (!incomingPart) {
    invariant(headPart, 'Must be a head or incoming part')
    return renderVersion(headPart, false)
  }

  if (!headVersion) {
    return renderVersion(incomingPart, !changeOrderComplete)
  }



  if (viewMode === 'unified') {
    return renderVersion(incomingPart, !changeOrderComplete)
  }

  return <div className='flex'>
    {renderVersion(headPart, false)}
    <div className='border-l border-gray-200 mx-10'></div>
    {renderVersion(incomingPart, !changeOrderComplete)}
  </div>
}
