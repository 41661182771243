import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

interface EmptyStateProps {
  message: string
}

const EmptyState = ({ message }: EmptyStateProps) => {
  return (
    <div className='text-sm flex items-center gap-2 justify-center text-gray-600 bg-gray-100 p-8 rounded-md'>
      <MagnifyingGlassIcon className='w-4' />
      <span>{message}</span>
    </div>
  )
}

export default EmptyState