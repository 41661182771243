import { QuantityUnit } from 'src/shared/types'
import type { MapperConfig } from '../mapperConfigs'

import { CreoLogo, SolidworksLogo, AltiumLogo } from 'src/components/CadLogo'

export const solidworksConfig: MapperConfig = {
  name: 'Solidworks',
  type: 'Upload',
  orgIds: ['demo-1', 'changeOrg', 'shaper-demo', 'vcycene-demo', 'xona-demo'],
  rootIsTopLevel: true,
  Logo: SolidworksLogo,
  importOutputFields: {
    metadata: []
  },
  standardizeBom: {
    columns: {
      metadata: {},
      sources: [{
        distributorName: {
          type: 'simple',
          value: '${row.distributor_name}'
        },
        distributorSku: {
          type: 'simple',
          value: '${row.distributor_sku}'
        },
        url: [{
          conditions: [{
            'column': 'distributor_id',
            type: 'neq',
            value: 'McMaster-Carr'
          }, {
            'column': 'distributor_sku',
            type: 'empty'
          }],
          onMatch: {
            type: 'simple',
            value: ''
          }
        }, {
          default: true,
          onMatch: {
            type: 'simple',
            value: 'https://www.mcmaster.com/${row.distributor_sku}/'
          }
        }]
      }],
      summary: {
        type: 'ignore'
      },
      hierarchy: {
        value: '{{row.level}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.part_number}}',
        type: 'simple'
      },
      cadRev: {
        value: '{{row.revision}}',
        type: 'simple'
      },
      categoryId: {
        type: 'split',
        input: '{{row.part_number}}',
        splitOn: '-',
        value: '{{parts.0}}',
      },
      isOffTheShelf: {
        value: '{{row.off_the_shelf}}',
        type: 'simple'
      },
      name: {
        value: '{{row.description}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.qty}}',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
    }
  }
}

// An attempt to make a standard mapping anyone could use
// if they just rearrange their spreadsheet
// Not yet used because it can't support metadata
export const bomelloStandardConfig: MapperConfig = {
  name: 'Bomello Standard',
  type: 'Upload',
  orgIds: [],
  rootIsTopLevel: true,
  Logo: SolidworksLogo,
  importOutputFields: {
    metadata: []
  },
  standardizeBom: {
    columns: {
      metadata: {},
      sources: [{
        distributorName: {
          type: 'simple',
          value: '${row.distributor_name}'
        },
        distributorSku: {
          type: 'simple',
          value: '${row.distributor_sku}'
        },
      }],
      summary: {
        value: '{{row.summary}}',
        type: 'simple'
      },
      hierarchy: {
        value: '{{row.level}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.part_number}}',
        type: 'simple'
      },
      cadRev: {
        value: '{{row.revision}}',
        type: 'simple'
      },
      categoryId: {
        value: '{{row.category_id}}',
        type: 'simple'
      },
      isOffTheShelf: {
        value: '{{row.off_the_shelf}}',
        type: 'simple'
      },
      name: {
        value: '{{row.name}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: [{
        conditions: [{
          type: 'empty',
          column: 'unit'
        }],
        onMatch: {
          type: 'quantity',
          value: 'each' as QuantityUnit,
        }
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.unit}}'
        }
      }],
    }
  }
}


export const onShapeConfig: MapperConfig = {
  name: 'OnShape',
  type: 'OnShapeApi',
  noProject: true,
  orgIds: ['demo-1',],
  rootIsTopLevel: false,
  Logo: AltiumLogo,
  importOutputFields: {
    metadata: []
  },
  standardizeBom: {
    columns: {
      artifacts: [
        {
          importId: {
            value: 'OnshapeThumbnail',
            type: 'simple'
          },
          filename: {
            value: '{{row.filename}}',
            type: 'simple'
          },
          fileId: {
            value: '{{row.file_ref_id}}',
            type: 'simple'
          },
          previewUrl: {
            value: '{{row.preview_url}}',
            type: 'simple'
          }
        }
      ],
      hierarchy: {
        value: '{{row.hierarchy}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.part_number}}',
        type: 'simple'
      },
      categoryId: {
        type: 'simple',
        value: '511',
      },
      isOffTheShelf: {
        value: 'false',
        type: 'simple'
      },
      cadRev: [{
        conditions: [{
          'column': 'revision',
          type: 'empty'
        }],
        onMatch: {
          type: 'empty',
        }
      }, {
        default: true,
        onMatch: {
          type: 'simple',
          value: '{{row.revision}}'
        }
      }],
      name: {
        value: '{{row.name}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
    }
  }
}

export const altiumConfig: MapperConfig = {
  name: 'Altium',
  type: 'AltiumApi',
  orgIds: ['demo-1', 'shaper-demo'],
  noProject: true,
  rootIsTopLevel: false,
  Logo: AltiumLogo,
  importOutputFields: {
    metadata: ['release-id', 'manufacturer', 'manufacturer-part-number']
  },
  standardizeBom: {
    columns: {
      metadata: {
        'release-id': {
          type: 'simple',
          value: '{{row.release_id}}',
        },
        'manufacturer-part-number': {
          type: 'simple',
          value: '{{row.manufacturer_pn}}',
        },
        'manufacturer': {
          type: 'simple',
          value: '{{row.manufacturer}}',
        }
      },
      artifacts: [
        {
          importId: {
            value: 'AltiumThumbnail',
            type: 'simple'
          },
          filename: {
            value: '{{row.thumbnail_filename}}',
            type: 'simple'
          },
          fileId: {
            value: '{{row.thumbnail_file_id}}',
            type: 'simple'
          },
          previewUrl: {
            value: '{{row.thumbnail_preview_url}}',
            type: 'simple'
          }
        }, {
          importId: {
            value: 'AltiumGerber',
            type: 'simple'
          },
          filename: {
            value: '{{row.gerber_filename}}',
            type: 'simple'
          },
          fileId: {
            value: '{{row.gerber_file_id}}',
            type: 'simple'
          },
        }
      ],
      // summary: {
      //   value: '{{row.description}}',
      //   type: 'simple'
      // },
      hierarchy: {
        value: '{{row.hierarchy}}',
        type: 'simple'
      },
      partNumber: {
        type: 'simple',
        value: '{{row.part_number}}'
      },
      referenceDesignator: {
        type: 'simple',
        value: '{{row.designators}}'
      },
      categoryId: {
        type: 'split',
        input: '{{row.part_number}}',
        splitOn: '-',
        value: '{{parts.0}}',
      },
      isOffTheShelf: {
        value: 'true',
        type: 'simple'
      },
      name: {
        value: '{{row.name}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
    }
  }
}

export const creoConfig: MapperConfig = {
  name: 'Creo',
  type: 'Upload',
  orgIds: ['demo-1'],
  Logo: CreoLogo,
  importOutputFields: {
    metadata: []
  },
  standardizeBom: {
    columns: {
      summary: {
        type: 'ignore'
      },
      metadata: {},
      hierarchy: {
        value: '{{row.level}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.part_number}}',
        type: 'simple'
      },
      cadRev: {
        value: '{{row.revision}}',
        type: 'simple'
      },
      categoryId: {
        value: 'mechanical-part',
        type: 'simple'
      },
      name: {
        value: '{{row.description}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.qty}}',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
      isOffTheShelf: {
        value: '{{row.off_the_shelf}}',
        type: 'simple'
      },
    }
  }
}

export const windchillConfig: MapperConfig = {
  name: 'Windchill',
  type: 'WindchillApi',
  noProject: true,
  orgIds: ['demo-1'],
  rootIsTopLevel: false,
  Logo: SolidworksLogo,
  importOutputFields: {
    metadata: []
  },
  standardizeBom: {
    columns: {
      hierarchy: {
        value: '{{row.hierarchy}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.number}}',
        type: 'simple'
      },
      categoryId: {
        type: 'simple',
        value: '632',
      },
      isOffTheShelf: {
        value: 'false',
        type: 'simple'
      },
      cadRev: {
        value: '{{row.version}}',
        type: 'simple'
      },
      name: {
        value: '{{row.name}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: {
        value: '${row.unit}' as QuantityUnit,
        type: 'simple'
      },
      artifacts: [
        {
          importId: {
            type: 'simple',
            value: 'WindchillThumbnail'
          },
          previewUrl: {
            type: 'simple',
            value: '{{row.thumbnail_preview_url}}'
          },
          fileId: { type: 'simple', value: '{{row.thumbnail_file_id}}' },
          filename: { type: 'simple', value: '{{row.thumbnail_filename}}' }
        },
        {
          importId: {
            type: 'simple',
            value: 'WindchillStepFile'
          },
          fileId: { type: 'simple', value: '{{row.step_file_id}}' },
          filename: { type: 'simple', value: '{{row.step_filename}}' }
        }
      ]
    }
  }
}
