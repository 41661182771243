import { PartTreeNodeFragment } from 'types/graphql'
import { quantityAndUnit } from '../PartHierarchy/hierarchyController'
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import Combobox from '../Combobox/Combobox'
import { quantityUnits } from 'api/src/shared/types'
import ToolButton from '../ToolButton/ToolButton'
import { CircleStackIcon } from '@heroicons/react/24/outline'

type QuantityCellProps = {
  readOnly?: boolean
  node: PartTreeNodeFragment & {
    previousQuantity?: number
    previousQuantityUnits?: string
  }
  onQuantityChange?: (quantity: number, units: string) => Promise<void>
}

const QuantityCell: React.FC<QuantityCellProps> = ({ node, onQuantityChange, readOnly }) => {
  const currentQuantity = node.parentToNodeDependency?.quantity
  const currentUnits = node.parentToNodeDependency?.units
  const previousQuantity = node.previousQuantity
  const previousUnits = node.previousQuantityUnits

  const [localQuantity, setLocalQuantity] = useState(currentQuantity)
  const [localUnits, setLocalUnits] = useState(currentUnits)
  const [localPreviousQuantity, setLocalPreviousQuantity] = useState(previousQuantity)
  const [localPreviousUnits, setLocalPreviousUnits] = useState(previousUnits)

  // Update local state when props change
  useEffect(() => {
    setLocalQuantity(currentQuantity)
    setLocalUnits(currentUnits)
    setLocalPreviousQuantity(previousQuantity)
    setLocalPreviousUnits(previousUnits)
  }, [currentQuantity, currentUnits, previousQuantity, previousUnits])

  const handleChange = async (quantity: number, units: string) => {
    // If this is the first change, set the current values as previous
    if (localPreviousQuantity === undefined) {
      setLocalPreviousQuantity(localQuantity)
      setLocalPreviousUnits(localUnits)
    }
    setLocalQuantity(quantity)
    setLocalUnits(units)
    if (onQuantityChange) {
      onQuantityChange(quantity, units)
    }
  }

  // If quantities are the same, just show one editable/readonly quantity
  if (localPreviousQuantity === undefined ||
      (localPreviousQuantity === localQuantity && localPreviousUnits === localUnits)) {
    return (
      <QuantityPill
        quantity={localQuantity}
        units={localUnits}
        readOnly={readOnly || !node.parentToNodeDependency}
        onChange={handleChange}
      />
    )
  }

  // Otherwise show the before/after view
  return (
    <div className='flex items-center gap-1'>
      <QuantityPill
        quantity={localPreviousQuantity}
        units={localPreviousUnits}
        readOnly
      />
      <div>→</div>
      <QuantityPill
        quantity={localQuantity}
        units={localUnits}
        readOnly={readOnly || !node.parentToNodeDependency}
        onChange={handleChange}
      />
    </div>
  )
}

interface QuantityPillProps {
  quantity?: number
  units?: string
  readOnly?: boolean
  onChange?: (quantity: number, units: string) => Promise<void>
}

const QuantityPill: React.FC<QuantityPillProps> = ({ quantity, units, readOnly = true, onChange }) => {
  if (readOnly) {
    return (
      <div className='cursor-default'>
        {quantityAndUnit(quantity, units)}
      </div>
    )
  }

  return (
    <QuantityPicker
      quantity={quantity}
      units={units}
      onChange={onChange}
    />
  )
}

interface QuantityPickerProps {
  quantity?: number
  units?: string
  onChange?: (quantity: number, units: string) => Promise<void>
}

const QuantityPicker: React.FC<QuantityPickerProps> = ({ quantity, units, onChange }) => {
  const [isEditingQuantity, setIsEditingQuantity] = useState(false)
  const [localQuantity, setLocalQuantity] = useState(quantity)

  const handleQuantityChange = async (newQuantity: number) => {
    setLocalQuantity(newQuantity)
    if (onChange) {
      onChange(newQuantity, units || 'each')
    }
  }

  return (
    <div className='group flex items-center gap-1 relative'>
      <NumberInput
        initialValue={localQuantity}
        isEditing={isEditingQuantity}
        onStartEdit={() => setIsEditingQuantity(true)}
        onClose={() => setIsEditingQuantity(false)}
        onChange={handleQuantityChange}
      />
      <UnitPicker
        unit={units}
        onChange={async (newUnit) => {
          if (onChange) {
            await onChange(localQuantity || 1, newUnit)
          }
        }}
      />
    </div>
  )
}

interface NumberInputProps {
  initialValue?: number
  isEditing: boolean
  onStartEdit: () => void
  onClose: () => void
  onChange?: (quantity: number) => Promise<void>
}

const NumberInput: React.FC<NumberInputProps> = ({
  initialValue,
  isEditing,
  onStartEdit,
  onClose,
  onChange
}) => {
  const [value, setValue] = useState(initialValue?.toString() || '')
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, -24] } },
      { name: 'flip', options: { fallbackPlacements: [] } },
      { name: 'preventOverflow', options: { mainAxis: false, altAxis: false } }
    ],
  })

  const handleSubmit = async () => {
    const newValue = parseFloat(value)
    if (!isNaN(newValue) && onChange) {
      await onChange(newValue)
    }
    onClose()
  }

  return (
    <>
      <div
        ref={setReferenceElement}
        onClick={(e) => {
          e.stopPropagation()
          onStartEdit()
        }}
        className='cursor-pointer hover:ring-2 ring-blue-500 px-1 rounded'
      >
        {initialValue?.toString() || '1'}
      </div>
      {isEditing && createPortal(
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-[1000]"
        >
          <div className='bg-white shadow-md rounded'>
            <input
              autoFocus
              type="number"
              className='w-24 px-2 py-1 text-sm rounded focus:outline-none focus:ring-2 focus:ring-blue-500'
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit()
                }
                if (e.key === 'Escape') {
                  onClose()
                }
              }}
              onBlur={handleSubmit}
            />
          </div>
        </div>,
        document.body
      )}
    </>
  )
}

interface UnitPickerProps {
  unit?: string
  onChange?: (unit: string) => Promise<void>
}

const UnitPicker: React.FC<UnitPickerProps> = ({ unit, onChange }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)

  const showToolButton = !isEditing && (!unit || unit === 'each')

  return (
    <>
      {showToolButton ? (
        <div
          ref={setReferenceElement}
          className='absolute -right-8 invisible group-hover:visible'
        >
          <ToolButton
            writeOnly
            tooltipContent="Set Unit"
            onClick={() => setIsEditing(true)}
          >
            <CircleStackIcon />
          </ToolButton>
        </div>
      ) : (
        <div
          ref={setReferenceElement}
          onClick={(e) => {
            e.stopPropagation()
            setIsEditing(true)
          }}
          className='cursor-pointer hover:ring-2 ring-blue-500 px-1 rounded'
        >
          {unit}
        </div>
      )}
      {isEditing && (
        <UnitCombobox
          referenceElement={referenceElement}
          unit={unit}
          onClose={() => setIsEditing(false)}
          onChange={async (newUnit) => {
            if (onChange) {
              await onChange(newUnit)
            }
            setIsEditing(false)
          }}
        />
      )}
    </>
  )
}

interface UnitComboboxProps {
  unit?: string
  referenceElement: HTMLDivElement | null
  onClose: () => void
  onChange: (unit: string) => Promise<void>
}

const UnitCombobox: React.FC<UnitComboboxProps> = ({
  unit,
  referenceElement,
  onClose,
  onChange
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, -24] } },
      { name: 'flip', options: { fallbackPlacements: [] } },
      { name: 'preventOverflow', options: { mainAxis: false, altAxis: false } }
    ],
  })

  const unitOptions = quantityUnits.map(unit => ({
    id: unit,
    display: unit
  }))

  return createPortal(
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      className="z-[1000]"
    >
      <Combobox
        noPill
        placeholder='Choose Unit'
        selectedId={unit || 'each'}
        onSelectId={async unit => {
          await onChange(unit)
          onClose()
        }}
        onClose={onClose}
        options={unitOptions}
        height={32}
        autoFocus
      />
    </div>,
    document.body
  )
}

export default QuantityCell
