import { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { navigate, routes, useParams } from '@redwoodjs/router'

const ACCEPT_INVITATION = gql`
  mutation AcceptOrgInvitation($token: String!) {
    acceptOrgInvitation(token: $token) {
      id
      name
    }
  }
`

const AcceptInvitePage = () => {
  const { token } = useParams()
  const [acceptInvitation, { loading, error }] = useMutation(ACCEPT_INVITATION, {
    onCompleted: (data) => {
      navigate(routes.home({ orgId: data.acceptOrgInvitation.id }))
    },
    onError: (error) => {
      console.error('Failed to accept invitation:', error)
    }
  })

  useEffect(() => {
    // Automatically try to accept the invitation when the page loads
    acceptInvitation({
      variables: {
        token
      }
    })
  }, [token, acceptInvitation])

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Accepting Invitation
        </h2>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {loading && (
              <div className="text-center text-gray-600">
                <p>Processing your invitation...</p>
              </div>
            )}
            {error && (
              <div className="text-center text-red-600">
                <p>Error accepting invitation: {error.message}</p>
                <button
                  onClick={() => navigate(routes.noOrg())}
                  className="mt-4 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-brand-500 border border-transparent rounded-md hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                  Return to Home
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AcceptInvitePage
