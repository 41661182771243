import { QuantityUnit } from 'src/shared/types'
import type { MapperConfig } from '../mapperConfigs'
import { DocumentIcon } from '@heroicons/react/24/outline'


export const amatisConfig: MapperConfig = {
  name: 'Onboarding',
  type: 'Upload',
  orgIds: ['amatis'],
  rootIsTopLevel: true,
  Logo: DocumentIcon,
  noProject: true,
  importOutputFields: {
    metadata: ['drawing-reference-number', 'manufacturer', 'manufacturer-part-number']
  },
  standardizeBom: {
    columns: {
      metadata: {
        'drawing-reference-number': {
          value: '{{row.drawing_ref}}',
          type: 'simple'
        },
        manufacturer: {
          value: '{{row.manufacturer}}',
          type: 'simple'
        },
        'manufacturer-part-number': {
          value: '{{row.manufacturer_part_number}}',
          type: 'simple'
        },
      },
      sources: [{
        distributorName: {
          type: 'simple',
          value: '${row.supplier}'
        },
      }],
      summary: {
        value: '{{row.description}}',
        type: 'simple'
      },
      hierarchy: {
        value: '{{row.hierarchy}}',
        type: 'simple'
      },
      partNumber: {
        value: '{{row.part_number}}',
        type: 'simple'
      },
      categoryId: {
        type: 'simple',
        value: '{{row.category}}',
      },
      isOffTheShelf: {
        value: '{{row.offtheshelf}}',
        type: 'simple'
      },
      name: {
        value: '{{row.name}}',
        type: 'simple'
      },
      quantity: {
        value: '{{row.quantity}}',
        type: 'simple',
      },
      units: {
        value: 'each' as QuantityUnit,
        type: 'quantity'
      },
    }
  }
}