import React from 'react'
import type { PropsWithChildren } from 'react'
import AppBodyCell from 'src/components/AppBodyCell'

type AppBodyProps = PropsWithChildren<{}>

const AppLayout = ({children}: AppBodyProps) => (
  <AppBodyCell key='stable'>
    {children}
  </AppBodyCell>
)

export default AppLayout

export const FullWidthLayout = ({children}: AppBodyProps) => (
  <AppBodyCell key='stable' width='full'>
    {children}
  </AppBodyCell>
)
