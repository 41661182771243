import { useParams } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'
import TaskCell from 'src/components/TaskCell'

const TaskPage = () => {
  const { taskNumber } = useParams()

  return (
    <>
      <MetaTags title={`Task #${taskNumber}`} description="Task details" />
      <div className='pt-12 px-6 max-w-[1200px] mx-auto'>
        <TaskCell taskNumber={parseInt(taskNumber)} />
      </div>
    </>
  )
}

export default TaskPage