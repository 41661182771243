import { MetaTags } from '@redwoodjs/web'

import NotificationsCell from "src/components/NotificationsCell"

const PartPage = () => {
  return (
    <>
      <MetaTags title={"Notifications"} description="Notification page" />
      <div className='flex flex-col items-center pt-8'>
      <div className='flex flex-col gap-8 mt-2 max-w-[56rem] w-full'>
        <div className='text-2xl'>Notifications</div>
        <NotificationsCell />
        </div>
        </div>
    </>
  )
}

export default PartPage
