import React from 'react'
import LoadingSpinner from 'src/components/Loading'

// Common logo used across auth pages
export const logoSrc = 'https://img.clerk.com/eyJ0eXBlIjoicHJveHkiLCJzcmMiOiJodHRwczovL2ltYWdlcy5jbGVyay5kZXYvdXBsb2FkZWQvaW1nXzJlSHVIWjdmbVRoelN2M2pTd3V2REJTOGt4dSJ9?width=200'

// Common card wrapper with brand styling
export const CardWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col w-96 bg-white p-8 rounded-xl shadow-lg border-t-4 border-brand-500 overflow-hidden">
    <div className="flex justify-center mb-6">
      <img src={logoSrc} alt="Bomello" className="max-w-40" />
    </div>
    {children}
  </div>
)

// Common error display component
export const ErrorDisplay = ({ error }: { error: string }) => {
  if (!error) return null

  return (
    <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-sm text-red-700">{error}</p>
        </div>
      </div>
    </div>
  )
}

// Common email input field
export const EmailInput = ({
  value,
  onChange
}: {
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => (
  <div className="relative">
    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
      Email Address
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
        </svg>
      </div>
      <input
        id="email"
        name="email"
        type="email"
        value={value}
        onChange={onChange}
        className="pl-10 block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-brand-500"
        placeholder="you@example.com"
        required
      />
    </div>
  </div>
)

// Common password input field
export const PasswordInput = ({
  id = "password",
  label = "Password",
  value,
  onChange,
  placeholder = "Your password"
}: {
  id?: string,
  label?: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string
}) => (
  <div className="relative">
    <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
      </div>
      <input
        id={id}
        name={id}
        type="password"
        value={value}
        onChange={onChange}
        className="pl-10 block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-brand-500 focus:border-brand-500"
        placeholder={placeholder}
        required
      />
    </div>
  </div>
)

// Common auth page container
export const AuthPageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className='flex items-center justify-center fixed top-0 left-0 right-0 bottom-0 bg-brand-500'>
    {children}
  </div>
)

// Helper to build redirect URLs with simple validation
export const buildRedirectUrl = (redirectTo: string | null, path: string) => {
  // If no redirect URL is provided, just return the path
  if (!redirectTo) {
    return path
  }

  try {
    // Normalize the redirect URL (ensure it starts with a slash)
    let normalizedRedirect = redirectTo
    if (!normalizedRedirect.startsWith('/')) {
      normalizedRedirect = `/${normalizedRedirect}`
    }

    // Remove any query parameters or fragments
    normalizedRedirect = normalizedRedirect.split('?')[0]!.split('#')[0]!

    // Split the path into segments
    const segments = normalizedRedirect.split('/').filter(Boolean)

    // Whitelist for simple paths (when segments.length < 2)
    const whitelist = [
      // Root paths from Routes.tsx
      //'login',
      //'signup',
      //'forgot-password',
      //'signupcontact',
      'onboard',
      'accept-invite',
      'nexarCallback',
      // Additional common paths
      'dashboard',
      'settings',
      'profile',
      'organizations',
      'billing',
      'help',
      'onboarding',
      'parts',
      'change-orders',
      'tasks',
      'notifications'
    ]

    // If path has at least 2 segments, it's likely valid
    // Otherwise, check if the first segment is in our whitelist
    if (segments.length >= 2 || (segments.length > 0 && whitelist.includes(segments[0]!))) {
      return `${path}?redirectTo=${encodeURIComponent(normalizedRedirect)}`
    } else {
      return path
    }
  } catch (error) {
    return path
  }
}
