import {
  CreatePartSequenceQuery,
  CreatePartSequenceQueryVariables,
} from 'types/graphql'

import padStart from 'lodash.padstart'
import * as HeadlessForm from '@redwoodjs/forms'
import { useWatch } from '@redwoodjs/forms'

import { templateBlocks, PartNumberSchemaWithConfig, getSequenceKey, SequenceBlock } from 'shared/partNumbers'

import { useState, useRef, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useAppContext } from 'src/lib/appContext'
import { Tooltip2 } from '../Tooltip'

// type PartNumberCategoryInput = Pick<PartCategory, 'id' | 'useRange'> & { schema: Pick<PartNumberSchema, 'template' | 'templateConfig' | 'key'> }
type PartNumberInputProps = {
  namePrefix: string | number
  categoryId: string | null
  formMethods: HeadlessForm.UseFormReturn<any, any, undefined>
}

const PartNumberInput: React.FC<PartNumberInputProps> = ({ namePrefix, formMethods, categoryId }) => {
  const appContext = useAppContext()
  const [seqBlocksEnabled, setSeqBlocksEnabled] = useState<Record<string, boolean>>({})

  const { setValue } = formMethods

  useEffect(() => {
    const categoryBlock = templateParts?.find(b => b.config.type === 'categoryId' || b.config.type === 'categoryNumber')
    if (categoryBlock) {
      setValue(`${namePrefix}.${categoryBlock.blockId}`, categoryId)
    }
  }, [categoryId])

  const { partCategories } = appContext
  const category = partCategories.find(c => c.id === categoryId)

  const schema = category ? category.schema as PartNumberSchemaWithConfig : null
  const templateParts = schema ? templateBlocks(schema) : null


  const getBlocks = () => {
    if (!schema || !templateParts || !categoryId) return <div className='text-gray-500'>-</div>

    return templateParts.map(block => {
      if (block.config.type === 'constant') {
        return <div key={`${namePrefix}.${block.blockIndex}`}>{block.value}</div>
      }
      if (block.config.type === 'categoryId' || block.config.type === 'categoryNumber') {
        // show category ID
        return <HeadlessForm.TextField
          disabled
          key={`${namePrefix}.${block.blockId}`}
          style={{ fieldSizing: 'content' }}
          minLength={block.config.length}
          maxLength={block.config.length}
          name={`${namePrefix}.${block.blockId}`}
           />
      }
      if (block.config.type === 'sequence') {
        return <PartNumberSequenceBlock
          schemaKey={schema.key}
          key={`${namePrefix}.${block.blockId}`}
          formMethods={formMethods}
          onEnabled={enabled => {
            setSeqBlocksEnabled((seqBlocksEnabled) => {
              return { ...seqBlocksEnabled, [block.blockId!]: enabled }
            })
          }}
          categoryId={categoryId}
          blockId={block.blockId!}
          namePrefix={namePrefix}
          name={`${namePrefix}.${block.blockId}`}
          templateParts={templateParts}
          />
      }
      if (block.config.type === 'text') {
        return <HeadlessForm.TextField
          key={`${namePrefix}.${block.blockId}`}
          style={{fieldSizing: 'content'}}
          minLength={block.config.length}
          maxLength={block.config.length}
          name={`${namePrefix}.${block.blockId}`}
          placeholder={padStart('', block.config.length, '0')} />
      }
      if (block.config.type === 'any') {
        return <HeadlessForm.TextField
          key={`${namePrefix}.${block.blockId}`}
          style={{fieldSizing: 'content'}}
          minLength={block.config.minLength}
          maxLength={block.config.maxLength}
          name={`${namePrefix}.${block.blockId}`}
          placeholder={padStart('', block.config.minLength, '0')} />
      }
    })
  }

  const disabledClass = () => {
    // if any blocks need to be filled, input is not disabled
    if (templateParts?.some(block => {
      if (block.config.type === 'text') return true
      if (block.config.type === 'any') return true
      // user needs to manually enter the category ID
      if (block.config.type === 'categoryNumber' && category!.useRange) return true
    })) {
      return ''
    }
    if (Object.values(seqBlocksEnabled).some(Boolean)) return ''
    return 'bg-gray-50'
  }

  return <div className={`
    rounded-md shadow-sm flex ring-1 gap-0.5
    ring-inset ring-gray-300 h-10 px-3 text-sm
    items-center
    ${disabledClass()}
  `}>{getBlocks()}</div>
}

export const SEQUENCE_QUERY = gql`
  query CreatePartSequenceQuery ($sequenceKey: String!, $schemaKey: String!) {
    partNumberSequence (input: { sequenceKey: $sequenceKey, schemaKey: $schemaKey }) {
      id
      sequence
    }
  }
`

type PartNumberSequenceBlockProps = {
  blockId: string
  namePrefix: number | string
  schemaKey: string
  name: `${number | string}.${string}`
  categoryId: string
  templateParts: ReturnType<typeof templateBlocks>
  onEnabled: (enabled: boolean) => void
  // setValue: HeadlessForm.UseFormSetValue<Record<string, string>[]>
  formMethods: HeadlessForm.UseFormReturn<Record<string, string>[], any, undefined>
}
const PartNumberSequenceBlock: React.FC<PartNumberSequenceBlockProps> =
  ({ blockId, namePrefix, templateParts, name, categoryId, onEnabled, schemaKey, formMethods }) => {
  const inputRef = useRef<HTMLInputElement | null>()

  const [getSequenceNumber, { loading, error, data: sequenceResponse }] = useLazyQuery<CreatePartSequenceQuery, CreatePartSequenceQueryVariables>(SEQUENCE_QUERY)

  const sequenceBlock = templateParts.find(b => b.blockId === blockId)! as SequenceBlock
  const blockValues = templateParts
    .map(block => {
      if (block.config.type === 'categoryId' || block.config.type === 'categoryNumber') {
        return {
          value: categoryId,
          blockId: block.blockId
        }
      }
      if (['sequence', 'text', 'any'].includes(block.config.type)) {
        return {
          value: useWatch({
            name: `${namePrefix}.${block.blockId}`
          }),
          blockId: block.blockId
        }
      }
    }).filter(Boolean) as { value: string, blockId: string }[]

  // order is IMPORTANT for sequence key generation
  const sequenceBlockValues = blockValues.filter(block => {
    return sequenceBlock.config.keyBlocks.some(blockId => {
      return blockId === block.blockId
    })
  }).map(b => b.value)
  const hasKeyValues = sequenceBlockValues.every(Boolean)

  const usedForOtherSequences = templateParts.some(b => {
    return b.config.type === 'sequence' && b.config.keyBlocks.includes(blockId)
  })

  const sequenceKey = getSequenceKey(sequenceBlock.blockIndex, sequenceBlockValues)
  useEffect(() => {
    onEnabled(hasKeyValues && usedForOtherSequences)
    if (hasKeyValues) {
      getSequenceNumber({
        variables: {
          schemaKey,
          sequenceKey
        }
      })
    }
  }, [hasKeyValues, usedForOtherSequences, sequenceKey])

  const nextSequenceNumber = sequenceResponse?.partNumberSequence.sequence

  const sequenceValue = blockValues.find(b => b.blockId === sequenceBlock.blockId)?.value
  const hideUpDownClass = '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
  const disabled = !hasKeyValues || !usedForOtherSequences || loading || !nextSequenceNumber || nextSequenceNumber < 2 || Boolean(error)
  const disabledClass = disabled ? 'text-gray-400' : ''

  useEffect(() => {
    formMethods.setValue(name, '')
  }, [disabled, hasKeyValues])

  const placeholder = (new Array(sequenceBlock.config.length)).fill('#').join('')

  return <div className='flex'>
    {sequenceValue && <div
      onClick={() => inputRef.current && inputRef.current.focus()}>
        {padStart('', sequenceBlock.config.length - String(sequenceValue || 1).length, '0')}</div>}
    <Tooltip2 content='Sequence will be generated'>
    <HeadlessForm.NumberField
      ref={(r) => inputRef.current = r}
      min={1}
      emptyAs="undefined"
      max={nextSequenceNumber && nextSequenceNumber - 1}
      minLength={sequenceBlock.config.length}
      maxLength={sequenceBlock.config.length}
      style={{fieldSizing: 'content'}}
      disabled={disabled}
      onKeyDown={e => {
        if (!inputRef.current) return
        if (disabled) return
        if (sequenceValue) return

        // when going from empty, always start from the highest sequence value
        if (e.code === 'ArrowUp') {
          e.preventDefault()
          formMethods.setValue(name, String(nextSequenceNumber - 1))
        }
        if(e.code === 'ArrowDown') {
          e.preventDefault()
          formMethods.setValue(name, String(nextSequenceNumber - 1))
        }
      }}
      name={name}
      className={`${hideUpDownClass} ${disabledClass}  bg-transparent`} placeholder={placeholder} />
    </Tooltip2>
  </div>
}

export default PartNumberInput
