import type { PartNumberSchema } from "@prisma/client"
import padStart from "lodash.padstart"
import { letF } from './functional'

export type SequenceConfig = {
  type: 'sequence'
  keyBlocks: string[]
  length: number
  minLength: never
  maxLength: never
}
export type TemplateBlockConfig = {
  type: 'any',
  length: never,
  minLength: number
  maxLength: number
} | {
  type: 'text'
  length: number
  minLength: never
  maxLength: never
} | {
  type: 'categoryNumber'
  length: number
  minLength: never
  maxLength: never
} | {
  type: 'categoryId'
  length: number
  minLength: never
  maxLength: never
} | SequenceConfig

type ConstantBlock = {
  type: 'constant'
  length: number
  minLength: never
  maxLength: never
}

export type BasicBlockConfig = SequenceConfig | TemplateBlockConfig
export type BlockType = Pick<BasicBlockConfig, 'type'>['type']

export type CategoryNumberRange = [number, number][]

export type TemplateBlockConfigs = Record<string, TemplateBlockConfig>

export type PartNumberSchemaWithConfig = PartNumberSchema & {
  templateConfig: TemplateBlockConfigs
}

export type PartInfoSchemaInput = Pick<PartNumberSchema, 'template'> & {
  templateConfig: TemplateBlockConfigs
}

export type SequenceBlock = {
  blockId: string
  config: SequenceConfig
  blockIndex: number
}

type Block = {
  blockId: string
  config: TemplateBlockConfig
  value: undefined
  blockIndex: number
} | {
  blockId: undefined
  config: ConstantBlock
  value: string
  blockIndex: number
}
export const templateBlocks: (schema: PartInfoSchemaInput) => Block[] = (schema) => {
  const rBlockConst = /(?<constant>[^<]+)|<(?<block>[^>]+)>/g

  let blocksAndConstants = []
  let blockMatch: RegExpExecArray | null
  do {
    blockMatch = rBlockConst.exec(schema.template)
    if (blockMatch?.groups?.constant) {
      blocksAndConstants.push({
        config: {
          type: 'constant',
        } as ConstantBlock,
        value: blockMatch.groups.constant
      })
    }
    if (blockMatch?.groups?.block) {
      const config = schema.templateConfig[blockMatch.groups.block]!
      blocksAndConstants.push({
        blockId: blockMatch.groups.block,
        config
      })
    }
  } while (blockMatch !== null)
  return blocksAndConstants.map((b, i) => {
    return {...b, blockIndex: i }
  })
}

export const getBlockValues = (blocks: Block[], categoryId: string) => {
  let blockValues: Record<string, string> = {}
  blocks.forEach(block => {
    if (!(block.config.type === 'constant')) {
      blockValues[block.blockId!] = block.value!
    }
    if (['categoryId', 'categoryNumber'].includes(block.config.type)) {
      blockValues[block.blockId!] = categoryId
    }
  })
  return blockValues;
}

type ValuesByBlockId = Record<string, number | string>
type MissingBlock = Exclude<Block, { config: { type: 'constant' } }>
type PartNumberFromBlockValuesReturn = {
  missingBlocks: MissingBlock[]
  partNumber: string
}
export const partNumberFromBlockValues = (valuesByBlockId: ValuesByBlockId, schema: PartInfoSchemaInput) => {
  const orderedConfig = templateBlocks(schema)
  const missingBlocks: MissingBlock[] = []
  const blockValues = orderedConfig.map(block => {
    if (block.config.type === 'constant') {
      return block.value
    }

    if (!block.blockId) throw new Error('Block ID expected')

    const blockValue = valuesByBlockId[block.blockId]
    if (blockValue === undefined) {
      missingBlocks.push(block)

      // This seems dangerous, it's better to not
      // return a part number at all in this scenario
      // but this is used in a mutation to generate
      // the sequence number :(
      return padStart('', block.config.length, '0')
    }

    if (block.config.type === 'sequence') {
      if (isNaN(Number(blockValue))) throw new Error(`Invalid sequence block value: ${blockValue}`)
      return padStart(String(blockValue), block.config.length, '0')
    }

    return blockValue
  })

  const output: PartNumberFromBlockValuesReturn = {
    missingBlocks,
    partNumber: blockValues.join('')
  }
  return output
}

interface PartInfoSchemaOutput extends PartNumberSchema {
  templateConfig: TemplateBlockConfigs
}

export function getSchemaFromCategory (category: { schema: any })  {
  const { schema } = category
  const outputSchema: PartInfoSchemaOutput = {
    ...schema,
    templateConfig: schema.templateConfig as TemplateBlockConfigs
  }
  return outputSchema
}

export const getPartNumberBlocks = (partNumber: string, schema: PartInfoSchemaInput) => {
  const orderedConfig = templateBlocks(schema)

  // const templateConfig = schema.templateConfig as TemplateBlockConfigs

  // escapes the following regex special chars to be used in the regex
  // . * + ? ^ $ { } ( ) | [ ] \
  const escapeSpecial = schema.template.replace(/([.*+?^${}()|[\]\\])/g, '\\$&')

  const finalRegex = orderedConfig.reduce((finalRegex, { blockId, config }) => {
    if (config.type === 'categoryNumber') {
      return finalRegex.replace(`<${blockId}>`, `(?<${blockId}>\\d{${config.length}})`)
    }
    if (config.type === 'categoryId') {
      return finalRegex.replace(`<${blockId}>`, `(?<${blockId}>[A-Za-z0-9]{${config.length}})`)
    }
    if (config.type === 'sequence') {
      return finalRegex.replace(`<${blockId}>`, `(?<${blockId}>\\d{${config.length}})`)
    }
    if (config.type === 'text') {
      return finalRegex.replace(`<${blockId}>`, `(?<${blockId}>[a-zA-Z0-9]{${config.length}})`)
    }
    if (config.type === 'any') {
      return finalRegex.replace(`<${blockId}>`, `(?<${blockId}>.{${config.minLength},${config.maxLength}})`)
    }
    return finalRegex
  }, escapeSpecial)

  const rPartNumber = new RegExp(`^${finalRegex}$`)
  const match = partNumber.match(rPartNumber)?.groups!

  return orderedConfig.map(({ blockId, config, value, blockIndex }) => {
    if (config.type === 'constant') return { config, value }
    const uncastValue = match[blockId!]!
    let blockValue
    if (config.type === 'categoryNumber' || config.type === 'sequence') blockValue = Number(uncastValue)
    else blockValue = uncastValue

    return {
      config,
      value: blockValue,
      blockId,
      blockIndex
    }
  })
}

export const getSequenceKey = (sequenceIndex: number, requiredBlocks: string[]) => {
  // For global sequences (no dependencies), we use a special key format
  if (requiredBlocks.length === 0) {
    return `${sequenceIndex}::GLOBAL`
  }
  return `${sequenceIndex}::${requiredBlocks.join('---')}`
}

export const getSequenceKeyFromPartNumber = (partNumber: string, blockId: string, schema: PartInfoSchemaInput) => {
  const pnBlocks = getPartNumberBlocks(partNumber, schema)
  const sequenceIndex = pnBlocks.findIndex(b => b.blockId === blockId)
  const sequenceConfig = pnBlocks[sequenceIndex]!.config as SequenceConfig
  const keyBlocks = pnBlocks.filter(b => b.blockId && sequenceConfig.keyBlocks.includes(b.blockId))
  return getSequenceKey(sequenceIndex, keyBlocks.map(b => String(b.value)))
}

export const getSequenceKeyFromBlockValues =
  (blockId: string, blockValues: Record<string, string>, schema: PartInfoSchemaInput) => {
  const blocks = templateBlocks(schema)
  const sequenceBlock = blocks.find(b => b.blockId === blockId)! as SequenceBlock
  const keyBlocks = blocks
    .filter(b => b.blockId && sequenceBlock.config.keyBlocks.includes(b.blockId))
    .map(b => {
      const value = blockValues[b.blockId!]
      if (value === undefined) throw new Error('Missing value for sequence block')
      return value
    })
  return getSequenceKey(sequenceBlock.blockIndex, keyBlocks)
}

export const getSequenceKeysFromPartOptions =
  (generatorOptions: Record<string, string>, schema: PartInfoSchemaInput) => {
  const blocks = templateBlocks(schema)

  return blocks
  .filter(block => {
    if (block.config.type !== 'sequence') return false
    const sequenceConfig = block.config
    const requiredBlocks = blocks
      .filter(b => b.blockId && sequenceConfig.keyBlocks.includes(b.blockId))

    // do not get keys for blocks that will require other sequence blocks
    if (requiredBlocks.some(b => b.config.type === 'sequence')) return false
    return true
  }).map((sBlock) => {
    const sequenceConfig = sBlock!.config as SequenceConfig
    const requiredBlocks = blocks
      .filter(b => b.blockId && sequenceConfig.keyBlocks.includes(b.blockId))
      .map(b => {
        const value = generatorOptions[b.blockId!]
        if (value === undefined) throw new Error(`Missing value for part number block: ${b.blockId}`)
        return value
      })
    return {
      key: getSequenceKey(sBlock.blockIndex, requiredBlocks),
      blockIndex: sBlock.blockIndex
    }
  })
}


export const getBlocks = (template: string) => [...template.matchAll(/<([^>]+)\>/g)].map(m => m[1]!)

export const validTemplate = (template: string, blocks: string[]) => {
  const openCount = template.match(/</g)?.length
  const closeCount = template.match(/>/g)?.length

  const unique = new Set(blocks).size === blocks.length;

  const validSymbols = blocks.every(b => /^[a-z]+$/ig.test(b))
  const validChars = /^[a-z0-9<>\-]+$/ig.test(template)

  const valid = unique && validSymbols && validChars && openCount && closeCount && openCount === closeCount && openCount === blocks.length

  if (!valid) return false
  return true
}

const validNumber = (n: number) => Number.isInteger(n) && n >= 0 && n <= 40

export const getInvalidationReason = ({key, template, templateConfig}: Partial<PartNumberSchema>) =>
  !key ? 'Key cannot be empty' :
  !template ? 'Template cannot be empty' :
  !templateConfig ? 'Template Configuration cannot be empty' :
  !/^[a-z0-9]+$/i.test(key) ? 'Key cannot contain special characters' :
  letF({
    blocks: getBlocks(template),
    blockCfgs: Object.values(templateConfig) as BasicBlockConfig[],
  }, ({blocks, blockCfgs}) =>
    !validTemplate(template, blocks) ? 'Invalid template' :
    !blockCfgs.filter(cfg => cfg.type !== 'any').every(cfg => (validNumber(cfg.length) && cfg.length > 0)) ? 'Invalid Length' :
    !blockCfgs.filter(cfg => cfg.type === 'any').every(cfg => {
      return (validNumber(cfg.minLength) && cfg.minLength > 0) &&
        (validNumber(cfg.maxLength) && cfg.maxLength > 0) && cfg.minLength <= cfg.maxLength
    }) ? 'Invalid Min/Max Length' :
    (blockCfgs.filter(cfg => cfg.type === 'categoryNumber').length > 1) ? 'Too many category number blocks' :
    letF(blockCfgs.filter(cfg => cfg.type === 'sequence') as SequenceConfig[], sequenceBlocks =>
      //!sequenceBlocks
        //.every(cfg => (cfg as SequenceConfig).keyBlocks.length > 0) ? 'Sequence blocks must have sequence contributors' :
      !sequenceBlocks
        .every(cfg => (cfg as SequenceConfig).keyBlocks.every(kb => blocks.includes(kb))) ? 'Key blocks must reference blocks' :
      new Set(sequenceBlocks.map(cfg => cfg.keyBlocks.length)).size !== sequenceBlocks.length ? 'Sequence dependencies must be unique' :
      undefined
    )
  )


export const rValidPartNumber = /[a-zA-Z0-9._-]+$/
