import { MetaTags } from '@redwoodjs/web'
import { useParams } from '@redwoodjs/router'
import ChangeOrderCell from 'src/components/ChangeOrderCell'
import SingleColumnTasksCell from 'src/components/SingleColumnTasksCell'
import ChangesTab from 'src/components/ChangesTab/ChangesTab'

const ChangeOrderPage = () => {
  const { orderNumber: on, tab, orgId, partContext } = useParams()
  const selectedTab = tab || 'activity'
  const orderNumber = on as unknown as number
  if (partContext && tab !== 'changes') {
    throw new Error("invalid route: " + window.location.toString())
  }


  const content = () => {
    if (selectedTab === 'changes') return <ChangesTab changeOrderNumber={orderNumber} partContext={partContext}/>
    if (selectedTab === 'tasks') {
      return <SingleColumnTasksCell
        filters={{
          assignedChangeOrderNumber: orderNumber
        }}
        newTask={{
          assignedChangeOrderNumber: orderNumber
        }}
        />
    }
    return null
  }
  return (
    <>
      <MetaTags title="ChangeOrder" description="ChangeOrder page" />
      <div className='min-w-[760px] max-w-[1200px] mx-auto px-12 pb-12 overflow-x-auto'>
        <ChangeOrderCell orgId={orgId!} tab={selectedTab} orderNumber={orderNumber}>
          {content()}
        </ChangeOrderCell>
      </div>
    </>
  )
}

export default ChangeOrderPage
