import RecentPartsCell from 'src/components/RecentPartsCell'
import ChangeOrdersCell from 'src/components/ChangeOrdersCell'
import NotificationsCell from 'src/components/NotificationsCell'
import SingleColumnTasksCell from 'src/components/SingleColumnTasksCell'
import { useAppContext } from 'src/lib/appContext'

const HomePage = () => {
  const { me } = useAppContext()
  return (
    <div className='flex flex-col items-center w-full'>
      <div className="pt-12 w-full max-w-[1200px] mx-auto px-6 pb-12 flex gap-10 flex-col">
        <div className='flex items-center flex-col'>
          <img className='w-[224px]' src='/bomello.avif' />
        </div>
        <div>
          <h2 className="text-md mb-4">Your Change Orders</h2>
          <div className="mb-8 -mx-0.5">
            <ChangeOrdersCell filter={['Draft', 'Review']} relevantToMe />
          </div>
        </div>

        <div>
          <h2 className="text-md mb-4">Your Tasks</h2>
          <div className="mb-8 -mx-0.5">
            <SingleColumnTasksCell
              viewOnly
              filters={{
                assignedToId: me.id,
                status: ['InProgress', 'Blocked', 'NotStarted']
              }}
              newTask={{
                assignedToId: me.id
              }}
            />
          </div>
        </div>

        <div>
          <h2 className="text-md mb-4">Recently Viewed Parts</h2>
          <div className="mb-8 -mx-0.5">
            <RecentPartsCell />
          </div>
        </div>

        <div>
          <h2 className="text-md mb-4">Notifications</h2>
          <div className=' -mx-0.5'><NotificationsCell take={10} /></div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
