import { useEffect, useState } from 'react'
import { navigate, routes, useParams, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { MetaTags } from '@redwoodjs/web'

// Slack logo SVG component
const SlackLogo = ({ className = "w-10 h-10" }: { className?: string }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M6.194 14.644c0 1.16-.943 2.107-2.103 2.107-1.16 0-2.103-.947-2.103-2.107 0-1.16.943-2.106 2.103-2.106h2.103v2.106zm1.061 0c0-1.16.944-2.106 2.104-2.106 1.16 0 2.103.946 2.103 2.106v5.274c0 1.16-.943 2.107-2.103 2.107-1.16 0-2.104-.947-2.104-2.107v-5.274z" fill="#E01E5A"/>
    <path d="M9.359 6.097c-1.16 0-2.104-.946-2.104-2.106 0-1.16.944-2.107 2.104-2.107 1.16 0 2.103.947 2.103 2.107v2.106H9.36zm0 1.068c1.16 0 2.103.946 2.103 2.106 0 1.16-.943 2.107-2.103 2.107H4.09c-1.16 0-2.103-.947-2.103-2.107 0-1.16.943-2.106 2.103-2.106h5.27z" fill="#36C5F0"/>
    <path d="M17.806 9.27c0-1.16.943-2.106 2.103-2.106 1.16 0 2.103.946 2.103 2.107 0 1.16-.943 2.106-2.103 2.106h-2.103V9.271zm-1.062 0c0 1.16-.943 2.107-2.103 2.107-1.16 0-2.103-.947-2.103-2.107V3.991c0-1.16.943-2.107 2.103-2.107 1.16 0 2.103.947 2.103 2.107V9.27z" fill="#2EB67D"/>
    <path d="M14.641 17.818c1.16 0 2.103.947 2.103 2.107 0 1.16-.943 2.107-2.103 2.107-1.16 0-2.103-.947-2.103-2.107v-2.107h2.103zm0-1.067c-1.16 0-2.103-.947-2.103-2.107 0-1.16.943-2.107 2.103-2.107h5.268c1.16 0 2.103.947 2.103 2.107 0 1.16-.943 2.107-2.103 2.107h-5.268z" fill="#ECB22E"/>
  </svg>
)

// Loading spinner component
const LoadingSpinner = () => (
  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-600"></div>
)

const HANDLE_SLACK_INSTALL = gql`
  mutation HandleSlackCallback($input: SlackInstallInput!) {
    handleSlackInstall(input: $input)
  }
`

const IDENTIFY_SLACK_USER = gql`
  mutation IdentifySlackUser($input: SlackIdentifyInput!) {
    identifySlackUser(input: $input) {
      id
      slackUserId
    }
  }
`

const OauthSlackPage = () => {
  const [error, setError] = useState<string | null>(null)
  const [isProcessing, setIsProcessing] = useState(true)
  const [handleInstall, { loading: installLoading }] = useMutation(HANDLE_SLACK_INSTALL)
  const [identifyUser, { loading: identifyLoading }] = useMutation(IDENTIFY_SLACK_USER)
  const params = useParams()
  const location = useLocation()

  const { code, orgId, error: errorDisplay } = params

  const isIdentifyVariant = location.pathname.includes(`${orgId}/identify`)
  const isLoading = isProcessing || installLoading || identifyLoading

  useEffect(() => {
    const processCallback = async () => {
      try {
        if (errorDisplay) {
          setError(`Slack authentication failed: ${errorDisplay}`)
          setIsProcessing(false)
          return
        }

        if (!code) {
          setError('Missing authorization code from Slack')
          setIsProcessing(false)
          return
        }

        if (!orgId) {
          setError('Missing organization ID')
          setIsProcessing(false)
          return
        }

        if (isIdentifyVariant) {
          await identifyUser({
            variables: {
              input: {
                code,
                orgId
              }
            }
          })
        } else {
          await handleInstall({
            variables: {
              input: {
                code,
                orgId
              }
            }
          })
        }

        // Navigate back to the settings page with full page reload
        window.location.href = routes.orgSettingsIntegrations({orgId})

      } catch (err) {
        console.error('Error in Slack OAuth callback:', err)
        setError(err instanceof Error ? err.message : 'An error occurred connecting to Slack')
        setIsProcessing(false)
      }
    }

    processCallback()
  }, [code, orgId, errorDisplay, isIdentifyVariant, identifyUser, handleInstall])

  const title = isIdentifyVariant
    ? "Connecting Your Slack Account"
    : "Connecting Slack Workspace"

  return (
    <>
      <MetaTags title={title} description="Slack integration" />

      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <SlackLogo className="mx-auto h-12 w-12 text-brand-600" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {title}
            </h2>
          </div>

          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {error ? (
              <div className="space-y-6">
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Connection Error
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <p>{error}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    onClick={() => navigate(routes.orgSettingsIntegrations({orgId: orgId!}))}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                  >
                    Return to Settings
                  </button>
                </div>
              </div>
            ) : (
              <div className="space-y-6 text-center">
                {isLoading && (
                  <div className="flex justify-center py-4">
                    <LoadingSpinner />
                  </div>
                )}

                <p className="text-lg text-gray-700">
                  {isIdentifyVariant
                    ? "Connecting your personal Slack account..."
                    : "Connecting your Slack workspace..."}
                </p>

                <p className="text-sm text-gray-500">
                  {isIdentifyVariant
                    ? "This will allow you to receive personal notifications in Slack."
                    : "This will enable organization-wide Slack notifications."}
                </p>

                <p className="text-sm text-gray-500">
                  Please wait while we complete the connection process.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OauthSlackPage

