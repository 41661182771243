import { block } from 'api/src/shared/functional'
import ThumbnailViewer, { ThumbnailArtifact } from '../ThumbnailViewer'
import { getThumbnail } from '../Artifacts/Artifacts'
import { StatusDot, StatusDotLabel, useStatusColor } from '../LifecycleStatus/LifecycleStatus'
import { getVersionTypeText, VersionIconRaw } from '../VersionRange/VersionRange'
import HoverPopover from '../HoverPopover'
import { ControllerTreeNode } from '../PartHierarchy'
import invariant from 'tiny-invariant'
import { MouseEventHandler, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import Combobox from '../Combobox/Combobox'
import { usePopper } from 'react-popper'
import { Part, PartVersions, PartVersionsVariables } from 'types/graphql'

// Add GraphQL query
const PART_VERSIONS_QUERY = gql`
  query PartVersions($partNumber: String!) {
    partProto(partNumber: $partNumber) {
      id
      currentVersion {
        id
        version
        lifeCycle
      }
      instances {
        id
        version
        lifeCycle
        branch
      }
    }
  }
`

interface Props {
  node: ControllerTreeNode,
  readOnly?: boolean
  changeOrderNumber: number
  onVersionRangeChange: (versionRange: string) => Promise<void>
  changeOrderPart?: Partial<Part>
}

const VersionCell: React.FC<Props> = ({ node, changeOrderNumber, onVersionRangeChange, readOnly, changeOrderPart }) => {
  const arrow = <div className='w-5 text-center'>→</div>
  const spacer = <><div className='w-5' /><div className='w-[66px]' /></>

  const noVersionChange = block(() => {
    if (!node.previousVersion) return true
    if (node.parentToNodeDependency && !node.previousVersionRange) return true
    return node.previousVersion === node.part.version &&
      node.previousVersionRange === node.parentToNodeDependency?.toVersionRange
  })

  const leftVersion = block(() => {
    if (noVersionChange) {
      const selected = {
        version: node.part.version,
        versionRange: node.parentToNodeDependency?.toVersionRange,
        lifeCycle: node.part.lifeCycle,
        thumbnail: getThumbnail(node.part.artifacts),
        isChangeOrderVersion: changeOrderPart?.id === node.part.id
      }

      const edit = (!readOnly && node.parentToNodeDependency && node.proto?.currentVersion) ? {
        partNumber: node.part.partNumber,
        changeOrderNumber,
        onVersionRangeChange,
        disableOptimistic: true
      } : undefined

      return <VersionPicker
        align='right'
        selected={selected}
        edit={edit}
        />
      }

    invariant(node.previousVersion)
    return <VersionPicker
      align='right'
      selected={{
        version: node.previousVersion,
        versionRange: node.previousVersionRange,
        lifeCycle: node.previousLifecycle,
        thumbnail: node.previousThumbnail
      }}
    />
  })

  const rightVersion = block(() => {
    if (noVersionChange) {
      return
    }

    const selected = {
      version: node.part.version,
      versionRange: node.parentToNodeDependency?.toVersionRange,
      lifeCycle: node.part.lifeCycle,
      thumbnail: getThumbnail(node.part.artifacts),
      isChangeOrderVersion: changeOrderPart?.id === node.part.id
    }

    const edit = (!readOnly && node.parentToNodeDependency) ? {
      partNumber: node.part.partNumber,
      changeOrderNumber,
      onVersionRangeChange
    } : undefined

    return <VersionPicker
      align='left'
      selected={selected}
      edit={edit}
    />
  })

  return <div className='flex items-center gap-1'>
    {leftVersion}
    {rightVersion ? <>{arrow}{rightVersion}</> : <>{spacer}</>}
  </div>
}

export default VersionCell

interface VersionPickerProps {
  align?: 'left' | 'right'
  selected: {
    version: string
    versionRange?: string
    lifeCycle?: string | null
    thumbnail?: ThumbnailArtifact
    isChangeOrderVersion?: boolean
  }
  edit?: {
    partNumber: string
    changeOrderNumber: number
    disableOptimistic?: boolean
    onVersionRangeChange: (versionRange: string) => Promise<void>
  }
  partInChangeOrder?: boolean
}

const VersionPicker: React.FC<VersionPickerProps> = ({
  align,
  selected,
  edit,
  partInChangeOrder
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [_localSelected, setLocalSelected] = useState(selected)

  const localSelected = edit?.disableOptimistic ? selected : _localSelected

  useEffect(() => {
    setLocalSelected(selected)
  }, [selected.version, selected.versionRange, selected.lifeCycle, selected.thumbnail?.file.url])

  const [loadOptions, { called, loading, data }] = useLazyQuery<PartVersions, PartVersionsVariables>(PART_VERSIONS_QUERY)

  const options = block(() => {
    if (!data) return []
    invariant(data.partProto)
    // This picker CANNOT be used for new parts, only existing
    invariant(data.partProto.currentVersion)
    const pinnedOptions = data.partProto.instances
      .filter(i => {
        return i.branch === -1 || i.branch === edit?.changeOrderNumber
      })
      .map((instance) => ({
        id: instance.version,
        isChangeOrderVersion: instance.branch !== -1,
        display: instance.version,
        version: instance.version,
        lifeCycle: instance.lifeCycle,
      }))
    const coVersion = data.partProto.instances.find(i => {
      return i.branch === edit?.changeOrderNumber
    })
    return [
      ...pinnedOptions,
      {
        id: '*',
        display: 'Always Update',
        isChangeOrderVersion: partInChangeOrder,
        version: coVersion?.version || data.partProto.currentVersion.version,
        primary: true,
        lifeCycle: data.partProto.currentVersion.lifeCycle
      }
    ]
  })

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -24], // Negative vertical offset to overlap with pill
        },
      },
      // Add a modifier to prevent flipping
      {
        name: 'flip',
        options: {
          fallbackPlacements: [],
        },
      },
      // Add a modifier to prevent shifting
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
          altAxis: false
        },
      }
    ],
  })

  const handleClick: MouseEventHandler<HTMLDivElement> = async (e) => {
    if (!edit) return
    e.stopPropagation()
    setIsEditing(true)
    if (!called) {
      await loadOptions({
        variables: {
          partNumber: edit.partNumber
        }
      })
    }
  }

  const handleSelect = async (versionRange: string) => {
    if (!edit) return
    const selected = options.find(o => o.id === versionRange)
    invariant(selected)
    setLocalSelected({
      version: selected.version,
      versionRange: selected.id,
      lifeCycle: selected.lifeCycle
    })
    await edit.onVersionRangeChange(selected.id)
  }

  return (
    <div ref={setReferenceElement} onClick={handleClick}>
      <VersionPill
        hoverEffect={!!edit}
        align={align}
        isChangeOrderVersion={localSelected.isChangeOrderVersion}
        version={localSelected.version}
        versionRange={localSelected.versionRange}
        lifeCycle={localSelected.lifeCycle}
        thumbnail={localSelected.thumbnail}
      />
      {isEditing && edit && createPortal(
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-[1000] "
        >
          <Combobox
            autoFocus
            noPill
            anchor={'bottom'}
            optionsLoading={loading}
            selectedId={selected.versionRange}
            onSelectId={handleSelect}
            onClose={() => setIsEditing(false)}
            options={options}
            height={24}
          />
        </div>,
        document.body
      )}
    </div>
  )
}

interface VersionPillProps {
  version: string
  versionRange?: string
  lifeCycle?: string | null
  hoverEffect?: boolean
  thumbnail?: ThumbnailArtifact
  align?: 'left' | 'right'
  isChangeOrderVersion?: boolean
}

const VersionPill: React.FC<VersionPillProps> = ({
  version,
  versionRange,
  lifeCycle,
  hoverEffect,
  thumbnail,
  align = 'right',
  isChangeOrderVersion
}) => {
  const color = useStatusColor(lifeCycle)
  const margin = align === 'right' ? 'ml-auto' : ''
  const hover = hoverEffect ? 'cursor-pointer hover:ring-2 ring-blue-500 hover:border-transparent' : 'cursor-default'
  return (
    <VersionPopover
      version={version}
      versionRange={versionRange}
      lifeCycle={lifeCycle}
      thumbnail={thumbnail}
      align={align}
      isChangeOrderVersion={isChangeOrderVersion}
    >
      <div className={`flex items-center gap-[2px] px-1 w-fit rounded border ${margin} ${color} ${hover}`}>
        {versionRange && (
          <div className='w-[12px] shrink-0'>
            <VersionIconRaw versionRange={versionRange} />
          </div>
        )}
        <div className='shrink-0'>{version}{isChangeOrderVersion ? '*' :''}</div>
      </div>
    </VersionPopover>
  )
}

interface VersionPopoverProps {
  version: string
  versionRange?: string
  lifeCycle?: string | null
  thumbnail?: ThumbnailArtifact
  align?: 'left' | 'right'
  children: React.ReactNode
  isChangeOrderVersion?: boolean
}

const VersionPopover: React.FC<VersionPopoverProps> = ({
  version,
  versionRange,
  lifeCycle,
  thumbnail,
  align = 'right',
  children,
  isChangeOrderVersion
}) => {
  const TAILWIND_UNIT_SCALE = 4
  const THUMBNAIL_HEIGHT = 48 * TAILWIND_UNIT_SCALE
  const STANDARD_LABEL_HEIGHT = 6 * TAILWIND_UNIT_SCALE
  const textAlign = align === 'left' ? 'text-left' : ''

  const hasPopoverContent = thumbnail || versionRange || lifeCycle
  if (!hasPopoverContent) {
    return <div className={`w-[66px] ${textAlign}`}>{children}</div>
  }

  const contentHeight =
    (thumbnail ? THUMBNAIL_HEIGHT : 0) +
    (versionRange ? STANDARD_LABEL_HEIGHT : 0) +
    (lifeCycle ? STANDARD_LABEL_HEIGHT : 0) +
    (isChangeOrderVersion ? STANDARD_LABEL_HEIGHT : 0)

  const gapCount = [thumbnail, versionRange, lifeCycle, isChangeOrderVersion].filter(Boolean).length - 1
  const popoverHeight = contentHeight + (gapCount * 8)

  return (
    <div className={`w-[66px] ${textAlign}`}>
      <HoverPopover
        direction={align === 'left' ? 'right' : 'left'}
        popoverWidth={thumbnail ? 320 : 240}
        popoverHeight={popoverHeight}
        popoverClassName='bg-gray-600 text-white rounded'
        trigger={children}
        content={() => (
          <div className='flex flex-col gap-2 text-xs'>
            {thumbnail && (
              <div className='h-48'>
                <ThumbnailViewer
                  artifact={thumbnail}
                  className="w-full h-full bg-gray-300 border-none"
                />
              </div>
            )}
            {versionRange && (
              <div className='flex gap-2 items-center h-6'>
                <div className='w-3 flex justify-center'>
                  <VersionIconRaw versionRange={versionRange} className='text-white' />
                </div>
                {getVersionTypeText(versionRange, version)}
              </div>
            )}
            {lifeCycle && (
              <div className='flex gap-2 items-center h-6'>
                <div className='w-3'>
                  <StatusDot lifeCycle={lifeCycle} size='sm' className='mx-auto' noTooltip />
                </div>
                <StatusDotLabel lifeCycle={lifeCycle} />
              </div>
            )}
            {
              isChangeOrderVersion && <div className='flex gap-2 items-center h-6 text-xs'>
                *Version in this Change Order
              </div>
            }
          </div>
        )}
      />
    </div>
  )
}
